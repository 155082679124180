import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCall } from "../../../../packages/components/src/ApiCall.web";
import dayjs from 'dayjs';
interface Course {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

interface CoursePackageAttributes {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  courses: Course[];
}

interface CoursePackage {
  id: string;
  type: string;
  attributes: CoursePackageAttributes;
}

interface CoursePackageData {
  data: CoursePackage[];
}

interface UpdatedPackageType {
  id?: string;
  name?: string;
  addCourse?: string[];
  removeCourse?: string[];
}

interface CourseSummary {
  image: string | null | undefined;
  id: string;
  title: string;
  description: string;
  duration: number;
  author_name: string | null;
  course_module_count: number;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  addPackageModal: boolean,
  createPackageModal: boolean,
  anchorElSort: any,
  currentSortKey: string,
  packageName: string,
  packages: CoursePackage[] | any,
  unFilteredpackages: CoursePackage[] | any,
  packageSearchQuery: string,
  userAnchorEl: any
  sortOrder:string,
  currentPackage: CoursePackage | null,
  updatedPackageName: string | undefined,
  updatePackageNameModal: boolean
  updatedPackage: UpdatedPackageType | null
  courseData: any[],
  courseSearchQuery: string,
  packageData: string[],
  updatePackageModal: boolean,
  deleteModal: boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  getPackageNamesApiCallId: string = "";
  updatePackageApiCallId: string = "";
  deletePackageApiCallId: string = "";
  clonePackageApiCallId: string = "";
  createPackageApiCallId: string = ""
  getCoursesNamesApiCallId: string = "";
  packageSearchQueryApiCallId: string = ""
  courseSearchQueryApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      addPackageModal: false,
      createPackageModal: false,
      anchorElSort: null,
      currentSortKey: "Relevance",
      sortOrder:'Relevance',
      packageName: "",
      packages: [],
      unFilteredpackages: [],
      packageSearchQuery: "",
      userAnchorEl: null,
      currentPackage: null,
      updatedPackageName: "",
      updatePackageNameModal: false,
      updatedPackage: null,
      courseData: [],
      courseSearchQuery: "",
      packageData: [],
      updatePackageModal: false,
      deleteModal: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getPackageNames()
    this.getCoursesName()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    this.handleResponse(message);
    // Customizable Area End
  }

  // Customizable Area Start

  handleResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    switch (apiRequestCallId) {
      case this.getPackageNamesApiCallId:
        this.handleGetPackageNameResponse(responseJson);
        break;

      case this.getCoursesNamesApiCallId:
        this.handleGetCoursesNamesResponse(responseJson);
        break;

      case this.packageSearchQueryApiCallId:
        this.handleGetPackageNameResponse(responseJson as CoursePackageData);
        break;
      case this.clonePackageApiCallId:
      case this.createPackageApiCallId:
      case this.updatePackageApiCallId:
        this.getPackageNames();
        this.handleClean()
        break;

      case this.deletePackageApiCallId:
        this.getPackageNames();
        this.handleClean()
        this.handleDeleteModalClose()
        break;


      default:
        break;
    }
  }

  handleGetPackageNameResponse = (response: any) => {
    this.setState({ packages: response.data, unFilteredpackages: [... response.data] })
  }

  handleGetCoursesNamesResponse = (response: any) => {
      this.setState({
        courseData: response.map((course: any) => ({
          id: course.id,
          title: course.title,
          description: course.description,
          duration: course.duration,
          status:course.status,
          author_name: course.author_name,
          image: course.images?.url,
          course_module_count: course.course_modules?.length
        }))
      })
  }

  sortKeys = [
    { key: 'nameAsc', label: 'Name (A-Z)' },
    { key: 'nameDesc', label: 'Name (Z-A)' },
    { key: 'relevance', label: 'Relevance' },
  ];

  handlePopoverClose = () => {
    this.setState({ userAnchorEl: null })
  }

  isSortPopoverOpen = () => Boolean(this.state.anchorElSort);

  isPackagePopoverOpen = () => Boolean(this.state.userAnchorEl);

  handlePackageOption = (event: React.MouseEvent<HTMLButtonElement>, user: CoursePackage) => {
    this.setState({
      userAnchorEl: event.currentTarget,
      currentPackage: user,
      updatedPackage: {
        id: user.id.toString(),
        name: user.attributes.name,
        addCourse: user.attributes.courses.map(c => c.id.toString()),
        removeCourse: [],
      },
    });
  };

  handleUpdateName = () => {
    this.openUpdatePackageNameModal()
    this.setState({
      updatedPackageName: this.state.currentPackage?.attributes.name
    })
  }

  handleUpdateCourse = () => {
    this.openUpdatePackageModal()
  }

  clonePackage = async () => {
    const clonedPackage = this.state.currentPackage;
    const body = {
      "course_package": {
        "name": `${clonedPackage?.attributes.name}(Clone)`,
        "course_package_courses_attributes": [
          { "course_id": clonedPackage?.attributes.courses.map(course => course.id) }
        ]
      }
    }

    if (clonedPackage) {
      this.clonePackageApiCallId = await apiCall({
        method: "POST",
        navigation: this.props.navigation,
        contentType: "application/json",
        token: localStorage.getItem("token"),
        body: body,
        endPoint: `/bx_block_content_management/course_packages`
      });
    }
  }

  createPackage = async () => {
    const response = {
      "course_package": {
        "name": this.state.packageName,
        "course_package_courses_attributes": [
          { "course_id": this.state.packageData }
        ]
      }
    }
    this.createPackageApiCallId = await apiCall({
      method: "POST",
      navigation: this.props.navigation,
      contentType: "application/json",
      token: localStorage.getItem("token"),
      body: response,
      endPoint: `/bx_block_content_management/course_packages`
    });
    this.closeCreatePackageModal();
    this.closeAddPackageModal()
  }

  handleDeleteModalClose = () => {
    this.setState({ deleteModal: false })
  }

  handleDeleteModalOpen = () => {
    this.setState({ deleteModal: true })
  }

  deletePackage = async () => {
    this.deletePackageApiCallId = await apiCall({
      method: "DELETE",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_content_management/course_packages/${this.state.currentPackage?.id}`,
    });
  }

  handlePopoverClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorElSort: event.currentTarget });
  };

  openAddPackageModal = () => {
    this.setState({ addPackageModal: true })
  }

  closeAddPackageModal = () => {
    this.setState({ addPackageModal: false, packageName: '' })
  }

  handlePackageName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ packageName: event.target.value })
  }

  closeCreatePackageModal = () => {
    this.setState({ createPackageModal: false, courseSearchQuery: "", packageName: "", packageData: [] })
  }

  addPackageTitle = () => {
    this.setState({ createPackageModal: true, addPackageModal: false })
  }


  getPackageNames = async () => {
    this.getPackageNamesApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_content_management/course_packages`,
    });
  }

  handleUpdatePackageName = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ updatedPackageName: event.target.value })
  }

  UpdatePackageTitle = () => {
    this.closeUpdatePackageNameModal()
    this.setState((prevState) => ({
      updatedPackage: {
        ...prevState.updatedPackage,
        name: prevState.updatedPackageName,
      }
    }), this.updatePackage);
  }

  closeUpdatePackageNameModal = () => {
    this.setState({
      updatePackageNameModal: false,
      packageSearchQuery: ""
    })
  }

  openUpdatePackageNameModal = () => {
    this.setState({
      updatePackageNameModal: true,
    }, () =>
      this.handlePopoverClose()
    )
  }

  updatePackage = async () => {
    const { updatedPackage } = this.state
    this.updatePackageApiCallId = await apiCall({
      method: "PUT",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      body: {
        "course_package": {
          "name": updatedPackage?.name,
          "add_course": updatedPackage?.addCourse,
          "remove_course": updatedPackage?.removeCourse
        }
      },
      endPoint: `bx_block_content_management/course_packages/${updatedPackage?.id}`,
    });
  }

  getCoursesName = async () => {
    let endPoint = `/bx_block_admin/courses/list_courses_and_learning_path`
    if (this.state.courseSearchQuery.length) {
      endPoint = endPoint + `?query=${this.state.courseSearchQuery}`
    }
    this.getCoursesNamesApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: endPoint
    });
  }

  handleCourseSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ courseSearchQuery: event.target.value }, async () => {
      this.getCoursesName();
    })
  }

  handlePackageSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ packageSearchQuery: event.target.value }, async () => {
      if (this.state.packageSearchQuery !== "") {
        this.packageSearchQueryApiCallId = await apiCall({
          method: "GET",
          navigation: this.props.navigation,
          token: localStorage.getItem("token"),
          endPoint: `/bx_block_content_management/course_packages/search_packages?query=${this.state.packageSearchQuery}`,
        })
      } else if (this.state.packageSearchQuery === "") {
        this.getPackageNames()
      }
    })
  }

  handleCourseSelection = (courseId: string) => {
    this.setState((prevState) => {
      const { packageData } = prevState;
      if (packageData.includes(courseId)) {
        return { packageData: packageData.filter((id) => id !== courseId) };
      } else {
        return { packageData: [...packageData, courseId] };
      }
    });
  };

  handleUpdateCourseSelection = (courseId: string) => {
    this.setState((prevState) => {
      const { updatedPackage } = prevState;

      if (!updatedPackage) {
        return {
          updatedPackage: {
            id: '',
            name: '',
            addCourse: [courseId],
            removeCourse: [],
          }
        };
      }

      const { addCourse = [], removeCourse = [] } = updatedPackage;

      if (addCourse.includes(courseId)) {
        return {
          updatedPackage: {
            ...updatedPackage,
            addCourse: addCourse.filter((id) => id !== courseId),
            removeCourse: [...removeCourse, courseId],
          }
        };
      } else {
        return {
          updatedPackage: {
            ...updatedPackage,
            addCourse: [...addCourse, courseId],
            removeCourse: removeCourse.filter((id) => id !== courseId),
          }
        };
      }
    });
  };

  handleDeselectAll = () => {
    this.setState({ packageData: [] });
  };

  handleSortOrderChange = (event: any) => {
    this.setState({ sortOrder: event.target.value }, this.updateFilteredPackages);
  };

  /* istanbul ignore next */
  updateFilteredPackages = () => {
    const { packages, sortOrder } = this.state;
    packages.sort((firstPackage: any, secondPackage: any) => {
      switch (sortOrder) {
        case "a-z":
          return firstPackage.attributes.name.localeCompare(secondPackage.attributes.name);
        case "z-a":
          return secondPackage.attributes.name.localeCompare(firstPackage.attributes.name);
        case "old-to-new":
          return dayjs(firstPackage.attributes.created_at).valueOf() - dayjs(secondPackage.attributes.created_at).valueOf();
        case "new-to-old":
          return dayjs(secondPackage.attributes.created_at).valueOf() - dayjs(firstPackage.attributes.created_at).valueOf();
        case 'low-to-high':
          return firstPackage.attributes.courses.length - secondPackage.attributes.courses.length;
        case 'high-to-low':
          return secondPackage.attributes.courses.length - firstPackage.attributes.courses.length;
        case "Relevance":
        default:
          return 0;
      }
    });

    if (sortOrder === "Relevance") {
      this.setState({ packages : [... this.state.unFilteredpackages] });    
    }else{
       this.setState({ packages });
    }
  };

  closeUpdatePackageModal = () => {
    this.setState({ updatePackageModal: false })
  }

  openUpdatePackageModal = () => {
    this.setState({ updatePackageModal: true })
  }

  handleClean = () => {
    this.setState({ updatedPackage: null, updatePackageModal: false, courseSearchQuery: "", packageSearchQuery: "", userAnchorEl: null })
  }
  // Customizable Area End
}
