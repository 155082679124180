import React from "react";
// Customizable Area Start
import {
  Container,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  TextField,
  FormControl,
  Modal,
  FormLabel,
  Typography,
  Box,
  IconButton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  InputAdornment,
  Pagination,
  Select,
  MenuItem,
  Popover,
  MenuList
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled
} from "@mui/material/styles";
import "@mui/styles";
const DatePicker = require("react-datepicker");

import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddIcon from '@mui/icons-material/Add';
import { KeyboardArrowDown, KeyboardArrowRight, Search, GroupAdd } from '@mui/icons-material';
import { CSVLink } from "react-csv";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
      contrastText: "#fff",
    },
  },
});

import CustomisableUserProfilesController, {
  Props,
  IField,
} from "./CustomisableUserProfilesController";
import { exportIcon, fileIcon, uploadIcon, uploadBtn } from "./assets";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}

// Customizable Area End

export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getFieldType = (field: IField) => {
    if (
      field.field_type === "integer" ||
      field.field_type === "number" ||
      field.field_type === "float"
    ) {
      return "number";
    }
    return "text";
  };

  DataTable = () => {

    const TabCellHeader = styled(TableCell)({
      fontFamily: 'Heebo-Bold', color: '#475569', fontWeight: 700, fontSize: '14px', backgroundColor: 'white', zIndex: 1
    })

    const TabCells = styled(TableCell)({
      fontFamily: 'Heebo-Regular', color: '#0F172A', fontWeight: 400, fontSize: '14px'
    })

    return (
      <TableContainer component={Box} style={{ width: '100%', maxHeight: '500px', overflowY:'scroll' }}>
        <style>
          {`
        .MuiTableCell-root{
          border="none"
        }
        `}
        </style>
        <Box style={{ width: '100%' }}>
          <Table aria-label="sticky table" stickyHeader>
            <TableHead>
              <TableRow>
                <TabCellHeaderOne>#</TabCellHeaderOne>
                <TabCellHeaderTwo style={{ whiteSpace: 'nowrap' }}>First and Last Name</TabCellHeaderTwo>
                <TabCellHeaderThree>Email</TabCellHeaderThree>
                <TabCellHeaderFour>Role</TabCellHeaderFour>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.csvData.map((user, index) => (
                <TableRow key={index}>
                  <TabCellsOne>{index + 1}</TabCellsOne>
                  <TabCellsTwo style={{ whiteSpace: 'nowrap' }}>{`${user.firstName} ${user.lastName}`}</TabCellsTwo>
                  <TabCellsThree>{user.email}</TabCellsThree>
                  <TabCellsFour>ADMIN</TabCellsFour>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    );
  };

  BulkUploadCsv = () => {
    const headers = [
      { label: "firstName", key: "firstName" },
      { label: "lastName", key: "lastName" },
      { label: "email", key: "email" },
      {label:"roleType",key:'roleType'}
    ];
    
    const data = [
      { firstName: "Ahmed", lastName: "Tomi", email: "ah@smthing.co.com",roleType:"ADMIN" },
      { firstName: "Raed", lastName: "Labes", email: "rl@smthing.co.com",roleType:"ADMIN" },
      { firstName: "Yezzi", lastName: "Min l3b", email: "ymin@cocococo.com",roleType:"ADMIN" }
    ];
    return (
      <Modal open={this.state.csvModal} onClose={this.handleModalClose} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container
          sx={{
            position: 'absolute',
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            boxShadow: "0px 8px 32px 0px #0000000F",
            width: '600px',
            padding: '24px',
            //height: "80vh",
            borderRadius: '8px 8px 32px 8px',
            backgroundColor: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            justifyContent: 'space-between',
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              CSV file upload
            </Typography>
            <IconButton data-testID="close-Modal" onClick={this.handleModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {this.state.isDoneClicked ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%"
              }}
            >
              {this.DataTable()}
              <Box
                sx={{
                  backgroundColor: this.state.isValid ? '#D1FAE5' : '#FEE2E2',
                  borderLeft: `4px solid ${this.state.isValid ? '#059669' : '#DC2626'}`,
                  padding: '10px',
                  borderRadius: '4px',
                  marginBottom: '16px',
                }}
              >
                <Typography
                  style={{
                    fontFamily: 'Heebo',
                    fontSize: '16px',
                    fontWeight: '600',
                    color: this.state.isValid ? '#059669' : '#DC2626',
                  }}
                >
                  {this.state.isValid
                    ? "All data validated, and ready for upload"
                    : `Error in rows`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px dashed #CBD5E1",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: '16px',
                  textAlign: "center",
                  padding: '24px',
                }}
                onDragOver={this.handleDragOver}
                onDrop={this.handleDrop}
                data-testID="dragFile"
                className="upload"
              >
                <img src={uploadIcon} alt=""/>
                <Typography
                  style={{
                    fontFamily: 'Heebo',
                    fontSize: '24px',
                    letterSpacing: '0.5%',
                    lineHeight: '32px',
                    fontWeight: '700',
                  }}>
                  Upload media
                </Typography>
                <Typography
                  style={{
                    fontFamily: 'Heebo',
                    fontSize: '16px',
                    color: '#334155',
                    lineHeight: '24px',
                    fontWeight: '400',
                  }}>
                  Drag and drop or add some files
                </Typography>
                <Button variant="text" component="label"
                  style={{
                    fontFamily: 'Heebo',
                    textTransform: 'none',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '700',
                    color: '#000000',
                    backgroundColor: '#F5F5F4',
                  }}>
                  Upload from your device
                  <input
                    type="file"
                    accept=".csv"
                    data-testID="fileInput"
                    onChange={this.handleFileChange}
                    style={{
                      position: 'absolute',
                      width: '1px',
                      height: '1px',
                      opacity: 0,
                      pointerEvents: 'none',
                    }}
                  />
                </Button>
                <Typography
                  style={{
                    fontFamily: 'Heebo',
                    fontSize: '12px',
                    lineHeight: '18px',
                    fontWeight: '400',
                    color: '#94A3B8',
                  }}>
                  Supported formats: CSV
                </Typography>
                <CSVLink style={{color:'rgb(51, 65, 85)',textDecoration:'underline',fontStyle:'italic'}} data={data} headers={headers} filename="admin_user.csv">
                  Download CSV template here!
                </CSVLink>
              </Box>

              {this.state.file && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="16px 12px 16px 16px"
                  border="1px solid #ccc"
                  borderRadius="8px"
                  gap="16px"
                >
                  <Box display="flex" gap="16px">
                    <img src={fileIcon} alt="" />
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2">{this.state.file.name}</Typography>
                      <Typography variant="caption" color="textSecondary">
                        {(this.state.file.size / 1024).toFixed(2)} KB
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              )}
            </>
          )}

          <Box display="flex" gap="16px" justifyContent="end" pt={'20px'} borderTop="1px solid #F0F0F0">
            {this.state.isDoneClicked ? (
              <>
                <Button variant="outlined"
                  style={{ color: 'black',padding:'16px', backgroundColor: '#F0F0F0', border: 'none',fontSize:'16px', height:'56px'}}
                  onClick={this.handleModalClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ color: 'white', backgroundColor: 'black',fontSize:'16px',padding:'16px',height:'56px' }}
                  disabled={!this.state.isValid}
                  onClick={this.handleCSVFileUpload}
                  data-testID="uploadValid-Btn"
                >
                  <img src={uploadBtn} style={{marginRight:'5px',width:'24px',height:'24px'}}/>
                  <div style={{height:'24px'}}>Upload</div>
                </Button>
              </>
            ) : (
              <>
                <Button variant="outlined"
                  style={{ color: 'black',padding:'16px 24px' ,backgroundColor: '#F0F0F0',fontSize:'16px', border: 'none',height:'56px' }}
                  onClick={this.handleModalClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ color: 'white', backgroundColor: 'black',padding:'16px 24px',fontSize:'16px',height:'56px' }}
                  disabled={!this.state.file}
                  onClick={this.handleDoneClick}
                  data-testID="done-btn"
                >
                  Done
                </Button>
              </>
            )}
          </Box>
        </Container>
      </Modal>
    )
  }

  AddNewUserModal = () => {
    const { newUserData } = this.state
    return (
      <Modal
        open={this.state.addNewUserModal}
        onClose={this.handleAddNewUserModalClose}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Container sx={{...webStyles.containerModal}}>
          <Box display="flex" justifyContent="space-between" alignItems="center" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '20px',
                letterSpacing: '0.5%',
                lineHeight: '28px',
                fontWeight: 700,
              }}>
              Add Admin
            </Typography>
            <IconButton data-testID="close-Modal" onClick={this.handleAddNewUserModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box height={"80%"} width={"100%"} display={'flex'} flexDirection={"column"} justifyContent={"space-between"} sx={{padding:'10px 16px'}}>
            <FormControl fullWidth sx={{padding:'24px 0px 0px 0px'}}>
              
            </FormControl>
            <Box sx={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>First Name</Typography>
              <TextField
                fullWidth
                placeholder="First name"
                value={newUserData.firstName}
                data-testID="addFirstName"
                onChange={this.handleUserDataChange("firstName")}
              />
            </Box>
            <Box sx={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>Last Name</Typography>
              <TextField
                fullWidth
                placeholder="Last name"
                data-testID="addLastName"
                value={newUserData.lastName}
                onChange={this.handleUserDataChange("lastName")}
              />
            </Box>
            <Box sx={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>Email</Typography>
              <TextField
                fullWidth
                placeholder="Email"
                type="email"
                value={newUserData.email}
                data-testID="addEmail"
                error={!!this.state.emailError}
                helperText={this.state.emailError}
                FormHelperTextProps={{
                  style: {fontSize:'14px',fontWeight:400}
                }}
                onChange={this.handleUserDataChange("email")}
              />
            </Box>
            <Box sx={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>Role</Typography>
              {/* <Typography style={webStyles.modalText}>Admin</Typography> */}
              <TextField
                fullWidth
                InputProps={{
                  sx: {
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none', 
                    },
                    '&:before': {
                      border: 'none',
                    },
                    '&:after': {
                      border: 'none',
                    },
                  },
                }}
                data-testID="userRole"
                disabled
                value={'Admin'}
              />
            </Box>
          </Box>

          <Box display="flex" justifySelf="end" gap="16px" paddingTop={'20px'} justifyContent="end" borderTop="1px solid #F0F0F0">
            <Button
              variant="outlined"
              style={{ color: 'black', backgroundColor: '#F0F0F0', border: 'none',height:'56px' }}
              onClick={this.handleAddNewUserModalClose}
            >
              <Typography sx={{ fontWeight: 700, lineHeight: '24px', fontSize: '16px',textTransform: 'none' }}>Cancel</Typography>
            </Button>
            <Button
              variant="contained"
              style={{ color: 'white', backgroundColor: 'black', fontWeight: 700, lineHeight: '24px', fontSize: '16px',height:'56px' }}
              onClick={this.handleAddNewUserDoneClick}
              data-testID="handleAddNewUserDoneClick"
            >
              <Typography sx={{ fontWeight: 700, lineHeight: '24px', fontSize: '16px' ,textTransform: 'none'}}>Done</Typography>

            </Button>
          </Box>
        </Container>
      </Modal>
    )
  }
  disableColor={
    height:'56px',
    color:'#94A3B8',
    '& .Mui-disabled':{
      WebkitTextFillColor:"#94A3B8 !important",
    }
  }
  ViewUserModal = () => {
    const { currentUser } = this.state
    
    return (
      <Modal
        open={this.state.viewUserModal}
        onClose={this.handleViewUserModalClose}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Container sx={{ ...webStyles.containerModal, justifyContent: 'normal' }}>
          <Box display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                letterSpacing: '0.5%',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '28px',
              }}>
              View Admin
            </Typography>
            <IconButton data-testID="closeViewModal" onClick={this.handleViewUserModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box height={"80%"} width={"100%"} display={'flex'} flexDirection={"column"} justifyContent={"space-between"} sx={{padding:'10px 16px'}}>
            <FormControl fullWidth sx={{padding:'24px 0px 0px 0px'}}>
              
            </FormControl>
            <Box style={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>First Name</Typography>
              <TextField disabled value={currentUser?.attributes.first_name} fullWidth sx={this.disableColor} />
            </Box>
            <Box style={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>Last Name</Typography>
              <TextField sx={this.disableColor} disabled value={currentUser?.attributes.last_name} fullWidth />
            </Box>
            
            <Box style={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>Email</Typography>
              <TextField disabled sx={this.disableColor} value={currentUser?.attributes.email} fullWidth />
            </Box>
            <Box style={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>Role</Typography>
              <TextField disabled sx={this.disableColor} value={'Admin'} fullWidth />
            </Box>
          </Box>

        </Container>
      </Modal>
    )
  }

  EditUserModal = () => {
    const { editUser } = this.state
    return (
      <Modal
        open={this.state.editUserModal}
        onClose={this.handleEditUserModalClose}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Container sx={{ ...webStyles.containerModal }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            borderBottom="1px solid #F0F0F0"
          >
            <Typography
              style={{
                fontSize: '20px',
                fontFamily: 'Heebo',
                lineHeight: '28px',
                letterSpacing: '0.5%',
                fontWeight: 700,
              }}
            >
              Edit Admin
            </Typography>
            <IconButton data-testID="closeEditModal" onClick={this.handleEditUserModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            height="80%"
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            sx={{padding:'10px 16px'}}         >
            <FormControl fullWidth sx={{padding:'24px 0px 0px 0px'}}>
              </FormControl>
            <Box sx={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>First Name</Typography>
              <TextField
                fullWidth
                data-testID="editFirstName"
                value={editUser.firstName}
                onChange={this.handleInputChange("firstName")}
              />
            </Box>
            <Box sx={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>Last Name</Typography>
              <TextField
                data-testID="editLastName"
                fullWidth
                value={editUser.lastName}
                onChange={this.handleInputChange("lastName")}
              />
            </Box>
            <Box>
              
            </Box>
            <Box sx={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>Email</Typography>
              <TextField
                data-testID="editEmail"
                fullWidth
                sx={this.disableColor}
                value={editUser.email}
                disabled
                // onChange={this.handleInputChange('email')}
              />
            </Box>
            <Box sx={webStyles.fieldContainer}>
              <Typography style={webStyles.modalText}>Role</Typography>
              {/* <TextField disabled sx={this.disableColor} value={'ADMIN'} fullWidth /> */}
              <TextField
                fullWidth
                sx={{border:'none'}}
                data-testID="userRole"
                disabled
                value={'Admin'}
              />
            </Box>

          </Box>

          <Box
            display="flex"
            justifySelf="end"
            gap="16px"
            paddingTop="20px"
            justifyContent="end"
            borderTop="1px solid #F0F0F0"
          >
            <Button
              variant="outlined"
              style={{ color: 'black', backgroundColor: '#F0F0F0', border: 'none',height:'56px' }}
              onClick={this.handleEditUserModalClose}
            >
              <Typography sx={{ fontWeight: 700, lineHeight: '24px', fontSize: '16px',textTransform: 'none' }}>Cancel</Typography>
              </Button>
            <Button
              variant="contained"
              style={{ color: 'white', backgroundColor: 'black' ,height:'56px'}}
              onClick={this.handleEditUserDoneClick}
              data-testID="editDoneBtn"
            >
              <Typography sx={{ fontWeight: 700, lineHeight: '24px', fontSize: '16px',textTransform: 'none' }}>Done</Typography>
              </Button>
          </Box>
        </Container>
      </Modal>
    )
  }

  DeleteModal = () => {
    const { currentUser } = this.state
    const deleteSingleString = `Are you sure you want to delete ${currentUser?.attributes.first_name} ${currentUser?.attributes.last_name}'s account?`
    const deleteMultipleString =`Are you sure you want to delete selected accounts?`
    return (
      <Modal open={this.state.deleteUserModal} onClose={this.handleDeleteUserModalClose}>

        <Container sx={{ ...webStyles.containerModal, width: '50vw',maxWidth:'600px !important' }}>
          <Box display={"flex"} gap={"10px"} flexDirection={"column"} justifyContent={'space-between'}>
            <Box display="flex" justifyContent="space-between" alignItems="center" >
              <Typography style={{ fontFamily: 'Heebo',flex:2, fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#0F172A' }} >
                {this.state.currentUser?.id ? deleteSingleString : deleteMultipleString}
              </Typography>
              <IconButton  data-testID="close-Modal" onClick={this.handleDeleteUserModalClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography style={{ fontFamily: 'Heebo', fontWeight: '400', fontSize: '20px', lineHeight: '32px', color: '#475569' }}>
              This action can't be undone and all the data stored in this account won't be accessible anymore.
            </Typography>
          </Box>

          <Box
            display="flex"
            justifySelf="end"
            paddingTop="20px"
            gap="16px"
            borderTop="1px solid #F0F0F0"
            justifyContent="end"
          >
            <Button
              variant="outlined"
              style={{
                color: 'black', backgroundColor: '#F0F0F0', border: 'none',
                padding: "10px 35px",
                textTransform: "none",
                borderRadius: '10px',
                fontSize: "16px",
                fontWeight: "700"
              }}
              onClick={this.handleDeleteUserModalClose}
            >
              No
            </Button>
            <Button
              variant="contained"
              style={{
                color: 'white', backgroundColor: 'black', padding: "10px 35px",
                textTransform: "none",
                borderRadius: '10px',
                fontSize: "16px",
                fontWeight: "700"
              }}
              onClick={() => this.state.currentUser ?  this.handleDeleteUser(this.state.currentUser?.id ?? "") : this.handleBulkDelete() }
              data-testID="deleteBtn"
            >
              Yes
            </Button>
          </Box>
        </Container>
      </Modal>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Wrapper>
          <Box width={"100%"} display={"flex"} >
            <Box >
              <Sidebar navigation={this.props.navigation} current='user_management' />
            </Box>
            <Box display={"flex"} padding={"30px"} width={"80%"}flexDirection={"column"} gap={"20px"} overflow={'scroll'}>
              <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                  <Typography style={{ fontFamily: 'Heebo', fontWeight: '400', fontSize: '24px', lineHeight: '32px', color: '#475569' }}>Admin User Management</Typography>
                  <KeyboardArrowRight />
                  <Typography style={{ fontFamily: 'Heebo', fontSize: '24px', fontWeight: '700', lineHeight: '32px', color: '#0F172A' }} >Admin</Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"} gap={"8px"}>
                  <Button
                    sx={{ fontFamily: 'Heebo', fontSize: '16px', textTransform: 'none', fontWeight: '400', color: '#000000', padding: '10px 16px', display: 'flex', gap: '8px' }}
                    data-testID="export-btn" onClick={()=>this.exportCSV()}>
                    <img src={exportIcon} alt="" />
                    <span>
                      Export
                    </span>
                  </Button>
                  <Button
                    sx={{ fontFamily: 'Heebo', fontSize: '16px', textTransform: 'none', fontWeight: '400', color: '#000000', padding: '10px 16px', borderRadius: '8px', border: '1px solid black' }}
                    data-testID="upload-btn" onClick={()=>this.handleModalOpen()}>
                    <IconButton
                      style={{
                        marginRight:'8px',
                        borderRadius: '50%',
                        borderWidth:'1px',
                        borderColor:'#1C1C1C',
                        width: '30px',
                        height: '30px',
                        position: 'relative',
                        color:'#1C1C1C',
                        border: '2.5px solid #1C1C1C',
                      }}
                      aria-label="add"
                    >
                      <AddIcon style={{color: '#1C1C1C'}} />
                    </IconButton>
                    Bulk upload CSV
                  </Button>
                  <AddNewUserButton
                    startIcon={<GroupAdd />}
                    onClick={this.handleAddNewUserModalOpen}
                    data-testID="AddNewUser"
                  >Add new user
                  </AddNewUserButton>
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%'
              }} >
               
                <Box style={{width:'70%'}}>
                  <TextField
                    variant="outlined"
                    placeholder="Search by user"
                    style={{
                      borderRadius: '4px',
                      border: "#E7E7E7 1px solid"
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                          height: '40px', 
                          '& fieldset': {
                              borderRadius: '4px',
                          },
                          '& input': {
                              padding: '4px 8px',
                          },
                      },
                  }}
                    value={this.state.searchQuery}
                    onChange={this.handleSearchQuery}
                    data-testID="searchQuery"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                </Box>
                <Box display={'flex'} gap={"5px"} alignItems={'center'}>
                  <Box>Sort by :</Box>
                  <Box
                    data-testID='btnSort'
                    onClick={this.handlePopoverClick}
                    style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
                  >
                    {this.state.currentSortKey}
                    <KeyboardArrowDown />
                  </Box>

                  <Popover
                    data-test-id='btnPopover'
                    open={this.isSortPopoverOpen()}
                    onClose={this.handlePopoverClose}
                    anchorEl={this.state.anchorElSort}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    style={{ boxShadow: 'none' }}
                  >
                    {this.sortKeys.map((item, index) => (
                      <MenuItem
                        key={'sortId' + index}
                        data-testID={`btnOptions${index}`}
                        onClick={() => this.handleSort(item)}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Popover>
                </Box>
              </Box>

              {
                this.state.deleteArr.length > 0 &&
                <Box display={"flex"} width={"25%"} alignItems={"center"}>
                  <Custombox
                    checked={this.state.deleteArr.length > 0}
                    data-testID="deselectAll"
                    onChange={this.handleDeselectAll}
                  />
                    <Typography sx={{margin:'0px 16px 0px 8px'}}>
                      {this.state.deleteArr.length}{" "}
                      {this.state.deleteArr.length === 1 ? "item" : "items"} selected
                    </Typography>
                  <Button
                  style={{
                    fontFamily:'Heebo',
                    fontWeight:'700',
                    textTransform:'none',
                    lineHeight:'24px',
                    color:'#DC2626',
                    border:"#F87171 1px solid",
                    borderRadius:'4px',
                    padding:'8px',
                  }} 
                  onClick={this.handleDeleteUserModalOpen}
                  data-testID="bulkDelete"
                  >
                    Delete
                  </Button>
                </Box>
              }
              <Box>
                {this.state.userData.length > 0 ? 
                <TableContainer component={Box} style={{ width: '100%', maxHeight: '100%', overflowY: 'scroll' }}>
                  <style>
                    {`
                    .MuiTableCell-root{
                      border="none"
                    }
                    `}
                      </style>
                      <Box style={{ width: '100%', overflowX: 'hidden' }}>
                        <Table aria-label="sticky table" stickyHeader>
                          <TabCellHeader>
                            <CustomCheckbox
                              data-test-id={'select-all-check'}
                              checked={this.state.isSelectAll}
                              onClick={() => {
                                this.handleSelectAllCheckboxToggle();
                              }}
                              sx={{
                                '&.Mui-checked': {
                                  color: 'black',
                                  borderRadius: '6px',
                                  '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                  },
                                },
                                "padding-left": "revert"
                              }}
                            />
                          </TabCellHeader>
                          <TabCellHeader>First and Last Name</TabCellHeader>
                          <TabCellHeader>Email</TabCellHeader>
                          <TabCellHeader>Role</TabCellHeader>
                          <TabCellHeader>Date Active</TabCellHeader>
                          <TabCellHeader></TabCellHeader>
                      <TableBody>
                        {this.state.userData.map((user, i) => (
                          <TableRow 
                          key={i}
                          style={{
                            backgroundColor: this.isUserSelected(user.id)  ? '#f8fafc' : 'white',
                            maxHeight:'57px'
                          }}
                          >
                            <TableCell sx={{padding:'6',margin:'0'}}>
                              <CustomCheckbox
                                checked={this.isUserSelected(user.id)}
                                onChange={() => { this.handleCheckboxChange(user.id) }}
                                color="info"
                                data-testID={`checkbox${i}`}
                              />
                            </TableCell>
                            <TabCells sx={this.isUserSelected(user.id) ? webStyles.selectedContent :{}} style={{ whiteSpace: 'nowrap'}}>{`${user.attributes.first_name} ${user.attributes.last_name}`}</TabCells>
                            <TabCells sx={this.isUserSelected(user.id) ? webStyles.selectedContent :{}}>{user.attributes.email}</TabCells>
                            <RoleTabCell>
                              <Typography sx={webStyles.adminContainer}>ADMIN</Typography>
                            </RoleTabCell>
                            <TabCells sx={this.isUserSelected(user.id) ? webStyles.selectedContent :{}}>{this.convertDate(user.attributes.created_at)}</TabCells>
                            <TabCells>
                              <IconButton data-testID="userOptions" onClick={(event) => this.handleUserOptionClick(event, user)}>
                                <MoreVertIcon />
                              </IconButton>
                              <Box>
                                <Popover
                                  sx={{
                                    "& .MuiPaper-root": {
                                      boxShadow: "0px 2px 8px 0px #0000000F",
                                      borderRadius: "4px", 
                                      width:'200px'
                                    },
                                  }}
                                  onClose={this.handlePopoverClose}
                                  anchorEl={this.state.userAnchorEl}
                                  open={this.handleCloseUserOption()}
                                  anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'left',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}
                                >
                                  <MenuList>
                                    <MenuItem data-testID="viewModal" onClick={this.handleViewUserModalOpen}>View</MenuItem>
                                    <MenuItem data-testID="editModal" onClick={this.handleEditUserModalOpen}>Edit</MenuItem>
                                    <MenuItem sx={{color:'#DC2626'}} data-testID="deleteModal" onClick={this.handleDeleteUserModalOpen}>Delete</MenuItem>
                                  </MenuList>
                                </Popover>
                                
                              </Box>
                            </TabCells>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                </TableContainer>
                : 
                <Box display={"flex"} justifyContent={"center"}>
                  No record found.
                </Box>
                }

              </Box>
              <Box sx={webStyles.paginationContainer}>
                <Pagination
                  count={this.state.totalPages}
                  page={this.state.currentPage}
                  onChange={this.handlePageChange}
                  shape="rounded"
                  data-testID="pageChange"
                />
              </Box>
            </Box>
            {this.BulkUploadCsv()}
            {this.AddNewUserModal()}
            {this.EditUserModal()}
            {this.DeleteModal()}
            {this.ViewUserModal()}
          </Box>
          </Wrapper>
          <AdminFooter/>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Wrapper =  styled(Box)({
  height: "calc(100vh - 72px)",
  display: 'flex',
  width:'100%',
  flexDirection: 'row',
  backgroundColor:'#fafafa',
  "& .right-section": {
      width: "100%",
      overflow: "auto",
      display: "flex",
      flexDirection: "column",
      padding: "25px 20px 25px 10px",
  },
  "& .bread-cum": {
      cursor: "pointer",
      fontFamily: 'Heebo-Regular',
      fontWeight: '400', 
      fontSize: '24px', 
      lineHeight: '32px',
      color: '#64748B',
      "&.user": {
          cursor: "default",
          fontFamily: 'Heebo-Bold',
          color: '#0F172A',
      }
  }
});

const AddNewUserButton = styled(Button)({
  fontFamily: 'Heebo',
  fontSize: '16px',
  textTransform: 'none',
  fontWeight: '400',
  color: '#fff',
  padding: '10px 16px',
  borderRadius: '8px',
  backgroundColor: '#000000',
  "&:hover": {
    backgroundColor: "black",
  },
});

const TabCellHeader = styled(TableCell)({
  fontFamily: 'Heebo-Bold',
  color: '#475569',
  fontWeight: 700,
  fontSize: '14px',
  backgroundColor: 'white', zIndex: 1
})



const TabCells = styled(TableCell)({
  fontFamily: 'Heebo-Regular',
  color: '#0F172A',
  fontWeight: 400,
  fontSize: '14px',
  padding:'16px'
})

const TabCellsOne = styled(TableCell)({
  fontWeight: 400,
  fontSize: '14px',
  fontFamily: 'Heebo-Regular',
  color: '#0F172A',
  padding:'0px'
})

const TabCellHeaderOne = styled(TableCell)({
  fontFamily: 'Heebo-Bold',
  color: '#475569',
  fontWeight: 700,
  fontSize: '14px',
  backgroundColor: 'white', zIndex: 1
})
const TabCellsTwo = styled(TableCell)({
  fontWeight: 400,
  color: '#0F172A',
  fontSize: '14px',
  fontFamily: 'Heebo-Regular',
  padding:'0px'
})

const RoleTabCell = styled(TableCell)({
  fontWeight: 700, lineHeight: '18px'
})

const TabCellsThree = styled(TableCell)({
  fontWeight: 400,
  fontSize: '14px',
  fontFamily: 'Heebo-Regular',
  color: '#0F172A',
  padding:'0px'
})

const TabCellHeaderTwo = styled(TableCell)({
  fontFamily: 'Heebo-Bold',
  color: '#475569',
  fontSize: '14px',
  backgroundColor: 'white', zIndex: 1,
  fontWeight: 700,
})

const TabCellsFour = styled(TableCell)({
  fontFamily: 'Heebo-Regular',
  color: '#0F172A',
  padding:'0px',
  fontWeight: 400,
  fontSize: '14px',
})

const CustomCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    width: '20px',
    backgroundColor: 'white',
    height: '20px',
    borderRadius: '6px',
    border: '1px solid black',
  },
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      borderColor: 'black',
      backgroundColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      d: 'path("M5 12l5 5L20 7")',
      strokeWidth: 2,
      stroke: 'white',
      fill: 'none',
    },
  },
});

const TabCellHeaderThree = styled(TableCell)({
  fontSize: '14px',
  fontFamily: 'Heebo-Bold',
  color: '#475569',
  backgroundColor: 'white', zIndex: 1,
  fontWeight: 700,
})

const Custombox = styled(Checkbox)({
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '& .MuiSvgIcon-root': {
    width: '20px',
    height: '20px',
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '6px',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      borderColor: 'black',
      backgroundColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      d: 'path("M6 12h12")',
      strokeWidth: 2,
      stroke: 'white',
      fill: 'none',
    },
  },
});

const TabCellHeaderFour = styled(TableCell)({
  color: '#475569',
  backgroundColor: 'white', zIndex: 1,
  fontSize: '14px',
  fontFamily: 'Heebo-Bold',
  fontWeight: 700,
})

const webStyles = {
  containerModal: {
    position: 'absolute',
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 8px 32px 0px #0000000F",
    width: '600px',
    padding: '10px 24px 24px 24px',
    borderRadius: '8px 8px 8px 8px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    //gap: '20px',
    justifyContent: 'space-between',
  },
  fieldContainer: {
    paddingBottom: '16px',
    marginRight: '30px',
  },
  modalText: {
    font: 'Heebo',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '22px',
    color: '#334155'
  },
  adminContainer: {
    fontFamily: 'Heebo',
    padding: '2px 8px',
    color: '#ffffff',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight:'18px',
    borderRadius: '4px',
    backgroundColor: '#000000',
    width: '55px',
    fontWeight: 700
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center'
  },
  cellWrapper: {
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px'
  },
  selectedContent: {
    fontFamily: 'Heebo',
    fontSize: '14px',
    lineHeight:'22px',
    fontWeight:700
  }
}
// Customizable Area End
