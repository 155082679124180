import React from "react";
// ...

// Customizable Area Start

import {
    Modal,
    MenuItem,
    Box,
    Button,
    IconButton,
    Typography,
    TextField,
    styled,
    Select as MuiSelect,
    InputAdornment,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Popover,Grid,Dialog, DialogContent, DialogActions, DialogTitle,
    InputBase, Checkbox,Card,CardContent,Container,Chip,CircularProgress,ThemeProvider
} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {ExpandMore,Search,AddCircleOutline ,MoreVert,KeyboardArrowDown} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from "yup";
import HtmlReactParser from 'html-react-parser';
import { myLearningCourseImg} from "../../dashboard/src/assets";
import {  timer } from "./assets";
import DOMPurify from 'dompurify';
import InfiniteScroll from 'react-infinite-scroller';
// Customizable Area End

import AdminContentManagementController, {
    Props,
} from "./AdminContentManagementController";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";

export default class AdminContentManagement extends AdminContentManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.loadItems = this.loadItems.bind(this);
        // Customizable Area End
    }

    // Customizable Area Start
  renderHTML = (data: any) => {
    return data.includes('<p') ? HtmlReactParser(data.replaceAll('<p>', '').replaceAll('</p>', '')) : data;
  }

  learningPathSchema = () => {
    return Yup.object().shape({
      title: Yup.string().required("*Title is required"),
      description: Yup.string().required("*Description is required"),
    });
  }

  renderCreateLearningPathMsg = () => {
    return (
      <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: '180px' }}><Box><Typography sx={webStyles.grayText}>You don’t have any learning path</Typography>
      </Box>
        <Box data-test-id="createLearningPath" onClick={() => this.openCreateLearningPathModal()}
          sx={webStyles.createPathContainer}><Typography sx={webStyles.createPathText}>Create New Learning Path</Typography></Box></Box>
    )
  }

  renderLearningPathDialog = () => {
    return (
      <CustomDialog
        open={this.state.isCreateLearningPathModalOpen}
        onClose={this.closeCreateLearningPathModal}
      >
        <DialogTitle className="dialog-title">
          <Typography className="heading-title">{this.modalTitle()}</Typography>
          <CloseIcon className="close-icon" data-test-id="close-btn" onClick={this.closeCreateLearningPathModal} />
        </DialogTitle>
        <DialogContent className="content">

          <Box mt="10px">
            <Typography className="label-title">Title of Learning Path</Typography>
            <CustomInputBase
              fullWidth
              data-test-id="title"
              error={!!this.state.titleError.length}
              value={this.state.title}
              placeholder="Write title here"
              onChange={this.onChangeTitle}
            />
            {this.state.titleError &&
              <div className="text-danger">
                <Typography sx={{fontSize:'14px',color:'#FC5555'}}>{this.state.titleError}</Typography>
              </div>
            }
          </Box>
          <Box mt="15px">
            <Typography className="label-title">Description</Typography>
            <CustomInputBase
              rows={5}
              fullWidth
              multiline={true}
              data-test-id="description"
              value={this.state.description}
              placeholder="Add description about learning path"
              onChange={(e: { target: { value: string; }; }) => {
                this.setState({ description: e.target.value });
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            data-test-id="cancel-btn"
            onClick={this.closeCreateLearningPathModal}
          >
            Cancel
          </CancelButton>
          <DoneButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            disabled={!!this.state.titleError.length}
            type="submit"
            data-test-id="saveBtntn"
            onClick={() => {this.state.updatedPackage ? this.updateLearningPath(true) : this.createLearningPath() }}
          >
            {this.submitText()}
          </DoneButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  renderDeletePathDialog = () => {
    return (
      <CustomDialog
        open={this.state.isDeleteLearningPathModalOpen}
        onClose={() => this.onDeletePathConfirm(false)}
      >
        <Box sx={{ display: 'flex', justifySelf: 'flex-end', justifyContent: 'flex-end', padding: '8px' }}> <CloseIcon className="close-icon" data-test-id="close-btn" onClick={() => this.onDeletePathConfirm(false)} /></Box>
        <DialogContent className="content">
          <Typography sx={{
            fontFamily: 'Heebo',
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '32px',
            letterSpacing: '-0.005em',
            textAlign: 'left'
          }}
          >{`Are you sure you want to delete ` + this.state.currentPackage?.attributes.title + `?`}</Typography>
          <Typography sx={{
            fontFamily: 'Heebo',
            fontSize: '18px',
            fontWeight: '400',
            lineHeight: '26px',
            textAlign: 'left',
            marginTop: '16px'
          }}>This action is irreversible, and you will lose the data permanently without any chance of recovery.</Typography>
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            data-test-id="cancel-btn"
            onClick={() => this.onDeletePathConfirm(false)}
          >
            Cancel
          </CancelButton>
          <DeleteButton
            sx={{
              padding: "8px 40px !important",
              borderRadius: "4px !important",
              width: "fit-content",
            }}
            data-test-id="save-btn"
            onClick={() => this.onDeletePathConfirm(true)}
          >
            Delete
          </DeleteButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  buttonText = (courseData:any) => {
    if (!courseData.length) {
      return 'Create New Course'
    } else if (this.state.isCreatingLearningPath) {
      return 'Create Learning Path'
    } else {
      return 'Assign Course'
    }
  }

  loadItems = (page: number, courseData: string | any[])  => {
    const { itemsPerPage } = this.state;
    const newItems = courseData.slice(
      page * itemsPerPage,
      (page + 1) * itemsPerPage
    );
    if (newItems.length) {
      this.setState(prevState => ({
        pageCourseData: [...prevState.pageCourseData, ...newItems],
        page: prevState.page + 1
      }));
    } else {
      this.setState({ hasMore: false });
    }
  }

  renderUpdatePackageModal = () => {
    const courseData: any = this.state.isCloning ? this.state.currentPackage.attributes.courses : this.state.courseData;
    const submitText = this.buttonText(courseData);
    const isLoadingCourses = this.state.loading && !courseData.length
    const isLoadingFullCourses = this.state.courseLoading;
    const noCoursesAvailable = !courseData.length && !this.state.loading;
    const finalResultData = !this.state.courseSearchQuery.trim() ? this.state.pageCourseData : courseData;

   
    return (
      <Modal open={this.state.updatePackageModal} onClose={this.closeUpdatePackageModal} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container sx={webStyles.container} key={this.state.updatedPackage?.addCourse || 'pkg_modal'}>
          <Box display="flex" justifyContent="space-between" alignItems="center" padding="10px 0" borderBottom="1px solid #F0F0F0">
            <Typography
              style={{
                fontFamily: 'Heebo',
                fontSize: '24px',
                letterSpacing: '0.5%',
                lineHeight: '32px',
                fontWeight: '700',
              }}>
              {this.state.isCloning ? 'Assign Courses to Learning Path' : 'Assign Courses'}
            </Typography>
            <IconButton data-testID="closeUpdatePackageModal" onClick={this.closeUpdatePackageModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          {!this.state.isCloning && <Box>
            <TextField
              data-test-id='courseSearch'
              variant="outlined"
              placeholder="Search all courses(Please enter minimum 3 characters)"
              style={{
                borderRadius: '4px',
                border: "#E7E7E7 1px solid"
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: '40px',
                  '& fieldset': {
                    borderRadius: '4px',
                  },
                  '& input': {
                    padding: '4px 8px',
                  },
                },
              }}
              value={this.state.courseSearchQuery}
              onChange={(e: any) => this.handleCourseSearchQuery(e)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>}
          <Box
            width="100%"
            padding={2}
            sx={{
              height: '100%',
              overflowY: 'scroll',
              boxSizing: 'border-box'
            }}
          >
            {this.state.isCloning && <Grid container>
              <Grid item xs={12}>
                <Box padding="10px 5px 32px 5px">
                  <Typography className="label-title">Select learning path</Typography>
                  <MuiSelect
                    data-testid="sort-select"
                    value={this.state.selectedLearningPathToAssign}
                    disableUnderline
                    variant='standard'
                    sx={{
                      width: '95%',
                      backgroundColor: 'transparent',
                      border: '1px solid #ccc',
                      padding: '5px',
                      borderRadius: '5px',
                      '& .MuiOutlinedInput-root': {
                        backgroundColor: 'transparent',
                        border: '1px solid #ccc',
                        '& input': {
                          backgroundColor: 'transparent',
                          border: '1px solid #ccc',
                        },
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 300,
                          overflowY: 'auto',
                        },
                      },
                    }}
                    IconComponent={ExpandMore}
                  >
                    {this.state.learningPathDropdown.map((path) => (
                      <MenuItem value={path.value} key={path.value} data-test-id='dropdownSelect' onClick={() => this.updateDropdownSelected(path)}>{path.label}</MenuItem>
                    ))
                    }
                  </MuiSelect>
                </Box>
              </Grid>
            </Grid>}
            {isLoadingFullCourses && this.renderLoader()}
            {courseData.length>0 && <InfiniteScroll
                        pageStart={0}
                        loadMore={(page) => this.loadItems(page, courseData)}
                        hasMore={this.state.hasMore}
                        loader={<div className="loader" key={0}>Loading ...</div>}
                        useWindow={false}
                        data-test-id="infinite-scroll"
                      >
            <Grid container spacing={4} justifyContent="center">
              {isLoadingCourses && this.renderLoader()}
              {noCoursesAvailable && <Box sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column', marginTop: '180px' }}>
                <Box><Typography sx={webStyles.grayText}>You don’t have any Courses</Typography>
                </Box></Box>}
                
              {finalResultData.map((course: any, index: number) => {
                const courseImage = course.images?.url || myLearningCourseImg;
                return (
                  <Grid item
                    padding={"10px"}
                    md={4}
                    key={course.id} style={{ width: '300px', height: '350px' }}>
                    {this.renderCard(course, courseImage, index,'19324u328', true)}
                  </Grid>
                )
              }
              )}
            </Grid>
            </InfiniteScroll>}
          </Box>
          <Box width={"100%"} display={"flex"} justifyContent={"end"}>
            <CancelButton data-testID="closeUpdatePackageModal" onClick={this.closeUpdatePackageModal}>{(this.state.isCreatingLearningPath && !courseData.length) ? 'Skip & Create' : 'Cancel'}</CancelButton>
            <DoneButton data-testID="updatePath" onClick={() => {this.onSubmitLearningPath(courseData)}}>{submitText}</DoneButton>
          </Box>
        </Container>
      </Modal>
    )
  }

  sanitizeData = (htmlString: string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  };

  renderCard = (course: any, courseImage: any, index: number,learningPathId:string, showCheckBox: boolean = false) => {
    return <Card sx={webStyles.courseCard}>
      <Box position="relative">
        <Box
          style={{
            position: 'relative',
            width: '100%',
            height: '160px',
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${courseImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          p={1}
        >
          <Box display="flex" justifyContent="space-between" alignItems={"center"} padding={"8px"}>
            <Chip style={{ borderRadius: '4px', backgroundColor: course.status === 'published' ? '#1C1C1C' : '#1C1C1C66' }} label={course.status?.toUpperCase() || 'DRAFT'} color="primary" />
            {showCheckBox ? <CustomCheckbox
              data-testID={`checks${index}`}
              checked={
                this.state.updatedPackage?.addCourse?.includes(course.id.toString()) ?? false
              }
              onChange={() => this.handleUpdateCourseSelection(course.id)}
            /> : <>
              <IconButton key={'btn-more-course' + course.id} data-test-id='btnMoreCourses' onClick={(e) => this.handleCoursePopoverClick(e, course)}
                sx={{
                  borderRadius: '4px',
                  width: 32,
                  height: 32,
                  backgroundColor: '#1C1C1C66',
                  marginRight: '12px',
                  color: '#FFFFFF'
                }}
              >
                <MoreHorizIcon />
              </IconButton>
              <Popover
                data-test-id='btnPopoverCourse'
                key={'more_popover_course' + course.id}
                id={course.id}
                open={this.state.showCourseActionPopup && this.state.selectedCourse.id == course.id}
                anchorEl={this.state.anchorElCourse}
                onClose={() => this.handleCoursePopoverClose()}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                  },
                }}
              >
                <Typography sx={{ p: 2 }}>
                  {course.status!=='published' && <MenuItem key={'action_a'+ course.id + learningPathId} data-test-id='btnCoursePublish' onClick={() => this.handlePublishCourse(course)}>Publish Course</MenuItem>}
                  <MenuItem key={'action_b'+ course.id + learningPathId} data-test-id='btnCourseUpdate' onClick={() => {this.handleUpdateCourse(course.id)}}>Update Course</MenuItem>
                  <MenuItem key={'action_c'+ course.id + learningPathId} data-test-id='btnCourseClone' onClick={() => this.handleCloneCourse()}>Clone</MenuItem>
                  <MenuItem key={'action_c'+ course.id + learningPathId} data-test-id='btnCourseDelete' onClick={this.handleOpenDeleteModal}><Box sx={{ color: '#DC2626' }}>Delete Course</Box></MenuItem>
                  <CustomDialog
                    open={this.state.isDeleteCourseModalOpen}
                    onClose={this.handleCloseDeleteModal}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
                      <CloseIcon
                        className="close-icon"
                        data-test-id="close-btn1"
                        onClick={() => {
                          this.handleCloseDeleteModal(); 
                        }}
                      />
                    </Box>

                    <DialogContent className="content">
                      <Typography
                        sx={{
                          fontFamily: 'Heebo',
                          fontSize: '24px',
                          fontWeight: '700',
                          lineHeight: '32px',
                          letterSpacing: '-0.005em',
                          textAlign: 'left',
                        }}
                      >
                        {`Are you sure you want to delete this course? This action cannot be undone.`}
                      </Typography>
                    </DialogContent>

                    <DialogActions className="dialog-action">
                      <CancelButton
                        sx={{ padding: '8px 40px !important', borderRadius: '4px !important', width: 'fit-content' }}
                        data-test-id="close-btn2"
                        onClick={() => {
                          this.handleDeleteCourse(false);
                          this.handleCloseDeleteModal();
                        }}
                      >
                        Cancel
                      </CancelButton>

                      <DeleteButton
                        sx={{ padding: '8px 40px !important', borderRadius: '4px !important', width: 'fit-content' }}
                        data-test-id="delete-btn1"
                        onClick={() => {
                          this.handleDeleteCourse(true);
                        }}
                      >
                        Confirm
                      </DeleteButton>
                    </DialogActions>
                  </CustomDialog>
                </Typography>
              </Popover>
            </>}
          </Box>
          <Box display={"flex"} padding={"5px"} alignItems={"center"}>
            <img src={timer} height={"16px"} width={"16px"} alt="" />
            <Typography color={"white"} margin={"10px"}>
              {course.duration} Hours
            </Typography>
          </Box>
        </Box>
      </Box>
      <CardContent>
        <Typography variant="h6" component="div" sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          fontFamily: 'Heebo',
          fontSize: '16px',
          fontWeight: '500',
          WebkitLineClamp: 2,
        }}>
          {this.sanitizeData(course.title)}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{
          fontFamily: 'Heebo',
          fontWeight: '400',
          fontSize: '12px'
        }}>
          {course.lessons} Lessons
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: 3,
          fontFamily: 'Heebo',
          fontWeight: '500',
          fontSize: '14px'
        }}>
          {this.sanitizeData(course.description)}
        </Typography>
      </CardContent>
    </Card>
  }

  renderLoader = () => {
    return <Box sx={{ width: '100%', marginTop: '150px' }} display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <CircularProgress
        style={{
          height: '80px',
          width: '80px',
          boxShadow: 'inset 0 0 1px 1px #D9d9d9',
          borderRadius: '50%'
        }}
        sx={{ 'svg circle': { stroke: '#1c1c1c' } }}
      />
    </Box>
  }


toggleNumberInArray = (inputArray:any, inputNumber:number)  => {
  const index = inputArray.indexOf(+inputNumber);

  if (index === -1) {
    // Number is not in the array, so add it
    inputArray.push(+inputNumber);
  } else {
    // Number is already in the array, so remove it
    inputArray.splice(index, 1);
  }

  return inputArray;
}
  // Toggle the accordion's expanded state
  handleAccordionChange = (id: any) => {
    let newExpandedArray = this.toggleNumberInArray(this.state.expanded, id);
    this.setState({expanded: newExpandedArray})
  };
 
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
          <ThemeProvider theme={{}}>
            <Box sx={webStyles.mainWrapper}>
              <Box sx={webStyles.mainBlock}>
                <Box>
                  <Sidebar navigation={this.props.navigation} current='courses' />
                </Box>
                <Box sx={webStyles.pageContent} data-testid="pageContent">
                  <Box sx={webStyles.contentHeader}>
                    <Box sx={webStyles.breadcrumbButton}>Courses

                    </Box>
                    <Box>
                      <Button
                        style={{ color: '#ffffff', padding: '15px', minWidth: '250px', display: 'flex', borderRadius: '4px 0px 0px 0px', opacity: '0px', gap: '8px', backgroundColor: '#1C1C1C', fontWeight: '700', fontFamily: 'Inter', fontSize: '16px', lineHeight: '24px', textTransform: "none" }}
                        startIcon={<AddCircleOutline />}
                        onClick={() => this.navigateToCourseCreation()}
                        data-test-id="createPackageBtn"
                      >
                        Create New Course
                      </Button>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection={'row'} alignItems="center" justifyContent="space-between" bgcolor="#f5f5f5" sx={{ paddingBottom: '20px', height: '56px' }}>
                    <Box sx={webStyles.searchBox}>
                      <TextField
                        InputProps={{
                          sx: {
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none',
                              height: '100%',
                            },
                            '& .MuiOutlinedInput-root': {
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                            },
                            '& input': {
                              paddingLeft: '16px',
                              lineHeight: '24px',
                              fontSize: '16px',
                            },
                            backgroundColor: 'white'
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        placeholder="Search learning path, course title, course description"
                        data-test-id='learningPathSearch'
                        sx={{ width: "100%", height: "100%", marginLeft: "4px" }}
                        value={this.state.learningPathSearchQuery}
                        onChange={this.handleLearningPathSearchQuery} /></Box>
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                      <div style={{ display: 'flex', alignItems: 'center', margin: '0px,25px 0px 25px' }}>
                        <Typography>Filter:</Typography>
                        <MuiSelect
                          data-test-id="filter-select"
                          value={this.state.currentFilter}
                          onChange={(e: any) => this.handleFilterChange(e)}
                          defaultValue="relevance"
                          disableUnderline
                          variant='standard'
                          MenuProps={{
                            PaperProps: {
                              style: {
                                borderRadius:'8px',
                              },
                            },
                          }}
                          sx={{
                            paddingLeft: '10px',
                            minWidth: '70px',
                            backgroundColor: 'transparent',
                            '& .MuiSelect-icon': {
                              color: 'gray',
                            },
                            '& .MuiOutlinedInput-root': {
                              backgroundColor: 'transparent',
                              border: '1px solid #ccc',
                              padding:0,
                              '& input': {
                                backgroundColor: 'transparent',
                                padding: ''
                              },
                            },
                           
                          }}
                        >
                          <MenuItem style={{fontSize:'14px'}} value="published">Published</MenuItem>
                          <MenuItem style={{fontSize:'14px'}} value="unpublished">Unpublished</MenuItem>
                          <MenuItem style={{fontSize:'14px'}} value="all">All</MenuItem>

                        </MuiSelect>
                      </div>
                      <div style={{ display: 'flex', alignItems: 'center',marginLeft:'25px' }}>
                      <Typography>Sort by:</Typography>
                      <MuiSelect
                        data-test-id="sort-select"
                        value={this.state.sortOrder}
                        onChange={this.handleSortOrderChange}
                        disableUnderline
                        variant='standard'
                        MenuProps={{
                          PaperProps: {
                            style: {
                              width: '270px', 
                              borderRadius:'8px',
                            },
                          },
                        }}
                        sx={{
                          paddingLeft: '10px',
                          minWidth: '150px',
                          backgroundColor: 'transparent',
                          '& .MuiSelect-icon': {
                            color: 'gray',
                          },
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'transparent',
                            border: '1px solid #ccc',
                            padding:0,
                            '& input': {
                              backgroundColor: 'transparent',
                              padding: ''
                            },
                          },
                         
                        }}
                      >
                        <MenuItem value="Relevance">
                          Relevance
                        </MenuItem>
                        <MenuItem style={{fontSize:'14px'}} value="a-z">Name (A-Z)</MenuItem>
                        <MenuItem style={{fontSize:'14px'}} value="z-a">Name (Z-A)</MenuItem>
                        <MenuItem style={{fontSize:'14px'}} value="old-to-new">Oldest to newest</MenuItem>
                        <MenuItem style={{fontSize:'14px'}} value="new-to-old">Newest to oldest</MenuItem>
                        <MenuItem style={{fontSize:'14px'}} value="high-to-low">Courses Assigned ( High to low)</MenuItem>
                        <MenuItem style={{fontSize:'14px'}} value="low-to-high">Courses Assigned ( Low to high)</MenuItem>
                      </MuiSelect>
                    </div>

                      <Box>

                      </Box>

                    </Box>
                  </Box>
                  {this.renderLearningPathDialog()}
                  {this.renderUpdatePackageModal()}
                  {this.renderDeletePathDialog()}
                  {this.state.loading ?
                    <>{this.renderLoader()}</> :
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {!this.state.filteredLearningPaths.length && this.renderCreateLearningPathMsg()}
                      {this.state.filteredLearningPaths.map((learningPath: any,index: number) => (
                        <StyledAccordion key={learningPath.id} expanded={this.state.expanded.findIndex((id) => id === +learningPath.id)!==-1} data-test-id={'accordion-display'}>
                          <AccordionSummary
                            expandIcon={<ExpandMore style={{color: '#1C1C1C'}}/>}
                            aria-controls={`panel-${learningPath.id}-content`}
                            id={`panel-${learningPath.id}-header`}
                            onClick={() => this.handleAccordionChange(learningPath.id)}
                            data-test-id={'accordion-sub-display'}
                            sx={{
                              flexDirection: 'row-reverse',
                              display: 'flex',
                              alignItems: 'center',
                              columnGap: '1rem'
                            }}
                          >
                            <Typography sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', fontFamily: 'Heebo', fontWeight: '600', fontSize: '18px' }}>
                            {learningPath.attributes.title}
                            </Typography>
                            <Box>
                              <IconButton key={'btn-more' + learningPath.id} data-test-id='btnMore' onClick={(e) => this.handlePopoverClick(e, learningPath)}
                                sx={{
                                  borderRadius: '50%',
                                  width: 40,
                                  height: 40,
                                  //backgroundColor: '#F0F0F0',
                                }}
                              >
                                <MoreVert />
                              </IconButton>
                              <Popover
                                data-test-id='btnPopover'
                                key={'more_popover' + learningPath.id}
                                id={learningPath.id}
                                open={this.state.showActionPopup}
                                anchorEl={this.state.anchorEl}
                                onClose={() => this.handlePopoverClose()}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                                sx={{
                                  '& .MuiPaper-root': {
                                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                                  },
                                }}
                              >
                                <Typography sx={{ p: 2 }}>
                                  <MenuItem key={learningPath.id + 'update'} data-test-id='btnOptionsUpdate' onClick={(e) => this.handleUpdateLearningPath(e, learningPath)}>Update</MenuItem>
                                  <MenuItem key={learningPath.id + 'assign'} data-test-id='btnOptionsAssign' onClick={(e) => this.handleAssignCourses(e, learningPath)}>Assign Courses</MenuItem>
                                  <MenuItem key={learningPath.id + 'clone'} data-test-id='btnOptionsClone' onClick={(e) => this.handleClone(e, learningPath)}>Clone</MenuItem>
                                  <MenuItem key={learningPath.id + 'delete'} data-test-id='btnOptionsDelete' onClick={(e) => this.handleDelete(e, learningPath)}><Box sx={{ color: '#DC2626' }}>Delete</Box></MenuItem>
                                </Typography>
                              </Popover>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography variant="body2" sx={{ padding: '0rem 2.1rem', fontFamily: 'Heebo', fontWeight: '500', fontSize: '14px' }}>
                              {this.renderHTML(learningPath.attributes.description)}
                            </Typography>
                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', padding: '20px' }}>
                              {learningPath.attributes.courses.map((course: any, index: any) => {
                                const courseImage = course.images?.url || myLearningCourseImg;
                                if(!this.isFilteredCourse(course)){
                                  return null;
                                }
                                return (
                                  <Grid item xs={3} key={course.id+learningPath.id +index}>
                                   {this.renderCard(course,courseImage,index,learningPath.id)}
                                  </Grid>
                                )
                              })}

                            </div>
                          </AccordionDetails>
                        </StyledAccordion>
                      ))}
                    </Box>}
                </Box>
              </Box>
              <Box sx={webStyles.footerBlock}>
                <Box sx={webStyles.leftFooter}>
                  <Box>Terms and Conditions</Box>
                  <Box>Privacy Policy</Box>
                  <Box></Box>
                </Box>

                <Box>
                  <Box>
                    © 2025 Thinkspike. All Rights Reserved</Box>
                </Box>
              </Box>
            </Box>
          </ThemeProvider>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const CustomDialog = styled(Dialog)({
  '& .MuiPaper-root': {
      maxWidth: '800px',
      width: '100%',
      borderRadius: "8px",
  },
  "& .dialog-title": {
      padding: "15px 30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #E2E8F0",
      "& .close-icon": {
          cursor: "pointer",
          color: "#334155",
      },
  },
  "& .create-title": {
      fontSize: "20px",
      color: "##1C1C1C",
      fontFamily: "Heebo-SemiBold",
  },
  "& .label-title": {
      fontSize: "14px",
      color: "#64748B",
      fontFamily: "Heebo-Regular",
      fontWeight: 600,
      padding: '5px 0',
  },
  "&.modal-menu": {
      '& .MuiPaper-root': {
          maxWidth: '670px',
      },
      "& .label-title": {
          color: "#334155"
      },
      "& .dialog-action": {
          padding: "17px",
      },
      "& form": {
          margin: "0px"
      },
      "& .requirement-editor": {
          border: "1px solid #9D9D9D",
          borderRadius: "4px",
          padding: "10px 5px 3px 0px",
          "& .public-DraftStyleDefault-block": {
              paddingLeft: "15px"
          },
          "& .public-DraftEditorPlaceholder-inner": {
              paddingLeft: "15px",
              fontSize: "14px",
              letterSpacing: "0.2px",
              color: "#c4c4c4",
              fontfamily: 'Heebo-Regular'
          }
      }
  },
  "& .heading-title": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
  },
  "& .content": {
      padding: "20px 30px 25px !important",
  },
  "& .dialog-action": {
      padding: "10px 17px",
      borderTop: "1px solid #E2E8F0",
  },
  "& .react-select__placeholder": {
      paddingLeft: "7px"
  },
  "& .react-select__input": {
      paddingLeft: "7px",
  },
  "& .unchecked": {
      width: "20px",
      height: "20px",
      border: "1px solid #484848",
      borderRadius: "3px",
  },
  "& .checked": {
      width: "20px",
      height: "20px",
      backgroundColor: "#000",
      border: "1px solid #000",
      borderRadius: "3px",
  },
  "& .checked svg": {
      color: "#fff",
      fontSize: "20px"
  },
  "& .react-select__multi-value__remove:hover": {
      backgroundColor: "black",
      color: "#fff",
  },
  ".react-select__menu-list": {
      maxHeight: "223px",
  },
  "& .react-select__menu": {
      color: "black",
  },
  "& .react-select__option": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      borderBottom: "1px solid #e8e8e8",
      backgroundColor: "#fff",
      color: "black",
      marginTop: "0px",
      padding: "0px 12px",
      paddingLeft: "0px",
      fontSize: "14px",
      "& label": {
          marginLeft: "15px"
      }
  },
  "& .react-select__option--is-selected": {
      fontFamily: "Heebo-SemiBold",
  },
  "& .react-select__option:hover": {
      backgroundColor: "#fff",
      fontFamily: "Heebo-SemiBold",
      color: "black",
  },
  "& .react-select__option:hover .MuiButtonBase-root:hover": {
      backgroundColor: "transparent"
  },
  "& .react-select__option:active": {
      backgroundColor: "#fff",
      color: "black",
      fontFamily: "Heebo-SemiBold",
  },
  "& .react-select__multi-value": {
      borderRadius: "6px",
      color: "#fff",
      backgroundColor: "black",
      padding: "0px 2px",
  },
  "& .react-select__multi-value__label": {
      color: "#fff",
  }
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  //width: "95px",
  height:'56px',
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  backgroundColor: "black",
  borderRadius: "8px !important",
  "&:hover": {
      backgroundColor: "black",
  },
});

const DeleteButton = styled(Button)({
  marginLeft: "15px !important",
  height:'56px',
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  backgroundColor: "#FC5555",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "#FC5555",
},
});

const CancelButton = styled(Button)({
  marginLeft: "15px !important",
  height:'56px',
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  background: "#F0F0F0",
  borderRadius: "8px !important",
  "&:hover": {
      backgroundColor: "#F0F0F0",
  },
});

const CustomCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    backgroundColor: 'white',
    width: '24px',
    borderRadius: '6px',
    height: '24px',
    border: '1px solid black',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      strokeWidth: 2,
      d: 'path("M5 12l5 5L20 7")',
      stroke: 'white',
      fill: 'none',
    },
  },
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
});

/* istanbul ignore next */
const CustomInputBase = styled(InputBase)(({ error }) => ({
  border: error ? "1px solid #FC5555" : "1px solid #CBD5E1",
  borderRadius: "5px",
  fontFamily: "Heebo-Regular",
  backgroundColor: "#fff",
  marginTop: "3px",
  fontSize: "14px",
  "& input": {
    padding: "10px 8px",
    '&:placeholder': {
      fontWeight: 500
    }
  },
  "& .MuiInputBase-inputMultiline": {
    fontSize: "14px",
    padding: "10px 8px",
  },

}));
const StyledAccordion = styled(Accordion)({
    border: '1px solid #D6D3D1',
    boxShadow: 'none', 
    marginBottom:'10px',
  });

  const webStyles = {
    courseCard:{
      width: '100%',
      display: 'flex',
      height: '350px',
      gap: '16px',
      maxWidth: '350px',
      flexDirection: 'column',
      position:'relative',
      boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
  },
    container: {
      left: "50%",
      position: 'absolute',
      transform: "translate(-50%, -50%)",
      top: "50%",
      boxShadow: "0px 8px 32px 0px #0000000F",
      height: "75%",
      width: '70%',
      padding: '24px',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px',
    },
    courseCardDescription: {
        gap: '16px',
        flexDirection: 'column',
        padding: '0px 16px 24px 16px',
        display: 'flex',
    },
    createPathContainer:{
      display:'flex',
      justifyContent:'center',
      width: '281px',
      height: '56px',
      padding: '10px 16px 10px 16px',
      borderRadius: '4px',
      border: '1px solid',
      borderColor: '#6A6A6A',
      marginTop:'20px',
      textAlign:'center',
      backgroundColor: '#FFFFFF'
    },
    createPathText:{
      fontFamily: 'Heebo',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      margin:'auto',
      
      textAlign: 'center'
    },
    grayText:{
      fontFamily: 'Heebo',
      fontSize: '32px',
      fontWeight: '400',
      lineHeight: '47px',
      textAlign: 'center',
      color: '#6A6A6A'
    },
    modalStyle: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      minWidth: 600,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    },
    filterText:{
      fontFamily: 'Heebo',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: '22px',
      textAlign: 'left',
      color:' #334155'
    },
    dateString: {
      marginLeft:'24px',
      fontFamily: 'Heebo',
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      textAlign: 'left',
      color: '#475569'
    },
    tableHeaderText: {
      fontFamily: 'Heebo',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      textAlign: 'left',
      color: '#6A6A6A',
      paddingTop:'4px',
      paddingBottom:'4px'
    },
    tableContentText:{
      fontFamily: 'Heebo',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      textAlign: 'left',
      color: '#232323',
      paddingTop:'8px',
      paddingBottom:'8px'
    },
    searchBox: {
      // minWidth: '830px',
      flex: 2,
      marginRight: '30px',
      display: 'flex',
      maxHeight: '56px',
      alignItems: 'center',
      alignContent: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: '4px',
      border: '1px solid rgba(214, 211, 209, 1)'
    },
    footerBlock: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '24px 52px 24px 52px',
      backgroundColor: '#1C1C1C',
      color: '#fff',
    },
    leftFooter: {
      display: 'flex',
      gap: '24px',
      flexGrow:1
    },
    rightFooter: {},
    modalRowViewStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    dropdownViewStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 280,
      justifyContent: 'space-between',
      padding: 10,
      border: '1px solid #ccc',
      borderRadius: 5,
    },
    modalButtonViewStyle: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '10px 0px',
    },
    tableViewStyle: {
      overflow: 'hidden',
      borderRadius:"6px"
    },
    tableContainerStyle: {
      maxHeight: 600,
      zIndex:0,
      width:'100%',
    },
    tableButtonViewStyle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerButtonViewStyle: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    secondButtonViewStyle: {
      marginLeft: 10,
    },
    btnAddAccounts: {
      backgroundColor: '#005500',
      marginLeft: 10,
    },
    btnDeleteAccounts: {
      backgroundColor: '#990000',
      marginLeft: 10,
    },
    checkCircleRed: {
      height: 10,
      width: 10,
      backgroundColor: 'red',
      borderRadius: 5,
    },
    innerTableBox: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    arrow: {
      height: 20,
      width: 20,
      resizeMode: 'contain',
    },
    dropdownListContainer: {
      maxHeight: 200,
      overflow: 'auto',
      width: 300,
    },
    errorMsg: {
      color: 'red',
      margin: 10,
    },
    mainWrapper: {
      flex: 1,
      flexDirection: 'col',
      backgroundColor: '#f6f6f6',
  
    },
    mainBlock: {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor:'#f6f6f6',
    },
    pageContent: {
      display: 'flex',
      overflowX:'auto',
      flexDirection: 'column', 
      alignItems:'left',
      paddingLeft:'21.5px',
      paddingRight:'40.5px',
      width:"100%",
    },
    paginationContainer: {
      display: 'flex',
      flexDirection: 'row',
      textAlign:"center",
      justifyContent:'center',
      color:"black"
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      height:'92px',
    },
    navigation: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%'
    },
    currentNavigationPage: {
      fontWeight: '700',
      fontSize: '24px',
      padding: '4px 4px'
    },
    breadcrumbButton: {
      display:'flex',
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      fontSize: '24px',
      fontWeight:'700',
      fontFamily:'Inter',
      lineHeight: '32px',
      letterSpacing: '-0.005em'
    },
  };
// Customizable Area End
