import React from "react";
// Customizable Area Start
import {
  Box, Grid, Button, Typography, Accordion, AccordionSummary,InputBase,

    TextField,
    Select as MuiSelect,
    InputAdornment,
    AccordionDetails,Chip,
    Checkbox,CardContent,Card} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpandMore, Search,TimerOutlined} from '@mui/icons-material';
import Sidebar from "./../../../components/src/sidebar/Sidebar.web";
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';
import { courseImg, timer } from "./assets";
import ClientUserManagementController from "./ClientUserManagementController.web";
import DOMPurify from "dompurify";
// Customizable Area End

export default class ClientUserManagement extends ClientUserManagementController {
  // Customizable Area Start
  getIcon = () => {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.99953 6.70978C8.60953 7.09978 8.60953 7.72978 8.99953 8.11978L12.8795 11.9998L8.99953 15.8798C8.60953 16.2698 8.60953 16.8998 8.99953 17.2898C9.38953 17.6798 10.0195 17.6798 10.4095 17.2898L14.9995 12.6998C15.3895 12.3098 15.3895 11.6798 14.9995 11.2898L10.4095 6.69978C10.0295 6.31978 9.38953 6.31978 8.99953 6.70978Z" fill="#6A6A6A" />
      </svg>

    );
  }

  renderPersonalInfo = () => {
    return <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box sx={{ width: '255px' }}>
        <Typography
          sx={{
            fontFamily: 'Heebo',
            fontSize: '14px',
            fontWeight: 700,
            lineHeight: '22px',
            textAlign: 'left',
          }} color={'#64748B'}>USER DETAILS</Typography>
      </Box>
      <Box>
        <Box ml="8px" sx={styles.inputContainer}>
          <Typography className="label-title" sx={styles.inputTitle}>First name</Typography>
          <CustomInput
            fullWidth
            placeholder={this.state.userData?.attributes.first_name}
            value={this.state.formData.firstName}
            onChange={(e)=>this.onChangeUserDetails(e,'firstName')}
          />
        </Box>
        <Box ml="8px" sx={styles.inputContainer}>
          <Typography className="label-title" sx={styles.inputTitle}>Last name</Typography>
          <CustomInput
            fullWidth
            placeholder={this.state.userData?.attributes.last_name}
            value={this.state.formData.lastName}
            onChange={(e)=>this.onChangeUserDetails(e,'lastName')}
          />
        </Box>

        <Box ml="8px" sx={styles.inputContainer}>
          <Typography className="label-title" sx={styles.inputTitle}>Job title</Typography>
          <CustomInput
            fullWidth
            placeholder={this.state.userData?.attributes.job_title}
            value={this.state.formData.jobTitle}
            onChange={(e)=>this.onChangeUserDetails(e,'jobTitle')}
          />
        </Box>
        <Box ml="8px" sx={styles.inputContainer}>
          <Typography className="label-title" sx={styles.inputTitle}>Email</Typography>
          <CustomInput
            fullWidth
            data-test-id="emailInput"
            placeholder={this.state.userData?.attributes.email}
            value={this.state.formData.email}
            onChange={(e)=>this.onChangeUserDetails(e,'email')}
          />
        </Box>
      </Box>
    </Box>
  }


  renderAssignCourses = () => {
    return <> 
      <Box display="flex" flexDirection={'row'} alignItems="center" bgcolor="#f5f5f5" sx={{ paddingBottom: '20px', height: '56px',width:'1085px' }}>
        <Typography sx={{
          fontFamily: 'Heebo',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '26.44px',
          textAlign: 'left',
          color: '#1C1C1C',
          height:'28px',
          padding:'10px',
          minWidth:'145px'
        }}>Assign Courses</Typography>
      <Box sx={styles.searchBox}>
        <TextField
          InputProps={{
            sx: {
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
                height: '100%',
              },
              '& .MuiOutlinedInput-root': {
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              },
                '& input': {
                  lineHeight: '24px',
                  fontSize: '16px',
                },
                '& input::placeholder': {
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#1C1C1C',
                },
                backgroundColor: 'white'
              },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          placeholder="Search Courses"
          data-test-id='learningPathSearch'
          sx={{ width: "100%", height: "100%", marginLeft: "4px" }}
          value={this.state.searchQuery}
          onChange={(e: any) => { this.onChangeSearchQuery(e) }} />
      </Box>
        {!!this.showAssignBtn() && <Box> <TabButton data-test-id='assignSelected' sx={{height:'44px',width:'150px',...styles.selectedBtn}} onClick={() => this.assignSelected()}><Typography sx={{padding:'10px, 17px'}}>Assign Selected</Typography></TabButton></Box>}
    </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {this.state.filteredLearningPaths.map((learningPath: any,index:number) => (
          <Box sx={{maxWidth:'1085px'}}>
          <StyledAccordion key={learningPath.id}>
            <AccordionSummary
              expandIcon={<CustomExpandIcon/>}
              aria-controls={`panel-${learningPath.id}-content`}
              id={`panel-${learningPath.id}-header`}
              sx={{
                flexDirection: 'row-reverse',
                display: 'flex',
                alignItems: 'center',
                columnGap: '1rem'
              }}
            >
             <Box display='flex' alignItems={'center'} flexDirection={'row'} justifyContent={'space-between'} minWidth={'100%'}> 
              <Box><Typography sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', fontWeight: '700' }}>
                {this.getTitle(learningPath)}
              </Typography></Box>
              <Box><CustomCheckbox
              data-testID={`checks${index}`}
              indeterminate={this.isIndeterminateLearningPath(learningPath)}
              checked={this.isSelectedLearningPath(learningPath)}
              onChange={(e)=>this.handleLearningPathSelect(e,learningPath)}
            /></Box></Box>
            </AccordionSummary>
            <AccordionDetails>
                 <Typography variant="body2" sx={{ padding: '0rem 2.1rem' }}>
                  {this.sanitizeData(this.getDisc(learningPath))}
                </Typography>
              <Box sx={{ display: 'grid', padding:'0px 24px 0px 24px', gridTemplateColumns: 'repeat(3, 1fr)'}} >
                {learningPath.courses.map((course: any, index: any) => {
                  const courseImage = course.thumbnail_image|| courseImg;
                  return (
                    <Grid item
                      justifyContent={'space-evenly'}
                      key={course.id} style={{ width: '300px', height: '350px', margin:'16px'}}>
                      {this.renderCard(course, courseImage, index, true)}
                    </Grid>
                 ) })}
              </Box>
            </AccordionDetails>
          </StyledAccordion>
          </Box>
        ))}
      </Box></>
  }

  renderCard = (course: any, courseImage: any, index: number, showCheckBox: boolean = false) => {
    return <Card style={{ width: '100%', height: '100%', position: 'relative', boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.08)'}}>
    <Box position="relative">
      <Box
        style={{
          position: 'relative',
          width: '100%',
          height: '160px',
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8)), url(${courseImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box display="flex" justifyContent="space-between" alignItems={"center"} padding={"8px"}>
        <Chip style={{ borderRadius: '4px', backgroundColor: course.status === 'published' ? '#1C1C1C' : '#1C1C1C66' }} label={course.status || 'draft'} color="primary" />
       {course.status === 'published' && <CustomCheckbox
            data-testID={`checks${index}`}
            checked={this.isSelectedCourse(course)}
            onChange={() => this.handleCourseSelection(course)}
          />}
        </Box>
            <Typography
              style={{
                height: '50px',
                display: 'flex',
                color: '#fff',
                padding: '10px',
                fontSize: '14px',
                alignItems: 'end',
                zIndex: '2',
                boxShadow: 'inset 0px -53px 25px -20px rgba(0, 0, 0, 0.82)'
              }}
            >
              <TimerOutlined style={{ marginRight: '5px' }} />
              {course.duration} Hours
            </Typography>
        </Box>
      </Box>
      <CardContent>
        <Typography variant="h6" component="div" sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: 2,
        }}>
          {this.sanitizeData(course.title)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {course.lessons} Lessons
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          WebkitLineClamp: 3,
        }}>
          {this.sanitizeData(course.description)}
        </Typography>
    </CardContent>
  </Card>
  }

  renderUserAnalytics = () => {
    return <Box>Anaytics</Box>
  }

  sanitizeData = (htmlString: string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  };
  
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Wrapper>
        <Box className="mainBlock">
          <Box>
            <Sidebar navigation={this.props.navigation} current='clients' />
          </Box>
          <Box className="pageContent">
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center" className="breadcrum">
                <Box
                  className="client-title"
                  data-test-id="client-btn"
                  onClick={this.onRedirect}
                >
                  Client
                </Box>
                {this.getIcon()}
                <Box
                  className="client-title"
                  data-test-id="client-name-btn"
                  onClick={this.onRedirectToClient}
                >
                  {this.state.userData?.attributes?.company_name}
                </Box>
                {this.getIcon()}
                <Box
                  className="client-title"
                  data-test-id="client-name-btn"
                  onClick={this.onRedirectToUser}
                >
                  Users
                </Box>
                {this.getIcon()}
                <Box className="last-title">{this.userName()}</Box>
              </Box>
              {this.state.currentTab === this.tabs.PERSONAL_INFO && <Box>
                <SaveButton data-test-id="save-btn" onClick={() => {this.onSaveUserDetails() }}>Save</SaveButton>
              </Box>}
            </Box>
            <Box className="tab-buttons">
              <TabButton data-test-id='personalTab' sx={this.isSelectedTab(this.tabs.PERSONAL_INFO) ? styles.selectedBtn : styles.normalBtn} onClick={() => this.onTabSwitch(this.tabs.PERSONAL_INFO)}>Personal</TabButton>
              <TabButton data-test-id='assignTab' sx={this.isSelectedTab(this.tabs.ASSIGN_COURSES) ? styles.selectedBtn : styles.normalBtn} onClick={() => this.onTabSwitch(this.tabs.ASSIGN_COURSES)}>Assign Courses</TabButton>
              <TabButton data-test-id='analyticsTab' sx={this.isSelectedTab(this.tabs.ANALYTICS) ? styles.selectedBtn : styles.normalBtn} onClick={() => this.onTabSwitch(this.tabs.ANALYTICS)}>User Analytics</TabButton>
            </Box>
            <Box>
              {this.isSelectedTab(this.tabs.PERSONAL_INFO) && <>{this.renderPersonalInfo()}</>}
              {this.isSelectedTab(this.tabs.ASSIGN_COURSES) && <>{this.renderAssignCourses()}</>}
              {this.isSelectedTab(this.tabs.ANALYTICS) && <>{this.renderUserAnalytics()}</>}
            </Box>
          </Box>
        </Box>
        <AdminFooter />
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box)(({ theme }) => ({
  "& .mainBlock": {
    height: "calc(100vh - 72px)",
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#f6f6f6',
  },
  "& .pageContent": {
    //backgroundColor: "#fafafa",
    padding: "20px 30px 10px 15px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    "& .breadcrum": {
      "& .client-title": {
        fontFamily: "Heebo-Regular",
        color: "#475569",
        fontSize: "24px",
        cursor: "pointer",
        letterSpacing: "-0.5px",
        [theme.breakpoints.down('md')]: {
          fontSize: "16px",
        }
      },
      "& .last-title": {
        color: "#0F172A",
        fontSize: "24px",
        letterSpacing: "-0.5px",
        fontFamily: "Heebo-Bold",
        [theme.breakpoints.down('md')]: {
          fontSize: "16px",
        }
      },
    },
    "& .tab-buttons": {
      display: "flex",
      marginBottom: "20px",
      marginTop: "30px",
      gap: "10px"
    },
    "& .accreditation-title": {
      fontSize: "24px",
      fontFamily: "Heebo-Medium",
    }
  },
}));

const CustomInput = styled(InputBase)({
  width: "476px",
  borderRadius: "8px",
  padding: "7px 8px",
  backgroundColor: "#fff",
  border: "1px solid #CBD5E1",
  color: '#94A3B8'

});

const CustomCheckbox = styled(Checkbox)({
  '& .MuiSvgIcon-root': {
    backgroundColor: 'white',
    width: '20px',
    borderRadius: '4px',
    height: '24px',
    border: '1px solid black',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      strokeWidth: 2,
      d: 'path("M5 12l5 5L20 7")',
      stroke: 'white',
      fill: 'none',
    },
  },
  '&.MuiCheckbox-indeterminate': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      strokeWidth: 2,
      d: 'path("M5 12h14")', // Horizontal line for indeterminate
      stroke: 'white',
      fill: 'none',
    },
  },
  '&:not(.Mui-checked):not(.MuiCheckbox-indeterminate) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '&.Mui-disabled': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'gray', // Set the background color for the disabled state
    },
    '& .MuiSvgIcon-root path': {
      fill: 'gray', // Change the fill color for the disabled state
    },
  },
});

// const Card = styled(Box)({
//   display: "flex",
//   flexDirection: "column",
//   gap:'16px',
//   overflow:'hidden',
//   justifyContent: "space-between",
//   padding: '16px 25px',
//   margin:'0px 0px 24px 0px',
//   backgroundColor: 'white',
//   border:'1px #D6D3D1',
//   //borderRadius: '8px 8px 32px 8px',
//   boxShadow: '0px 2px 10px 2px rgba(0, 0, 0, 0.08)'

// });

const CustomExpandIcon = styled(ExpandMore)({
  color: '#1C1C1C', 
  transition: 'transform 0.3s', 
  width: '24px',
  height:'24px',
  '&.Mui-expanded': {
    transform: 'rotate(180deg)', 
  },
});

const StyledAccordion = styled(Accordion)({
  border: '1px solid #D6D3D1',
  boxShadow: 'none',
  marginBottom: '10px'
});

const styles = {
  mainBlock: {
    flexDirection: 'row',
    display: 'flex',
  },
  searchBox: {
    minWidth: '532px',
    display: 'flex',
    maxHeight: '56px',
    alignItems: 'center',
    alignContent: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    marginLeft:'20px',
    marginRight:'245px',
    border: '1px solid rgba(214, 211, 209, 1)'
  },
  selectedBtn: {
    backgroundColor: '#1C1C1C',
    color: 'white',
    fontSize: '14px',
    marginRight:'24px',
  },
  normalBtn: {
    color: "#1C1C1C",
    fontSize: '16px',
    marginRight:'24px'
  },
  inputTitle: {
    fontFamily: 'Heebo',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '22px',
    textAlign: 'left',
    color: '#334155'
  },
  inputContainer: {
    paddingBottom: '16px'
  },
  logoutButton: {
    flexDirection: 'row',
    display: 'flex',
    color: 'red',
    gap: '8px',
    justifyContent: 'start',
    flexGrow: '1',
    textTransform: 'none',
    fontSize: '16px',
  },
  commonButton: {
    backgroundColor: 'white',
    fontSize: '14px',
    padding: '5px 15px',
    textTransform: 'none',
    color: 'black',
  },
  blackButton: {
    color: 'white',
    backgroundColor: 'black',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#333333',
    },
  },
  courseCard: {
    display: 'flex',
    width: '100%',
    maxWidth: '350px',
    gap: '16px',
    flexDirection: 'column',
    boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
  },
  courseCardDescription: {
    gap: '16px',
    flexDirection: 'column',
    padding: '0px 16px 0px 16px',
    display: 'flex',
  },
}

const TabButton = styled(Button)({
  textTransform: "none",
  fontFamily: "Heebo-Regular",
  //color: "white !important",
  //fontSize: "14px !important",
  padding: "10px 16px !important",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "black",
    color: 'white',
  },
});

const SaveButton = styled(Button)({
  marginLeft: "15px !important",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "white !important",
  fontSize: "16px !important",
  padding: "7px 16px !important",
  backgroundColor: "black",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "black",
  },
});
// Customizable Area End