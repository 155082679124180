import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  auditTrail: any;
  loading: boolean;
  searchQuery: any;
  filterAchorEl: any;
  sortBy: any;
  itemsPerPage: number;
  startDate: any;
  endDate: any;
  token: string;
  adminNames: any;
  auditableTypes: any;
  auditActions: any;
  filterModalVisible: boolean;
  selectedActionType: any;
  selectedAdminUser: any;
  showAllFilters: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export interface AuditTrailDataItem {
  // Customizable Area Start
  id: string;
  type: string;
  attributes: {
    action: string;
    account_id: null | string | number;
    auditable_type: string;
    auditable_id: null | string| number;
    new_data: {name:string};
    previous_data: {name:string};
    created_at: string;
    updated_at: string;
    account_holder_name: string;
  };
  // Customizable Area End
}
export default class AuditTrailDetailController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAuditDetailApiCallId = "";
  downloadCSVApiCallId = "";
  getAdminNamesApiCallId = "";
  getAuditableTypesApiCallId = "";
  searchAuditsApiCallId = "";
  getAuditActionsApiCallId = "";
  // Customizable Area End
 
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    // Customizable Area Start
    this.state = {
      token: '',
      loading: false,
      searchQuery: '',
      filterAchorEl: null,
      sortBy: 'Date',
      itemsPerPage: 10,
      startDate: null,
      endDate: null,
      auditTrail: null,
      adminNames: null,
      auditableTypes: null,
      auditActions: null,
      filterModalVisible: false,
      selectedActionType: '',
      selectedAdminUser: '',
      showAllFilters:false
    // Customizable Area End

    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  
  
  
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch(apiRequestCallId){
          case this.downloadCSVApiCallId : this.downloadCsvFile(responseJson);
          break;
          case this.getAuditDetailApiCallId : this.handleAuditDetailsResponse(responseJson);
          break;
          case this.getAdminNamesApiCallId : this.handleAdminNamesResponse(responseJson);
          break;
          case this.getAuditableTypesApiCallId : this.handleAuditableTypesResponse(responseJson);
          break;
          case this.getAuditActionsApiCallId : this.handleAuditActionsResponse(responseJson);
          break;
        }
      }
      this.setState({ loading: false });
    }

    // Customizable Area End
  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getAuditDetail(1);
    this.getAdminNamesList();
    this.getAuditableTypes();
    this.getAuditActions();
  }

  //function to get audittrail data

   debounce = (func:any, delay:any) => {
    let timeoutId:any;
    return (...args:any) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  formatDateForParam=(date:Date)=>{
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; 
  }

  getAuditDetail = async (page: number) => {
    this.setState({ loading: true });
    let endPoint = `bx_block_audittrail/audits/?page=${page}`;
    if (this.state.selectedActionType?.length) {
      endPoint = endPoint + `&admin_action=${this.state.selectedActionType}`
    }
    if (this.state.selectedAdminUser) {
      endPoint = endPoint + `&name=${this.state.selectedAdminUser?.full_name}`
    }
    if (this.state.searchQuery?.length) {
      endPoint = endPoint + `&name=${this.state.searchQuery}`
    }
    if (this.state.startDate && this.state.endDate) {
      const start = this.formatDateForParam(this.state.startDate);
      const end = this.formatDateForParam(this.state.endDate);
      endPoint = endPoint + `&start_date=${start}&end_date=${end}`
    }
    this.getAuditDetailApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: endPoint,
      token: localStorage.getItem("token"),
    });
  };

  debouncedFetchResults = this.debounce(this.getAuditDetail, 300);
  
  getAdminNamesList = async () => {
    this.setState({ loading: true });
    this.getAdminNamesApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `/bx_block_audittrail/audits/admin_names_list`,
      token: localStorage.getItem("token"),
    });
  };

  getAuditableTypes= async () => {
    this.setState({ loading: true });
    this.getAuditableTypesApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_audittrail/audits/auditable_types`,
      token: localStorage.getItem("token"),
    });
  };


  getAuditActions = async () => {
    this.setState({ loading: true });
    this.getAuditActionsApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      endPoint: `bx_block_audittrail/audits/audit_actions`,
      token: localStorage.getItem("token"),
    });
  };

  handleAuditDetailsResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ auditTrail: responseJson }, () => {
        this.handleSortChange(this.state.sortBy);
      })
    } else if (responseJson.errors || responseJson.error) {
      toast.error(responseJson.errors);
    }
  }

  handleAdminNamesResponse = (responseJson: any) => {
    if (responseJson.admin_users) {
      this.setState({ adminNames: responseJson.admin_users })
    }
  }

  handleAuditableTypesResponse = (responseJson: any) => {
    if (responseJson.auditable_types) {
      this.setState({ auditableTypes: responseJson.auditable_types })
    }
  }

  handleAuditActionsResponse = (responseJson: any) => {
    if (responseJson.actions) {
      this.setState({ auditActions: responseJson.actions })
    }
  }

  exportCSV = async () => {
    this.downloadCSVApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_audittrail/audits/download_csv`,
    });
  };

  downloadCsvFile = (responseJson: any) => {
    if (responseJson?.csv_url?.download_url) {
      const link = document.createElement("a");
      link.href = responseJson.csv_url.download_url;
      link.download = "filename.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  onChangeSearchQuery = (value: string) => {
    this.setState({ searchQuery: value }, () => {
      if (value.length) {
        this.debouncedFetchResults(1);
      } else {
        this.getAuditDetail(1);
      }
    });
  }

  openFilterModal = (showAllFilters: boolean) => {
    this.setState({ showAllFilters: showAllFilters }, () => {
      this.setState({ filterModalVisible: true })
    })
  }

  getFormatedDate = (myDate: Date) => {
    const date = new Date(myDate);
    const options = { month: 'short', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options as any).replace(',', '');
    return formattedDate;
  }

  getDateString = () => {
    const str1 = this.state.startDate ? this.getFormatedDate(this.state.startDate) : 'Start Date ';
    const str2 = this.state.endDate ? this.getFormatedDate(this.state.endDate) : 'End Date';
    return str1 + ' - ' + str2;
  }

  applyFilter = () => {
    this.closeFilterModal();
    this.getAuditDetail(1);
  }

  handleActionTypeChange = (e: any) => {
    this.setState({ selectedActionType: e.target.value })
  }

  removeActionTypeFilter = () => this.setState({ selectedActionType: '' })

  removeAdminUserFilter = () => this.setState({ selectedAdminUser: '' })

  handleAdminUserChange = (e: any) => {
    this.setState({ selectedAdminUser: e.target.value })
  }

  closeFilterModal = () => {
    this.setState({ filterModalVisible: !this.state.filterModalVisible })
  }

  setStartDate = (val: any) => this.setState({ startDate: val });

  setEndDate = (val: any) => this.setState({ endDate: val });

  handleSortChange = (val:string) => {
    const sortBy = val
    let sortedData = this.state.auditTrail.data;
    if (sortBy === 'User(A-Z)') {
      sortedData.sort((a: any, b: any) =>
        (a.attributes?.admin_user_name || '').localeCompare(b.attributes?.admin_user_name || '')
      );
    } else if (sortBy === 'User(Z-A)') {
      sortedData.sort((a: any, b: any) =>
        (b.attributes?.admin_user_name || '').localeCompare(a.attributes?.admin_user_name || '')
      );
    } else if (sortBy === 'Date') {
      sortedData.sort((a: any, b: any) =>
        new Date(b.attributes.updated_at).getTime() - new Date(a.attributes.updated_at).getTime()
      );
    } else if (sortBy === 'Action') {
      sortedData.sort((a: any, b: any) =>
        a.attributes.action.localeCompare(b.attributes.action)
      );
    }
    this.setState({ auditTrail: { meta: this.state.auditTrail.meta, data: sortedData },sortBy:sortBy });
  };

  convertTimestampToCustomFormat(timestamp: string) {
    const [datePart, timePart] = timestamp.split(', ');
    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes] = timePart.split(':').map(Number);
    const date = new Date(year, month - 1, day, hours, minutes);
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
    const formattedTime = `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
    return `${formattedDate}  ${formattedTime}`;
  }

  // Customizable Area End
}
