import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgBell } from "./assets";
import { apiCall } from '../../../components/src/ApiCall.web';
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  notifications: any[];
  selectedData: any;
  token: any;
  anchorEl:any;
  checked:boolean;
  sortBy:string;
  selectedNotifications: any;
  currentSelected:any;
  currentPage:number
  totalPages:number;
  data:any,
  allSelected:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDataCallId: string = "";
  markAsReadCallId: string = "";
  createClientApiCallId: string = "";
  addNewUserDoneApiCallId: string = "";
  deleteNotificationsApiCallId: string = "";
  getNotificationsApiCallId:string="";
  deleteCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      notifications: [],
      selectedData: null,
      token: "",
      anchorEl: null,
      checked: false,
      sortBy:'new-old',
      selectedNotifications: [],
      currentSelected:null,
      totalPages:0,
      currentPage:1,
      data:[],
      allSelected:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getNotifications(this.state.currentPage);
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      switch(apiRequestCallId){
         case this.deleteNotificationsApiCallId:this.handleActionResponse(responseJson);
         break;
         case this.markAsReadCallId:this.handleActionResponse(responseJson);
         break;
        case this.getNotificationsApiCallId:this.handleNotificationsResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  iconBellProps = {
    source: imgBell,
  };

  handleNotificationsResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({ notifications: responseJson.data, currentPage: responseJson.meta.current_page, totalPages: responseJson.meta.total_pages},()=>{
        this.handleSortChange({target:{value:'new-old'}})
      });
    }
  }

  handleSortChange = (event: any) => {
    const sortedBy = event.target.value as string;
    let sortedData = [...this.state.notifications];
    if (sortedBy === 'A-Z') {
      sortedData.sort((a: any, b: any) =>
        a.attributes.contents.localeCompare(b.attributes.contents)
      );
    } else if (sortedBy === 'Z-A') {
      sortedData.sort((a: any, b: any) =>
        b.attributes.contents.localeCompare(a.attributes.contents)
      );
    } else if (sortedBy === 'old-new') {
      sortedData.sort((a: any, b: any) =>
        new Date(a.attributes.created_at).getTime() - new Date(b.attributes.created_at).getTime()
      );
    } else if (sortedBy === 'new-old') {
      sortedData.sort((a: any, b: any) =>
        new Date(b.attributes.created_at).getTime() - new Date(a.attributes.created_at).getTime()
      );
    }
    this.setState({ notifications: sortedData, sortBy: sortedBy });
  };

  formatDate(date:string) {
    const myDate = new Date(date);
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayName = days[myDate.getUTCDay()];
    let hours = myDate.getHours();
    const minutes = myDate.getMinutes().toString().padStart(2, '0'); // Ensure 2-digit minutes
    const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine AM/PM
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formatted = `${dayName} ${hours}:${minutes}${ampm}`;
    return formatted;
  }

  handleActionResponse = (responseJson: any) => {
    if (responseJson.message) {
      toast.success(responseJson.message);
      this.setState({selectedNotifications:[],allSelected:false})
      this.getNotifications(this.state.currentPage);
    }
  }

  handlePageChange = ( page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getNotifications(page)
    });
  };

  getNotifications = async (page:number) => {
    this.getNotificationsApiCallId = await apiCall({
      method: "GET",
      endPoint: `/bx_block_notifications/notifications/?is_admin=yes&page=${page}&per_page=8`,
      contentType: 'application/json',
      token: localStorage.getItem('token')
    })
  }

  selectAllNotifications = (isDeselect:boolean=false) => {
    if(isDeselect){
      this.setState({allSelected:false,selectedNotifications:[]})
      return
    }
    this.setState({ allSelected: !this.state.allSelected }, () => {
      const allNotifications = this.state.allSelected ? this.state.notifications.map(item => item.id) : [];
      this.setState({ selectedNotifications: allNotifications })
    })
    
  }

  markAsRead = async (isArray: boolean = true, id: any = '') => {
    let query = `/bx_block_notifications/notifications/mark_as_read?is_admin=yes&ids=`;
    query = query + (isArray ? this.state.selectedNotifications?.join(',') : id);
    this.markAsReadCallId  = await apiCall({
      method: "PATCH",
      contentType: "application/json",
      endPoint: query,
      token: localStorage.getItem("token"),
    });
  }

  deleteNotifications = async (isArray: boolean = true, id: any = '') => {
    let query = `/bx_block_notifications/notifications/destroy_multiple?is_admin=yes&ids=`;
    query = query + (isArray ? this.state.selectedNotifications?.join(',') : id);
    this.deleteNotificationsApiCallId  = await apiCall({
      method: "DELETE",
      contentType: "application/json",
      endPoint: query,
      token: localStorage.getItem("token"),
    });
  }

  convertDate(inputFormat: string) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("-");
  }

  handleClick = (event: any, notification: any) => {
    this.setState({ anchorEl: event.currentTarget, currentSelected: notification });
  };

  handleClose = (type: any) => {
    switch (type) {
      case 'mark_read': this.markAsRead(false, this.state.currentSelected?.id.toString());
        break;
      case 'delete': this.deleteNotifications(false, this.state.currentSelected?.id.toString());
        break;
    }
    this.setState({ anchorEl: null });
  };

  fontWeight = (notification: any) => notification.attributes.is_read ? 400 : 700;

  handleCheckboxChange = (notification: any) => {
    let selectedData = this.state.selectedNotifications;
    if (selectedData.includes(notification.id)) {
      selectedData = selectedData.filter((item: any) => item !== notification.id);
    } else {
      selectedData.push(notification.id)
    }
    this.setState({ selectedNotifications: selectedData });
  };
  // Customizable Area End
}
