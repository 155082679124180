import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Checkbox,
  Paper,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableFooter,
  TableRow,
  Pagination,
  Select as MuiSelect,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon, MoreVert as MoreVertIcon, ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  styled,
} from "@mui/material/styles";
import Sidebar from "../../../components/src/sidebar/Sidebar.web";
import AdminFooter from "../../../components/src/AdminFooter.web";
import { DeselectIcon } from "./assets";
import DOMPurify from 'dompurify';
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  sanitizeData = (htmlString: string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box display={"flex"} flexDirection="column" minHeight="100vh">
            <Box display={"flex"} flexGrow={1} overflow="hidden">
              <Box>
                <Sidebar
                  navigation={this.props.navigation}
                  current="admin_dashboard"
                />
              </Box>
              <Box sx={{ flexGrow: 1, padding: 2, overflow:'auto',marginTop:'20px' }}>
                <Typography sx={{ fontStyle:'Heebo',colot:'#1C1C1C',fontWeight:700,fontSize:'24px',lineHeight:'32px' }}>
                  Notifications
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft:'26px',
                    marginTop:'27px',
                    marginBottom:'16px'
                  }}
                >
                  {this.state.selectedNotifications.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box onClick={this.selectAllNotifications.bind(this,true)}><img src={DeselectIcon} style={{ marginRight: '20px' }} data-test-id="deselect-btn" /></Box>
                    <Typography>{this.state.selectedNotifications.length} item{this.state.selectedNotifications.length > 1 ? 's' : ''} selected</Typography>
                    <Button
                      variant="outlined"
                      data-test-id={'mark-all-read'}
                        sx={{
                          border: "1px solid #A8A29E", color: "black", opacity: 0.9, textTransform: "none", marginRight: '10px', marginLeft: '10px', "&:hover": {
                            borderColor: "#A8A29E"
                          },
                        }}
                      onClick={this.markAsRead.bind(this,true,'')}
                    >
                      Mark as read
                    </Button>
                    <Button
                      variant="outlined"
                      data-test-id={'delete-selected'}
                      color="error"
                      onClick={this.deleteNotifications.bind(this,true,'')}
                      sx={{ opacity: 0.9, textTransform: "none" }}
                    >
                      Delete
                    </Button>
                  </Box>
                )}
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2, marginLeft: "auto" ,paddingRight:'49px'}}>
                    <Typography variant="body2" color="neutral.700">
                      Sort by:
                    </Typography>
                    <MuiSelect
                        data-test-id="sort-select"
                        value={this.state.sortBy}
                        onChange={(e: any) => this.handleSortChange(e)}
                        defaultValue="relevance"
                        disableUnderline
                        variant='standard'
                        sx={{
                          paddingLeft: '10px',
                          width: '150px',
                          backgroundColor: 'transparent',
                          '& .MuiSelect-icon': {
                            color: 'gray',
                          },
                          '& .MuiOutlinedInput-root': {
                            backgroundColor: 'transparent',
                            border: '1px solid #ccc',
                            '& input': {
                              backgroundColor: 'transparent',
                              padding: ''
                            },
                          },
                        }}
                      >
                        <MenuItem value="A-Z">Name (A-Z)</MenuItem>
                        <MenuItem value="Z-A">Name (Z-A)</MenuItem>
                        <MenuItem value="old-new">Oldest to newest</MenuItem>
                        <MenuItem value="new-old">Newest to oldest</MenuItem>
                      </MuiSelect>
                  </Box>
                </Box>

                <Paper sx={{ borderRadius: 1,marginRight:'49px' }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      p: 2,
                      borderBottom: 1,
                      borderColor: "neutral.400",
                    }}
                  >
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: 'black',
                        },
                      }}
                      checked={this.state.allSelected}
                      onChange={()=>this.selectAllNotifications()}
                    />
                    <Typography
                      variant="body2"
                      sx={{ fontStyle:'Heebo',colot:'#1C1C1C',fontWeight:700,fontSize:'14px',lineHeight:'22px' }}

                    >
                      Notification
                    </Typography>
                  </Box>
                  <List>
                  {this.state.notifications?.map((notification, index) => {
   return   <ListItem key={index} data-test-id='index_notification' sx={{backgroundColor:!notification.attributes.is_read ? '#F1F1F1' : '#FFFFFF',marginBottom:'24px'}}>
        <ListItemIcon>
          <Checkbox
            data-test-id="checkbox-select"
            checked={this.state.selectedNotifications?.includes(notification.id)}
            onChange={this.handleCheckboxChange.bind(this,notification)}
            sx={{
              '&.Mui-checked': {
                color: 'black',
              },
            }}
          />

        </ListItemIcon>
        <ListItemText 
                    primary={
                      <Typography 
                        variant="body1" 
                        noWrap 
                        fontWeight={this.fontWeight(notification)}
                        style={webStyle.primary}
                      >
                        {this.sanitizeData(notification.attributes?.contents)}
                      </Typography>
                    }
                    secondary={
                      <Typography 
                        variant="body1" 
                        noWrap 
                        style={webStyle.secondary}
                      >
                    {this.formatDate(notification.attributes?.created_at)} 
                    </Typography>
                    }
                    
                  />
        <IconButton data-test-id='more-options' onClick={(event)=>this.handleClick(event,notification)}>
          <MoreVertIcon />
        </IconButton>
      </ListItem>
})}                </List>
                  <TableFooter
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '16px',
                      borderTop: '1px solid #E0E0E0',
                    }}
                  >

                    <Box sx={webStyle.paginationContainer}>
                      <Pagination
                        count={this.state.totalPages}
                        page={this.state.currentPage}
                        onChange={(e,page)=>this.handlePageChange(page)}
                        shape="rounded"
                        data-testID="pageChange"
                      />
                    </Box>
                  </TableFooter>
                </Paper>

                <Menu
                  anchorEl={this.state.anchorEl}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                  PaperProps={{
                    elevation: 1,
                    sx: {
                      width: 143,
                      mt: 1.5,
                      "& .MuiMenuItem-root": {
                        px: 4,
                        py: 1.5,
                      },
                    },
                  }}
                >
                  <MenuItem data-test-id={'mark_read'} onClick={this.handleClose.bind(this,'mark_read')}>Mark as read</MenuItem>
                  <MenuItem data-test-id={'delete'} onClick={this.handleClose.bind(this,'delete')} sx={{color:'red'}}>Delete</MenuItem>
                </Menu>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: 52,
                bgcolor: "primary.main",
                display: "flex",
                alignItems: "center",
                gap: 6,
              }}
            >
            </Box>
          </Box>
          <AdminFooter />
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  paginationContainer: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'center'
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  itemWrapper: {
    border: "1px solid #767676",
    display: "flex",
    width: "70%",
    flexDirection: "row" as "row",
    marginTop: 10,
    padding: 16,
    paddingTop: 10,
    cursor: "pointer",
  },
  primary: {
    fontStyle: 'Heebo',
    fontSize: '16px',
    lineHeight: '16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: '24px'
  },
  secondary: {
    marginTop: '10px',
    fontStyle: 'Heebo',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '14px',
    color: '#A8A29E'
  },
  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",
  },
  iconStyle: {
    width: 20,
    height: 26,
    marginTop: 6,
  },
  itemHeading: {
    color: "#000",
    flex: 1,
    fontWeight: 700,
  },
  itemHeadingRead: {
    color: "#6200EE",
    flex: 1,
    fontWeight: 700,
  },
  contents: {
    fontSize: 16,
    color: "#767676",
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};
const CustomPagination = styled(Pagination)({
  "& .MuiPaginationItem-root": {
      "&.Mui-selected": {
          backgroundColor: "transparent",
          color: "#6200EA",
          fontWeight: "700",
      },
  },
})
// Customizable Area End
