import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { MyContext } from '../../../components/src/NavigationRouteWrapper/Web';
import { apiCall } from "../../../components/src/ApiCall.web";
import Papa from "papaparse"
import { SelectChangeEvent } from '@mui/material/Select';
const baseURL = require("../../../framework/src/config.js").baseURL;
import dayjs from 'dayjs'
import debounce from 'lodash/debounce';

export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

export interface IUserProfile {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  job_title: string | null;
  company_name: string | null;
}

export interface IErrors {
  profile: string;
  token: string;
}
export interface IResponse {
   message: string;
   errors: IErrors[];
   status: number;
   error: string;
}

interface CSVData {
  firstName: string;
  lastName: string;
  email: string;
  roleType: string;
}

interface UserAttributes {
  first_name: string;
  last_name: string;
  email: string;
  job_title: string;
  created_at: string;
  updated_at: string;
  company_name: string;
}

interface User {
  id: string ;
  type: string;
  attributes: UserAttributes;
}

interface MetaData {
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
  total_pages: number;
  total_count: number;
}

interface UserData {
  data: User[];
  meta: MetaData;
}

interface ClientResponse {
  data: ClientData[];
  meta: {
    message: string;
  };
}

interface ClientData {
  id: string;
  type: string;
  attributes: ClientAttributes;
}

interface ClientAttributes {
  company_name: string;
  contact_name: string;
  contact_email: string;
  contact_job_title: string;
  contact_phone: string;
  created_at: string;
  updated_at: string;
  company_logo: CompanyLogo;
}

interface CompanyLogo {
  id: number;
  date: string;
  url: string;
}

interface ClientInfo {
  id:string;
  company_name:string
}

interface NewUserData{
  firstName: string,
  lastName: string,
  jobTitle: string,
  email: string,
  role: "ADMIN",
}

interface EditUserData{
  firstName: string,
  lastName: string,
  jobTitle: string,
  email: string,
  role: string,
  id:string,
  companyName:string
}

interface BulkFile {
  id: number;
  date: string; 
  url: string;
}

interface Attributes {
  bulk_file: BulkFile;
}

interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

interface ExampleFile {
  data: Data;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  loading: boolean;
  openDialog: string | null;
  userDetail: IUserProfile | null;
  file:File | null,
  csvData:CSVData[],
  csvModal:boolean,
  csvDataUpload:boolean
  isDoneClicked: boolean, 
  isValid: boolean,
  anchorEl: any;
  anchorElSort: any;
  currentPage:number
  totalPages:number
  userData:User[]
  addNewUserModal:boolean
  clientInfo:ClientInfo[]
  selectedClient:{
    clientId:string
    clientName:string
  }
  isSelectAll:boolean;
  viewUserModal:boolean;
  deleteUserModal:boolean
  newUserData:NewUserData
  currentSortKey:string
  userOption:boolean
  currentUser:User | null
  editUserModal:boolean,
  userAnchorEl:any
  editUser:EditUserData
  deleteArr:string[]
  searchQuery:string | null,
  originalData:any,
  emailError:any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  userDetailApiCallId: string = "";
  resetPasswordApiCallId: string = "";
  exportApiCallId:string = "";
  csvFileUploadApiCallId:string = ""
  getUserIndexApiCallId:string = ""
  getClientNamesApiCallId:string = ""
  addNewUserDoneApiCallId:string = ""
  exampleFileApiCallId:string=""
  editUserDoneApiCallId:string= ""
  deleteUserDoneApiCallId:string=""
  bulkdeleteUserDoneApiCallId:string=""
  searchQueryApiCallId:string=""

  sortKeys = [
    { key: 'nameAsc', label: 'Name (A-Z)' },
    { key: 'nameDesc', label: 'Name (Z-A)' },
    { key: 'newest', label: 'Newest to Oldest' },
    { key: 'oldest', label: 'Oldest to Newest' },
  ];

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],
      emailError:'',
      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
      isSelectAll:false,
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      loading: false,
      openDialog: null,
      userDetail: null,
      file:null,
      csvData:[],
      csvModal:false,
      csvDataUpload:false,
      isDoneClicked: false,
      isValid: true,
      anchorEl: null,
      anchorElSort: null,
      totalPages:0,
      currentPage:1,
      userData:[],
      addNewUserModal:false,
      clientInfo:[],
      selectedClient:{
        clientId:"",
        clientName:""
      },
      deleteUserModal:false,
      viewUserModal:false,
      newUserData: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
        role: "ADMIN",
      },
      currentSortKey:"Name (A-Z)",
      userOption:false,
      currentUser: null,
      editUserModal:false,
      userAnchorEl:null,
      editUser:{
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
        role: "",
        companyName:"",
        id:""
      },
      deleteArr:[],
      searchQuery:"",
      originalData:[],
      // Customizable Area End
    };
    // Customizable Area Start
    CustomisableUserProfilesController.contextType = MyContext;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    this.getUserDetailApi();
    this.getUserIndex();
    this.getClientNames();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.userDetailApiCallId) {
        if (responseJson.data) {
          this.setState({ userDetail: responseJson.data.attributes });
        }
        this.setState({ loading: false });
      } else {
        this.handleResponse(apiRequestCallId, responseJson);
        this.handleApiResponse(apiRequestCallId,responseJson)
      }
      this.setState({ loading: false });
    }

    this.handleUserManagementResponse(message)

    // Customizable Area End
  }

  // Customizable Area Start
  handleResponse = (apiRequestCallId: string, responseJson: IResponse) => {
    if (apiRequestCallId === this.resetPasswordApiCallId) {
      const { message, errors, status, error } = responseJson;
      if (message) {
        this.setState({ openDialog: "alert" });
      } else if (Array.isArray(errors)) {
        const errorType = errors[0]?.token || errors[0]?.profile;
        if (errorType) {
          toast.error(errorType);
        }
      } else if (status === 500) {
        toast.error(error);
      }
    }
    if(apiRequestCallId === this.exportApiCallId){
      this.downloadCsvFile(responseJson)
    }
    else if(apiRequestCallId === this.searchQueryApiCallId){
      this.handleSearchResponse(responseJson)
    }else if(apiRequestCallId === this.csvFileUploadApiCallId){
      this.handleBulkUploadResp(responseJson);
    }
  }

   validateEmail = (email:string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  }
  
  handleBulkUploadResp = (responseJson: any) => {
    if (responseJson.message) {
      toast.success(responseJson.message);
    } else {
      toast.error(responseJson.errors || 'Something went wrong')
    }
  }

  handleApiResponse = (apiRequestCallId:string,responseJson:UserData)=>{  
    if(apiRequestCallId === this.getUserIndexApiCallId){
      this.handleGetUserIndexResponse(responseJson)
    }
  }

  handleUserManagementResponse = (message:Message)=>{
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch(apiRequestCallId){
      case this.getClientNamesApiCallId :
        this.handleGetClientResponse(responseJson)
        break;

      case this.exampleFileApiCallId:
        this.downloadTemplateFile(responseJson as ExampleFile)
        break;

      case this.addNewUserDoneApiCallId:
        this.getUserIndex();
        this.handleNewUserResponse(responseJson);
        break;

      case this.deleteUserDoneApiCallId:
        this.getUserIndex();
        this.setState({deleteArr:[]})
        toast.success(responseJson.message)
        break;

      case this.bulkdeleteUserDoneApiCallId:
        this.handleReceiveBulkResponse(responseJson)
        break;
      }

  }

  handleGetClientResponse = (responseJson:ClientResponse)=>{
    if (!responseJson.data) { return }
    const data = responseJson.data.map((client)=>{
      const {id,attributes} = client;
      return {
      id,
      company_name: attributes.company_name,
      }
    })
    this.setState({clientInfo : data})
  }

  handleNewUserResponse = (responseJson: any) => {
    if (responseJson.meta) {
      toast.success(responseJson.meta.message);
      this.handleAddNewUserModalClose();
    } else if (responseJson.errors.email) {
      this.setState({emailError:'*Email ' + responseJson.errors.email[0]})
    } else if (responseJson.errors.contact_email) {
      this.setState({emailError:'*Email ' + responseJson.errors.contact_email[0]})
    }
  }

  handleReceiveBulkResponse = (responseJson: any) => {
    this.getUserIndex()
    toast.success(responseJson.message)
  }
  handleClose = () => {
    this.setState({ openDialog: null });
  };

  onButtonClick = (val: string) => {
    this.setState({ openDialog: val });
  }

  onLogout = () => {
    const { setAccessPermission } = this.context as any;
    setAccessPermission?.(false);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("p");
    localStorage.removeItem("u");
    this.props.navigation.replace("EmailAccountRegistration");
  }

  getUserDetailApi = async () => {
    this.setState({ loading: true });
    this.userDetailApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/account_block/accounts/account_details`,
    });
  }

  resetPasswordApi = async (values: { password: string; confirmPassword: string; }) => {
    this.setState({ loading: true });
    this.resetPasswordApiCallId = await apiCall({
      method: "PATCH",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/passwords/reset_password`,
      body: {
        "data": {
          "new_password": values.password,
          "confirm_password": values.confirmPassword,
        }
      }
    });
  }

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };


  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {
    if (field.field_type === "integer" && (event.key === "." || event.key === ",")) {
      event.preventDefault();
    }
  };

  getCustomizableProfileFields = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileFieldsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileFieldsEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getCustomizableProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };
  handleSelectAllCheckboxToggle = () => {
    this.setState({ isSelectAll: !this.state.isSelectAll }, () => {
      if (this.state.isSelectAll) {
        this.handleAllCheckboxChange(this.state.userData.map(item => item.id))
      } else {
        this.handleAllCheckboxChange([])
      }
    })
  }
  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getProfileAccountID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.baseURLGetProfileAccount
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchAttributes = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getAttributesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFields
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateProfile = async () => {
    const header = {
      "Content-Type": "application/json",
      token: configJSON.temporaryToken
    };

    const deliveredData = this.state.attributes
      .map((item) => {
        if (item && item.is_enable) {
          return {
            [item.name]: item.value
          };
        }
      })
      .filter((item) => item != undefined);

    const filteredPosts: IProfileData = {};

    deliveredData.map((item) => {
      filteredPosts[Object.keys(item as object)[0]] = Object.values(item as object)[0];
    });

    const dataToBeUsed = {
      profile: filteredPosts
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.updateProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(dataToBeUsed)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
  };

  handleDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files) {
      const file = event.dataTransfer.files[0];
      this.handlCSVFile(file)
    }
  }; 

  handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | any,
  ) => {
    if (event?.target?.files) {
      const file = event.target.files[0];
      this.handlCSVFile(file)
    }
  };

  handleDoneClick = () => {
    const isValid = this.validateCSVData(this.state.csvData);
    this.setState({
      isDoneClicked: true,
      isValid: isValid,
    });
  };

  validateCSVData = (data: CSVData[]): boolean => {
      const requiredFields: (keyof CSVData)[] = [
        'firstName',
        'lastName',
        'email',
        'roleType',
      ];
      for (const row of data) {
        for (const field of requiredFields) {
          if (!row[field]) {
            this.setState({ isValid: false });
            return false;
          }
        }
      }
  
      this.setState({ isValid: true });
      return true;
  };
  

  getClientNames = async ()=> {
    this.getClientNamesApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_account_groups/clients`,
    });
  }

  handlCSVFile = (file:File | any) => {
  const fileExtension = file?.name?.split(".")[1]
        if (fileExtension === "csv") {
          this.setState({ file }, () => {
            const reader = new FileReader();
            reader.onload = (event: ProgressEvent<FileReader>) => {
              const csv = event.target?.result;
              if (csv) {
                Papa.parse(csv.toString(), {
                  header: true, 
                  skipEmptyLines: true,
                  complete: (results) => {
                    const data = this.transformCSVData(results.data)
                    this.setState({ csvData: data },()=>{
                      this.setState({csvDataUpload:true})
                    });
                  },
                });
              }
            };
            reader.readAsText(file);
          });
        } 
  }

  transformCSVData = (data: any[]): CSVData[] => {
    const headerMap: { [key: string]: keyof CSVData } = {
      'first_name': 'firstName',
      'last_name': 'lastName',
      'email': 'email',
      'role': 'roleType',
    };
    return data.map(row => {
      const transformedRow: Partial<CSVData> = {};
      for (const header in headerMap) {
        const field = headerMap[header];
        transformedRow[field] = row[field];
      }
      return transformedRow as CSVData;
    });
  };

  handleCSVFileUpload = async () => {
    const formattedData = {
      admins: this.state.csvData.map((user) => ({
        first_name: user.firstName.trim(),
        last_name: user.lastName.trim(),
        email: user.email.trim(),
        role_type: 'admin',
      }))
    };

    this.csvFileUploadApiCallId = await apiCall({
      method: "POST",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/admin_managements/bulk_upload_for_admin`,
      body: formattedData
    });
    this.handleModalClose();
  };

  exportCSV = async () => {
    this.exportApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/admin_managements/csv_export_for_admin`,
    });
  };

  handleModalOpen = () => this.setState({ csvModal: true });

  handleModalClose = () => this.setState({ csvModal: false, 
    file: null,
    csvDataUpload:false,
    isDoneClicked: false,
    isValid: true,
  });

  getUserIndexWithQuery = async (page:number) =>{
    this.getUserIndexApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/admin_managements?query=${this.state.searchQuery}&page=${page}&per_page=10`,
    });
  }
  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page }, () => {
      this.getUserIndexWithQuery(page)
    });
  };

  downloadCsvFile = (responseJson:any) => {
    const link = document.createElement("a");
    link.href = `${baseURL}${responseJson.csv_url}`;
    link.download = "filename.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  downloadExampleFile = async ()=>{
    this.exampleFileApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_admin/user_managements/download_template`,
    });
  }

  downloadTemplateFile = (responseJson:ExampleFile)=>{
    const templateLink = document.createElement("a");
    templateLink.href = responseJson.data.attributes.bulk_file.url;
    templateLink.download = "example.csv";
    document.body.appendChild(templateLink);
    templateLink.click();
    document.body.removeChild(templateLink);
  }

  getUserIndex = async () =>{
    this.getUserIndexApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/admin_managements?page=${this.state.currentPage}&per_page=10`,
    });
  }
  
  handleGetUserIndexResponse = (responseJson:UserData)=>{
    const data = responseJson.data
    this.setState({
      userData: data,
      originalData:data,
      currentPage: responseJson.meta.current_page,
      totalPages: responseJson.meta.total_pages,
    });
  }
  
  handleSearchResponse = (responesJson:any)=>{
    if(responesJson.data){
      this.setState({
      userData:responesJson.data
    })
    }
    else{
      this.setState({userData:[]})
    }
  }

  convertDate = (isoString:string)=>{
    return dayjs(isoString).format("DD.MM.YYYY hh:mm")
  }

  handleAddNewUserModalClose = ()=>{
    this.setState({
      addNewUserModal:false
    })
  }

  handleAddNewUserModalOpen = ()=>{
    this.setState({
      addNewUserModal:true,
      newUserData:{
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
        role: "ADMIN",
      }
    })
  }

  handleChangeClient = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const selectedId = event.target.value
    const selectedClient = this.state.clientInfo.find(client => client.id === selectedId);

    if (selectedClient) {
      this.setState({
        selectedClient: {
          clientId: selectedClient.id,
          clientName: selectedClient.company_name,
        },
      });
    }
  };

  handleUserDataChange = (field: keyof NewUserData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      emailError:'',
      newUserData: {
        ...prevState.newUserData,
        [field]: event.target.value,
      },
    }));
  };

  handleAddNewUserDoneClick = async () => {
    const { newUserData, selectedClient } = this.state;
    if (newUserData.email.length && !this.validateEmail(newUserData.email)) {
      this.setState({ emailError: 'Please enter a valid email address.' });
      return;
    }
    if (this.state.newUserData.firstName.trim() == '' || this.state.newUserData.lastName.trim() == '' || this.state.newUserData.email.trim() == '') {
      toast.error('Please fill user details.');
      return;
    }
    this.setState({ emailError: '' });
    const dataToSubmit = {
        admin: {
          first_name: newUserData.firstName,
          last_name: newUserData.lastName,
          email: newUserData.email,
        }
      }  
    this.addNewUserDoneApiCallId = await apiCall({
      method: "POST",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/admin_managements`,
      body: dataToSubmit,
    });

  };
  
  handleUserOptionClick = (event:React.MouseEvent<HTMLButtonElement> , user:User) => {
    this.setState({
      userAnchorEl: event.currentTarget,
      currentUser: user,
      userOption:true,
      deleteArr:[],
      editUser:{
        email:user.attributes.email,
        firstName:user.attributes.first_name,
        lastName:user.attributes.last_name,
        jobTitle:user.attributes.job_title,
        role:user.type,
        companyName:user.attributes.company_name,
        id:user.id
      }
    });
  };

  handleViewUserModalClose = ()=>{
    this.setState({viewUserModal:false})
  }

  handleViewUserModalOpen = ()=>{
    this.setState({viewUserModal:true})
    this.handlePopoverClose();
  }

  handleDeleteUserModalClose = ()=>{
    this.setState({deleteUserModal:false})
    this.handlePopoverClose();
  }

  handleDeleteUserModalOpen = ()=>{
    this.setState({deleteUserModal:true})
    //this.handlePopoverClose();
  }

  handleEditUserModalClose = ()=>{
    this.setState({editUserModal:false})
  }

  handleEditUserModalOpen = ()=>{
    this.setState({editUserModal:true})
    this.handlePopoverClose();
  }
  
  sortByDate = (list:User[], isNewest = true) => {
    return list.sort((a, b) => {
      const dateA = new Date(a.attributes.created_at ?? 0).getTime();
      const dateB = new Date(b.attributes.created_at ?? 0).getTime();
      return isNewest ? dateB - dateA : dateA - dateB;
    });
  };

  handleSort = (sortKey:any) => {
    let sortedData;

    switch (sortKey.key) {
      case 'nameAsc':
        sortedData = [...this.state.userData].sort((a, b) => {
          const fullNameA = `${a.attributes.first_name} ${a.attributes.last_name}`.toLowerCase();
          const fullNameB = `${b.attributes.first_name} ${b.attributes.last_name}`.toLowerCase();
          return fullNameA.localeCompare(fullNameB);
        });
        break;

      case 'nameDesc':
        sortedData = [...this.state.userData].sort((a, b) => {
          const fullNameA = `${a.attributes.first_name} ${a.attributes.last_name}`.toLowerCase();
          const fullNameB = `${b.attributes.first_name} ${b.attributes.last_name}`.toLowerCase();
          return fullNameB.localeCompare(fullNameA);
        });
        break;

      case 'newest':
        sortedData = this.sortByDate([...this.state.userData], true); 
        break;
  
      case 'oldest':
        sortedData = this.sortByDate([...this.state.userData], false); 
        break;

      default:
        sortedData = this.state.userData;
        break;
    }

    this.setState({
      userData: sortedData,
      currentSortKey: sortKey.label,
      anchorElSort: null,
    });
  };

  handlePopoverClick = (event:React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorElSort: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorElSort: null, userAnchorEl:null});
  };

  isSortPopoverOpen = () => Boolean(this.state.anchorElSort);
  
  handleCloseUserOption = () => Boolean(this.state.userAnchorEl);
  
  handleEditUserDoneClick = async () => {
    const { editUser } = this.state;
    const companyName = this.state.clientInfo.find(client => client.id === editUser.companyName);

    const dataToSubmit = {
      data: {
        first_name: editUser.firstName,
        last_name: editUser.lastName,
        job_title: editUser.jobTitle,
        client_id: editUser.companyName,
        company_name:companyName
      },
    };
  
    this.editUserDoneApiCallId = await apiCall({
      method: "PATCH",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/user_managements/${this.state.editUser.id}`,
      body: dataToSubmit,
    });

    this.handleEditUserModalClose()
    this.handlePopoverClose()
  }

  handleInputChange = (field: keyof EditUserData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState((prevState) => ({
      editUser: {
        ...prevState.editUser,
        [field]: event.target.value,
      },
    }));
  };

  handleEditChangeClient = (event: SelectChangeEvent<string>, child: React.ReactNode) => {    
      this.setState((prevState)=>({
        editUser:{
          ...prevState.editUser,
          companyName : event.target.value
        }
      }));
  }

  handleDeleteUser = async (id:string) => {
    this.deleteUserDoneApiCallId = await apiCall({
      method: "DELETE",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/admin_managements/bulk_delete_admin?ids=${id}`,
      
    });
    this.handleDeleteUserModalClose()
    this.handlePopoverClose()
  }

  handleCheckboxChange = (userId:string) => {
    this.setState({ currentUser: null });
    this.setState((prevState) => {
      const { deleteArr } = prevState;
      if (deleteArr.includes(userId)) {
        return { deleteArr: deleteArr.filter((id) => id !== userId) };
      } else {
        return { deleteArr: [...deleteArr, userId] };
      }
    });
  };

  handleAllCheckboxChange = (userId: string[]) => {
    this.setState({ currentUser: null });
    this.setState((prevState) => {
      const { deleteArr } = prevState;
      this.handleCheckboxChange
        return { deleteArr: [...userId]
        }
    });
  };

  isUserSelected = (userId:string) => {
    return this.state.deleteArr.includes(userId);
  };

  handleBulkDelete = async () => {
    this.bulkdeleteUserDoneApiCallId = await apiCall({
      method: "DELETE",
      contentType: 'application/json',
      navigation: this.props.navigation,
      endPoint: `bx_block_account_groups/admin_managements/bulk_delete_admin`,
      token: localStorage.getItem("token"),
      body:{
        "ids":this.state.deleteArr
      }
    });

    this.setState({
      deleteArr:[],
    },() => {
      this.handleDeleteUserModalClose();
      this.getUserIndex();
    })
  }

  handleDeselectAll = () => {
    this.setState({ deleteArr: [] });
    this.setState({ isSelectAll: false });
  };
  
  handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value.toLowerCase();
    this.setState({
      searchQuery: event.target.value,
    });
    this.debounceSearchUser(searchQuery)
  }
  debounceSearchUser = debounce( async (query:string) => {
    if (query !== "") {
        this.getUserIndexApiCallId = await apiCall({
          method: "GET",
          contentType: "application/json",
          token: localStorage.getItem("token"),
          endPoint: `bx_block_account_groups/admin_managements?query=${query}&page=${this.state.currentPage}&per_page=10`,
        });
    } else if (query === "") {
        this.getUserIndex();
    }
},500)
  // Customizable Area End
}
