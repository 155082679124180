//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { createRef } from 'react';
import { toast } from "react-toastify";
import DOMPurify from 'dompurify';
import { dummyImage, dummyVideo, userImage } from './assets';
import { v4 as uuidv4 } from 'uuid';
import { apiCall } from '../../../components/src/ApiCall.web';
import {uploadFileToApi} from '../../../components/src/UploadFile.web';
import cloneDeep from 'lodash/cloneDeep';

const defaultCarouselContent = {
  media: dummyImage,
  type: "image",
  heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">This is heading for carousel</p>`,
  description: `<p style="font-family:'Crimson Text';font-size:18px">This is subtext</p>`,
};

const defaultContent = {
  "Text": {
    type: "Text",
    paddingTop: 30,
    paddingBottom: 30,
    width: "50vw",
    text_format :'',
    heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">Heading</p>`,
    content: `<p style="font-family:'Crimson Text';font-size:20px">At its core, UX design is about enhancing the overall experience a user has while interacting with a product, whether it's a website, an app, or any digital tool. UX design involves understanding the users' needs, behaviors, and motivations to create products that solve real problems in an intuitive way.</p>`,
  },
  "List": {
    type: "List",
    paddingTop: 30,
    paddingBottom: 30,
    width: "50vw",
    listType: "unordered",
    heading: `<p style="font-family:'Crimson Text';font-size:24px;font-weight:bold;">This is the subheading with bullet points.</p>`,
    content: `<ul>
        <li style="font-family:'Crimson Text';font-size:18px;">Research: Gathering information about users and their needs through interviews, surveys, and observations.</li>
        <li style="font-family:'Crimson Text';font-size:18px;">Empathy: Understanding and sharing the feelings of users, which helps in creating user-focused designs.</li>
        <li style="font-family:'Crimson Text';font-size:18px;">Defining the Problem: Based on the research, identifying the core issues users face that need to be addressed.</li>
      </ul>`
  },
  "Image": {
    type: "Image",
    paddingTop: 30,
    paddingBottom: 30,
    width: "70vw",
    image: dummyImage,
    caption: "This is the image caption, you can left it empty if not needed"
  },
  "Video": {
    type: "Video",
    paddingTop: 30,
    paddingBottom: 30,
    width: "50vw",
    video: dummyVideo,
    caption: "This is the caption of the video you can add, if not needed then leave empty"
  },
  "Quote": {
    type: "Quote",
    paddingTop: 30,
    paddingBottom: 30,
    width: "50vw",
    image: userImage,
    content: `<blockquote style="text-align:center;"><span style="font-size:16px;font-family:Crimson Text;">"Consistency is one of the most powerful usability principles: when things always behave the same, users don't have to worry about what will happen."</span></blockquote>`,
    name: `<p style="text-align:center;"><span style="font-size:20px;font-family:Crimson Text;">Jakob Nielson</span></p>`,
  },
  "Labeled graphic":{
    type: "Label",
    paddingTop: 30,
    width:'50vw',
    paddingBottom: 30,
    image: dummyImage,
    content:[
      {
        id:'1',
        x:100,
        y:100,
        title:`<p style="font-family:'Crimson Text';font-size:16px;font-weight:bold;">Heading of Card</p>`,
        description: `<p style="font-family:'Crimson Text';font-size:14px">This is the subtext that you can edit in the editor</p>`,
        visibleContent:false,
        contentRightSide:true
      }
    ]
  },
  "Carousel": {
    type: "Carousel",
    paddingTop: 30,
    paddingBottom: 30,
    width: "50vw",
    content: [defaultCarouselContent,defaultCarouselContent]
  },
  "Table":{
    type:"Table",
    paddingTop: 30,
    paddingBottom: 30,
    width: "50vw",
    heading: "Default heading",
    table_rows_attributes: [
      {
        id:'row-1',
        "column1": {id:'1',value:`<p style="font-size:24px;text-align:center;font-weight:bold">UX Design</p>`},
        "column2": {id:'2',value:`<p style="font-weight:bold;font-size:24px;text-align:center">UI Design</p>`},
      },
      {
        id:'row-2',
        "column1": {id:'5',
          value:"<p>Overall experience a user has while interacting with a product</p>"},
        "column2": {id:'6',
          value:"<p>Visual and interactive elements that a user interacts with</p>"},
      },
      {
        id:'row-3',
        "column1": {
          value:"<p>Enhance usability, ease of use, and satisfaction</p>",
          id:'9',
        },
        "column2": {
          value:"<p>Create an appealing and intuitive interface for users</p>",
          id:'10',
        },
      },
      {
        id:'row-4',
        "column1": {
          value:"<p>User research, persona development, user flow, journey mapping</p>",
          id:'11',
        },
        "column2": {
          value:"<p>Layout design, selecting colors, buttons, and typography</p>",
          id:'12',
        },
      },
      {
        id:'row-5',
        "column1": {
          value:"<p>Tools like Figma, Adobe XD, Sketch, Miro (for wireframing and prototyping)</p>",
          id:'13',
        },
        "column2": {
          value:"<p>Tools like Adobe Photoshop, Figma, Illustrator (for visual design)</p>",
          id:'114',
        },
      }
    ]
  },
  "Flash card":{
    type:"Card",
    paddingTop: 30,
    paddingBottom: 30,
    cards:[
      {
        id:'1',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 1</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">True. Both UX and UI designers often use these tools for different purposes—UX for wireframes/prototypes and UI for visual designs.</p>`,
        flipped:true
      },
      {
        id:'2',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 2</p>`,
        back:`<p style="font-family:'Crimson Text'font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'3',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 3</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'4',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 4</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'5',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 5</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
      {
        id:'6',
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card 6</p>`,
        back:`<p style="font-family:'Crimson Text';font-size:14px">Back of Card 2</p>`,
        flipped:false
      },
    ]
  },
  "Test knowledge":{
    type:'Test',
    paddingTop: 30,
    paddingBottom: 30,
    width: "50vw",
    content:{
      question:'Enter your question here ',
      isSubmit:false,
      answerCorrect:false,
      isMultiple:false,
      supportingText:'This is the text that will appear after the submission of correct answer.',
      supportingTextInCorrect:'This is the text that will appear after the submission of incorrect answer.',
      options:[
        {
          id:'1',
          answer:'This is the option one to be selected if right.',
          isSelected:false,
          isCorrect:false
        },
        {
          id:'2',
          answer:'This is the option two to be selected if right.',
          isSelected:false,
          isCorrect:false
        },
        {
          id:'3',
          answer:'This is the option three to be selected if right or wrong.',
          isSelected:false,
          isCorrect:false
        },
        {
          id:'4',
          answer:'All of the above.',
          isSelected:false,
          isCorrect:false
        },
      ]
    }
  },
  "Sorting": {
    type: "Sorting",
    paddingTop: 30,
    paddingBottom: 30,
    width: "50vw",
    content:[
      {
        id:'drop-1',
        title:'Category 1',
        items:[
          {
            id:'item-1-1',
            title:'Item 1',
            category_id:'drop-1'
          },
          {
            id:'item-1-2',
            title:'Item 2',
            category_id:'drop-1'
          }
        ]
      },
      {
        id:'drop-2',
        title:'Category 2',
        items:[
          {
            id:'item-2-1',
            title:'Item 3',
            category_id:'drop-2'
          },
          {
            id:'item-2-2',
            category_id:'drop-2',
            title:'Item 4'
          }
        ]
      }
    ]
  },
  "Button":{
    type:"Button",
    paddingTop: 50,
    paddingBottom: 50,
    width: "50vw",
    finishButton:false,
  }
};

export type PaginationProps = {
  pages: number[];
  activePage: number;
  onClick: (page: number) => void;
}

export type CarouselButtonProps = {
  type: "PREV" | "NEXT";
  isEdge: boolean;
  onClick: () => void;
}

export interface DefaultInputTitle {
  title: string,
  isActiveToChange: boolean
}

export interface LessonItem {
  id: number,
  lessonTitle: DefaultInputTitle,
  isPopUpActive: boolean;
  courseData: any[];
  quizData: any[];
  assessmentData: any;
}

export interface LessonCreationBar {
  title: string,
  isActive: boolean,
}

export interface Position {
  x: number,
  y: number
}

export type LessonListType = "ordered" | "unordered" | "grid";
export const crypto = require('crypto');

export interface ICourseImage {
  id?: string,
  image?: string,
  fileName?: string
};
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  navigation: object;
  idContent: string | number;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  description: string;
  lessonCreationBar: LessonCreationBar;
  lessonList: LessonItem[];
  lessonListType: LessonListType;
  courseTitle: string;
  authorName: string
  lessonTitle: string;
  lessonAuthorName: string;
  currentLessonId: number;
  isLessonVisible: boolean;
  isToolbarVisible: boolean;
  isFormatVisible: boolean;
  courseData: any;
  currentObj: any;
  currentIndex: number;
  isDrawerOpen: boolean;
  buttonType: string;
  isSidebarOpen: boolean;
  top: number;
  bottom: number;
  isPreview: boolean;
  draggedItemIndex: null | number;
  isQuizVisible: boolean;
  isCourseLearning: boolean;
  selectedCourseId: number;
  selectedCourseIndex: number;
  isFromMainPage: boolean;
  selectedId: number;
  quizData: any;
  quizObj: any;
  isQuizEditorVisibile: boolean;
  draggingIndex:number;
  currentParentIndex:number;
  offset:{x:number,y:number};
  selectedRowId: number,
  selectedColumnIndex: number,
  selectedCellId:string,
  flip:boolean,
  isLoadingFile:boolean,
  currentQuestion: any;
  errors: null | Array<string>;
  isErrorVisible: boolean;
  learningPath: string[];
  requirement: {
    "duration": string,
    "course_requirements": string,
  }
  courseStatus:string;
  imageObj: ICourseImage;
  coverImg: ICourseImage;
  coverDescImg: ICourseImage;
  assessmentTitle: string;
  assessmentRequirements: any;
  currentAssessmentData: any;
  isFinalAssessment: boolean;
  isCurrentAssessmentFinal: boolean;
  finalAssessmentQuizData: null;
  assessmentsList: any;
  assessmentDataSaved: boolean;
  courseId:number,
  courseModuleIDs:Array<number>;
  titleLoader: boolean;
  isCourseSidebarOpen: boolean;
  plusAnchorEl: HTMLElement | null;
  isUpload: boolean;
  isEditingCourse:boolean;
  selectedContentId:string
  isShowRenderCard: boolean;
  isDeletingContent:boolean;
  currentContentIndex:number;
  currentContentId:string;
  currentPreviewQuestion:any;
  currentPreviewAssessmentData:any;
  anchorEl:any;
  isEditingSortType:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  lessonPopupMenuRef: React.RefObject<HTMLDivElement>;
  lessonTypeMenuRef: React.RefObject<HTMLDivElement>;
  bottomRef: React.RefObject<HTMLDivElement>;
  formatRef: React.RefObject<HTMLDivElement>;
  createAssessmentApiCallId: string;
  createFinalAssessmentApiCallId: string = "";
  createCourseApiCallId: string = "";
  editCourseApiCallId:string ="";
  getCourseInfoApiCallId: string = "";
  publishCourseApiCall:string = "";
  getAllAssessmentApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      description: `<p>This is the starting of the course description. Following are the numbered formatting.</p>`,
      lessonCreationBar: { title: "", isActive: true },
      isLoading: false,
      lessonList: [],
      lessonListType: "unordered",
      courseTitle: "",
      authorName: "",
      lessonTitle: "",
      lessonAuthorName: "",
      isInitialLoad: true,
      currentLessonId: -1,
      isLessonVisible: false,
      isToolbarVisible: false,
      courseData: [],
      currentObj: {},
      top: 0,
      bottom: 0,
      currentIndex: -1,
      isDrawerOpen: false,
      isSidebarOpen: false,
      buttonType: "",
      isPreview: false,
      draggedItemIndex: null,
      isQuizVisible: false,
      isCourseLearning: false,
      selectedCourseId: 0,
      courseId: -1,
      selectedCourseIndex: -1,
      isFromMainPage: false,
      selectedId: -1,
      quizData: [],
      quizObj: {},
      isQuizEditorVisibile: false,
      offset: { x: 0, y: 0 },
      draggingIndex:null,
      selectedRowId: null,
      selectedColumnIndex: null,
      selectedCellId:null,
      flip:false,
      currentQuestion: null,
      currentPreviewQuestion:null,
      currentPreviewAssessmentData:null,
      errors: null,
      isErrorVisible: false,
      selectedContentId:'',
      learningPath: [],
      requirement: {
        "duration": "",
        "course_requirements": "",
      },
      imageObj: { id: "", image: "", fileName: "" },
      coverImg: { id: "", image: "", fileName: "" },
      coverDescImg: { id: "", image: "", fileName: "" },
      assessmentTitle: "",
      assessmentRequirements: null,
      assessmentsList:[],
      currentAssessmentData: null,
      isFinalAssessment: false,
      isCurrentAssessmentFinal: false,
      finalAssessmentQuizData: null,
      assessmentDataSaved:false,
      titleLoader: false,
      isCourseSidebarOpen: true,
      plusAnchorEl: null,
      isUpload: false,
      isEditingCourse:false,
      isDeletingContent:false,
      isLoadingFile:false,
      isEditingSortType:false,
      anchorEl:null,
      isShowRenderCard: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
        this.lessonPopupMenuRefs = {};
        this.lessonTypeMenuRef = createRef<HTMLDivElement>();
        this.bottomRef = createRef<HTMLDivElement>();
        this.formatRef = createRef<HTMLDivElement>();
        this.createAssessmentApiCallId = '';
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.createAssessmentApiCallId:
            this.handleCreateAssessmentResponse(responseJson);
            break;
        
          case this.createCourseApiCallId:
            this.handleCreateCourseResponse(responseJson);
            break;
        
          case this.getCourseInfoApiCallId:
            this.handleCourseInfoApiResponse(responseJson);
            break;
        
          case this.createFinalAssessmentApiCallId:
            this.handleFinalAssessmentResponse(responseJson);
            break;
        
          case this.editCourseApiCallId:
            this.handleUpdateCourse(responseJson);
            break;

          case this.publishCourseApiCall:
            this.handlePublishCoursesResponse(responseJson)
            break
          case this.getAllAssessmentApiCallId:
            this.handleGetAllAssessment(responseJson)
            break
          default:
            break;
        }
        this.setState({ isLoading: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleGetAllAssessment = (res:any)=>{
    if(res.data){
      this.setState({
        currentAssessmentData:res.data.attributes,
        quizData:res.data.attributes.questions.data.map(question=>({...question.attributes,answer_options_attributes:[...question.attributes.answer_options]}))
      })
    }
  }
  handlePublishCoursesResponse=(res:any)=>{
    if(res.message){
      toast.success("The course has been published successfully.")
    this.getCourseInfo(res.course.id)
    }else if (res.errors && Array.isArray(res.errors)) {
      this.handleCourseApiError(res.errors);
    }
  }
  handleUpdateCourse = (res:any)=>{
    if (res.errors && Array.isArray(res.errors)) {
      this.handleCourseApiError(res.errors);
    }
    if(res.error){
      return toast.error(res.error)
    }
    const routeName = this.props.navigation.state.routeName.split('/')
    const id = routeName[routeName.length -1]
    this.getCourseInfo(id)
    toast.success(res.message)
    this.handleLessonGoBack()
  }
  handleTableResponse = (data: any) => {
    return data.map((row) => {
      const rowId = row.id;
      const columns = row.columns;
      return {
        id: rowId,
        ...Object.keys(columns).reduce((acc, key, index) => {
          console.log(acc)
          acc[key] = {
            id: uuidv4(),
            value: columns[key],
          };
          return acc;
        }, {}),
      };
    });
  };

  getCourseDataFromResponse = (contentArr: {}) => {
    return contentArr.map((item) => {
      if (item.block_type === "text") {
        return {
          type: "Text",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          heading: item.heading,
          content: item.content,
          text_format:item.text_format
        }
      } else if (item.block_type === "list") {
        return {
          type: "List",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          listType: item.list_type,
          heading: item.heading,
          content: item.content
        }
      } else if (item.block_type === "image") {
        return {
          type: "Image",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          image:  item.image_url,
          isMediaUrl: true,
          caption: item.caption
        }
      } else if (item.block_type === "video") {
        return {
          type: "Video",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          video: item.video_url,
          isMediaUrl: true,
          caption: item.caption
        }
      } else if (item.block_type === "tabular") {
        return {
          type: "Table",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          heading: item.heading,
          table_rows_attributes: this.handleTableResponse(item.table_rows)
        }
      } else if (item.block_type === "carousel") {
        return {
          type: "Carousel",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          content: item.slides.map((item) => ({
            id: item.id,
            isMediaUrl: true,
            type: item.media_type,
            media: item.media_url,
            heading: item.title,
            description: item.description,
          }))
        }
      } else if (item.block_type === "flashcard") {
        return {
          type: "Card",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          cards: item.cards.map((card) => ({ id: card.id, front: card.front_side, back: card.back_side, flipped: false })).reverse()
        }
      } else if (item.block_type === "labeled_graphic") {
        return {
          type: "Label",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          image: item.image_url,
          isMediaUrl: true,
          content: item.labels.map((item, index: number) => ({
            id: item.id,
            x: item.x_position,
            y: item.y_position,
            title: item.title,
            contentRightSide: false,
            description: item.description,
            visibleContent: item.visible_content,
          }))
        }
      } else if (item.block_type === "quote") {
        return {
          type: "Quote",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          image: item.image_url,
          content: item.content,
          name: item.quote_owner_name,
        }
      } else if (item.block_type === "test_knowledge") {
        return {
          type: "Test",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          content: {
            isSubmit: false,
            answerCorrect: false,
            question: item.test_questions[0].question_text,
            isMultiple: item.test_questions[0].question_type !== "single_response",
            supportingText: item.test_questions[0].correct_feedback,
            supportingTextInCorrect: item.test_questions[0].incorrect_feedback,
            options: item.test_questions[0].options.map((item) => ({
              id: item.id,
              answer: item.text,
              isSelected: false,
              isCorrect: item.is_correct,
            }))
          }
        }
      } else if (item.block_type === "button") {
        return {
          type: "Button",
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          finishButton: item.is_finished,
        }
      } else if(item.block_type ==='sorting_content'){
        return {
          type:"Sorting",
          id: item.id,
          paddingTop: parseInt(item.padding_top),
          paddingBottom: parseInt(item.padding_bottom),
          width: item.width,
          sorting_block_id:item.sorting_block.id,
          content:item.sorting_block.categories|| []
        }
      }
    });
  }

  handleCourseInfoApiResponse = (responseJson: {}) => {
    if(responseJson.error) {
      toast.error(responseJson.error);
      return;
    }
    const response = responseJson.data.attributes;
    const lessonArr = response.course_modules.map((item) => {
      const quizData =item.assessment? item.assessment.questions.map(question=>({...question,answer_options_attributes:[...question.answer_options]})) :[]
      return {
        id: item.id,
        courseData: this.getCourseDataFromResponse(item.sections?.[0]?.content_blocks ?? []),
        lessonTitle: {
          isActiveToChange: false,
          title: item.title
        },
        sectionId:item.sections?.[0]?.id,
        assessmentData:{...item.assessment},
        quizData
      }
    });
    this.setState({
      lessonList: lessonArr,
      titleLoader: true,
      courseTitle: response.title,
      description: response.description,
      courseStatus:response.status,
      authorName: response.author_name,
      lessonListType: response.lesson_format,
      learningPath: response.learning_paths_ids,
      finalAssessmentQuizData:response.final_assessment,
      isFinalAssessment:!!response.final_assessment,
      lessonCreationBar:{isActive:false,title:''},
      isUpload:true,
      coverImg:{
        ...response.course_cover_image,
        image:response.course_cover_image.url,
        fileName:response.course_cover_image.url? 'CoverPhoto.jpg':''
      },
      coverDescImg:{
        ...response.course_description_image,
        image:response.course_description_image.url,
        fileName:response.course_description_image.url?'CourseDescriptionCoverPhoto.jpg':''
      },
      imageObj:{
        ...response.thumbnail_image,
        image:response.thumbnail_image.url,
        fileName:response.thumbnail_image.url?'ThumbnailPicture.jpg':''
      },
      requirement: {
        "duration": response.duration,
        "course_requirements": response.course_requirements,
      }
    },() => {
      this.setState({ isLoading: false });
    });
  }

  handleCourseApiError = (errors: Array<string> | Array<{token?: string}>) => {
    if (errors[0]?.token) {
      this.props.navigation.navigate("AdminLogin");
    } else {
      this.setState({ isErrorVisible: true, errors: errors });
    }
  }

  onGoBack = () => {
    if(this.state.isFromMainPage) {
      this.setState({ isFromMainPage: false, isCourseLearning: false });
    } else if(this.state.isCourseLearning) {
      this.setState({ isCourseLearning: false, isLessonVisible: true, isCourseSidebarOpen: true, isPreview: false, selectedId: -1 });
    } else if(this.state.isPreview) {
      this.setState({ isPreview: false });
    } else if(this.state.isQuizVisible) {
      this.setState({
        quizData: [],
        quizObj: {},
        assessmentTitle: "",
        isQuizVisible: false,
        currentAssessmentData: null,
        isQuizEditorVisibile: false,
        isCurrentAssessmentFinal: false,
      });
    } else {
      this.handleLessonGoBack()
    }
  };

  handleCreateCourseResponse = (responseJson: any) => {
    if (responseJson.data) {
      toast.success("You have successfully created a course.");
      this.setState({
        courseId: responseJson.data.id,
        courseModuleIDs: responseJson.course_module_ids,
        isEditingCourse:true
      })
      this.props.navigation.navigate('UpdateCourse',{path:{courseId:responseJson.data.id}})
      this.getCourseInfo(responseJson.data.id)
    } else if (responseJson.errors && Array.isArray(responseJson.errors)) {
      this.handleCourseApiError(responseJson.errors);
    }
  }

  onCloseErrorDialog = () => {
    this.setState({ isErrorVisible: null });
  }

  handleFinalAssessmentResponse = (responseJson) => {
    if (responseJson.data) {
        toast.success('Assessment data saved successfully');
        this.props.navigation.goBack();
    } else {
      toast.error(responseJson?.errors?.join(' '));
    }
  }

  handleCreateAssessmentResponse = (responseJson) => {
    if (responseJson.data) {
      if (this.state.assessmentDataSaved && !this.state.isFinalAssessment) {
        toast.success('Assessment data saved successfully');
        this.props.navigation.navigate("CourseLibrary");
      }
    } else {
      toast.error(responseJson?.errors?.join(' '));
    }
  };

  onUploadButtonClick = () => {
    this.setState({ isUpload: !this.state.isUpload });
  }

  onCourseImageChange = async(e: React.ChangeEvent<HTMLInputElement>, currentState: string, index: number) => {
    const file = e.target.files?.[0];
    const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png'];

    this.setState({ isLoading: true })
    if (!file) return;

    if (supportedFormats.includes(file.type)) {
      const res = await uploadFileToApi(file)
          this.setState(prevState => ({
            [currentState]: {
              ...prevState[currentState],
              fileError: "",
              fileName: file.name,
              image: res.image_url
            }
          }))
    } else {
      this.setState(prevState => ({
        [currentState]: { ...prevState[currentState], fileError: "Only .png, .jpg, .jpeg allowed" }
      }));
    }
    this.setState({isLoading:false})
  }

  handleLessonGoBack = ()=>{
    let lessonList = [...this.state.lessonList];
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, courseData: this.state.courseData }
        : item
    ));
    this.setState({
      lessonList,
      courseData: [],
      currentObj: {},
      buttonType: "",
      isPreview: false,
      currentIndex: -1,
      currentLessonId: -1,
      isDrawerOpen: false,
      isSidebarOpen: false,
      isLessonVisible: false,
      isToolbarVisible: false,
      currentAssessmentData: null,
    });
  }

  handleQuizGoBack = () => {
    let lessonList = [...this.state.lessonList];
    if(this.state.isCurrentAssessmentFinal) {
      this.setState({ finalAssessmentQuizData: this.state.currentAssessmentData });
    } else {
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, quizData: this.state.quizData, assessmentData: this.state.currentAssessmentData || null }
        : item
    ));
    }
    this.setState({
      lessonList: lessonList,
      isQuizEditorVisibile: false,
      isQuizVisible: false,
      quizData: [],
      currentAssessmentData: null,
      quizObj: {},
      assessmentTitle: "",
      isCurrentAssessmentFinal: false,
    });
  }

  sidebarHandler = () => {
    this.setState({
      currentIndex: this.state.courseData.length - 1,
      isSidebarOpen: !this.state.isSidebarOpen,
    });
  }

  openToolbar = () => {
    this.setState({ 
      buttonType: "",
      currentIndex: -1,
      isToolbarVisible: true,
    },() => {
      this.bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    });
  }

  onScrollToBottom = (id: string) => {
    setTimeout(() => {
      const container = document.getElementById(id);
      if (container) {
        container.scrollIntoView({ behavior: 'smooth', block: "end" });
      }
    }, 0);
  }
  moveDestroyedItemsToEnd(courseData) {
    // Separate items with _destroy:true and others
    const activeItems = courseData?.filter((item) => !item?._destroy);
    const destroyedItems = courseData?.filter((item) => item?._destroy);
  
    // Concatenate active items and destroyed items
    return [...activeItems, ...destroyedItems];
  }
  handleTypeButtonClick = (label: string, isFromSidebar?: boolean) => {
    let courseData = [...this.state.courseData];
    const obj = { ...cloneDeep(defaultContent[label]), id: uuidv4() };

    const isButtonTypeVisible = courseData?.findIndex(item=>item?.type === 'Button') !== -1
    if(label === "Button" && (isButtonTypeVisible || this.state.currentIndex !== -1)){
      return
    }
    if(isButtonTypeVisible && this.state.currentIndex===this.state.courseData.length -1) return
    if (obj) {
      const { selectedCourseIndex, lessonList } = this.state;
    
      const isScroll = isFromSidebar && this.state.currentIndex === this.state.courseData.length - 1;
      const index = isFromSidebar ? this.state.currentIndex : this.state.courseData.length - 1;
      const currentCourseData =this.moveDestroyedItemsToEnd( lessonList.find((_,index)=>selectedCourseIndex===index)?.courseData)
      currentCourseData.splice(index + 1, 0, obj);
      const updatedLessonList = lessonList.map((course, courseIndex) =>
        courseIndex === selectedCourseIndex
          ? {
              ...course,
              courseData: currentCourseData,
            }
          : course
      ); 
      this.setState({ courseData:currentCourseData, currentIndex: -1, isToolbarVisible: false, isSidebarOpen: false ,lessonList:updatedLessonList}, () => {
        if (isScroll) {
        this.onScrollToBottom('bottom-content');
        }
      });
    }
  }
  handleTextTypeFormatClick = (index: number,id:any,e:any) => {
    this.setState({ 
      anchorEl:e.currentTarget,
      buttonType:'textType',
      currentIndex:index,
      selectedContentId:id,
      currentObj: this.state.courseData[index],
    });
  }
  handleEditClick = (index: number,id:any) => {
    this.setState({ 
      currentIndex: index,
      selectedContentId:id,
      buttonType: "edit",
      isDrawerOpen: true,
      currentObj: cloneDeep(this.state.courseData[index]),
    });
  }
  handleEditSortingClick = (index: number,id:any) => {
    this.setState({ 
      currentIndex: index,
      selectedContentId:id,
      buttonType: "sorting",
      currentObj: this.state.courseData[index],
      isEditingSortType:true
    });
  }
  handleUpdateSortingType = () => {
    const { 
      courseData,
      selectedCourseIndex, 
      lessonList, 
      selectedContentId 
    } = this.state;
    const newLessonList = lessonList.map((course1, index) =>
      index === selectedCourseIndex
        ? {
            ...course1,
            courseData: course1.courseData.map((content) =>
               `${selectedContentId}` === `${content.id}`?this.getCurrentContent() : content
            ),
          }
        : course1
    ); 
    const updateCourseData = [...courseData];
    updateCourseData[this.state.currentIndex] = this.getCurrentContent();
    this.setState({
      lessonList: newLessonList ,
      buttonType: "",
      courseData:updateCourseData,
      currentIndex: -1,
      currentObj: {},
      isEditingSortType:false
    });
  }

  handleFormatClick = (index: number) => {
    if(this.state.buttonType === "format" && this.state.currentIndex === index) {
      this.hideFormatOption();
    } else {
      this.setState({
        currentIndex: index,
        buttonType: "format",
      }, () => {
        if (this.formatRef.current) {
          this.formatRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      });
    }
  }

  handleCopyClick = (index: number) => {
    const { selectedCourseIndex, lessonList } = this.state;
    let courseData = [...this.state.courseData];
    const replaceIdsWithUuid = (obj: any): any => {
      if (Array.isArray(obj)) {
        return obj.map(replaceIdsWithUuid);
      } else if (typeof obj === 'object' && obj !== null) {
        return Object.keys(obj).reduce((acc, key) => {
          acc[key] = key === 'id' ? uuidv4() : replaceIdsWithUuid(obj[key]);
          return acc;
        }, {} as any);
      }
      return obj;
    };
    let obj = { ...replaceIdsWithUuid(courseData[index]) };
    courseData.splice(index + 1, 0, obj);
      const updatedCourseData =this.moveDestroyedItemsToEnd( courseData)
      const updatedLessonList = lessonList.map((course, courseIndex) =>
    courseIndex === selectedCourseIndex
      ? {
          ...course,
          courseData:updatedCourseData
        }
      : course
  ); 
    this.setState({ courseData:updatedCourseData,lessonList:updatedLessonList });
  }

  handleDeleteClick = (index: number, id: any) => {
    const { selectedCourseIndex, lessonList, courseData } = this.state;
      const updatedLessonList = lessonList.map((course, courseIndex) =>
      courseIndex === selectedCourseIndex
        ? {
            ...course,
            courseData: course.courseData.map((item) =>
              `${item.id}` === `${id}` ? { ...item, _destroy: true } : item
            ),
          }
        : course
    ); 
      const updatedCourseData =this.moveDestroyedItemsToEnd( updatedLessonList.find((_,index)=>selectedCourseIndex===index)?.courseData)
    this.setState({ 
      courseData: updatedCourseData, 
      lessonList: updatedLessonList,
      isDeletingContent:false
    });
  };
  handleChangeTextFormat = (formatValue) => {
    this.setState((prevState) => ({
      currentObj: {
        ...prevState.currentObj,
        text_format: formatValue,
        heading: this.sanitizeData(prevState.currentObj.heading),
        content: this.sanitizeData(prevState.currentObj.content),
      },
    }));
  };
  hideFormatOption = () => {
    this.setState({ currentIndex: -1, buttonType: "" });
  }
  hideFormatTextTypeOption=()=>{
    const { selectedCourseIndex, lessonList, courseData,selectedContentId } = this.state;
    const newLessonList = lessonList.map((course, index) =>
      index === selectedCourseIndex
        ? {
            ...course,
            courseData: course.courseData.map((itemCourse) =>
              `${itemCourse.id}` === `${selectedContentId}` ?this.getCurrentContent() : itemCourse
            ),
          }
        : course
    ); 
    const newCourseData = [...courseData];
    newCourseData[this.state.currentIndex] = this.getCurrentContent();
    console.log(this.getCurrentContent())
    this.setState({
      anchorEl:null,
      currentIndex: -1,
      lessonList: newLessonList ,
      courseData:newCourseData,
      buttonType: "",
      currentObj: {},
    });
  }

  handlePadding = (e, key) => {
    const value = e.target.value;
  
    this.setState((prevState) => {
      const { currentIndex, selectedCourseIndex, courseData, lessonList } = prevState;
      if (currentIndex < 0 || currentIndex >= courseData.length) return null;
  
      // Create a new array and update the specific object
      const updatedCourseData = courseData.map((item, index) =>
        index === currentIndex ? { ...item, [key]: value } : item
      );
  
      // Update lessonList
      const updatedLessonList = lessonList.map((course, courseIndex) =>
        courseIndex === selectedCourseIndex
          ? { ...course, courseData: updatedCourseData }
          : course
      );
  
      return { courseData: updatedCourseData, lessonList: updatedLessonList };
    });
  };
  
  handleBlockPadding = (e) => {
    const value = parseInt(e.target.value, 10);
  
    this.setState((prevState) => {
      const { currentIndex, selectedCourseIndex, courseData, lessonList } = prevState;
      if (currentIndex < 0 || currentIndex >= courseData.length) return null;
  
      // Create a new array and update the specific object
      const updatedCourseData = courseData.map((item, index) =>
        index === currentIndex
          ? { ...item, paddingTop: value, paddingBottom: value }
          : item
      );
  
      // Update lessonList
      const updatedLessonList = lessonList.map((course, courseIndex) =>
        courseIndex === selectedCourseIndex
          ? { ...course, courseData: updatedCourseData }
          : course
      );
  
      return { courseData: updatedCourseData, lessonList: updatedLessonList };
    });
  };
  

  getCurrentContent = () => {
    const { currentObj } = this.state;
    const { type, isMediaUrl, image, video } = currentObj;
    let obj = {
      ...currentObj,
      fileError: undefined,
      urlError: undefined,
    };
    if (type === "Image") {
      obj.mediaUrl = isMediaUrl ? image : undefined;
    } else if (type === "Video") {
      obj.mediaUrl = isMediaUrl ? video : undefined;
    } else if (type === "Carousel") {
      obj.content = obj.content.map((item) => ({
        ...item,
        fileError: undefined,
        urlError: undefined,
        mediaUrl: item.isMediaUrl ? item.media : undefined
      }));
    }
    return obj;
  }

  handleDrawerToggle = () => {
    const { selectedCourseIndex, lessonList, courseData,selectedContentId } = this.state;
    const updatedLessonList = lessonList.map((course, courseIndex) =>
      courseIndex === selectedCourseIndex
        ? {
            ...course,
            courseData: course.courseData.map((item) =>
              `${item.id}` === `${selectedContentId}` ?this.getCurrentContent() : item
            ),
          }
        : course
    ); 
    const courseDataCopy = [...courseData];
    courseDataCopy[this.state.currentIndex] = this.getCurrentContent();
    this.setState({
      courseData:courseDataCopy,
      lessonList: updatedLessonList ,
      buttonType: "",
      currentObj: {},
      currentIndex: -1,
      isDrawerOpen: !this.state.isDrawerOpen
    });
  }

  onLessonCreation = (lessonItem: LessonItem, index: number) => {
    this.setState({
      isLessonVisible: true,
      selectedCourseIndex: index,
      selectedCourseId: index,
      currentLessonId: lessonItem.id,
      lessonTitle: lessonItem.lessonTitle.title,
      lessonAuthorName: this.state.authorName,
      courseData: this.state.lessonList[index].courseData,
      lessonCreationBar:{isActive:false}
    }, () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  deleteAssessment = (id: number | string) => {
    this.setState(prevState => ({
      lessonList: prevState.lessonList.map(item =>
        item.id === id ? { ...item, quizData: [],assessmentData:{...item.assessmentData,_destroy:true} } : item
      )
    }));
  }

  changeChapter = (index: number) => {
    
    if(index > this.state.lessonList.length - 1 && index !== 99){
      this.setState({isCourseLearning:false})
      return
    }
    this.setState({ selectedCourseId: index,currentPreviewQuestion:null,currentPreviewAssessmentData:null }, () => {
      const container = document.getElementById('learning-wrapper');
      if (container) {
        container.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  }

  onStartCourse = () => {
    this.setState({ isCourseLearning: true, selectedCourseId: 0, isFromMainPage: true });
  }

  handleCourseSidebar = () => {
    this.setState({ isCourseSidebarOpen: !this.state.isCourseSidebarOpen });
  }

  onCreateFinalAssessment = () => {
      const quizData =this.state.finalAssessmentQuizData? this.state.finalAssessmentQuizData?.questions?.map(question=>({...question,answer_options_attributes:[...question.answer_options]})) :[]
      const requirementData = {
      noOfQuestions: this.state.finalAssessmentQuizData?.no_of_questions,
      noOfAnswers: this.state.finalAssessmentQuizData?.no_of_answers,
      immediateFeedback: this.state.finalAssessmentQuizData?.immediate_feedback,
      showAnswer: this.state.finalAssessmentQuizData?.show_answer,
      scoreOnly: this.state.finalAssessmentQuizData?.score_only,
      retakeOption: this.state.finalAssessmentQuizData?.retake_option 
    };
    this.setState({
      isQuizVisible: true,
      isQuizEditorVisibile: true,
      isCurrentAssessmentFinal: true,
      quizData: quizData,
      assessmentRequirements: requirementData,
      currentAssessmentData: this.state.finalAssessmentQuizData,
    },() => {
      if(quizData?.length === 0) {
        this.handleAddNewQuestion();
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });
  }

  onFinalAssessmentClick = () => {
    this.setState({
      quizData: [],
      plusAnchorEl: null,
      isFinalAssessment: true,
      currentAssessmentData: null,
    });
  }

  onDeleteFinalAssessment = () => {
    this.setState({
      quizData: [],
      isFinalAssessment: false,
      currentAssessmentData: null,
      finalAssessmentQuizData: {...this.state.finalAssessmentQuizData,_destroy:true},
      isCurrentAssessmentFinal: false,
    });
  }
  getAllAssessment= async (id:number) =>{ 
    this.getAllAssessmentApiCallId = await apiCall({
      method: 'GET',
      contentType: 'application/json',
      endPoint: `bx_block_assessmenttest/assessments/${id}`,
      token: localStorage.getItem('token'),
    });
  }

  onQuizCreation = (lessonItem: LessonItem, index: number) => {
    const routeName = this.props.navigation.state.routeName.split('/')
    const id = routeName[routeName.length -1]
    
    this.setState(
      {
        currentLessonId: lessonItem.id,
        isQuizVisible: true,
        isQuizEditorVisibile: true,
        quizData: this.state.lessonList[index]?.quizData,
        currentAssessmentData: this.state.lessonList[index]?.assessmentData || null,
        assessmentTitle:this.state.lessonList[index]?.assessmentData?.title
      },
      () => {
       
        if (!lessonItem.quizData?.length) {
          this.setState({currentAssessmentData:{}},()=>{
            this.handleAddNewQuestion();
          })
        }
        else if(id!=='course-creation'){
          this.getAllAssessment(this.state.lessonList[index]?.assessmentData.id)
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    );
  };

  handleModalClose = () => {
    this.setState({ currentQuestion: null });
  };

  onClickDeleteQuestion = (index) => {
    this.setState({ currentQuestion: index });
  };

  handleDeleteQuestion = () => {
    const data = Object.assign(this.state.quizData);
    data.splice(this.state.currentQuestion, 1);
    this.setState({ quizData: data,currentQuestion:null });
  };

  isMultiSelect = (questionType) => questionType === "multiple_correct_options";
  
  validateQuestions = () => {
    let valMsg = [];
    let descriptionNotAvailable = false;
    let optionDescNotAvailable=false;
    let multiSelectValidation = false;
    this.state.quizData.forEach((item, index) => {
      if(!item.description?.replaceAll('<p>','').replaceAll('</p>','')?.replaceAll('\n','')?.length) {
        descriptionNotAvailable = true;
      }
      let count = 0;
      let singleSelected = false;
      item.answer_options_attributes.forEach(option => {
        if (!option.description?.replaceAll('<p>','').replaceAll('</p>','')?.replaceAll('\n','')?.length) {
          optionDescNotAvailable = true;
        }
        if (option.is_correct && this.isMultiSelect(item.question_type)) {
          count = count + 1;
        } else if (option.is_correct && !this.isMultiSelect(item.question_type)) {
          singleSelected = true;
        }
      })
      valMsg = this.checkSelectVal(item, count, index, singleSelected, valMsg);
    })
    valMsg = this.checkDescVal(descriptionNotAvailable, optionDescNotAvailable, valMsg);
    return valMsg;
  }

  checkDescVal = (descriptionNotAvailable, optionDescNotAvailable, valMsg) => {
    let errors = valMsg;
    if (descriptionNotAvailable) {
      errors.unshift('Please add description for all question.')
    }
    if (optionDescNotAvailable) {
      errors.push('Please add description to all options ');
    }
    return errors;
  }

  checkSelectVal = (item, count, index, singleSelected, valMsg) => {
    let errors = valMsg;
    if (this.isMultiSelect(item.question_type) && count < 2) {
      errors.push(`Question ${index + 1}: Please select at least two correct options.`);
    }
    if (!singleSelected && !this.isMultiSelect(item.question_type)) {
      errors.push(`Question ${index + 1}: Please select one correct answer.`);
    }
    return errors;
  }

  updateValidData = () => {
    if (this.state.assessmentRequirements|| (this.state.currentAssessmentData?.no_of_questions && this.state.currentAssessmentData?.no_of_answers)) {
      let assessment = {
        id:this.state.currentAssessmentData?.id??null,
        title: this.state?.assessmentTitle || '',
        no_of_questions: this.state.assessmentRequirements?.noOfQuestions,
        no_of_answers: this.state.assessmentRequirements?.noOfAnswers,
        show_answer: this.state.assessmentRequirements?.showAnswer,
        immediate_feedback: this.state.assessmentRequirements?.immediateFeedback,
        score_only: this.state.assessmentRequirements?.scoreOnly,
        retake_option: this.state.isCurrentAssessmentFinal ? this.state.assessmentRequirements?.retakeOption : undefined,
        assessment_type: this.state.isCurrentAssessmentFinal ? "final_assessment" : "lesson_assessment",
        questionbanks_attributes: Object.assign(this.state.quizData),
        questions_attributes: Object.assign(this.state.quizData),
      };
      this.setState({ currentAssessmentData: Object.assign(assessment) })
    }
    this.setState({ assessmentRequirements: null, assessmentTitle: '' }, () => {
      this.handleQuizGoBack();
    });
  }

  saveAssessment = () => {
    const result = this.validateQuestions();
    const isReqValid = ((this.state.assessmentRequirements?.noOfAnswers && this.state.assessmentRequirements?.noOfQuestions) || (this.state.currentAssessmentData?.no_of_questions && this.state.currentAssessmentData?.no_of_answers))
    if (result.length) {
      result.forEach(errorMessage => {
        toast.error(errorMessage);
      });
      return;
    } else if (!isReqValid) {
      toast.error('Please add assessment requirements.');
      return;
    } else if (!this.state.assessmentTitle?.length && !this.state.currentAssessmentData?.title?.length) {
      toast.error('Assessment title can\'t be blank.');
      return;
    } else {
      this.updateValidData();
    }
}

  prepareAssessmentReq = (lesson, index) => {
    let data = lesson.assessmentData;
    data['course_id'] = this.state.courseId;
    data['course_module_id'] = this.state.courseModuleIDs[index];
    return data;
  }

  finalAssessmentApi = async (course_id) => {
    let reqBody = this.state.finalAssessmentQuizData;
    reqBody['course_id'] = course_id;
    this.createFinalAssessmentApiCallId = await apiCall({
      method: 'POST',
      contentType: 'application/json',
      token: localStorage.getItem('token'),
      endPoint: `bx_block_assessmenttest/assessments`,
      body: reqBody,
    });
  }

  createAssessment = async () => {
    this.setState({ assessmentDataSaved: false });
    for (const [index, lesson] of this.state.lessonList.entries()) {
      if (lesson.assessmentData) {
        const reqBody = this.prepareAssessmentReq(lesson, index);
        this.createAssessmentApiCallId = await apiCall({
          method: 'POST',
          contentType: 'application/json',
          endPoint: `bx_block_assessmenttest/assessments`,
          body: reqBody,
          token: localStorage.getItem('token'),
        });
      }
      this.setState({ assessmentDataSaved: true });
    }
  };

  addLeadingZero(num) {
    return num < 10 ? String(num).padStart(2, '0') : num;
  }

  handleQuestionTypeChange = (data, index) => {
    const quizData = [...this.state.quizData];
    quizData[index].question_type = data.value;
    const question = quizData[index];
    question.answer_options_attributes = question.answer_options_attributes.map((opt, idx) => ({
      ...opt,
      is_correct: false
    }));
    quizData[index] = question;
    this.setState({ quizData });
  };

  handleQuestionChange = (value, index) => {
    const quizData = [...this.state.quizData];
    quizData[index].description = value;
    this.setState({ quizData });
  };

  updateRequirements = (values) => {
    this.setState({ assessmentRequirements: values })
  }

  onSaveCourseRequirements = (duration: string, course_requirements: string) => {
    this.setState({ requirement: { duration, course_requirements } });
  }

  updateAssessmentTitle = (value) => {
    this.setState({ assessmentTitle: value })
  }

  handleSupportingTextChange = (value, index, isCorrect = false) => {
    const quizData = [...this.state.quizData];
    if (isCorrect) {
      quizData[index].correct_feedback = value
    } else {
      quizData[index].incorrect_feedback = value
    } 
    this.setState({ quizData });
  };

  handleOptionTextChange = (value, questionIndex, optionIndex) => {
    const quizData = [...this.state.quizData];
    quizData[questionIndex].answer_options_attributes[optionIndex].description =
      value.replace('\n','');
    this.setState({ quizData });
  };

  handleOptionCorrectChange = (questionIndex, optionIndex) => {
    const quizData = [...this.state.quizData];
    const question = quizData[questionIndex];
    if (!this.isMultiSelect(question.question_type)) {
      question.answer_options_attributes = question.answer_options_attributes.map((opt, idx) => ({
        ...opt,
        is_correct: idx === optionIndex
      }));
      quizData[questionIndex] = question;
    } else {
      quizData[questionIndex].answer_options_attributes[optionIndex].is_correct =
      !quizData[questionIndex].answer_options_attributes[optionIndex]
        .is_correct;
    }
    this.setState({ quizData });
  };

  handleAddNewQuestion = () => {
    const newQuestion = {
      question_type: 'multiple_correct_options',
      description: '',
      total_marks: '25',
      correct_feedback: "This is the text that will appear after the submission of answer.",
      incorrect_feedback: "This is the text that will appear after the submission of answer.",
      answer_options_attributes: [
        { description: '', is_correct: false },
        { description: '', is_correct: false },
        { description: '', is_correct: false },
        { description: '', is_correct: false },
      ],
    };
    this.setState((prevState) => ({
      quizData: [...(prevState.quizData || []), newQuestion]
    }));
  };

  handleShowQuizEditor = () => {
    this.setState({
      isQuizEditorVisibile: true
    })
  }

  handleCloseQuizEditor = () => {
    this.setState({
      isQuizEditorVisibile: false
    })
  }

  onChangeLessonTitle = (event) => {
    let lessonList = [...this.state.lessonList];
    lessonList = lessonList.map((item) => (
      item.id === this.state.currentLessonId
        ? { ...item, lessonTitle: { ...item.lessonTitle, title: event.target.value }}
        : item
    ));
    this.setState({ lessonList, lessonTitle: event.target.value });
  }

  onChangeAuthorName = (event) => {
    this.setState({
      authorName: event.target.value,
      lessonAuthorName: event.target.value,
    });
  }

  onChangeValue = (key: string, value: string) => {
    this.setState({ currentObj: { ...this.state.currentObj, [key]: value } });
  }

  onChangeHeading = (value: string) => {
    this.setState({ currentObj: { ...this.state.currentObj, heading: value } });
  }

  handleUpDownClick = (index: number) => {
      const { selectedCourseIndex, lessonList } = this.state;
      const courseData = [...this.state.courseData];
    const currentCourseData =this.moveDestroyedItemsToEnd( lessonList.find((_,index)=>selectedCourseIndex===index)?.courseData)
      const updatedLessonList = lessonList.map((course, courseIndex) =>
        courseIndex === selectedCourseIndex
          ? {
              ...course,
              courseData: currentCourseData,
            }
          : course
      ); 
    const isButtonTypeVisible = courseData.findIndex(item=>item.type === 'Button') !== -1;
    if(isButtonTypeVisible && index === courseData.length-1){
      return
    }
    [currentCourseData[index],currentCourseData[index-1]] = [currentCourseData[index-1],currentCourseData[index]];
    this.setState({ courseData:currentCourseData,lessonList:updatedLessonList });
  }

  onPreview = () => {
    this.setState({
      buttonType: "",
      currentObj: {},
      isQuizVisible: false,
      isToolbarVisible: false,
      isPreview: !this.state.isPreview,
    });
    if(this.state.isLessonVisible) {
      this.setState({
        isCourseLearning: true,
        isLessonVisible: false,
        selectedId: this.state.selectedCourseIndex,
        selectedCourseId: this.state.selectedCourseIndex
      });
    }
  }

  onPlusClick = (index: number) => {
    this.setState({
      isSidebarOpen: true,
      currentIndex: index
    });
  }

  onChangeListValue = (key: string, value: string) => {
    let { content } = this.state.currentObj;
  
    if (key === "listType") {
      if (value === "ordered") {
        // Change all <ul> tags to <ol>
        content = content
          .replace(/<ul>/g, '<ol>')
          .replace(/<\/ul>/g, '</ol>');
      } else {
        // Change all <ol> tags to <ul>
        content = content
          .replace(/<ol>/g, '<ul>')
          .replace(/<\/ol>/g, '</ul>');
      }
  
      // Append a new empty list if none exists
      if (!content.includes('<ul>') && !content.includes('<ol>')) {
        const newList = value === "ordered" ? '<ol><li></li></ol>' : '<ul><li></li></ul>';
        content += newList;
      }
  
      this.setState({
        currentObj: {
          ...this.state.currentObj,
          [key]: value,
          content: content,
        }
      });
    } else {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          [key]: value,
        }
      }));
    }
  };

  handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      this.onImportFromLink();
    }
  }

  onChangeUrl = (url: string) => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        mediaUrl: url,
        urlError: ""
      }
    }));
  }

  onRemoveImage = () => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        mediaUrl: "",
        fileError: "",
        fileName: "",
        urlError: "",
        image: null,
        isMediaUrl: false,
      }
    }));
  }

  checkImageResolution = (img: React.HTMLImageElement, callback: (isHighResolution: boolean) => void): void => {
    const { type } = this.state.currentObj;
    const isQuote = type === "Quote";
    const recommendedWidth = 1920;
    const recommendedHeight = 1080;
    const isHighResolution = isQuote
      ? img.width >= 30 && img.height >= 30
      : img.width >= recommendedWidth && img.height >= recommendedHeight;
    callback(isHighResolution);
  }

  doesImageExist = (url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        this.checkImageResolution(img, (isHighResolution) => {
          this.setState(prevState => ({
            currentObj: {
              ...prevState.currentObj,
              fileError: "",
              fileName: "",
              image: isHighResolution ? url : prevState.currentObj.image,
              isMediaUrl: isHighResolution ? true : prevState.currentObj.isMediaUrl,
              urlError: isHighResolution ? "" : "Image resolution is too low",
            }
          }));
         return resolve(true);
        });
      };
      img.onerror = () => {
        this.setState(prevState => ({
          currentObj: {
            ...prevState.currentObj,
            fileError: "",
            urlError: "Invalid URL or image not fetched"
          }
        }));
        return reject(false);
      };
    });
  }

  onImportFromLink = async () => {
    const url = this.state.currentObj.media_Url;
    try {
      await this.doesImageExist(url);
    } catch (err) {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          fileError: "",
          urlError: "Image not fetched"
        }
      }));
    }
  }

  onCaptureMedia = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const supportedFormats = ['image/jpg', 'image/jpeg', 'image/png'];

    if (!file) return;

    if (supportedFormats.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          this.checkImageResolution(img, (isHighResolution) => {
            if (isHighResolution) {
              this.setState(prevState => ({
                currentObj: {
                  ...prevState.currentObj,
                  mediaUrl: "",
                  fileError: "",
                  urlError: "",
                  isMediaUrl: false,
                  fileName: file.name,
                  [key]: reader.result as string
                }
              }));
            } else {
              this.setState(prevState => ({
                currentObj: {
                  ...prevState.currentObj,
                  urlError: "",
                  fileError: "Image resolution is too low"
                }
              }));
            }
          });
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          urlError: "",
          fileError: "Only .png, .jpg, .jpeg allowed"
        }
      }));
    }
  }
   handleFileUpload = async(key: string, e: any) => {
    const file = e.target.files[0];
    if (file) {
      this.setState({isLoadingFile:true})
      const res = await uploadFileToApi(file,key==='image')
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          mediaUrl: res[`${key}_url`],
          fileError: "",
          urlError: "",
          isMediaUrl: true,
          fileName: file.name,
          [key]: res[`${key}_url`]
        },
        isLoadingFile:false
      }));
    }
  };
  handleKeyDownForVideo = (key: string) => {
    if (key === 'Enter') {
      this.onImportVideoFromLink();
    }
  }

  onImportVideoFromLink = () => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        fileError: "",
        fileName: "",
        urlError: "",
        video: this.state.currentObj.mediaUrl,
        isMediaUrl: true,
      }
    }));
  }

  onCaptureVideo = (key: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const supportedVideoFormats = ['video/mp4', 'video/webm', 'video/ogg', 'video/x-msvideo', 'video/avi'];

    if (!file) return;

    if (supportedVideoFormats.includes(file.type)) {
      const maxFileSize = 25 * 1024 * 1024;
      if (file.size > maxFileSize) {
        this.setState(prevState => ({
          currentObj: {
            ...prevState.currentObj,
            urlError: "",
            fileError: "Video size exceeds 25 MB",
          }
        }));
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const video = document.createElement('video');
        video.onloadedmetadata = () => {
          this.setState(prevState => ({
            currentObj: {
              ...prevState.currentObj,
              mediaUrl: "",
              fileError: "",
              urlError: "",
              isMediaUrl: false,
              fileName: file.name,
              [key]: reader.result as string
            }
          }));
        }
        video.onerror = () => {
          this.setState(prevState => ({
            currentObj: {
              ...prevState.currentObj,
              urlError: "",
              fileError: "Invalid video file"
            }
          }));
        };
        video.src = event.target?.result as string;
      };
      reader.readAsDataURL(file);
    } else {
      this.setState(prevState => ({
        currentObj: {
          ...prevState.currentObj,
          urlError: "",
          fileError: "Only .mp4, .webm, .ogg, and .avi formats are allowed"
        }
      }));
    }
  }

  onChangeCarouselHeading = (index: number, key: string, value: string) => {
    this.setState(prevState => {
      const updatedContent = prevState.currentObj.content.map((item, idx) =>
        idx === index ? { ...item, [key]: value } : item
      );
      return {
        currentObj: {
          ...prevState.currentObj,
          content: updatedContent
        }
      };
    });
  }

  onAddCarouselSides = () => {
    this.setState(prevState => ({
      currentObj: {
        ...prevState.currentObj,
        content: [...prevState.currentObj.content, defaultCarouselContent]
      }
    }));
  }

  processCarouselImageFile = async(file: File, key: string, updateContent: Function) => {
    const res = await uploadFileToApi(file)
    if(res){
      updateContent({
        mediaUrl: "",
        fileError: "",
        urlError: "",
        type: "image",
        isMediaUrl: false,
        fileName: file.name,
        [key]: res.image_url
      });
    }else {
      const newContent = [...this.state.currentObj.content];
      updateContent({
        urlError: "",
        fileError: "Image resolution is too low"
      })
    }
  }

  processCarouselVideoFile = async(file: File, key: string, updateContent: Function) => {
    const res = await uploadFileToApi(file,false)
    if(res){
      updateContent({
        mediaUrl: "",
        fileError: "",
        urlError: "",
        type: "video",
        isMediaUrl: false,
        fileName: file.name,
        [key]: res.video_url
      });
    }else {
      updateContent({
        urlError: "",
        fileError: "Uploading Video failed! Please try again!"
      })
    }
  }

  onCaptureCarouselMedia = (key: string, index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    const imageFormats = ['image/jpg', 'image/jpeg', 'image/png'];
    const videoFormats = ['video/mp4', 'video/webm', 'video/ogg', 'video/x-msvideo', 'video/avi'];

    if (!file) return;

    const updateContent = (updatedFields: {}) => {
      this.setState(prevState => {
        const newContent = [...prevState.currentObj.content];
        newContent[index] = { ...newContent[index], ...updatedFields };
        return {
          currentObj: {
            ...prevState.currentObj,
            content: newContent,
          },
          isLoadingFile:false
        };
      });
    }

      this.setState({isLoadingFile:true})
    if (imageFormats.includes(file.type)) {
      this.processCarouselImageFile(file, key, updateContent);
    } else if (videoFormats.includes(file.type)) {
      this.processCarouselVideoFile(file, key, updateContent);
    } else {
      updateContent({
        urlError: "",
        fileError: "Only .png, .jpg, .jpeg, .mp4, .webm, .avi allowed",
      });
    }
  }

  handleKeyDownForCarousel = (key: number, index: number) => {
    if (key === 'Enter') {
      this.onImportMediaFromLink(index);
    }
  }

  doesMediaImageExist = (updateContentForCarousel: Function, url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        this.checkImageResolution(img, (isHighResolution) => {
          if (isHighResolution) {
            updateContentForCarousel({
              fileName: "",
              type: "image",
              media: url,
              fileError: "",
              urlError: "",
              isMediaUrl: true,
            });
          } else {
            updateContentForCarousel({
              fileError: "",
              urlError: "Image resolution is too low",
            })
          }
          return resolve(true);
        });
      }
      img.onerror = () => {
        return reject(false);
      };
    });
  }

  onImportMediaFromLink = async (index: number) => {
    const url = this.state.currentObj.content[index].mediaUrl;
    const updateContentForCarousel = (updatedCarouselFields: {}) => {
      this.setState(prevState => {
        const newCarouselContent = [...prevState.currentObj.content];
        newCarouselContent[index] = { ...newCarouselContent[index], ...updatedCarouselFields };
        return {
          currentObj: {
            ...prevState.currentObj,
            content: newCarouselContent
          }
        };
      });
    }

    try {
      await this.doesMediaImageExist(updateContentForCarousel, url);
    } catch (err) {
      updateContentForCarousel({
        fileError: "",
        fileName: "",
        urlError: "",
        media: url,
        isMediaUrl: true,
        type: "video"
      });
    }
  }

  scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight
    });
  };

  componentDidMount() {
    const routeName = this.props.navigation.state.routeName.split('/')
    const id = routeName[routeName.length -1]
    this.setState({ isInitialLoad: false,isEditingCourse:false });
    this.handleAddNewQuestion();
    if (id !== 'course-creation') {
      this.getCourseInfo(id);
      this.setState({ isEditingCourse: true })
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (prevState.lessonList.length != this.state.lessonList.length) {
      if (this.state.lessonList.length === 0) {
        this.setState({ ...this.state, lessonCreationBar: { title: "", isActive: true } })
        this.scrollToBottom()
      }
    }

    if (!this.state.isInitialLoad && !prevState.lessonCreationBar.isActive && this.state.lessonCreationBar.isActive) {
      this.scrollToBottom();
    }
  }

  addNewLesson = (title: string) => {
    const generatedId = crypto.randomBytes(16).toString('hex');
    if (this.state.lessonList.some(lesson => lesson.lessonTitle?.title === title)) {
      return;
    }

    this.setState({
      lessonCreationBar: { title: "", isActive: false },
      lessonList: [...this.state.lessonList, { lessonTitle: { title, isActiveToChange: false }, id: generatedId, courseData: [], quizData: [] }],
    });

    this.scrollToBottom()
  };

  deleteLesson = (id: number) => {
  this.setState({
    lessonList: this.state.lessonList.map((lesson) =>
      lesson.id === id ? { ...lesson, _destroy: true } : lesson
    ),
  });
};

  handlePlusClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ plusAnchorEl: event.currentTarget });
  }

  handlePlusClose = () => {
    this.setState({ plusAnchorEl: null });
  }

  setCreationBarActive = () => {
    this.setState({ plusAnchorEl: null, lessonCreationBar: { ...this.state.lessonCreationBar, isActive: true } });
  }

  onHandleEnterPress = (event, callback) => {
    if (event.key === 'Enter') {
      callback();
    }
  }

  onToggleLessonTypeMenu = (event: React.FormEvent<HTMLInputElement>) => {
    const { clientX: posX, clientY: posY } = event;
    if (this.lessonTypeMenuRef.current) {
      this.lessonTypeMenuRef.current.openMenu(posX, posY);
    }
  }

  onHandleSetLessonTitleActive = (lessonId, value: boolean) => {
    this.setState(prevState => ({
      lessonList: prevState.lessonList.map(lessonItem =>
        lessonItem.id === lessonId
          ? {
            ...lessonItem,
            lessonTitle: {
              ...lessonItem.lessonTitle,
              isActiveToChange: value
            }
          }
          : lessonItem
      )
    }));
  };

  onHandleLessonTitleChange = (event, lessonId) => {
    this.setState(prevState => ({
      lessonList: prevState.lessonList.map(item =>
        item.id === lessonId
          ? {
            ...item,
            lessonTitle: {
              ...item.lessonTitle,
              title: event.target.value
            }
          }
          : item
      )
    }));
  }

  onHandleCourseTitleChange = (courseTitle: string) => {
    if (courseTitle === "<p></p>\n") {
      this.setState({ courseTitle: "" });
    } else {
      this.setState({ courseTitle });
    }
  }

  onHandleAuthorNameTitleChange = (event) => {
    this.setState({ authorName: event.target.value });
  }

  setLessonListType = (type: LessonListType) => {
    this.setState({ lessonListType: type });
    if (this.lessonTypeMenuRef.current) {
      this.lessonTypeMenuRef.current.closeMenu();
    }
  }

  onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) {
      return;
    }

    const reorderedList = Array.from(this.state.lessonList);
    const [removed] = reorderedList.splice(source.index, 1);
    reorderedList.splice(destination.index, 0, removed);
    this.setState({ lessonList: reorderedList });
  };

  onOpenLessonPopupMenu = (event: React.FormEvent<HTMLInputElement>, lessonId: number) => {
    const targetElement = event.currentTarget;
    const { x, y, height } = targetElement.getBoundingClientRect();
    if (this.lessonPopupMenuRefs[lessonId] && this.lessonPopupMenuRefs[lessonId].current) {
      this.lessonPopupMenuRefs[lessonId].current.openMenu(x - 180, y + height + 8)
      this.setState(prevState => ({
        lessonList: prevState.lessonList.map(item => ({
          ...item,
          isPopUpActive: item.id === lessonId
        }))
      }));
    }
  };

  onCloseLessonPopupMenu = () => {
    this.setState({
      lessonList: this.state.lessonList.map(item => ({
        ...item,
        isPopUpActive: false
      }))
    });
  }

  onChangeCourseDescription = (value: string) => {
    this.setState({ description: value });
  }

  onEditorSave = (result: string) => {
    this.setState({description: result});
    if (this.editorMenuRef.current) {
      this.editorMenuRef.current.closeMenu();
    }
  }
  handleImageClick = (e,index:number) => {
    const { courseData } = this.state;
    const containerBounds = this.containerRef.current.getBoundingClientRect();

    const x = e.clientX - containerBounds.left;
    const y = e.clientY - containerBounds.top;

    const newButton = {
      title:`<p style="font-family:'Crimson Text';font-size:16px;font-weight:bold;">Heading of Card</p>`,
        description: `<p style="font-family:'Crimson Text';font-size:14px">This is the subtext that you can edit in the editor</p>`,
        visibleContent:false,
      x: Math.max(0, Math.min(x, containerBounds.width)),
      y: Math.max(0, Math.min(y, containerBounds.height)),
      contentRightSide:true,
      id:`button-${Date.now()}`
    };

    const updatedCourseData = [...courseData];
    const labelGraphicData = { ...updatedCourseData[index] };

    if (labelGraphicData.content.length < 6) {
      labelGraphicData.content = [...labelGraphicData.content, newButton];
      updatedCourseData[index] = labelGraphicData;

      this.setState({ courseData: updatedCourseData });
    }
  };

  handleDragStart = (e, buttonIndex, parentIndex) => {
    const { courseData } = this.state;
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    
    const labelGraphicData = courseData[parentIndex];
    const button = labelGraphicData.content[buttonIndex];
    
    const offsetX = e.clientX - containerBounds.left - button.x;
    const offsetY = e.clientY - containerBounds.top - button.y;
    
    this.setState({
      draggingIndex: buttonIndex,
      offset: { x: offsetX, y: offsetY }
    });
  };

  handleDrag = (e, index:number) => {
    const { draggingIndex, courseData, offset } = this.state;
    const updatedCourseData = [...courseData];
    const labelGraphicData = { ...updatedCourseData[index] };
  
    if (draggingIndex === null) return;

    // Calculate new position during drag
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    const x = e.clientX - containerBounds.left - offset.x;
    const y = e.clientY - containerBounds.top - offset.y;
    const buttonRef = document.querySelector(`[data-test-id="draggable-btn-${draggingIndex}"]`);
    const buttonBounds = buttonRef ? buttonRef.getBoundingClientRect() : { width: 0, height: 0 };
  
    // Update button position within container boundaries
    const updatedButtons = [...labelGraphicData.content];
    updatedButtons[draggingIndex] = {
      ...updatedButtons[draggingIndex],
      x: Math.max(0, Math.min(x, containerBounds.width - buttonBounds.width)),
      y: Math.max(0, Math.min(y, containerBounds.height - buttonBounds.height)),
    };
    labelGraphicData.content = updatedButtons;
    updatedCourseData[index] = labelGraphicData;
  
    this.setState({ courseData: updatedCourseData });
  };

  handleDragEnd = () => {
    this.setState({ draggingIndex: null });
  };

  getCourseDataObj = () => {
    let courseData = this.state.courseData;
    if((this.state.isPreview && this.state.isCourseLearning) && (this.state.selectedCourseId !== this.state.selectedId)) {
      courseData = this.state.lessonList[this.state.selectedCourseId].courseData;
    }
    return courseData;
  }

  updateCourseObj = (updatedCourseData) => {
    if((this.state.isPreview && this.state.isCourseLearning) && (this.state.selectedCourseId !== this.state.selectedId)) {
      let lessonList = [...this.state.lessonList];
      lessonList[this.state.selectedCourseId].courseData = updatedCourseData;
      this.setState({ lessonList });
    } else {
      this.setState({ courseData: updatedCourseData });
    }
  }

  handleEditLabelContent = (parentIndex: number, id: string, key: string, valueUpdate: any) => {
    const courseData = this.getCourseDataObj();
    const updatedCourseData = [...courseData];
    updatedCourseData[parentIndex] = {
      ...updatedCourseData[parentIndex],
      content: updatedCourseData[parentIndex].content.map(item =>
        item.id === id ? { ...item, [key]: valueUpdate } : item
      )
    };
    this.updateCourseObj(updatedCourseData);
  }

  handleEditContent = (key:string,value:string, id: string)=>{
    const currentObj = { ...this.state.currentObj };
  
    const index = currentObj.content.findIndex(item => item.id === id);
  
    if (index !== -1) {
      const updatedContent = [...currentObj.content];
      updatedContent[index] = {
        ...updatedContent[index],
        [key]: value
      };
  
      currentObj.content = updatedContent;
      this.setState({ currentObj });
    }
  }
  deleteContentItem = (id: string) => {
    const currentObj = { ...this.state.currentObj };
    
    const updatedContent = currentObj.content.filter(item => item.id !== id);
  
    currentObj.content = updatedContent;
    this.setState({ currentObj });
  };
  handleAddNewBtn = () => {
    const { currentObj } = this.state;

    const newButton = {
      contentRightSide:true,
      id:`button-${Date.now()}`,
      title:`<p style="font-family:'Crimson Text';font-size:16px;font-weight:bold;">Heading of Card</p>`,
        description: `<p style="font-family:'Crimson Text';font-size:14px">This is the subtext that you can edit in the editor</p>`,
        visibleContent:false,
      x: 100,
      y: 100,
    };

    const updatedObj = {...currentObj};

    if (updatedObj.content.length < 6) {
      updatedObj.content = [...updatedObj.content, newButton];

      this.setState({ currentObj: updatedObj });
    }
  };

  handleFlipCard = (index:number,parentIndex:number,valueUpdate:boolean)=>{
    const courseData = this.getCourseDataObj();
    const updatedCourseData = [...courseData];
    const flashCardData = { ...updatedCourseData[parentIndex] };
    const updatedContent = [...flashCardData.cards].sort((a, b) => {
      return a._destroy === b._destroy ? 0 : a._destroy ? 1 : -1;
    });
    updatedContent[index] = {
      ...updatedContent[index],
      flipped: valueUpdate
    };
    flashCardData.cards = updatedContent;
    updatedCourseData[parentIndex] = flashCardData;
    this.updateCourseObj(updatedCourseData);
  }

  handleEditCardContent = (key:string,value:string, id: number)=>{
    const currentObj = { ...this.state.currentObj };
  const updatedCards = currentObj.cards.map((card) => {
    if (card.id === id) {
      return {
        ...card,
        [key]: value,
      };
    }
    return card;
  });

  this.setState({ currentObj: { ...currentObj, cards: updatedCards } });
  }
  handleDeleteCardContent = (id:string)=>{
    const currentObj = { ...this.state.currentObj };
    const updatedCards = [...currentObj.cards].map(card=>card.id!== id?card:{...card,_destroy:true});
    currentObj.cards=updatedCards

    this.setState({ currentObj });
  }
  handleAddNewCard=()=>{
    const { currentObj } = this.state;

    const newCard = {
      id:uuidv4(),
        front:`<p style="font-family:'Crimson Text';font-size:14px">Front of Card</p>`,
        back:`<p style="font-family:'Crimson Text'font-size:14px">Back of Card</p>`,
        flipped:false
    };

    const updatedObj = {...currentObj};

    if (updatedObj.cards.filter(item=>!item._destroy).length < 6) {
      updatedObj.cards = [...updatedObj.cards, newCard];

      this.setState({ currentObj: updatedObj });
    }
  }
  handleTouchStart = (e, buttonIndex, parentIndex) => {
    const { courseData } = this.state;
    const touch = e.touches[0];
    
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    const labelGraphicData = courseData[parentIndex];
    const button = labelGraphicData.content[buttonIndex];

    const offsetX = touch.clientX - containerBounds.left - button.x;
    const offsetY = touch.clientY - containerBounds.top - button.y;

    this.setState({
      draggingIndex: buttonIndex,
      currentParentIndex:parentIndex,
      offset: { x: offsetX, y: offsetY },
    });

    document.addEventListener('touchmove', this.handleTouchMove);
    document.addEventListener('touchend', this.handleTouchEnd);
  };

handleTouchMove = (e) => {
    const { draggingIndex, courseData, offset } = this.state;
    const updatedCourseData = [...courseData];
    const labelGraphicData = { ...updatedCourseData[this.state.currentParentIndex] };

    if (draggingIndex === null) return;

    const touch = e.touches[0];
    const containerBounds = this.containerRef.current.getBoundingClientRect();
    const x = touch.clientX - containerBounds.left - offset.x;
    const y = touch.clientY - containerBounds.top - offset.y;
    const buttonRef = document.querySelector(`[data-test-id="draggable-btn-${draggingIndex}"]`);
    const buttonBounds = buttonRef ? buttonRef.getBoundingClientRect() : { width: 0, height: 0 };
    const updatedButtons = [...labelGraphicData.content];
    updatedButtons[draggingIndex] = {
      ...updatedButtons[draggingIndex],
      x: Math.max(0, Math.min(x, containerBounds.width -  buttonBounds.width)),
      y: Math.max(0, Math.min(y, containerBounds.height - buttonBounds.height)),
    };

    labelGraphicData.content = updatedButtons;
    updatedCourseData[this.state.currentParentIndex] = labelGraphicData;

    this.setState({ courseData: updatedCourseData });
  };

handleTouchEnd = () => {
    this.setState({ draggingIndex: null });
    document.removeEventListener('touchmove', this.handleTouchMove);
    document.removeEventListener('touchend', this.handleTouchEnd);
  };
  addRow = (position = "after") => {
    const { selectedCellId, currentObj} = this.state;
    if (!selectedCellId) return;
  
    const rowsData = [...currentObj.table_rows_attributes];
    const selectedRowIndex = rowsData.findIndex((row) =>
      {
            return Object.values(row).some((cell) => cell?.id === selectedCellId);
        }
    );
  
    if (selectedRowIndex === -1) {
      alert("Please select a cell first!");
      return;
    }
  
    if (position === "before" && selectedRowIndex === 0) {
      alert("Cannot add a row before the first row");
      return;
    }
  
    const newId = uuidv4();
    const newRow = { id: `row-${newId}` };
    const columnKeys = Object.keys(rowsData[0]).filter((key) => key !== "id");
  
    columnKeys.forEach((columnKey) => {
      const columnId = uuidv4(); // Generate a new unique ID for each cell
      newRow[columnKey] = { id: columnId, value: "<p>New Cell</p>" };
    });
  
    const newIndex = position === "after" ? selectedRowIndex + 1 : selectedRowIndex;
  
    rowsData.splice(newIndex, 0, newRow);
  
    this.setState({
      currentObj: {
        ...currentObj,
        table_rows_attributes: rowsData,
      },
    });
  };
  
  addColumn = (position = "after") => {
    const { selectedCellId, currentObj } = this.state;
    if (!selectedCellId) return;
    const rowsData = [...currentObj.table_rows_attributes];
    let selectedColumnIndex = -1;
    let columnKeys = Object.keys(rowsData[0]).filter((key) => key !== "id");
    for (let i = 0; i < columnKeys.length; i++) {
       if (rowsData.some((row) => row[columnKeys[i]]?.id === selectedCellId)) {
        selectedColumnIndex = i;
        break;
      }
    }
  if (selectedColumnIndex === -1) return alert("Cell selection invalid!");
    if (position === "before" && selectedColumnIndex === 0) {
   return alert("Cannot add a column before the first column");
    }
   const newColumnIndex = position === "after" ? selectedColumnIndex + 1 : selectedColumnIndex;
  const newColumnId = uuidv4();

  const updatedContent = rowsData.map((row,index) => {
    let newRow = { ...row }; 
    let newRowEntries = Object.entries(newRow).filter(([key]) => key !== "id");
    newRowEntries.splice(newColumnIndex, 0, [`columnTEMP`, { id: newColumnId, value: "New cell" + index }]);
    let finalRow = { id: row.id };
    newRowEntries.forEach(([key, value], index) => {
      finalRow[`column${index + 1}`] = value;
         });
    return finalRow;
  });
    this.setState({
      currentObj: {
        ...currentObj,
        table_rows_attributes: updatedContent,
      },
    });
  };
  handleCellSelect = (rowId:number, colIndex:number,cellId:string) => {
    this.setState({
      selectedRowId: rowId,
      selectedColumnIndex: colIndex,
      selectedCellId:cellId
    });
  };
  deleteColumn = () => {
    const { currentObj, selectedCellId } = this.state;
    if (!selectedCellId || !currentObj?.table_rows_attributes) return;
    let columnToDelete = null;
    for (const row of currentObj.table_rows_attributes) {
      columnToDelete = Object.keys(row).find(
        (key) => row[key]?.id === selectedCellId
      );
      if (columnToDelete) break;
    }
  
    if (!columnToDelete) {
      alert("Column not found for the selected cell.");
      return;
    }
  
    const firstRow = currentObj.table_rows_attributes[0];
    const totalColumns = Object.keys(firstRow).length - 1; // Excluding 'id'
  
    if (totalColumns <= 1) {
      alert("Cannot delete the last remaining column.");
      return;
    }
  
    const updatedRows = currentObj.table_rows_attributes.map((row) => {
      let newRow = { id: row.id };
      let rowEntries = Object.entries(row).filter(([key]) => key !== columnToDelete && key !== "id");
        rowEntries.forEach(([key, value], index) => {
        newRow[`column${index + 1}`] = value;
      });
  
      return newRow;
    });
    this.setState({
      currentObj: {
        ...currentObj,
        table_rows_attributes: updatedRows,
      },
      selectedCellId: null,
    });
  };
  
  
  
  deleteRow = () => {
    const { selectedRowId, currentObj } = this.state;
  
    // Check if a row is selected
    if (selectedRowId === null) return;
  
    const totalRows = currentObj.table_rows_attributes.length;
  
    // Ensure there is more than one row remaining
    if (totalRows <= 1) {
      alert("Cannot delete the last remaining row.");
      return;
    }
  
    const updatedContent = { ...currentObj };
    const updatedRowsDataContent = updatedContent.table_rows_attributes.map((item,index)=>{
      return item.id === selectedRowId ? {...item,_destroy:true}:item
    });
  
    this.setState({
      currentObj: {...this.state.currentObj,table_rows_attributes:updatedRowsDataContent},
      selectedRowId: null,
    });
  };
  handleTableValueChange =   (value:string, rowIndex:number, cellId:string) => {
    const updatedContent = { ...this.state.currentObj };
  const tableData = [...updatedContent.table_rows_attributes];

  const updatedCell = Object.values(tableData[rowIndex]).find(item=>item?.id===cellId);

  if (updatedCell) {
    updatedCell.value = value;  
  }

  // Update the state with the modified content
  this.setState({
    currentObj: updatedContent
  });
  };
  handleCorrectOptionChange = (optionIndex) => {
    this.setState((prevState) => {
      const { currentObj } = prevState;
      const question = { ...currentObj.content };

      // Reset all isCorrect flags if it's a single response question
      if (!question.isMultiple) {
        question.options = question.options.map((opt, idx) => ({
          ...opt,
          isCorrect: idx === optionIndex
        }));
      } else {
        // Toggle the isCorrect flag for multiple response question
        question.options[optionIndex].isCorrect = !question.options[optionIndex].isCorrect;
      }

      return { currentObj: { ...currentObj, content: question } };
    });
  };

  handleTextAnswerChange = (value, optionIndex) => {
    this.setState((prevState) => {
      const updatedOptions = prevState.currentObj.content.options.map((opt, idx) =>
        idx === optionIndex ? { ...opt, answer: value } : opt
      );
      return {
        currentObj: {
          ...prevState.currentObj,
          content: {
            ...prevState.currentObj.content,
            options: updatedOptions
          }
        }
      };
    });
  };
  handleTestContentChange = (key:string,value)=>{
    this.setState((prevState) => ({
      currentObj: {
        ...prevState.currentObj,
        content: {
          ...prevState.currentObj.content,
          [key]: value
        }
      }
    }));
  }
  handleChangeQuestionType = (e)=>{
    const {currentObj} = this.state
    const question = {...currentObj.content}
    const updatedQuestion = question.options.map(ques=>({...ques,isCorrect:false,isSelected:false}))
    this.setState(
      {
        currentObj: {
          ...currentObj,
          content: {
            ...currentObj.content,
            isMultiple: e?.value,
            options: updatedQuestion
          }
        }
      }
    )
  }
  handleDeleteAnswer = (id:string)=>{
    const {currentObj} = this.state
    const question = {...currentObj.content}
    const updatedQuestion = question.options.filter(ques=>ques.id !== id)
    question.options = updatedQuestion
    this.setState({currentObj:{...currentObj,content:question}})
  }
  handleAddAnswer = () => {
    this.setState((prevState) => {
      const { options } = prevState.currentObj.content;

      if (options.length >= 4) {
        return prevState; 
      }

      const newOption = { answer: `New Answer`, isCorrect: false, id:`${uuidv4()}`,isSelected:false };
      return {
        currentObj: {
          ...prevState.currentObj,
          content: {
            ...prevState.currentObj.content,
            options: [...options, newOption]
          }
        }
      };
    });
  };

  handleSelectAnswer = (index, optionId) => {
      let courseData = this.getCourseDataObj();
      const updatedCourseData = [...courseData];
      const testKnowledge = updatedCourseData[index];
  
      const updatedOptions = testKnowledge.content.options.map((option) => {
        if (testKnowledge.content.isMultiple) {
          return option.id === optionId
            ? { ...option, isSelected: !option.isSelected }
            : option;
        } else {
          return { ...option, isSelected: option.id === optionId };
        }
      });
  
      updatedCourseData[index] = {
        ...testKnowledge,
        content: {
          ...testKnowledge.content,
          options: updatedOptions,
        },
      };
      this.updateCourseObj(updatedCourseData);
  };

  submitAnswer = (id: string) => {
    const courseData = this.getCourseDataObj();
    const updatedCourseData = courseData.map(item =>
      item.id === id
        ? {
            ...item,
            content: {
              ...item.content,
              options: item.content.options.map(option => ({ ...option })),
            },
          }
        : { ...item }
    );

    const questionContent = updatedCourseData.find(item => item.id === id)?.content;
    if (!questionContent) return;
  
    const { question_type, options } = questionContent;
    const isAnswerCorrect =
      question_type === 'single_response'
        ? 
          options.some(option => option.isCorrect && option.isSelected)
        : 
          options.every(
            option => option.isCorrect === option.isSelected
          );
    questionContent.isSubmit = true;
    questionContent.answerCorrect = isAnswerCorrect;
   this.updateCourseObj(updatedCourseData);
  };

  handleChangeButtonType = (index:number, value:boolean)=>{
    const { courseData } = this.state;
    const updatedCourseData = [...courseData];
    const buttonData = updatedCourseData[index]
    buttonData.finishButton = value;
    this.setState({courseData:updatedCourseData})
  }

  sanitizeData = (htmlString: string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  }

  getCourseInfo = async (id: string | number) => {
    this.setState({ isLoading: true });
    this.getCourseInfoApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/courses/get_course?id=${id}`,
    });
  }

  handleRemainingCourseData = ( index: number,course = {}) => {
    const baseAttributes = {
        _destroy:course?._destroy,
        id: typeof course?.id === 'number' ? course.id : null,
      content_block_position: index + 1,
      padding_top: course?.paddingTop,
      padding_bottom: course?.paddingBottom,
      width: course?.width,
    };
  
    switch (course?.type) {
      case "Label":
        return {
          ...baseAttributes,
          block_type: "labeled_graphic",
          image_url: course?.image,
          labels_attributes: course?.content?.map((item) => ({
            id: typeof item.id === 'number' ? item.id : null,
            x_position: item.x,
            y_position: item.y,
            title: item.title,
            description: item.description,
            visible_content: item.visibleContent,
            _destroy:item?._destroy
          })),
        };
  
      case "Test":
        return {
          ...baseAttributes,
          block_type: "test_knowledge",
          test_questions_attributes: [
            {
              question_text: course?.content?.question,
              question_type: course?.content?.isMultiple ? "multiple_response" : "single_response",
              correct_feedback: course?.content?.supportingText,
              incorrect_feedback: course?.content?.supportingTextInCorrect,
              options_attributes: course?.content?.options?.map((obj) => ({
                _destroy:obj?._destroy,
                id: typeof obj.id === 'number' ? obj.id : null,
                text: obj.answer,
                is_correct: obj.isCorrect,
              })),
            },
          ],
        };
  
      case "Button":
        return {
          ...baseAttributes,
          block_type: "button",
          is_finished: course?.finishButton,
        };
    }
  };
  

  handleCourseData = (course: {}, index: number) => {
    if (course?.type === "Carousel") {
      return {
        "_destroy":course?._destroy,
        "id":typeof course?.id === 'number'?course?.id:null,
        "block_type": "carousel",
        "content_block_position": index + 1,
        "padding_top": course?.paddingTop,
        "padding_bottom": course?.paddingBottom,
        "width": course?.width,
        "slides_attributes": course?.content.map((item) => ({
          "id":typeof item.id === 'number'?item.id:null,
          "title": item.heading,
          "description": item.description,
          "media_type": item.type,
          "_destroy":item?._destroy,
          // "media": item.isMediaUrl ? undefined : item.media,
          "media_url": item.media,
        }))
      }
    } else if (course?.type === "Quote") {
      return {
        "_destroy":course?._destroy,
        "id":typeof course?.id === 'number'?course?.id:null,
        "block_type": "quote",
        "content_block_position": index + 1,
        "padding_top": course?.paddingTop,
        "padding_bottom": course?.paddingBottom,
        "width": course?.width,
        "content": course?.content,
        "quote_owner_name": course?.name,
        "image_url": course?.image,
      }
    } else {
      return this.handleRemainingCourseData(index,course);
    }
  }

  getRowsData = (data:any) => {
    const data1=data.map((row) => {
      const { id, ...columns } = row; 

      return {
        id: typeof id === "number" ? id : null,
        columns: Object.keys(columns).reduce((acc, key) => {
          acc[key] = columns[key].value; 
          return acc;
        }, {}),
        _destroy: row._destroy || undefined, 
      };
    });
  return data1
  };
  

  getCourseData = (courseData:any) => {
    const typeHandlers = {
      Text: (course, index) => ({
        ...course,
        id: typeof course?.id === "number" ? course?.id : null,
        block_type: "text",
        content_block_position: index + 1,
        padding_top: course?.paddingTop,
        padding_bottom: course?.paddingBottom,
        width: course?.width,
        heading: course?.heading,
        content: course?.content,
      }),
      List: (course, index) => ({
        ...course,
        id: typeof course?.id === "number" ? course?.id : null,
        block_type: "list",
        content_block_position: index + 1,
        padding_top: course?.paddingTop,
        padding_bottom: course?.paddingBottom,
        list_type: course?.listType,
        width: course?.width,
        heading: course?.heading,
        content: course?.content,
      }),
      Image: (course, index) => ({
        ...course,
        id: typeof course?.id === "number" ? course?.id : null,
        block_type: "image",
        content_block_position: index + 1,
        padding_top: course?.paddingTop,
        padding_bottom: course?.paddingBottom,
        width: course?.width,
        caption: course?.caption,
        image_url: course?.image,
      }),
      Video: (course, index) => {
        const isBase64Video = (video) =>
          typeof video === "string" &&
          /^data:video\/[a-zA-Z0-9.+-]+;base64,/.test(video);
        return ({
          _destroy:course?._destroy,
          id: typeof course?.id === "number" ? course?.id : null,
          block_type: "video",
          content_block_position: index + 1,
          padding_top: course?.paddingTop,
          padding_bottom: course?.paddingBottom,
          width: course?.width,
          caption: course?.caption,
          ...(isBase64Video(course?.video)
      ? {
          video: course?.video,
          video_url:null
        }
      : {
          video_url: course?.video,
          video:null
        }),
        });
      },
      Table: (course, index) => ({
        ...course,
        id: typeof course?.id === "number" ? course?.id : null,
        block_type: "tabular",
        content_block_position: index + 1,
        padding_top: course?.paddingTop,
        padding_bottom: course?.paddingBottom,
        width: course?.width,
        table_rows_attributes: this.getRowsData(course?.table_rows_attributes),
      }),
      Card: (course, index) => ({
        ...course,
        id: typeof course?.id === "number" ? course?.id : null,
        block_type: "flashcard",
        content_block_position: index + 1,
        padding_top: course?.paddingTop,
        padding_bottom: course?.paddingBottom,
        width: course?.width,
        flashcards_attributes: course?.cards.map((item) => ({
          ...item,
          id:typeof item.id === "number" ? item.id : null,
          front_side: item.front,
          back_side: item.back,
        })),
      }),
      Sorting: (course, index) => ({
        ...(typeof course.id === 'number' && { id: course.id }),
        _destroy:course._destroy,
        block_type: "sorting_content",
        sorting_block_attributes: {
          content_block_position: index + 1,
          id:course.sorting_block_id,
          padding_top: course.paddingTop,
          padding_bottom: course.paddingBottom,
          width: course.width,
          sorting_categories_attributes: course.content.map(category => ({
            title: category.title,
            _destroy:category._destroy,
            ...(typeof category.id === "number" && { id: category.id }),
            sorting_items_attributes: category.items.map(item => ({
              title: item.title,
            _destroy:item._destroy,
            ...(typeof item.id === "number" && { id: item.id })
            }))
          }))
        },
      })
    };
    return courseData.map((course, index) => {
        const handler = typeHandlers[course?.type];
      return handler
        ? handler(course, index)
        : this.handleCourseData(course, index);
    });
  };
  

  onPublish = async (packageIds: string[]) => {
    const errors = [];
    if (!this.sanitizeData(this.state.courseTitle).replace(/\n/g, "").trim()) {
      errors.push("Course title can't be blank!");
    }
    if (!this.state.authorName) {
      errors.push("Author name can't be blank!");
    }
    if (!this.state.coverImg.image && !this.state.coverDescImg.image && !this.state.imageObj.image) {
      errors.push("Course picture can't be blank!");
    }
    if(!this.sanitizeData(this.state.description).trim()){
      errors.push("Course description can't be blank!");
    }
    if (!this.state.requirement.duration) {
      errors.push("Total hours can't be blank!");
    }
    if (!this.sanitizeData(this.state.requirement.course_requirements).replace(/\n/g, "").trim()) {
      errors.push("Course requirement can't be blank!");
    }
    if (packageIds.length === 0) {
      errors.push("Learning paths must include at least one learning path");
    }
    if (errors.length !== 0) {
      this.setState({ isErrorVisible: true, errors });
      return;
    }
    this.setState({ isLoading: true }, () => {
      this.onPublishApi(packageIds);
    });
  }

  onPublishApi = async (packageIds: string[]) => {
    const lessonList = [...this.state.lessonList];
    const lessonArr = lessonList.map((item: LessonItem, index: number) => {
      return ({
        ...item,
        "course_module_position":index,
        "title": item.lessonTitle.title,
        "id":typeof item.id === 'number'?item.id:null,
        "sections_attributes": [{
          "id":item.sectionId ?? null,
          "content_blocks_attributes": this.getCourseData(item.courseData)
        }],
        "assessment_attributes":Object.keys(item?.assessmentData ?? {}).length >0?item.assessmentData:null
      });
    });
    const body = {
      "data": {
        "type": "courses",
        "attributes": {
          "title": this.state.courseTitle,
          "description": this.state.description,
          "author_name": this.state.authorName,
          "thumbnail_image": this.state.imageObj.image || undefined,
          "course_cover_image": this.state.coverImg.image || undefined,
          "course_description_image": this.state.coverDescImg.image || undefined,
          "learning_path_ids": packageIds,
          "duration": this.state.requirement.duration,
          "course_requirements": this.state.requirement.course_requirements,
          "lesson_format": this.state.lessonListType,
          "course_modules_attributes": lessonArr,
          "assessment_attributes": this.state.finalAssessmentQuizData
        }
      }
    }
    const updatedBody =  {
      "data": {
        "type": "courses",
        "attributes": {
          "title": this.state.courseTitle,
          "description": this.state.description,
          "author_name": this.state.authorName,
          "learning_path_ids": packageIds,
          "thumbnail_image": this.state.imageObj.image || undefined,
          "course_cover_image": this.state.coverImg.image || undefined,
          "course_description_image": this.state.coverDescImg.image || undefined,
          "duration": this.state.requirement.duration,
          "course_requirements": this.state.requirement.course_requirements,
          "lesson_format": this.state.lessonListType,
          "course_modules_attributes": lessonArr,
          "assessment_attributes": this.state.finalAssessmentQuizData
        }
      }
    }
    const routeName = this.props.navigation.state.routeName.split('/')
    const id = routeName[routeName.length -1]
    if(this.state.isEditingCourse){
      this.editCourseApiCallId = await apiCall({
        method: "PATCH",
        body: updatedBody,
        contentType: "application/json",
        navigation: this.props.navigation,
        token: localStorage.getItem("token"),
        endPoint: `/bx_block_admin/courses/${id}`,
      })
    } else {
     this.createCourseApiCallId = await apiCall({
      method: "POST",
      body: body,
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/courses`,
    });
  }
  this.setState({isLoading:true})
  }
  handlePublishCourses = async(packageIds: string[])=>{
    const routeName = this.props.navigation.state.routeName.split('/')
    const id = routeName[routeName.length -1]
    const updatedBody =  {
      "data": {
        "type": "courses",
        "attributes": {
          "status":"published",
        }
      }
    }
    this.publishCourseApiCall = await apiCall({
      method: "PATCH",
      body: updatedBody,
      contentType: "application/json",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/courses/${id}`,
    })
  }
  handleDeleteContentClose=()=>{
    this.setState({isDeletingContent:false})
  }
  handleGoToNextPreviewQuestion =() => {
    const { currentPreviewQuestion, currentPreviewAssessmentData } = this.state;
    const { questions } = currentPreviewAssessmentData;
  
    const nextPosition = currentPreviewQuestion.position + 1;
  
    if (nextPosition < questions.length) {
      this.setState({
        currentPreviewQuestion: {...questions[nextPosition],position:nextPosition},
      });
    } else {
      this.changeChapter(this.state.selectedCourseId+1); this.setState({isShowRenderCard: false})
    }
  }
  handleClickAssessmentItem = (index:any,item:any)=>{
    this.changeChapter(index)
    this.setState({isShowRenderCard: true,currentPreviewQuestion:{...item.assessmentData.questions[0],position:0},currentPreviewAssessmentData:item.assessmentData})
  }
  handleClickFinalAssessmentItem = ()=> { 
    this.changeChapter(99)
    this.setState({isShowRenderCard: true,currentPreviewQuestion:{...this.state.finalAssessmentQuizData.questions[0],position:0},currentPreviewAssessmentData:this.state.finalAssessmentQuizData})
  }
  handleUpdateCategoryTitle = (categoryId, newTitle) => {
    this.setState((prevState) => ({
      currentObj: {
        ...prevState.currentObj,
        content: prevState.currentObj.content.map((category) =>
          category.id === categoryId ? { ...category, title: newTitle } : category
        ),
      },
    }));
  };
  handleUpdateItemTitle = (categoryId, itemId, newTitle) => {
    this.setState((prevState) => ({
      currentObj: {
        ...prevState.currentObj,
        content: prevState.currentObj.content.map((category) =>
          category.id === categoryId
            ? {
                ...category,
                items: category.items.map((item) =>
                  item.id === itemId ? { ...item, title: newTitle } : item
                ),
              }
            : category
        ),
      },
    }));
  };
  handleDeleteCategory = (categoryId) => {
    this.setState((prevState) => ({
      currentObj: {
        ...prevState.currentObj,
        content: prevState.currentObj.content.map((category) =>
          category.id === categoryId ? { ...category, _destroy: true,items:category.items.map(item=>({...item,_destroy:true})) } : category
        ),
      },
    }));
  };

  handleDeleteItem = (categoryId, itemId) => {
    this.setState((prevState) => ({
      currentObj: {
        ...prevState.currentObj,
        content: prevState.currentObj.content.map((category) =>
          category.id === categoryId
            ? {
                ...category,
                items: category.items.map((item) =>
                  item.id === itemId ? { ...item, _destroy: true } : item
                ),
              }
            : category
        ),
      },
    }));
  };
handleAddCategory = () => {
  const newCategory = {
    id: uuidv4(),
    title: `New Category`,
    items: [],
  };

  this.setState((prevState) => ({
    currentObj: {
      ...prevState.currentObj,
      content: [...prevState.currentObj.content, newCategory],
    },
  }));
};

handleAddItem = (categoryId) => {
  const newItem = {
    id: uuidv4(),
    title: `New Item`,
    category_id:categoryId,
  };

  this.setState((prevState) => ({
    currentObj: {
      ...prevState.currentObj,
      content: prevState.currentObj.content.map((category) =>
        category.id === categoryId
          ? { ...category, items: [...category.items, newItem] }
          : category
      ),
    },
  }));
};
  // Customizable Area End
}
