export const textTypeStyle:any ={
    paragraph:{
        color:'#585858',
        '.heading':{
            display:'none'
        }
    },
    paraHeading:{
        color:'#585858',
        '.heading':{
            display:'block',
            fontWeight:'700',
            fontFamily:'Heebo-bold',
            fontSize:'24px'
        }
    },
    paraSubHeading:{
        color:'#585858',
        '.heading':{
            display:'block',
            fontWeight:'500',
            fontSize:'24px'
        }
    },
    heading:{
        '.heading':{
            color:'black',
            display:'block',
            fontWeight:'700',
            fontFamily:'Heebo-bold',
            fontSize:'24px'
        },
        '.content':{
            display:'none'
        }
    },
    subHeading:{
        '.heading':{
            color:'black',
            display:'block',
            fontWeight:'500',
            fontSize:'24px'
        },
        '.content':{
            display:'none'
        }
    },
    column:{
        '.heading':{
            display:'none'
        },
        '.content':{
        color:'#585858',
            columns:2
        }
    },
    styleA:{
        display:'flex',
        justifyContent:'center',
        '.heading':{
            display:'none'
        },
        '.content':{
        color:'#585858',
            width:'50%',
            textAlign:'center',
            borderTop:'1px solid #E7E5E4',
            paddingTop:'20px',
            overflowY:'scroll',
            scrollbarWidth:'none'
        }
    },
    styleB:{
        display:'flex',
        justifyContent:'center',
        '.heading':{
            display:'none'
        },
        '.content':{
        color:'#585858',
            width:'50%',
            textAlign:'center',
            paddingTop:'20px',
            overflowY:'scroll',
            scrollbarWidth:'none',
            position:'relative',
            '&::before': {
                content: '" "', 
                position: 'absolute',
                display: 'block',
                backgroundColor: '#7B61FF',
                height:'3px',
                transform: 'translate(-50%, 0)',
                left:'50%',
                top: '0',
                borderRadius:'10px',
                width:'64px'
              },
        }
    },
    styleC:{
        '.heading':{
            display:'none'
        },
        display:'flex',
        justifyContent:'center',
        '.content':{
        color:'#585858',
            width:'80%',
            padding:'20px',
            textAlign:'center',
            overflowY:'scroll',
            scrollbarWidth:'none',
            borderRadius:'4px',
            backgroundColor:"#F0F0F0"
        }
    },
    styleD: {
        '.heading': {
          display: 'none',
        },
        display: 'flex',
        justifyContent: 'center',
        '.content': {
          padding: '30px',
          color: '#444444',
          width: '80%',
          fontWeight: '700',
          fontFamily: 'Heebo-bold',
          backgroundColor: '#F0F0F0',
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          borderRadius: '4px',
          paddingTop: '30px',
          position: 'relative',
          '&::before': {
            content: '" "', 
            position: 'absolute',
            display: 'block',
            backgroundColor: '#7B61FF',
            height:'3px',
            top: '15px',
            left: '30px',
            borderRadius:'10px',
            width:'64px'
          },
        },
      }
      ,
    note:{
        '.heading':{
            display:'none'
        },
        '.content':{
            color:'#303030',
            padding:'20px',
            border:'1px solid #F87171',
            borderRadius:'8px',
            backgroundColor:'#FEF2F2',
          position: 'relative',
          paddingLeft:'50px',
           '&::before': {
        content: '""',  
        position: 'absolute',
        display: 'block',
        top: '20px',
        left: '-5px',
        width: '64px',
        height: '25px', 
        backgroundImage: "url('https://jointalentelearn-638028-ruby.b638028.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa3dNIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a7f145639ddcd126bad28e180610bb8d7f81bb3d/image_warning.png')",
        backgroundSize: 'contain', 
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    }
        }
    }
}