//@ts-nocheck
import React, { ChangeEvent } from "react";
// Customizable Area Start
import {
  InputBase, Box, Button, List, ListItem, Grid, Typography, ClickAwayListener, Divider,
  ToggleButtonGroup, ToggleButton, Drawer, Slider, Dialog, DialogTitle, DialogContent, DialogActions,
  Paper, ListItemButton, ListItemText, CircularProgress, Menu, MenuItem, LinearProgress,
  Checkbox, FormControlLabel, TextField, Tooltip, Zoom, RadioGroup, Radio, IconButton,Backdrop
} from "@mui/material";
import { ViewHeadline, RadioButtonUnchecked, CheckCircle, Notes, ExpandMore,QuizOutlined, AddCircleOutline } from '@mui/icons-material';
import MenuIcon from "@mui/icons-material/Menu";
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import AddIcon from '@mui/icons-material/Add';
import LoopIcon from '@mui/icons-material/Loop';
import {
  addImage, addImageLight, imageArrowRight, imageArrowUp,
  imageListBulleted, imageListEmpty, imageListNumbered,
  plusIcon, copyIcon, editIcon, formateIcon, deleteIcon, deleteIcon2, textIcon, listIcon,
  videoIcon, tableIcon, flashcardIcon, carouselIcon, buttonIcon, imageIcon,  binIcon,
  repeatBtnBlack, repeatBtnWhite, courseDescImg,sortingIcon,imageArrowLeft,textTypeIcon
} from "./assets";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Select, { components }  from 'react-select';
import { styled } from "@mui/material/styles";
import ReactPlayer from 'react-player';
import Carousel from "react-elastic-carousel";
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import ContentManagementController, {
  DefaultInputTitle, Props, configJSON, PaginationProps, CarouselButtonProps,LessonItem
} from "./ContentManagementController";
import CourseCreationHeader from "./CourseCreationHeader.web";
import Editor from "./Editor.web";
import PopupMenu from "./PopupMenu.web";
import CustomEditor from "../../../components/src/CustomEditor.web";
import Loader from "../../../components/src/Loader.web";
import {finalAssessmentIcon} from '../../assessmenttest/src/assets';
import HtmlReactParser from 'html-react-parser';
import { textTypeStyle } from "../../../components/src/textTypeStyle";
import SortingType from '../../../components/src/SortingType.web'

const toolbarItems = [
  { label: 'Text', icon: textIcon },
  { label: 'List', icon: listIcon },
  { label: 'Image', icon: imageIcon },
  { label: 'Video', icon: videoIcon },
  { label: 'Table', icon: tableIcon },
  { label: 'Flash card', icon: flashcardIcon },
  { label: 'Carousel', icon: carouselIcon },
  { label: 'Sorting', icon: sortingIcon },
];
const textTypeFormat = [
  { label: "Paragraph", value: "paragraph" },
  { label: "Paragraph with heading", value: "paraHeading" },
  { label: "Paragraph with subheading", value: "paraSubHeading" },
  { label: "Heading", value: "heading" },
  { label: "Subheading", value: "subHeading" },
  { label: "Columns", value: "column" },
  { label: "Statement style A", value: "styleA" },
  { label: "Statement style B", value: "styleB" },
  { label: "Statement style C", value: "styleC" },
  { label: "Statement style D", value: "styleD" },
  { label: "Note", value: "note" },
]

type ChangeTitleRenderProps = {
  state: DefaultInputTitle,
  inputStyles: React.CSSProperties;
  toggleActivity: (val: boolean) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  dataTestId?: string;
};
// Customizable Area End

export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.containerRef = React.createRef();
    // Customizable Area End
  }

  // Customizable Area Start

  getListStyleType = (number: number) => {
    if (this.state.lessonListType === "unordered") {
      return <Circle></Circle>
    } else if (this.state.lessonListType === "ordered") {
      return <div>{number}</div>
    }
  }

  ChangeTitleRender: React.FC<ChangeTitleRenderProps> = ({
    state,
    inputStyles,
    toggleActivity,
    onChange,
    placeholder,
    dataTestId
  }) => {
    return (
      <div data-test-id="change-title-render" onDoubleClick={() => !this.state.isPreview && toggleActivity(true)}>
        {state.isActiveToChange ?
          <CustomInput
            readOnly={this.state.isPreview}
            fullWidth
            autoFocus
            data-test-id={dataTestId}
            placeholder={placeholder}
            style={inputStyles}
            value={state.title}
            onKeyDown={(event) => this.onHandleEnterPress(event, () => toggleActivity(false))}
            onChange={(event) => onChange(event)}
          />
          :
          state.title?.length === 0 ?
            <div className="placeholder">{placeholder}</div>
            :
            state.title
        }
      </div>
    );
  }

  renderTitleAndAuthor = (props) => {
    return (
      <div data-test-id="change-title-render">
        <CustomInput
          fullWidth
          autoFocus
          value={props.value}
          onChange={(event) => props.onChange(event)}
          readOnly={this.state.isPreview}
          data-test-id={props.dataTestId}
          placeholder={props.placeholder}
          style={props.inputStyles}
        />
      </div>
    );
  }

  renderToolbar = () => {
    const isToolbarVisible = this.state.isToolbarVisible || this.state.courseData?.length === 0;
    return (
      <>
        {this.state.courseData?.length !== 0 && this.state.courseData?.findIndex(item => item?.type === "Button") === -1 &&
          <PlusLine onClick={this.openToolbar} data-test-id="plus-btn">
            <div />
            <img src={plusIcon} />
          </PlusLine>
        }
        <Toolbar isVisible={isToolbarVisible}>
          <Grid container spacing={1} justifyContent="center">
            {toolbarItems.map((item, index) => (
              <Grid item key={index}>
                <button data-test-id="content-type" onClick={() => this.handleTypeButtonClick(item.label)}>
                  <img src={item.icon} />
                  <span>{item.label}</span>
                </button>
              </Grid>
            ))}
          </Grid>
        </Toolbar>
      </>
    );
  };

  renderSortingOptionList = (index: number,id:any) => {
    const isEditActive = this.state.currentIndex === index && this.state.buttonType === "edit";
    const isFormatActive = this.state.currentIndex === index && this.state.buttonType === "format";
    return (
      <OptionList className="option-list">
        <button><img src={editIcon} onClick={() => this.handleEditSortingClick(index,id)} className={isEditActive ? "active" : ""} data-test-id="edit-sorting-btn" /></button>
        <button><img src={formateIcon} onClick={() => this.handleFormatClick(index)} className={isFormatActive ? "active" : ""} data-test-id="format-sorting-btn" /></button>
        <button><img src={copyIcon} onClick={() => this.handleCopyClick(index)} data-test-id="copy-sorting-btn" /></button>
        <button><img src={deleteIcon} onClick={() => this.setState({isDeletingContent:true,currentContentIndex:index,currentContentId:id})} data-test-id="delete-sorting-btn" /></button>
        {index !== 0 && <button><ArrowUpIcon onClick={() => this.handleUpDownClick(index)} className="up-down" data-test-id="up-sorting-btn" /></button>}
        {index !== (this.state.courseData.length - 1) && <button><ArrowDownIcon onClick={() => this.handleUpDownClick(index + 1)} className="up-down" data-test-id="down-sorting-btn" /></button>}
      </OptionList>
    );
  }
  renderPopoverTextTypeStyle = (id: any) => {
    const {currentObj} = this.state
    return <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={this.hideFormatTextTypeOption}
    >
      <FormatList ref={this.state.anchorEl} className="format-option" maxWidth={'750px'} maxHeight={'440px'} style={{padding:0}}>
        <Box display={'flex'} width={'100%'}>
          <Box flex={3} 
          minWidth={'540px'}
          backgroundColor='#D7D7D7' display={'flex'} alignItems='center' justifyContent={'center'}>
            <Box width={`70%`} borderRadius={'4px'} padding={'36px 24px'} backgroundColor="#fff" overflow='scroll' maxHeight="200px" sx={{...textTypeStyle[currentObj.text_format],zoom:'70%'}}>
              <div  style={{ marginBottom: "30px", whiteSpace: "pre-wrap", wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: currentObj.heading }} className="margin0 heading" />
              <div  dangerouslySetInnerHTML={{ __html: currentObj.content }} style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }} className="margin0 content" />
            </Box>
          </Box>
          <Box flex={1} display={'flex'} flexDirection={'column'}  minWidth={'210px'}>
            {textTypeFormat.map(item =>
              <Typography
              key={item.value}
              data-test-id='text-format-btn'
                onClick={()=>this.handleChangeTextFormat(item.value)}
                style={{ cursor: 'pointer' }}
                fontSize="14px"
                backgroundColor={currentObj.text_format === item.value?'#D7D7D7':'#FFF'}
                fontWeight={currentObj.text_format === item.value?'bold':'normal'}
                lineHeight={'22px'}
                padding="5px 15px">
                {item.label}
              </Typography>
            )}
          </Box>

        </Box>
      </FormatList>
    </ClickAwayListener>
  }
  renderOptionList = (index: number,id:any,isTextType:boolean=false) => {
    const isEditActive = this.state.currentIndex === index && this.state.buttonType === "edit";
    const isFormatActive = this.state.currentIndex === index && this.state.buttonType === "format";
    const isTextTypeActive = this.state.currentIndex === index && this.state.buttonType === "textType";
    return (
      <OptionList className="option-list">
        {isTextType && 
        <button><img src={textTypeIcon} onClick={(e) => this.handleTextTypeFormatClick(index,id,e)} className={isTextTypeActive ? "active" : ""} data-test-id="text-type-btn" /></button>
        }
        <button><img src={editIcon} onClick={() => this.handleEditClick(index,id)} className={isEditActive ? "active" : ""} data-test-id="edit-btn" /></button>
        <button><img src={formateIcon} onClick={() => this.handleFormatClick(index)} className={isFormatActive ? "active" : ""} data-test-id="format-btn" /></button>
        <button><img src={copyIcon} onClick={() => this.handleCopyClick(index)} data-test-id="copy-btn" /></button>
        <button><img src={deleteIcon} onClick={() => this.setState({isDeletingContent:true,currentContentIndex:index,currentContentId:id})} data-test-id="delete-btn" /></button>
        {index !== 0 && <button><ArrowUpIcon onClick={() => this.handleUpDownClick(index)} className="up-down" data-test-id="up-btn" /></button>}
        {index !== (this.state.courseData.length - 1) && <button><ArrowDownIcon onClick={() => this.handleUpDownClick(index + 1)} className="up-down" data-test-id="down-btn" /></button>}
      </OptionList>
    );
  }

  renderButtonOptionList = (index: number,id:any) => {
    const isFormatActive = this.state.currentIndex === index && this.state.buttonType === "format";
    return (
      <OptionList className="option-list">
        <button><img src={formateIcon} onClick={() => this.handleFormatClick(index)} className={isFormatActive ? "active" : ""} data-test-id="format-btn-btn" /></button>
        <button><img src={deleteIcon} onClick={() => this.handleDeleteClick(index,id)} data-test-id="delete-btn-btn" /></button>
      </OptionList>
    );
  }
  DeleteContentModal = () => {
    return (
        <CustomDialog
            open={this.state.isDeletingContent}
            onClose={this.handleDeleteContentClose}
            className="delete-popup"
        >
            <DialogTitle className="dialog-title">
                <CloseIcon className="close-icon" data-testID="close-Modal" onClick={this.handleDeleteContentClose} />
            </DialogTitle>
            <DialogContent className="heading">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box padding="0px 5px 0px 15px">
                            <Typography className="delete-title">Are you sure you want to delete?</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="dialog-action">
                <CancelButton
                    onClick={this.handleDeleteContentClose}
                >
                    No
                </CancelButton>
                <DoneButton
                    onClick={() => this.handleDeleteClick(this.state.currentContentIndex,this.state.currentContentId)}
                    data-test-id="delete-content-btn"
                    sx={{
                      borderRadius: "8px !important",
                      backgroundColor: "#FC5555 !important",
                    }}
                >
                    Yes
                </DoneButton>
            </DialogActions>
        </CustomDialog>
    );
}
  isContentPaddingVisible = (type: string) => {
    return ["Text", "List", "Quote"].includes(type);
  }

  renderQuizOptionList = () => {
    return (
      <OptionList className="option-list">
        <button><img src={editIcon} onClick={() => this.handleShowQuizEditor()} data-test-id="quiz-edit-btn" /></button>
        <button><img src={deleteIcon} data-test-id="delete-btn" /></button>
      </OptionList>
    )
  }

  renderFormatPopup = () => {
    const obj = this.state.courseData[this.state.currentIndex];
    const top = obj.paddingTop;
    const bottom = obj.paddingBottom;
    const blockPadding = top === bottom ? top : 0;
    const isContentPaddingVisible = this.isContentPaddingVisible(obj.type);
    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={this.hideFormatOption}
      >
        <FormatList ref={this.formatRef} className="format-option">
          <Box>
            <Box display="flex" justifyContent="space-between">
              <Typography className="format">Format</Typography>
              <CloseIcon onClick={this.hideFormatOption} className="close-btn" data-test-id="close-btn" />
            </Box>
            <Box>
              <Typography className="text">Block Padding</Typography>
              <ToggleButtonGroup
                exclusive
                value={blockPadding}
                data-test-id="block-padding-btn"
                onChange={(e) => this.handleBlockPadding(e)}
              >
                <CustomToggleButton value={30}>S</CustomToggleButton>
                <CustomToggleButton value={60}>M</CustomToggleButton>
                <CustomToggleButton value={120}>L</CustomToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box>
              <Typography className="small-text">Top</Typography>
              <CustomSlider
                data-test-id="padding-top-btn"
                valueLabelDisplay="auto"
                min={0}
                max={200}
                step={10}
                value={top}
                onChange={(e) => this.handlePadding(e, "paddingTop")}
              />
            </Box>
            <Box>
              <Typography className="small-text">Bottom</Typography>
              <CustomSlider
                data-test-id="padding-bottom-btn"
                valueLabelDisplay="auto"
                min={0}
                max={200}
                step={10}
                value={bottom}
                onChange={(e) => this.handlePadding(e, "paddingBottom")}
              />
            </Box>
            {isContentPaddingVisible &&
              <Box>
                <Typography className="text">Content Padding</Typography>
                <ToggleButtonGroup
                  exclusive
                  data-test-id="content-padding"
                  value={obj.width}
                  onChange={(e) => this.handlePadding(e, "width")}
                >
                  <CustomToggleButton value="50vw">S</CustomToggleButton>
                  <CustomToggleButton value="70vw">M</CustomToggleButton>
                  <CustomToggleButton value="90vw">L</CustomToggleButton>
                </ToggleButtonGroup>
              </Box>
            }
          </Box>
        </FormatList>
      </ClickAwayListener>
    );
  }

  renderTextDrawer = () => {
    return (
      <>
        <Typography className="title">
          Heading
        </Typography>
        <Box paddingBottom="15px">
          <CustomEditor
            value={this.state.currentObj.heading}
            onChange={this.onChangeHeading}
          />
        </Box>
        <Typography className="title">
          Paragraph
        </Typography>
        <Box paddingBottom="15px">
          <CustomEditor
            data-test-id="paragraph"
            value={this.state.currentObj.content}
            onChange={(value: string) => this.onChangeValue("content", value)}
          />
        </Box>
      </>
    );
  }

  renderListDrawer = () => {
    const type = this.state.currentObj.listType;
    const listTypes = [
      { label: 'Bulleted List', value: 'unordered' },
      { label: 'Numbered List', value: 'ordered' }
    ];
    return (
      <>
        <Box>
          <Typography className="title">
            Type of List
          </Typography>
          <Select
            data-test-id="list-type"
            isSearchable={false}
            options={listTypes}
            placeholder="Select list type"
            value={listTypes.filter((item) => item.value === type)[0]}
            onChange={(e) => this.onChangeListValue("listType", e.value)}
            styles={{
              menu: (base) => ({
                ...base,
                zIndex: '999',
              })
            }}
          />
        </Box>
        <Box>
          <Typography className="title">
            Heading
          </Typography>
          <Box >
            <CustomEditor
              hideBottomBorder={true}
              data-test-id="list-heading-editor"
              value={this.state.currentObj.heading}
              onChange={(value) => this.onChangeListValue("heading", value)}
            />
          </Box>
        </Box>
        <Box position="relative" display="flex" alignItems="center">
          <ListDrawer width="100%">
            <CustomEditor
              key={this.state.currentObj.listType}
              isListHide={true}
              hideBottomBorder={true}
              value={this.state.currentObj.content}
              data-test-id="list-content-editor"
              onChange={(value) => this.onChangeListValue("content", value)}
            />
          </ListDrawer>
        </Box>
      </>
    );
  }

  renderImageDrawer = () => {
    const width = this.state.currentObj.width;
    const imageWidth = [
      { label: 'Centred Image', value: '70vw',tooltip: 'Please upload the (560px by 800px) image for optimal resolution'  },
      { label: 'Full Width Image', value: '100vw',tooltip: 'Please upload the "(700px by 800px) image for optimal resolution'  },
    ];
    return (
      <>
        <Box mt="30px">
          <Select
            data-test-id="image-width"
            isSearchable={false}
            options={imageWidth.map(option => ({
              ...option,
              label: (
                <Box display="flex" alignItems="center">
                  <Typography className="text-regular" paddingRight='10px'>{option.label}</Typography>
                {
                  <CustomTooltip
                    arrow
                    placement="right"
                    TransitionComponent={Zoom}
                    title={option.tooltip}
                  >
                    <InfoOutlinedIcon className="icon" />
                  </CustomTooltip>
                }
              </Box>
              )
            }))}
            placeholder="Select image width"
            value={imageWidth.filter((item) => item.value === width)[0]}
            onChange={(e) => this.onChangeValue("width", e.value)}
            styles={{
              menu: (base) => ({
                ...base,
                zIndex: '999',
              })
            }}
          />
        </Box>
        <Box paddingBottom="30px">
          <Typography className="title">
            Image Caption
          </Typography>
          <Box>
            <CustomEditor
              data-test-id="image-caption-editor"
              value={this.state.currentObj.caption}
              onChange={(value) => this.onChangeValue("caption", value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <img src={this.state.currentObj.image} width="134px" height="88px" style={{ objectFit: "cover" }} />
            <Typography sx={{ marginLeft: "20px" }}>{this.state.currentObj.fileName}</Typography>
          </Box>
          <Box ml="25px">
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              style={{ display: "none" }}
              id="capture-image"
              data-test-id="capture-image"
              onChange={(e)=>this.handleFileUpload('image',e)}
            />
            <label htmlFor="capture-image">
              <UploadButton component="span">Upload</UploadButton>
            </label>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.fileError}</Box>
        <Box textAlign="center" mt="20px">
          <Typography sx={{ fontSize: "16px", fontFamily: "Heebo-Medium" }}>Or</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <Box width="100%">
            <Typography sx={{ margin: "0px 0px 8px 0px !important" }} className="title">
              Import from URL
            </Typography>
            <UrlInputBase
              placeholder="Enter URL"
              
              data-test-id="url-input"
              value={this.state.currentObj.media_Url}
              onKeyDown={(e) => this.handleKeyDown(e.key)}
              onChange={(e) => this.onChangeUrl(e.target.value)}
            />
          </Box>
          <Box ml="25px">
            <UploadButton  onClick={this.onImportFromLink} data-test-id="import-btn">Import</UploadButton>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.urlError}</Box>
      </>
    );
  }

  renderVideoDrawer = () => {
    const width = this.state.currentObj.width;
    const videoWidth = [
      { label: 'Centred Video', value: '70vw' },
      { label: 'Full Width Video', value: '100vw' },
    ];
    return (
      <>
        <Box mt="30px">
          <Select
            data-test-id="video-width"
            isSearchable={false}
            options={videoWidth}
            placeholder="Select video width"
            value={videoWidth.filter((item) => item.value === width)[0]}
            onChange={(e) => this.onChangeValue("width", e.value)}
            styles={{
              menu: (base) => ({
                ...base,
                zIndex: '999',
              })
            }}
          />
        </Box>
        <Box paddingBottom="30px">
          <Typography className="title">
            Video Caption
          </Typography>
          <Box>
            <CustomEditor
              data-test-id="video-caption-editor"
              value={this.state.currentObj.caption}
              onChange={(value) => this.onChangeValue("caption", value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box width="200px" height="120px">
              <Box position='relative' display="flex" justifyContent="center" width='100%' height="100%">
                <ReactPlayer
                  controls
                  url={this.state.currentObj.video}
                  width="100%"
                  height="100%"
                />
              </Box>
            </Box>
            <Typography sx={{ marginLeft: "20px" }}>{this.state.currentObj.fileName}</Typography>
          </Box>
          <Box ml="25px">
            <input
              type="file"
              accept="video/mp4, video/webm, video/ogg, video/x-msvideo"
              style={{ display: "none" }}
              id="capture-video"
              
              data-test-id="capture-video"
              onChange={(e)=>this.handleFileUpload('video',e)}
            />
            <label htmlFor="capture-video">
              <UploadButton  component="span">Upload</UploadButton>
            </label>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.fileError}</Box>
        <Box mt="20px" textAlign="center">
          <Typography sx={{ fontFamily: "Heebo-Medium", fontSize: "16px" }}>Or</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <Box width="100%">
            <Typography sx={{ margin: "0px 0px 8px 0px !important" }} className="title">
              Import from URL
            </Typography>
            <UrlInputBase
              placeholder="Enter URL"
              data-test-id="video-url-input"
              value={this.state.currentObj.mediaUrl}
              onKeyDown={(e) => this.handleKeyDownForVideo(e.key)}
              onChange={(e) => this.onChangeUrl(e.target.value)}
            />
          </Box>
          <Box ml="25px">
            <UploadButton  onClick={this.onImportVideoFromLink} data-test-id="video-import-btn">Import</UploadButton>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.urlError}</Box>
      </>
    );
  }

  renderQuoteDrawer = () => {
    return (
      <>
        <Box paddingBottom="15px">
          <Typography className="title" sx={{ display: "flex" }}>
            <Box component="span" mr="5px">Quote Text</Box>
            <CustomTooltip
              arrow
              placement="right"
              TransitionComponent={Zoom}
              title="Maximum 200 characters allowed"
            >
              <InfoOutlinedIcon className="icon" />
            </CustomTooltip>
          </Typography>
          <Box>
            <CustomEditor
              maxCharLimit={200}
              isListHide={true}
              isBlockTypeVisible={true}
              data-test-id="quote-text"
              value={this.state.currentObj.content}
              onChange={(value: string) => this.onChangeValue("content", value)}
            />
          </Box>
        </Box>
        <Box paddingBottom="15px">
          <Typography className="title">
            Name
          </Typography>
          <Box>
            <CustomEditor
              data-test-id="name-text"
              value={this.state.currentObj.name}
              onChange={(value: string) => this.onChangeValue("name", value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems={{ xs: "start", sm: "center" }}>
          <Box display="flex" alignItems="center" flexDirection={{ xs: "column", sm: "row" }}>
            {this.state.currentObj.image && <img src={this.state.currentObj.image} width="88px" height="88px" style={{ objectFit: "cover", borderRadius: "50%" }} />}
            <Typography sx={{ marginLeft: "20px" }}>{this.state.currentObj.fileName}</Typography>
          </Box>
          <Box margin="23px 0px" gap="10px" display="flex" flexDirection={{ xs: "column", sm: "row" }}>
            {this.state.currentObj.image &&
              <UploadButton
                component="span"
                data-test-id="remove-btn"
                
                onClick={this.onRemoveImage}
              >
                Remove
              </UploadButton>
            }
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              style={{ display: "none" }}
              id="capture-quote-image"
              data-test-id="capture-quote-image"
              onChange={(e)=>this.handleFileUpload('image',e)}
            />
            <label htmlFor="capture-quote-image">
              <UploadButton  component="span" data-test-id="upload-btn">Upload</UploadButton>
            </label>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.fileError}</Box>
      </>
    );
  }

  renderLabelDrawer = () => {
    return (
      <>
        <Box paddingBottom="30px">
          <Typography className="title">
            You can add up to 6 labels
          </Typography>
          <CustomTooltip
            arrow
            placement="left"
            TransitionComponent={Zoom}
            title={<p>Recommended Image Dimensions: 1920x1080 pixels.<br />
              Ensure image fits within these dimensions to avoid cut-offs.</p>}
          >
            <InfoOutlinedIcon className="icon" />
          </CustomTooltip>
        </Box>
        <Box justifyContent="space-between" display="flex" alignItems="center">
          <Box alignItems="center" display="flex">
            <img height="88px" width="134px" style={{ objectFit: "cover" }} src={this.state.currentObj.image} />
            <Typography
              sx={{ marginLeft: "20px" }}
            >
              {this.state.currentObj.fileName}
            </Typography>
          </Box>
          <Box ml="25px">
            <input
              accept=".png, .jpg, .jpeg"
              type="file"
              id="captureLabelImage"
              style={{ display: "none" }}
              onChange={(e)=>this.handleFileUpload('image',e)}

              data-test-id="captureLabelImage"
            />
            <label htmlFor="captureLabelImage">
              <UploadButton  component="span">Upload</UploadButton>
            </label>
          </Box>
        </Box>
        <Box data-test-id="errorMessage" color="red">{this.state.currentObj.fileError}</Box>
        <Box mt="20px" textAlign="center" >
          <Typography sx={{
            fontFamily: "Heebo-Medium",
            fontSize: "16px",
          }}>
            Or
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="end"
          justifyContent="space-between"
        >
          <Box width="100%">
            <Typography className="title" sx={{ margin: "0px 0px 8px 0px !important" }} >
              Import from URL
            </Typography>
            <UrlInputBase
              data-test-id="url-input-label"
              placeholder="Enter URL"
              onChange={(e) => this.onChangeUrl(e.target.value)}
              onKeyDown={(e) => this.handleKeyDown(e.key)}
              value={this.state.currentObj.media_Url}
            />
          </Box>
          <Box ml="25px">
            <UploadButton  onClick={this.onImportFromLink} data-test-id="import-btn">Import</UploadButton>
          </Box>
        </Box>
        <Box color="red">{this.state.currentObj.urlError}</Box>
        <Box display={'flex'} flexDirection={'column'} sx={{ borderTop: '1px solid #D6D3D1', marginTop: '40px', padding: '20px 0' }}>
          {this.state.currentObj.content.map((content, index) => {
            return (
              <Box key={content.id} display={'flex'} alignItems={'center'} >
                <Box sx={{ flex: '3' }}>
                  <Box sx={{ marginBottom: '10px', marginTop: '20px' }}>
                    <CustomEditor
                      hideBottomBorder={true}
                      isListHide={true}
                      isBlockTypeVisible={true}
                      data-test-id={`content-header-${index}`}
                      value={content.title}
                      onChange={(value: string) => { this.handleEditContent('title', value, content.id) }}
                    />
                  </Box>
                  <Box sx={{ marginBottom: '10px', marginTop: '20px' }}>
                    <CustomEditor
                      hideBottomBorder={true}
                      isListHide={true}
                      isBlockTypeVisible={true}
                      data-test-id={`content-description-${index}`}
                      value={content.description}
                      onChange={(value: string) => { this.handleEditContent('description', value, content.id) }}
                    />
                  </Box>
                </Box>
                <DeleteButton data-test-id={`delete-button-${index}`} style={{ marginLeft: '20px' }} onClick={() => { this.deleteContentItem(content.id) }}>
                  {this.renderTrashIcon()}
                </DeleteButton>
              </Box>
            )
          })}
        </Box>
        {this.state.currentObj.content.length < 6 &&
          <Button
            data-test-id="add-new-btn"
            style={{
              width: '100%',
              border: '1px solid #D6D3D1',
              backgroundColor: "#FFF",
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '14px'
            }}
            onClick={this.handleAddNewBtn}>
            Add Item
          </Button>}
      </>
    );
  }

  renderCarouselDrawer = () => {
    const obj = this.state.currentObj;
    return (
      <Box key={obj.id}>
        <Typography sx={{ marginTop: "-12px",marginBottom:'15px' }}>You can add up to 5 slides</Typography>
       
        {obj.content.map((item, index: number) => {
          return (
            <Box position="relative" key={item.id}>
              <Box className="border-bottom" borderTop={index === 0 ? "none" : "1.5px solid #D6D3D1"} />
              <Typography className="side-title">Slide {index + 1}</Typography>
              <Box>
                <Typography className="title">
                  Heading Slide {index + 1}
                </Typography>
                <Box paddingBottom="15px">
                  <CustomEditor
                    data-test-id="heading"
                    value={item.heading}
                    onChange={(value: string) => this.onChangeCarouselHeading(index, "heading", value)}
                  />
                </Box>
              </Box>
              <Box>
                <Typography className="title">
                  Text Slide {index + 1}
                </Typography>
                <Box paddingBottom="15px">
                  <CustomEditor
                    data-test-id="description"
                    value={item.description}
                    onChange={(value: string) => this.onChangeCarouselHeading(index, "description", value)}
                  />
                </Box>
              </Box>
              <CustomTooltip
                    arrow
                    placement="left"
                    TransitionComponent={Zoom}
                    title={<p>Recommended Image Dimensions: 1920x1080 pixels.<br />
                      Ensure image fits within these dimensions to avoid cut-offs.</p>}
                  >
                    <InfoOutlinedIcon className="icon" />
                  </CustomTooltip>
              <Box mb="40px" mt={'15px'}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center">
                    {item.type === "video" && <Box width="200px" height="120px">
                      <Box position='relative' display="flex" justifyContent="center" width='100%' height="100%">
                        <ReactPlayer
                          controls
                          url={item.media}
                          width="100%"
                          height="100%"
                        />
                      </Box>
                    </Box>}
                    {item.type === "image" && <img src={item.media} width="200px" height="120px" style={{ objectFit: "cover" }} />}
                    <Typography sx={{ marginLeft: "20px" }}>{item.fileName}</Typography>
                  </Box>
                  <Box ml="25px">
                    <input
                    
                      type="file"
                      accept=".png, .jpg, .jpeg, video/mp4, video/webm, video/ogg, video/x-msvideo"
                      style={{ display: "none" }}
                      id={`capture-media-${index}`}
                      data-test-id={`capture-media-${index}`}
                      onChange={(e) => this.onCaptureCarouselMedia("media", index, e)}
                    />
                    <label htmlFor={`capture-media-${index}`}>
                      <UploadButton  component="span">Upload</UploadButton>
                    </label>
                  </Box>
                </Box>
                <Box color="red">{item.fileError}</Box>
                <Box textAlign="center" mt="20px">
                  <Typography sx={{ fontSize: "16px", fontFamily: "Heebo-Medium" }}>Or</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="end">
                  <Box width="100%">
                    <Typography sx={{ margin: "0px 0px 8px 0px !important" }} className="title">
                      Import from URL
                    </Typography>
                    <UrlInputBase
                      placeholder="Enter URL"
                      data-test-id="media-url-input"
                      onChange={(e) => this.onChangeCarouselHeading(index, "mediaUrl", e.target.value)}
                      onKeyDown={(e) => this.handleKeyDownForCarousel(e.key, index)}
                      value={item.mediaUrl}
                    />
                  </Box>
                  <Box ml="25px">
                    <UploadButton
                    
                      data-test-id="import-url-btn"
                      onClick={() => this.onImportMediaFromLink(index)}
                    >Import</UploadButton>
                  </Box>
                </Box>
                <Box color="red">{item.urlError}</Box>
              </Box>
            </Box>
          )
        }
        )}
          <UploadButton
            data-test-id="add-item"
            component="span"
            sx={{
              height: "50px",
              width: "100%",
              marginBottom: "30px",
            }}
            
            onClick={this.onAddCarouselSides}
          >
            Add Slides
          </UploadButton>
      </Box>
    );
  }

  renderTableDrawer = () => {
    const { currentObj } = this.state;
    const tableData = {...currentObj};
  
   
    const rowsData = [ ...tableData.table_rows_attributes].filter(item=>!item._destroy);
  
    return (
      <>
        <Box paddingBottom="30px">
        <Box paddingBottom="15px">
          <CustomEditor
          data-test-id="drawers"
            value={this.state.currentObj.heading !== "" ? this.state.currentObj.heading : configJSON.headerTag}
            onChange={this.onChangeHeading}
          />
        </Box>
        </Box>
        <Box>
          <CustomTooltip
            arrow
            placement="left"
            TransitionComponent={Zoom}
            title="Ensure each table has a heading for better organization and readability."
          >
            <InfoOutlinedIcon className="icon" />
          </CustomTooltip>
          <div style={{position:'relative'}}>
            <div style={{ marginBottom:'30px',paddingBottom:'20px',marginTop:'30px' }}>
              <StyledTable>
                <tbody>
                  {rowsData.map((row, rowIndex) => (
                    <tr key={row.id} >
                      {Object.entries(row).map(([key, cell], cellIndex) => {
                        return (
                          key !== 'id' && cell && (<td key={`${rowIndex}-${cell.id}`} data-test-id={`cell-${rowIndex}-${cellIndex}`}
                            onClick={() => this.handleCellSelect(row.id, cellIndex, cell.id)} style={{ minWidth: '100px',maxWidth:'300px',wordWrap:'break-word',overflowWrap:'break-word' }}>
                            <CustomEditor
                              isTableEditor={true}
                              hideBottomBorder={true}
                              isListHide={true}
                              isBlockTypeVisible={true}
                              data-test-id={`table-cell-${rowIndex}-${cellIndex}`}
                              value={cell.value}
                              onChange={(value) => this.handleTableValueChange(value, rowIndex, cell.id)} />
                          </td>)
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </StyledTable>
            </div>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Button data-test-id="add-column-before" style={webStyles.buttonTable} onClick={() => this.addColumn('before')}>Add Column Before</Button>
            </Grid>
            <Grid item xs={6}>
              <Button data-test-id="add-column-after" style={webStyles.buttonTable} onClick={() => this.addColumn('after')}>Add Column After</Button>
            </Grid>
            <Grid item xs={6}>
              <Button data-test-id="add-row-before" style={webStyles.buttonTable} onClick={() => this.addRow('before')}>Add Row Before</Button>
            </Grid>
            <Grid item xs={6}>
              <Button data-test-id="add-row-after" style={webStyles.buttonTable} onClick={() => this.addRow('after')}>Add Row After</Button>
            </Grid>
            <Grid item xs={12}>
              <Button data-test-id="delete-column-selected" style={{ ...webStyles.buttonTable, borderColor: '#F87171', color: '#F87171' }} onClick={this.deleteColumn}>Delete Column Selected</Button>
            </Grid>
            <Grid item xs={12}>
              <Button data-test-id="delete-row-selected" style={{ ...webStyles.buttonTable, borderColor: '#F87171', color: '#F87171' }} onClick={this.deleteRow}>Delete Row Selected</Button>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  };

  renderFlashCardDrawer = () => {
    return (
      <>
        <Box>
          <Typography className="title">
            You can add up to 6 Flashcards
          </Typography>
          <CustomTooltip
              arrow
              placement="left"
              TransitionComponent={Zoom}
              title={`   Maximum characters allowed: 250. Please keep text concise for better visibility.`}
            >
              <InfoOutlinedIcon className="icon" />
            </CustomTooltip>
        </Box>
        <Box display={'flex'} flexDirection={'column'} >
          {this.state.currentObj.cards?.filter(card=>!card?._destroy)?.map((content, index) => {
            return (
              <Box display={'flex'} alignItems={'center'} key={content.id}>
                <Box sx={{ padding: '20px 0', flex: '3' }}>
                  <Typography color={'#6A6A6A'} fontSize={16} style={{ marginBottom: '10px', marginTop: '20px' }}>{`Card ${index + 1} Front`}</Typography>
                  <CustomEditor
                    isListHide={true}
                    isBlockTypeVisible={true}
                    data-test-id={`card-front-${index}`}
                    value={content.front}
                    maxCharLimit={250}
                    onChange={(value: string) => { this.handleEditCardContent('front', value, content.id) }}
                  />
                  <Typography style={{ marginBottom: '10px', marginTop: '20px' }} color={'#6A6A6A'} fontSize={16}>{`Card ${index + 1} Back`}</Typography>
                  <CustomEditor
                    isListHide={true}
                    isBlockTypeVisible={true}
                    maxCharLimit={250}
                    data-test-id={`card-back-${index}`}
                    value={content.back}
                    onChange={(value: string) => { this.handleEditCardContent('back', value, content.id) }}
                  />
                </Box>
                <DeleteButton data-test-id={`delete-button-${index}`} style={{ marginLeft: '20px' }} onClick={() => { this.handleDeleteCardContent(content.id) }}>
                  {this.renderTrashIcon()}
                </DeleteButton>
              </Box>
            )
          })}
        </Box>
        {this.state.currentObj.cards.filter(item=>!item._destroy).length < 6 &&
          <Button
            data-test-id="add-new-btn"
            style={{
              width: '100%',
              border: '1px solid #D6D3D1',
              backgroundColor: "#FFF",
              textTransform: 'none',
              fontWeight: 700,
              fontSize: '14px'
            }}
            onClick={this.handleAddNewCard}>
            Add Item
          </Button>}
      </>
    );
  }
  renderTestKnowledgeDrawer = () => {
    const { currentObj } = this.state
    const question = currentObj.content
    const listTypes = [
      { label: 'Single Response', value: false },
      { label: 'Multiple Response', value: true }
    ];
    return (<>
        <Typography style={webStyles.quizEditorSubHeading}>You can add up to 4 options</Typography>
      <Box display={"flex"} flexDirection={"column"} gap={"32px"} paddingBottom={'30px'} sx={{overflowX:'hidden'}}>
        <Box>
          <Typography
            paddingY={"10px"}
            className="title">Type of Question</Typography>
          <Select
           components={{
            DropdownIndicator:this.DropdownIndicator,
            IndicatorSeparator:()=>null
          }}
          isSearchable={false}
          styles={{
            control:(base)=>({
              ...base,
              boxShadow: '0px 4px 4px 0px #00000014',
              border:'none',
              paddingLeft:'5px'
            }),
            menu: (base) => ({
              ...base,
              boxShadow: '0px 4px 4px 0px #00000014',
              border:'none',
              zIndex: '999',
              marginTop:0,
            }),
            option:(base, props) => ({
              ...base,
              backgroundColor:"#fff",
              color:'black',
              fontWeight:props.isSelected?'bolder':'normal',
              padding:'15px',
              fontSize:'14px'
            }),
            dropdownIndicator: (base, state) => ({
              ...base,
              transition: 'all .2s ease',
              transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
            })
          }}
            data-test-id="test-type-select"
            options={listTypes}
            value={listTypes.find(type => type.value === question.isMultiple)}
            onChange={this.handleChangeQuestionType}
          />
        </Box>

        <Box>
          <Typography paddingY={"10px"} className="title">Question</Typography>
          <CustomEditor
            value={question.question}
            data-test-id="question-title-field"
            onChange={(value) => this.handleTestContentChange('question', value)}
          />
        </Box>
        <Box >
          <Box display={"flex"} justifyContent={"space-between"} height={"22px"} gap={"24px"} width={"122px"}>
            <Typography style={webStyles.quizOptionHeading}>Correct</Typography>
            <Typography style={webStyles.quizOptionHeading}>Choices</Typography>
          </Box>
          {question.options.map((option, optionIndex) => (
            <Box
              key={option.id}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="35px"
              paddingTop="10px"
            >
              {question.isMultiple ? (
                <CustomCheckbox
                  data-test-id={`checkbox-${optionIndex}`}
                  checked={option.isCorrect}
                  onChange={() => this.handleCorrectOptionChange(optionIndex)}
                />
              ) : (
                <Radio
                  data-test-id={`radio-${optionIndex}`}
                  checked={option.isCorrect}
                  style={{ color: 'black' }}
                  onChange={() => this.handleCorrectOptionChange(optionIndex)}
                />
              )}
              <Box style={{ width: '100%' }}>
                <CustomEditor
                  data-test-id={`answer-option-${optionIndex}`}
                  value={option.answer}
                  onChange={(value) => this.handleTextAnswerChange(value, optionIndex)}
                />
              </Box>
              <IconButton
                onClick={() => { this.handleDeleteAnswer(option.id) }}
                data-test-id={`delete-answer-${optionIndex}`}
              >
                <img src={deleteIcon2} alt="delete option" />
              </IconButton>
            </Box>
          ))}
        </Box>
        {question.options.length < 4 && (
          <Button onClick={this.handleAddAnswer}
            data-test-id="add-test-option"
          >Add Option</Button>
        )}
         <Box >
          <Typography paddingY={"10px"} className="title">
          Correct answer Supporting Text
          </Typography>
          <CustomEditor
            value={question.supportingText}
            data-test-id="supporting-text-field"
            onChange={(value)=>this.handleTestContentChange('supportingText',value)}
          />
        </Box>
        <Box >
          <Typography paddingY={"10px"} className="title">
            Incorrect Answer Supporting Text
          </Typography>
          <CustomEditor
            value={question.supportingTextInCorrect}
            data-test-id="supporting-incorrect-text-field"
            onChange={(value)=>this.handleTestContentChange('supportingTextInCorrect',value)}
          />
        </Box>
      </Box>
      <Divider />
    </>)
  }

  renderDrawerContent = () => {
    const drawerMap = {
      Text: this.renderTextDrawer,
      List: this.renderListDrawer,
      Image: this.renderImageDrawer,
      Video: this.renderVideoDrawer,
      Quote: this.renderQuoteDrawer,
      Carousel: this.renderCarouselDrawer,
      Label: this.renderLabelDrawer,
      Table: this.renderTableDrawer,
      Card: this.renderFlashCardDrawer,
      Test: this.renderTestKnowledgeDrawer
    };
    const renderDrawer = drawerMap[this.state.currentObj.type];
    return renderDrawer && renderDrawer();
  }

  renderEditDrawer = () => {
    return (
      <CustomDrawer open={this.state.isDrawerOpen} onClose={this.handleDrawerToggle}>
        <Box sx={{ width: { xs: 250, sm: 640 }, padding: "30px", position: 'relative' }}>
          <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={2}>
            <Typography variant="h6" sx={{  fontWeight: 'bold' }}>
              Edit {this.state.courseData?.[this.state.currentIndex]?.type === "Test"?"Test Knowledge":this.state.courseData?.[this.state.currentIndex]?.type}
            </Typography>
            <IconButton
              data-test-id="close-drawer"
              onClick={this.handleDrawerToggle}
            >
              <CloseIcon style={{color:'black'}}/>
            </IconButton>
          </Box>
          {this.renderDrawerContent()}
        </Box>
        <Backdrop
        style={{zIndex:99999,color:'black'}}
        open={this.state.isLoadingFile}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </CustomDrawer>
    );
  };

  renderQuizEditor = () => {
    const listTypes = [
      { label: 'Single Response', value: 'single_correct_option' },
      { label: 'Multiple Response', value: 'multiple_correct_options' },
    ];

    return (
      <Box>
        {this.state.quizData?.map((question, index) => (
          <Box
            key={'question'+index + this.state.quizData?.length}
            padding={'30px'}
            marginTop={'20px'}
            marginBottom={'20px'}
            sx={{
              width: 640,
              backgroundColor: 'white',
              boxShadow: '0px 2px 20px 0px #00000014',
              borderRadius: "4px",
            }}
            display={'flex'}
            flexDirection={'column'}
            gap={'32px'}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Box fontSize={'32'} fontWeight={600}>
                {this.addLeadingZero(index + 1)}/
                {this.addLeadingZero(this.state.quizData?.length)}
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    this.onClickDeleteQuestion(index);
                  }}
                  data-test-id={`delete-question}`}
                >
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.6666 13.3333V30C11.6666 31.841 13.159 33.3333 15 33.3333H25C26.8409 33.3333 28.3333 31.841 28.3333 30V13.3333H31.6666V30C31.6666 33.6819 28.6819 36.6667 25 36.6667H15C11.3181 36.6667 8.33331 33.6819 8.33331 30V13.3333H11.6666Z" fill="#FC5555"/>
                    <path d="M16.6667 18.3333C15.7462 18.3333 15 19.0795 15 20V26.6667C15 27.5871 15.7462 28.3333 16.6667 28.3333C17.5871 28.3333 18.3333 27.5871 18.3333 26.6667V20C18.3333 19.0795 17.5871 18.3333 16.6667 18.3333Z" fill="#FC5555"/>
                    <path d="M23.3333 18.3333C22.4129 18.3333 21.6667 19.0795 21.6667 20V26.6667C21.6667 27.5871 22.4129 28.3333 23.3333 28.3333C24.2538 28.3333 25 27.5871 25 26.6667V20C25 19.0795 24.2538 18.3333 23.3333 18.3333Z" fill="#FC5555"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M13.3334 10V8.33334C13.3334 5.57191 15.5719 3.33334 18.3334 3.33334H21.6667C24.4281 3.33334 26.6667 5.57191 26.6667 8.33334V10H31.6667C32.5872 10 33.3334 10.7462 33.3334 11.6667C33.3334 12.5871 32.5872 13.3333 31.6667 13.3333H8.33335C7.41288 13.3333 6.66669 12.5871 6.66669 11.6667C6.66669 10.7462 7.41288 10 8.33335 10H13.3334ZM16.6667 8.33334C16.6667 7.41286 17.4129 6.66667 18.3334 6.66667H21.6667C22.5872 6.66667 23.3334 7.41286 23.3334 8.33334V10H16.6667V8.33334Z" fill="#FC5555"/>
                  </svg>
                </IconButton>
              </Box>
            </Box>
            <Box sx={{zIndex:100}}>
              <Typography
                paddingY={'10px'}
                fontSize={'16px'}
                color={'rgba(106, 106, 106, 1)'}
                fontWeight={500}
                fontFamily={"Heebo-Medium"}
                className="title">Type of Question</Typography>
                <Select
                  data-test-id="quiz-select"
                  options={listTypes}
                  value={listTypes.find(
                  (type) => type.value === question.question_type
                  )}
                  onChange={(value) => this.handleQuestionTypeChange(value, index)}
                  components={{
                  IndicatorSeparator: () => null,
                  IndicatorContainer: (props) => (
                  <div
                    {...props.innerProps}
                    style={{
                      ...props.innerProps.style,
                      color: "#0F172A", 
                    }}
                  >
                    {props.children}
                  </div>
                  ),
                  }}
                  styles={{
                  control: (base) => ({
                  ...base,
                  borderStyle: "none", 
                  borderWidth: "none",
                  borderBottomWidth: "1px",
                  borderBottomStyle: "solid",
                  boxShadow:"none",
                  "&:hover": {
                    borderBottomStyle: "solid", 
                  },
                  }),
                  valueContainer: (base) => ({
                  ...base,
                  padding: "0px", 
                  }),
                  zIndex: 12,
                  }}
                />
            </Box>

            <Box>
              <Typography
                paddingY={"10px"}
                className="title"
                fontSize={'16px'}
                fontFamily={"Heebo-Medium"}
                color={'rgba(106, 106, 106, 1)'}
                fontWeight={500}>Question</Typography>
              <CustomEditor
                key={'question' + index}
                value={question.description}
                data-test-id="question-field"
                onChange={(value) => this.handleQuestionChange(value, index)}
              />
            </Box>
            <Box>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                height={'22px'}
                gap={'24px'}
                width={'122px'}
              >
                <Typography
                  fontSize={'14px'}
                  color={'rgba(106, 106, 106, 1)'}
                  fontFamily={"Heebo-Medium"}
                  fontWeight={500}>
                  Correct
                </Typography>
                <Typography
                  fontSize={'14px'}
                  fontFamily={"Heebo-Medium"}
                  color={'rgba(106, 106, 106, 1)'}
                  fontWeight={500}>
                  Choices
                </Typography>
              </Box>
              {question.answer_options_attributes?.map((option, optionIndex) => (
                <Box
                  key={optionIndex}
                  display="flex"
                  alignItems="center"
                  justifyContent={'space-between'}
                  gap={'35px'}
                  paddingTop={'10px'}
                >
                  {this.isMultiSelect(question.question_type) ? (
                <CustomCheckbox
                  data-test-id={`checkbox-${optionIndex}`}
                  checked={option.is_correct}
                  onChange={() => this.handleOptionCorrectChange(index,optionIndex)}
                />
              ) : (
                <Radio
                  data-test-id={`radio-${optionIndex}`}
                  checked={option.is_correct}
                  style={{ color: 'black' }}
                  onChange={() => this.handleOptionCorrectChange(index,optionIndex)}
                />
              )}
                  <Box style={{ width: '100%' }}>
                    <CustomEditor
                      data-test-id={`quiz-option-${optionIndex}`}
                      value={option.description}
                      onChange={(value) =>
                        this.handleOptionTextChange(value, index, optionIndex)
                      }
                    />
                  </Box>
                </Box>
              ))}
              <Box>
                <Typography paddingY={'10px'}
                  fontSize={'16px'}
                  color={'rgba(106, 106, 106, 1)'}
                  fontWeight={500} fontFamily={"Heebo-Medium"} className="title">
                  Correct answer Supporting Text
                </Typography>
                <CustomEditor
                  value={question.correct_feedback}
                  data-test-id="correct_feedback-field"
                  onChange={(value) =>
                    this.handleSupportingTextChange(value, index, true)
                  }
                />
              </Box>
              <Box>
                <Typography paddingY={'10px'}
                  fontSize={'16px'}
                  color={'rgba(106, 106, 106, 1)'}
                  fontWeight={500} fontFamily={"Heebo-Medium"} className="title">
                  Incorrect answer Supporting Text
                </Typography>
                <CustomEditor
                  value={question.incorrect_feedback}
                  data-test-id="incorrect_feedback-field"
                  onChange={(value) =>
                    this.handleSupportingTextChange(value, index)
                  }
                />
              </Box>
            </Box>
          </Box>
        ))}
        <Box display={'flex'} justifyContent={'center'}>
          <Button
            textAlign="center"
            variant="contained"
            style={{
              backgroundColor: `rgba(28, 28, 28, 1)`,
              borderRadius: '4px',
              padding: '16px',
              minHeight: '60px',
              width: '100%',
              fontFamily: 'Heebo-Bold',
              fontWeight: '700',
              fontSize: '14px',
              lineHeight: '22px',
              color: '#ffffff',
              textTransform: 'none',
            }}
            data-test-id="add-new-question"
            onClick={this.handleAddNewQuestion}
          >
            Add Another Question
          </Button>
        </Box>
        {this.renderDeleteModal()}
      </Box>
    );
  };

  renderSidebarDrawer = () => {
    return (
      <CustomDrawer open={this.state.isSidebarOpen} onClose={this.sidebarHandler}>
        <Box className="content-sidebar">
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography className="content-library">Content Library</Typography>
            <IconButton onClick={this.sidebarHandler} data-test-id="close-sidebar">
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            {['TEXT', 'LIST', 'IMAGE', 'VIDEO', 'TABLE', 'FLASH CARD', 'LABELED GRAPHIC', 'QUOTE', 'TEST KNOWLEDGE',"SORTING "].map((text, index) => (
              <ListItem className="list-item" key={`${index + 1}`} onClick={() => this.handleTypeButtonClick(text.charAt(0) + text.slice(1).toLowerCase(), true)} data-test-id="sidebar-content-type">
                <Typography className="list-text">{text}</Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </CustomDrawer>
    );
  }

  renderPlusButton = (index: number) => {
    return (
      <IconButton
        data-test-id="plus-button"
        className="plus-button"
        onClick={() => this.onPlusClick(index)}
        sx={{ padding: "0px", position: "absolute", left: "50%", bottom: "-1px" }}
      >
        <PlusLine sx={{ marginBottom: "0px" }}>
          <div />
          <img src={plusIcon} />
        </PlusLine>
      </IconButton>
    );
  }

  isOptionVisible = (index: number) => {
    return this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");
  }

  renderTextType = (item, index: number) => {
    return (
      <MainWrapper
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`} sx={{...textTypeStyle[item.text_format]}}>
          <div style={{ marginBottom: "30px", whiteSpace: "pre-wrap", wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: item.heading }} className="margin0 heading" />
          <div dangerouslySetInnerHTML={{ __html: item.content }} style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }} className="margin0 content" />
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {this.state.currentIndex === index && this.state.buttonType === "textType" && this.renderPopoverTextTypeStyle(item.id)}
          {!this.state.isPreview && this.renderOptionList(index,item.id,true)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderListType = (item, index: number) => {
    const contentWithBreaks =(content)=> content.replace(/<p><\/p>/g, '<p><br></p>');
    return (
      <MainWrapper
        data-test-id="list-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`}>
          <div style={{ marginBottom: "30px", wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: contentWithBreaks(item.heading) }} className="margin0" />
          <div style={{ wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: contentWithBreaks(item.content) }} className="margin0 list-content" />
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index,item.id)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderImageType = (item, index: number) => {
    return (
      <MainWrapper
        data-test-id="image-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`}>
          <Box textAlign="center">
            <img src={item.image} className="margin0 image" />
            <Box sx={{ marginTop: "30px",whiteSpace: "pre-wrap", }} dangerouslySetInnerHTML={{ __html: item.caption }} />
          </Box>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index,item.id)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderVideoType = (item, index: number) => {
    return (
      <MainWrapper
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`} height="100%">
          <Box textAlign="center">
            <Box width="100%" height={{ xs: "fit-content", sm: "500px" }}>
              <ReactPlayer
                controls
                url={item.video}
                width="100%"
                height="100%"
                style={{ maxHeight: "500px" }}
              />
            </Box>
            <Box sx={{whiteSpace: "pre-wrap", marginTop: "30px" }} dangerouslySetInnerHTML={{ __html: item.caption }} />
          </Box>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index,item.id)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  };

  renderQuoteType = (item, index: number) => {
    return (
      <MainWrapper
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`}>
          <Box>
            <div style={{whiteSpace: "pre-wrap", marginBottom: "30px" }} dangerouslySetInnerHTML={{ __html: item.content }} className="margin0" />
            <Box textAlign="center">
              {item.image && <img width="88px" height="88px" style={{ borderRadius: "50%", textAlign: "center" }} src={item.image} />}
            </Box>
            <div style={{whiteSpace: "pre-wrap",}} dangerouslySetInnerHTML={{ __html: item.name }} />
            {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
            {!this.state.isPreview && this.renderOptionList(index,item.id)}
          </Box>
          {this.renderPlusButton(index)}
        </Box>
      </MainWrapper>
    );
  }

  renderLabelGraphicType = (item, index: number) => {
    const isOptionVisible = this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");
    return (
      <MainWrapper
        data-test-id="image-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={isOptionVisible}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box width={`${item.width}`}>
          <Box
            textAlign="center"
            sx={{ position: 'relative', overflow: 'hidden' }}
            data-test-id="backgroundLabelImage"
            ref={this.containerRef}
            onMouseMove={(e) => !this.state.isPreview && this.handleDrag(e, index)}
            onMouseUp={(e) => !this.state.isPreview && this.handleDragEnd()}
            onDoubleClick={(e) => !this.state.isPreview && this.handleImageClick(e, index)}
          >
            <img
              src={item.image}
              className="margin0 image"
              alt="Background"
              style={{ display: 'block', maxWidth: '100%' }}
            />
            {item.content.map((button, index2) => (
              <Box
                sx={{
                  position: 'absolute',
                  top: `${button.y}px`,
                  left: `${button.x}px`,
                  cursor: 'move',
                  width: 'max-content',
                  userSelect: 'none',
                  touchAction: 'none',
                }}
                data-test-id={`draggable-btn-${index2}`}
                display={'flex'}
                flexDirection={'column'}
                onTouchStart={(e) => !this.state.isPreview && this.handleDragStart(e, index2, index)}
                onMouseDown={(e) => !this.state.isPreview && this.handleDragStart(e, index2, index)}
                onMouseUp={(e) => !this.state.isPreview && this.handleDragEnd()}
              >
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} position="relative">
                  <Button
                    style={{
                      ...webStyles.contentBtn,
                    }}
                  >
                    <AddIcon data-test-id={`show-content-${index2}`} style={webStyles.plusIcon} onClick={() => { this.handleEditLabelContent(index, button.id, 'visibleContent', !button.visibleContent) }} />
                  </Button>
                  <LoopIcon
                    style={{ color: "#fff", cursor: "pointer", marginTop: "5px" }} 
                    data-test-id={`reverse-btn-${index2}`}
                    onClick={() => {
                      this.handleEditLabelContent(index, button.id, 'contentRightSide', !button.contentRightSide)
                    }}
                  />
                  <div
                    data-test-id={`content-show-${index2}`}
                    style={{
                      ...webStyles.labelContent,
                      display: button.visibleContent ? 'flex' : 'none',
                      position: 'absolute',
                      top: 0,
                      [button.contentRightSide ? 'left' : 'right']: '100%',
                      marginLeft: button.contentRightSide ? '10px' : '0',
                      marginRight: button.contentRightSide ? '0' : '10px',
                      overflow:'scroll'
                    }}
                  >
                    <div style={{ marginBottom: "15px",whiteSpace: "pre-wrap", }} dangerouslySetInnerHTML={{ __html: button.title }} className="margin0" />
                    <div dangerouslySetInnerHTML={{ __html: button.description }} className="margin0" style={{whiteSpace: "pre-wrap",}} />
                  </div>
                </Box>
              </Box>

            ))}
          </Box>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index,item.id)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderCarouselPagination = ({ pages, activePage, onClick }: PaginationProps) => {
    return (
      <Box className="pagination">
        {pages.map((page) => (
          <Box
            key={page}
            className="page"
            onClick={() => onClick(page)}
            backgroundColor={activePage === page ? 'black' : '#e0e0e0'}
          />
        ))}
      </Box>
    );
  }

  renderCarouselArrow = ({ type, isEdge, onClick }: CarouselButtonProps) => {
    const fill = isEdge ? "#999999" : "#0F172A";
    return (
      <Box display="flex" alignItems="center">
        <Box
          onClick={!isEdge ? onClick : undefined}
          className="arrow-btn"
          sx={{
            cursor: isEdge ? "not-allowed" : "pointer"
          }}
        >
          {type === 'PREV' ?
            <svg width="10" height="17" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.0009 19.7469L5.24094 11.9869L13.0009 4.2269C13.7809 3.4469 13.7809 2.1869 13.0009 1.4069C12.2209 0.626902 10.9609 0.626902 10.1809 1.4069L1.00094 10.5869C0.220938 11.3669 0.220938 12.6269 1.00094 13.4069L10.1809 22.5869C10.5546 22.9614 11.0619 23.1719 11.5909 23.1719C12.12 23.1719 12.6273 22.9614 13.0009 22.5869C13.7609 21.8069 13.7809 20.5269 13.0009 19.7469Z" fill={fill} />
            </svg>
            : <svg width="10" height="17" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.999062 19.7469L8.75906 11.9869L0.999062 4.2269C0.219062 3.4469 0.219062 2.1869 0.999062 1.4069C1.77906 0.626902 3.03906 0.626902 3.81906 1.4069L12.9991 10.5869C13.7791 11.3669 13.7791 12.6269 12.9991 13.4069L3.81906 22.5869C3.4454 22.9614 2.9381 23.1719 2.40906 23.1719C1.88003 23.1719 1.37273 22.9614 0.999062 22.5869C0.239062 21.8069 0.219062 20.5269 0.999062 19.7469Z" fill={fill} />
            </svg>
          }
        </Box>
      </Box>
    );
  }

  renderCarouselContent = (item) => {
    return item.content.map((obj, index: number) => {
      return (
        <Box margin={{ xs: "0px 35px", sm: "0px 10px" }} width="100%" key={obj.id}>
          <Box sx={{whiteSpace: "pre-wrap",}} dangerouslySetInnerHTML={{ __html: obj.heading }} />
          {obj.type === "image" && <img src={obj.media} className="margin0 image"/>}
          {obj.type === "video" &&
            <Box width="100%" height={{ xs: "fit-content", sm: "300px" }}>
              <ReactPlayer
                controls
                url={obj.media}
                width="100%"
                height="100%"
                style={{ maxHeight: "300px" }}
              />
            </Box>}
          <Box mt="30px" sx={{whiteSpace: "pre-wrap",}} dangerouslySetInnerHTML={{ __html: obj.description }} />
        </Box>
      );
    })
  }

  renderCarouselType = (item, index: number) => {
    return (
      <MainWrapper
        isPreview={this.state.isPreview}
        isOptionVisible={this.isOptionVisible(index)}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
      >
        <Box sx={{width:'70vw'}}>
          <Box className="carousel">
            <Box textAlign="center" position="relative"  sx={{
              '& .rec-carousel-item':{
              height:'100% ',
              display:'flex',
              flexDirection:'column',
              alignItems:'center',
              justifyContent:'center'
            }}}>
              <Carousel
                isRTL={false}
                data-test-id="carousel"
                renderArrow={this.renderCarouselArrow}
                renderPagination={this.renderCarouselPagination}
              >
                {this.renderCarouselContent(item)}
              </Carousel>
            </Box>
          </Box>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index,item.id)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderFlashCardType = (item, index: number) => {
    const isVisibleOptions = this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");

    return (
      <MainWrapper
        data-test-id="flash-card-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={isVisibleOptions}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}>
        <Box sx={{ width: '70vw' }}>
          <Grid container spacing={4} >
            {item.cards.filter(card=>!card._destroy).map((card, childIndex: number) => {
              return (
                <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} key={childIndex}>
                  <FlipCard className={card.flipped ? "flipped" : ""}>
                    <div className="flip-card-inner">
                      <div data-test-id={`front-card-${childIndex}`} className="flip-card-front" >
                        <div className="card-content">
                          <div dangerouslySetInnerHTML={{ __html: card.front }} style={{whiteSpace: "pre-wrap",}} className="margin0" />
                        </div>
                        <img className="flip-icon" data-test-id={`flip-front-${childIndex}`} src={repeatBtnBlack} onClick={() => { this.handleFlipCard(childIndex, index, true) }} />

                      </div>
                      <div data-test-id={`back-card-${childIndex}`} className="flip-card-back">
                        <div className="card-content">
                          <div style={{whiteSpace: "pre-wrap",}} dangerouslySetInnerHTML={{ __html: card.back }} className="margin0" />
                        </div>
                        <img className="flip-icon" data-test-id={`flip-back-${childIndex}`} src={repeatBtnWhite} onClick={() => { this.handleFlipCard(childIndex, index, false) }} />
                      </div>
                    </div>
                  </FlipCard>

                </Grid>
              )
            })}
          </Grid>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index,item.id)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    );
  }

  renderTableType = (item, index: number) => {
    const isOptionVisible = this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");
    
    const rows = [...item.table_rows_attributes].filter(row=>!row._destroy);
    return (
      <MainWrapper
        data-test-id="image-wrapper1"
        isPreview={this.state.isPreview}
        isOptionVisible={isOptionVisible}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box sx={{
          width: `${item.width}`
        }}>
          <div
            style={{marginBottom: "30px",whiteSpace: "pre-wrap",wordWrap: "break-word",}}
            dangerouslySetInnerHTML={{ __html: item.heading !== "" ? item.heading : configJSON.headerTag }}
            className="margin0"
          />   
       <div style={{marginBottom:'30px',paddingBottom:'20px'}}>
          <StyledTable>
          <tbody>
  {rows.map((row, rowIndex) => (
    <tr key={row.id || `row-${rowIndex}`}>
      {Object.entries(row).map(([key, cell], cellIndex) => {
        if (key === "id" || !cell) return null; // Skip ID

        return (
          <td
            key={`cell-${rowIndex}-${cellIndex}`} // Ensure unique key
            style={{
              minWidth: "100px",
              maxWidth: "300px",
              wordWrap: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "pre-wrap",
            }}
          >
            <div
              dangerouslySetInnerHTML={{ __html: typeof cell === "object" ? cell.value || "" : cell }}
              className="margin0"
            />
          </td>
        );
      })}
    </tr>
  ))}
</tbody>

          </StyledTable>
          </div>
          {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
          {!this.state.isPreview && this.renderOptionList(index,item.id)}
        </Box>
        {this.renderPlusButton(index)}
      </MainWrapper>
    )
  }

  renderTestKnowledgeType = (item, index: number) => {
    const isOptionVisible = this.state.currentIndex === index && (this.state.buttonType === "edit" || this.state.buttonType === "format");
    const { content } = item
    return (
      <MainWrapper
        data-test-id="test-knowledge-wrapper"
        isPreview={this.state.isPreview}
        isOptionVisible={isOptionVisible}
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
        }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Box width={item.width} sx={webStyles.testCard} >
          <Box display={"flex"} borderBottom={"1px solid #D7D7D7"} justifyContent={"space-between"} gap={"10px"} padding={"40px"}>
            <Typography style={webStyles.quizBlockQuestion}>
              <span dangerouslySetInnerHTML={{ __html: content.question }}  className="margin0" />
            </Typography>
          </Box>
          <Box padding={"40px 72px"}  display={"flex"} flexDirection={"column"}>
            {item.content.isMultiple ?
              content.options.map(option => {
                return <FormControlLabel
                  data-test-id={`check-answer-${option.id}`}
                  style={{  padding: '20px 0',gap: '40px',margin:0 }}
                  control={<CustomCheckbox
                    checked={option.isSelected}
                    onChange={() => { this.handleSelectAnswer(index, option.id) }} />}
                  label={<Typography sx={{'& p':{margin:0}}} style={webStyles.checkboxLabel}><span dangerouslySetInnerHTML={{ __html: option.answer }} /></Typography>}
                />
              })
              :
              <RadioGroup>
                {content.options.map(option => {
                  return <FormControlLabel
                    style={{ gap: '40px', padding: '20px 0',margin:0 }}
                    data-test-id={`radio-answer-${option.id}`}
                    control={<Radio
                      data-test-id="test"
                      checked={option.isSelected}
                      onChange={() => { this.handleSelectAnswer(index, option.id) }}
                      style={{
                        color: 'black',
                        width: '32px',
                        height: '32px',
                      }} />}
                    label={<Typography style={webStyles.checkboxLabel} sx={{'& p':{margin:0}}}><span  dangerouslySetInnerHTML={{ __html: option.answer }} /></Typography>}
                  />
                })}
              </RadioGroup>
            }
          </Box>
        {content.isSubmit &&
          <Typography style={{ ...webStyles.checkboxLabel, margin: '15px 0',textAlign:'center' }}>
            <div style={{whiteSpace: "pre-wrap",}} dangerouslySetInnerHTML={{ __html: content.answerCorrect ? content.supportingText : content.supportingTextInCorrect }} />
          </Typography>}
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingBottom: '40px',
        }}>
          <Button style={webStyles.submitButton} data-test-id="submit-test-button" onClick={() => { this.submitAnswer(item.id) }} variant="contained">Submit</Button>
        </Box>
        </Box>
        {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
        {!this.state.isPreview && this.renderOptionList(index,item.id)}
        {this.renderPlusButton(index)}
      </MainWrapper>
    )
  }

   DropdownIndicator = (
    props: ElementConfig<typeof components.DropdownIndicator>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <ExpandMore data-test-id='expand-indicator'style={{color:'black'}}/>
      </components.DropdownIndicator>
    );
  };

  renderButtonType = (item, index: number) => {
    const isLastLesson = this.state.lessonList.filter(item=>!item._destroy).findIndex(lesson => lesson.id === this.state.currentLessonId) === (this.state.lessonList.filter(item=>!item._destroy).length - 1)
    const buttonTypes = [
      { label: 'Continue Button', value: false },
      { label: 'Finish Button', value: true, isDisabled: !isLastLesson }
    ]
    return (
      <MainWrapper
        sx={{
          paddingTop: `${item.paddingTop}px`,
          paddingBottom: `${item.paddingBottom}px`,
          position: 'relative'
        }}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <Box sx={{ width: '50vw' }}>
          {!this.state.isPreview && <Box sx={{
            position: 'absolute',
            top: 10,
            left: 10,
            width: '100%',
            maxWidth: '230px'
          }}>
            <Select
              data-test-id="list-button-type"
              isSearchable={false}
              options={buttonTypes}
              placeholder="Select button type"
              components={{
                DropdownIndicator:this.DropdownIndicator,
                IndicatorSeparator:()=>null
              }}
              value={buttonTypes.filter((button) => button.value === item.finishButton)[0]}
              onChange={(e) => { this.handleChangeButtonType(index, e?.value) }}
              styles={{
                control:(base)=>({
                  ...base,
                  border:'none',
                  boxShadow: '0px 4px 4px 0px #00000014',
                  paddingLeft:'5px'
                }),
                menu: (base) => ({
                  ...base,
                  boxShadow: '0px 4px 4px 0px #00000014',
                  border:'none',
                  marginTop:0,
                  zIndex: '999',
                }),
                option:(base, props) => ({
                  ...base,
                  backgroundColor:"#fff",
                  color:'black',
                  fontWeight:props.isSelected?'bolder':'normal',
                  padding:'15px',
                  fontSize:'14px'
                }),
                dropdownIndicator: (base, state) => ({
                  ...base,
                  transition: 'all .2s ease',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
                })
              }}
            />
          </Box>}
          <Box display={'flex'} height='102px' borderTop={'1px solid #1c1c1c'} alignItems={'end'}><Button style={buttonStyle.blackButton}>{item.finishButton ? "Finish" : "Continue"}</Button></Box>
          {item.finishButton?
          <p style={{textAlign:'center', color:'#78716C',fontSize:'16px',lineHeight:'32px'}}>Adding the <b>‘Finish’</b> button marks all lessons as completed. No additional lessons can be added after this point.</p>
          :<></>}
        </Box>
          <Toolbar isVisible={true}>
            <Box display={'flex'} justifyContent="space-around">
              {toolbarItems.map((item, index) => (
                <button data-test-id="content-type" style={{ color: '#A8A29E' }} >
                  <img src={item.icon} style={{ filter: 'invert(74%) sepia(9%) saturate(178%) hue-rotate(341deg) brightness(89%) contrast(81%)'}}/>
                  <span>{item.label}</span>
                </button>
              ))}
            </Box>
          </Toolbar>
        {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
        {!this.state.isPreview && this.renderButtonOptionList(index,item.id)}
      </MainWrapper>
    )
  }
  renderSortingType = (item,index:number)=>{
    const { content } = item
    return (
      <MainWrapper
      isPreview={this.state.isPreview}
      isOptionVisible={this.isOptionVisible(index)}
      sx={{
        paddingTop: `${item.paddingTop}px`,
        paddingBottom: `${item.paddingBottom}px`,
      }}
    >
      <Box width={`${item.width}`}>
        <SortingType data={content ?? []}/>
        {this.state.currentIndex === index && this.state.buttonType === "format" && this.renderFormatPopup()}
        {!this.state.isPreview && this.renderSortingOptionList(index,item.id)}
      </Box>
      {this.renderPlusButton(index)}
      {this.state.selectedContentId === item.id && this.renderEditSortingPage() }
    </MainWrapper>
    )
  }

  renderContentType = (courseData) => {
    return (
      <ContentWrapper>
        {!this.state.isCourseLearning && 
          <Box className="course-wrapper">
            {!this.state.isPreview && !this.state.isEditingSortType &&
              <Box className="menu-icon">
                <IconButton data-test-id='content-handler' onClick={this.sidebarHandler}>
                  <MenuIcon />
                </IconButton>
              </Box>
            }
            <Box padding="40px 30px 30px 70px" sx={{position:'relative'}}>
             {this.state.coverImg.image&&  <img src={this.state.coverImg.image} style={webStyles.coverImage}/>}
              <Box className="title">
                {this.renderTitleAndAuthor({
                  value: this.state.lessonTitle,
                  inputStyles: inputStyle.courseTitle,
                  onChange: (event) => this.onChangeLessonTitle(event),
                  placeholder: "Enter lesson title",
                  dataTestId: "lesson-title-input"
                })}
              </Box>
              <Box className="author">
                {this.renderTitleAndAuthor({
                  value: this.state.lessonAuthorName,
                  inputStyles: inputStyle.authorName,
                  onChange: (event) => this.onChangeAuthorName(event),
                  placeholder: "Enter author name",
                  dataTestId: "author-name-input"
                })}
              </Box>
            </Box>
          </Box>
        }
        <Box marginTop="50px">
          {courseData?.map((item, index: number) => {
            if(item?._destroy)return
            const renderType = {
              Text: this.renderTextType,
              List: this.renderListType,
              Image: this.renderImageType,
              Video: this.renderVideoType,
              Quote: this.renderQuoteType,
              Carousel: this.renderCarouselType,
              Label: this.renderLabelGraphicType,
              Card: this.renderFlashCardType,
              Table: this.renderTableType,
              Test: this.renderTestKnowledgeType,
              Button: this.renderButtonType,
              Sorting:this.renderSortingType
            }[item?.type];

            return renderType && (
              <Box key={`${index + 1}`}>
                {renderType(item, index)}
              </Box>
            );
          })}
          {!this.state.isPreview && this.renderToolbar()}
        </Box>
        <div id="bottom-content"></div>
      </ContentWrapper>
    );
  }

  renderEditSortingPage = ()=>{
    const {currentObj} = this.state
    return  currentObj.type==="Sorting"?(
      <Backdrop open={this.state.isEditingSortType} sx={{zIndex:'9999'}}
      >
        <header style={{ borderBottom: '1px solid #E7E5E4',backgroundColor:"#FFF", padding: "15px 43px 15px 0px",position:'fixed', top:0,right:0,left:0 }}>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              "& .content": {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                "& .block": {
                  gap: 16,
                  display: "flex",
                  alignItems: "center"
                }
              }
            }}
            data-test-id="wrapper"
          >
            <div className="content">
              <div className="block">
                <CustomBackBtn onClick={this.handleUpdateSortingType} data-test-id="back-sorting-btn">
                  <img src={imageArrowLeft} alt="" />
                  <span>Sorting</span>
                </CustomBackBtn>
              </div>
              <div className="block">
                <BlackButton
                  data-test-id="close-sorting-btn"
                  style={{
                    textTransform: "none",
                    padding: "6px 10px",
                    width: 'max-content',
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                  onClick={this.handleUpdateSortingType}
                >
                  Close
                </BlackButton>
              </div>
            </div>
          </Box>
        </header>
        <main style={{width:'100%'}}>
          <Box width={'100%'} height='100vh' display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{backgroundColor:'#FAFAF9'}}>
              <Grid container width={'70vw'} alignItems={'flex-start'}>
                  {currentObj?.content.filter(item=>!item._destroy).map(category=>{
                    return <Grid item xs={5} style={{
                      display: 'inline-flex',  
                      flexDirection:'column',
                      alignSelf: 'flex-start',
                      margin: '20px 0 0 20px',
                      padding: '32px',
                      boxShadow: '0px 2px 20px 0px #00000014'
                    }}>
                      <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'} borderBottom={'1px solid #1c1c1c'} marginBottom={'20px'}>
                          <img 
                          src={deleteIcon} 
                          style={{width:'24px',height:'24px',cursor:'pointer'}} 
                          onClick={() => { this.handleDeleteCategory(category.id) }} 
                          data-test-id={`delete-category-btn`}
                          />
                        <CustomInput
                        style={{width:'100%'}}
                        data-test-id={`category-title`}
                          value={category.title}
                          onChange={(e) => this.handleUpdateCategoryTitle(category.id, e.target.value)}
                        />
                      </Box>
                      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} >
                        {category.items.filter(item=>!item._destroy).map(item =>
                          <CustomInput
                            style={{
                              border: '1px solid #CBD5E1',
                              borderRadius: '8px',
                              padding: '10px 8px',
                              margin: '5px 0',
                              height:'auto'
                            }}
                        data-test-id={`category-item-title`}
                            value={item.title}
                            renderSuffix={() =>
                              <img src={deleteIcon} data-test-id='delete-category-item-btn' style={{ width: '24px', height: '24px',opacity:'0.7',zIndex:1,cursor:'pointer' }}
                               onClick={() => { this.handleDeleteItem(category.id,item.id)}} 
                               />
                            }
                            onChange={(e) => this.handleUpdateItemTitle(category.id, item.id, e.target.value)} />)
                        }
                        {category.items.filter(item=>!item._destroy).length<5 && 
                          <Box 
                          onClick={() => this.handleAddItem(category.id)}
                          data-test-id='add-category-item'
                          textAlign={'center'}
                          mt={'15px'}
                          padding={'10px'} 
                          border={'1px solid #1c1c1c'} 
                          borderRadius={'8px'} 
                          color={'#303030'} 
                          fontWeight={700}>
                            Add an Item
                            </Box>

                        }
                      </Box>
                    </Grid>
                  })}
                  {
                    currentObj.content.filter(item=>!item._destroy).length<4 && 
                    <Grid 
                    item 
                    xs={5} 
                    style={{
                        margin: '20px 0 0 20px',
                        padding: '32px',
                        boxShadow: '0px 2px 20px 0px #00000014'
                      }}>
                          <Box data-test-id='add-category-btn' onClick={this.handleAddCategory} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                          <Box padding={'10px'} border={'1px solid #D7D7D7'} borderRadius={'8px'}><AddCircleOutline/></Box>
                          <Typography color='#6A6A6A' fontSize={'18px'}>Add new category</Typography>
                        </Box>
                      </Grid>
                  }
            </Grid>
          </Box>
        </main>
      </Backdrop>
    ):<></>
  }

  renderDeleteModal = () => {
    return (
      <CustomDialog
        open={this.state.currentQuestion !== null}
        onClose={this.handleModalClose}
        className="delete-popup"
      >
        <DialogTitle className="dialog-title">
          <CloseIcon className="close-icon" data-testID="close-Modal" onClick={this.handleModalClose} />
        </DialogTitle>
        <DialogContent className="heading">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box padding="0px 5px 0px 15px">
                <Typography className="delete-title">Are you sure you want to delete this question?</Typography>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action">
          <CancelButton onClick={this.handleModalClose}>
            Cancel
          </CancelButton>
          <DoneButton data-testID="deleteBtn" onClick={() => this.handleDeleteQuestion()}>
            Delete
          </DoneButton>
        </DialogActions>
      </CustomDialog>
    );
  }

  renderQuizContent = () => {
    return (
      <QuizWrapper>
        <Box
          className="course-wrapper"
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          {this.renderQuizEditor()}
        </Box>
      </QuizWrapper>
    );
  };

  hasFinishButton = (lessons) => {
    return lessons.some((lesson) =>
      lesson.courseData?.some((data) => data.type === 'Button' && data.finishButton === true)
    );
  }

  renderErrors = () => {
    return (
      <CustomDialog
        data-test-id="close-dialog"
        open={this.state.isErrorVisible}
        onClose={this.onCloseErrorDialog}
      >
         <Box className="dialog-title">
          <Typography>Errors</Typography>
          <CloseIcon className="close-icon" onClick={this.onCloseErrorDialog} />
        </Box>
        <Box padding="15px">
          {this.state.errors?.map((error, index: number) => (
            <Box className="error-wrapper" key={`${index + 1}`}>
              <Typography className="text">
                {error}
              </Typography>
            </Box>
          ))}
        </Box>
      </CustomDialog>
    );
  }

  renderCourseSideBar = () => {
    return (
      <Drawer
        sx={{
          width: "250px",
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            marginTop: "66px",
            width: "250px",
            height: "calc(100vh - 70px)",
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={this.state.isCourseSidebarOpen}
      >
        <CourseSidebar>
          <Box className="title-wrapper">
            <Box dangerouslySetInnerHTML={{ __html: this.sanitizeData(this.state.courseTitle) }} />
          </Box>
          <Box>
            {this.state.lessonList.filter(item=>!item._destroy).map((item, index) => {
              return (
                <Box key={item.id} display='flex' flexDirection='column'>
                  <ListItemButton
                    className="list-item"
                    data-test-id={`lesson-test`}
                    sx={{ backgroundColor: this.state.selectedCourseId === index && !this.state.isShowRenderCard ? "#F0F0F0" : "#FAFAFA" }}
                    onClick={()=> {
                      this.changeChapter(index); this.setState({isShowRenderCard: false})}}
                  >
                    <Notes width='24px' height='24px' />
                    <ListItemText
                      primary={item.lessonTitle.title}
                      primaryTypographyProps={{ fontSize: '12px', fontWeight: 'medium', padding: '0 10px' }}
                    />
                    <CircularProgress
                      value={0}
                      variant="determinate"
                      className="progressbar"
                      sx={{ 'svg circle': { stroke: '#1c1c1c' } }} />
                  </ListItemButton>
                  {item?.assessmentData && Object.keys(item?.assessmentData).length > 0 &&
                      <ListItemButton
                        key={item.assessmentData.id}
                        data-test-id="assessment-test"
                        sx={{
                          backgroundColor:this.state.selectedCourseId === index && this.state.isShowRenderCard ? "#F0F0F0" : "#FAFAFA",
                          py: '10px',
                          minHeight: 32,
                          color: '#1C1C1C',
                          display: 'flex',
                        }}
                        onClick={() => this.handleClickAssessmentItem(index,item)}  
                      >
                        <QuizOutlined style={{ width: '24px', height: '24px' }} />
                        <ListItemText
                          primary={item.assessmentData.title}
                          primaryTypographyProps={{ fontSize: '12px', fontWeight: 'medium', padding: '0 10px' }} />
                      </ListItemButton>
                    }
                </Box>
              );
            })}
            {this.state.finalAssessmentQuizData &&
             <ListItemButton
             data-test-id="final-assessment-test"
             sx={{
               backgroundColor:this.state.selectedCourseId === 99 && this.state.isShowRenderCard ? "#F0F0F0" : "#FAFAFA",
               py: '10px',
               minHeight: 32,
               color: '#1C1C1C',
               display: 'flex',
             }}
             onClick={this.handleClickFinalAssessmentItem}  
           >
              <img src={finalAssessmentIcon} alt="finalAssessment" style={{ width: '24px', height: '24px' }} />
             <ListItemText
               primary={this.state.finalAssessmentQuizData.title}
               primaryTypographyProps={{ fontSize: '12px', fontWeight: 'medium', padding: '0 10px' }} />
           </ListItemButton>
                }
          </Box>
        </CourseSidebar>
      </Drawer>
    )
  }

  renderLessonDetail = () => {
    const lessonData = this.state.lessonList.filter(item=>!item._destroy)[this.state.selectedCourseId];
    let courseData = lessonData?.courseData;
    if(this.state.selectedCourseId === this.state.selectedId) {
      courseData = this.state.courseData;
    }
    const coverImg = 
      this.state.coverImg.image ||
      this.state.coverDescImg.image ||
      this.state.imageObj.image ||
      courseDescImg;

    return (
      <DetailWrapper open={this.state.isCourseSidebarOpen} className="learning-wrapper">
        <div id="learning-wrapper"></div>
        <Box className="lesson-header">
          <MenuIcon className="menu-icon" onClick={this.handleCourseSidebar} />
          <img src={coverImg} className="course-heading" />
          <Box className="content-wrapper">
            <Typography className="lesson">Lesson {this.state.selectedCourseId + 1} of {this.state.lessonList.filter(item=>!item._destroy).length}</Typography>
            <Typography className="title">{lessonData?.lessonTitle?.title}</Typography>
            <Typography className="author">by {this.state.authorName}</Typography>
          </Box>
        </Box>
        {(courseData && courseData.length !== 0) &&
          <Box margin="0px 25px">
            {this.renderContentType(courseData)}
          </Box>
        }
      </DetailWrapper>
    )
  }

  renderCourseLearning = () => {
    return (
      <LearningWrapper>
        {this.renderCourseSideBar()}
        {!this.state.isShowRenderCard && this.renderLessonDetail()}
        {this.state.isShowRenderCard && this.renderCard()}
      </LearningWrapper>
    );
  }

  renderLessonItem = (title: string,item:LessonItem) => {
    return (
      <Box display='flex' flexDirection='column' gap={'16px'}>
        <Box className="chapter">
          <Box display="flex" gap="10px">
            <ViewHeadline />
            <Typography className="lesson-title">{title}</Typography>
          </Box>
          <Checkbox
            icon={<RadioButtonUnchecked />}
            checkedIcon={<CheckCircle />}
            checked={false}
            className="checkbox"
          />
        </Box>
        {item?.assessmentData && Object.keys(item?.assessmentData).length > 0 &&
          <Box className="chapter">
            <Box display={"flex"} gap={"10px"} >
              <QuizOutlined style={{ width: '24px', height: '24px' }} />
              <Typography
                className="lesson-title"
              >{item.assessmentData.title}</Typography>
            </Box>
            <Checkbox
            checkedIcon={<CheckCircle />}
            checked={false}
            className="checkbox"
            icon={<RadioButtonUnchecked />}
          />
          </Box>
        }
      </Box>
    );
  }

  renderHTML = (data: any) => {
    const content = data?.replace('\n/g', '');
    return content?.includes('<p') ? HtmlReactParser(content.replace('\n/g', '')) : content;
  }


  customRadioBtn = (isChecked:boolean,optionColor:any) => {
    return (
        <Box height={isChecked ? '12.8px' : '30px'} width={isChecked ? '12.8px' : '30px'} borderRadius={'50%'} border={isChecked ? '12px solid #1C1C1C' : `1px solid ${optionColor}`}> </Box>
    )
}

renderCard() {
  const renderOption = (option,index) => {
    const {currentPreviewQuestion} = this.state
      const isMultiSelect = currentPreviewQuestion.question_type==='multiple_correct_options' // need to change the logic based on question type;
      const color = "black"; // getOptionColor(); need to change logic based on option and submitted answer
      const commonProps = {
          sx: { color },
          'data-test-id': isMultiSelect 
          ? `checkbox-${option.id}` 
          : `radio-button-${option.id}`,
      };

      return (
          <Box
              sx={{ flexDirection: 'row', display: 'flex', marginBottom: '32px', alignContent: 'center', alignItems: 'center' }} 
              key={option.id}
          >
              {isMultiSelect ? (
                        <CustomCheckbox
                            {...commonProps}
                            checked={option.is_correct}
                            onChange={() => this.onChangeOption(option)}
                            sx={{ '& .MuiSvgIcon-root': { borderColor: color } }}
                        />
                    ) : 
                  (<Box
                      {...commonProps}
                      sx={{ marginRight: '10px' }}
                  >
                      {this.customRadioBtn(option.is_correct, color)}
                  </Box>)
              }
              <Box sx={{ color }}>{this.renderHTML(option.description)}</Box>
          </Box>
      );
  };

  const renderOptions = () => (
      this.state.currentPreviewQuestion?.answer_options?.map(renderOption)
  );

  return (
      <Box width={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Box sx={styles.card}>
            <Box sx={styles.card.header}>
                <Box display="flex" flexDirection="row" alignItems="center" flex={1}>
                    <Box>{this.state.currentPreviewQuestion?.position+1}.</Box>
                    <Box alignItems="center">{this.renderHTML(this.state.currentPreviewQuestion?.description)}</Box>
                </Box>
                <Box>{this.state.currentPreviewQuestion?.position+1}/{this.state.currentPreviewAssessmentData?.questions?.length}</Box>
            </Box>
            <Box sx={styles.card.content}>
                <Box>{renderOptions()}</Box>
                <Box width='100%' display={'flex'} justifyContent={'center'}>
                  <BlackButton
                          data-test-id="submit-test-preview"
                          style={{ marginTop: "40px" }}
                          onClick={this.handleGoToNextPreviewQuestion}
                      >
                          Next
                      </BlackButton>
                </Box>
            </Box>
        </Box>
      </Box>
  );
}

  renderPreview = () => {
    const coverImg = 
      this.state.coverDescImg.image ||
      this.state.coverImg.image ||
      this.state.imageObj.image ||
      courseDescImg;

    return (
      <PreviewComp>
        <Box className="image-wrapper">
          <img src={coverImg} alt="cover-image" style={{position:'absolute',width:'100%',height:'100%',zIndex:1}} />
          <Box className="backdrop" />
          <Box className="text-wrapper">
            <Box dangerouslySetInnerHTML={{ __html: this.state.courseTitle }} />
            {this.state.authorName &&
              <Typography className="author-name">
                by {this.state.authorName}
              </Typography>
            }
            <Box>
              <StartButton
                variant="contained"
                data-test-id='start-btn'
                onClick={this.onStartCourse}
              >
                Start
              </StartButton>
            </Box>
            <Box mt="30px">
              <Typography sx={{ mb: 1 }}>
                0% Completed
              </Typography>
              <LinearProgress
                variant="determinate"
                value={0}
                sx={{
                  height: '8px',
                  backgroundColor: 'white',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: 'black',
                  },
                }} />
            </Box>
          </Box>
        </Box>
        <Box className="bottom-section">
          <Box className="lesson-item">
            <Box className="item">
              <Box dangerouslySetInnerHTML={{ __html: this.state.description }} />
              <Box className="item-wrapper">
                {this.state.lessonList.filter(item=>!item._destroy).length > 0 && this.state.lessonList.filter(item=>!item._destroy).map((item, index: number) => {
                  return (
                    <Box key={item.id}>
                      {this.renderLessonItem(item.lessonTitle.title,item)}
                    </Box>
                  )
                })}
                {this.state.finalAssessmentQuizData &&
                  <Box className="chapter">
                    <Box display={"flex"} gap={"10px"} >
                    <img src={finalAssessmentIcon} alt="finalAssessment" style={{ width: '24px', height: '24px' }} />
                    <Typography
                        className="lesson-title"
                      >
                        {this.state.finalAssessmentQuizData.title}
                      </Typography>
                    </Box>
                    <Checkbox
                      checked={false}
                      icon={<RadioButtonUnchecked />}
                      checkedIcon={<CheckCircle />}
                      className="checkbox"
                    />
                  </Box>
                }
              </Box>
            </Box>
          </Box>
          <Box>
            <Paper elevation={2} className="paper">
              <Box className="description-section">
                <Box display="flex" gap="24px">
                  <Box className="details">
                    <Typography variant="h4" fontWeight="bold">{this.state.lessonList.filter(item=>!item._destroy).length}</Typography>
                    <Typography variant="subtitle1" color="textSecondary">{this.state.lessonList.filter(item=>!item._destroy).length > 1 ? "Lessons" : "Lesson"}</Typography>
                  </Box>
                  <Box className="details">
                    <Typography variant="h4" fontWeight="bold">{this.state.requirement.duration ? this.state.requirement.duration : 0}</Typography>
                    <Typography variant="subtitle1" color="textSecondary">{parseInt(this.state.requirement.duration) > 1 ? "Hours" : "Hour"}</Typography>
                  </Box>
                </Box>
                {this.state.requirement.course_requirements &&
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Box>
                      <Typography className="requirements">Course Requirements</Typography>
                    </Box>
                    <Divider />
                    <Box className="info">
                      <Box className="req" dangerouslySetInnerHTML={{ __html: this.state.requirement.course_requirements }} />
                    </Box>
                  </Box>
                }
              </Box>
            </Paper>
          </Box>
        </Box>
      </PreviewComp>
    );
  }

  renderDropdown = (lessonItem: { id: string,quizData: [] }, index: number) => {
    return (
      <div style={{ position: "relative" }}>
        <EditLessonButton
          data-test-id="edit-button"
          onClick={(event) => {
            lessonItem.quizData?.length === 0
              ? this.onOpenLessonPopupMenu(event, lessonItem.id)
              : this.onLessonCreation(lessonItem, index)
          }}
        >
          {lessonItem.isPopUpActive ?
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: 8, marginRight: "-8px" }}><span>{configJSON.labelAddLesson}</span><img src={imageArrowUp} /></div>
            :
            <span>{configJSON.labelEditLesson}</span>
          }
        </EditLessonButton>
        <PopupMenu onClose={this.onCloseLessonPopupMenu} ref={this.lessonPopupMenuRefs[lessonItem.id]}>
          <LessonPopupMenu>
            <div data-test-id="lesson-creation-btn" onClick={() => this.onLessonCreation(lessonItem, index)} className="lesson-popup-menu-block"><span>{lessonItem.courseData?.length === 0 ? "Create new lesson" : "Edit lesson"}</span><img src={imageArrowRight} /></div>
            {lessonItem.quizData?.length === 0 &&
              <div
                className="lesson-popup-menu-block"
                data-test-id="quiz-creation-btn"
                onClick={() => this.onQuizCreation(lessonItem, index)}
              ><span>Create new assessment</span><img src={imageArrowRight} /></div>
            }
          </LessonPopupMenu>
        </PopupMenu>
      </div>
    );
  }

  renderUploadUI = (title: string, resolution: string, currentState: string, index: number) => {
    return (
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography className="text-regular">{title}</Typography>
          <Box display="flex" alignItems="center">
            <Box>
              <input
                type="file"
                
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
                id={`capture-course-image-${index}`}
                data-test-id="capture-course-image"
                onChange={(e) => this.onCourseImageChange(e,currentState, index)}
              />
              <label htmlFor={`capture-course-image-${index}`}>
                <GrayUploadButton component="span">Upload</GrayUploadButton>
              </label>
            </Box>
            <CustomTooltip
              arrow
              placement="right"
              TransitionComponent={Zoom}
              title={`Please upload ${resolution} for optimal resolution`}
            >
              <InfoOutlinedIcon className="icon" />
            </CustomTooltip>
          </Box>
        </Box>
        <Box mr="46px" display="flex" flexDirection="column" alignItems="end">
          <div className="text-danger">{this.state[currentState]?.fileError}</div>
          <div>{this.state[currentState]?.fileName}</div>
        </Box>
      </Box>
    );
  }

  renderCourseImage = () => {
    return (
      <UploadWrapper>
        <Box display="flex" alignItems="center">
          <Box className="button" onClick={this.onUploadButtonClick}>
            <Typography className="text-regular">Upload Pictures</Typography>
            <ChevronRightOutlinedIcon className={this.state.isUpload ? "right-icon" : "expand-icon"} />
          </Box>
          {!this.state.isUpload &&
            <CustomTooltip
              arrow
              placement="right"
              TransitionComponent={Zoom}
              title="Please upload the recommended sizes for optimal resolution"
            >
              <InfoOutlinedIcon className="icon" />
            </CustomTooltip>
          }
        </Box>
        <Box
          className="img-upload"
          sx={{
            height: this.state.isUpload ? "100%" : "0px",
            opacity: this.state.isUpload ? "1" : "0",
          }}
        >
          {this.renderUploadUI("Thumbnail Picture", "(250px by 160px)", "imageObj", 0)}
          {this.renderUploadUI("Cover Photo", "(1200px by 250px)", "coverImg", 1)}
          {this.renderUploadUI("Course Description Cover Photo", "(1200px by 450px)", "coverDescImg", 2)}
        </Box>
      </UploadWrapper>
    );
  }

  renderTrashIcon = () => {
    return (
      <svg width="27" height="27" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z" fill="#1C1C1C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M11 12V22C11 23.1046 11.8954 24 13 24H19C20.1046 24 21 23.1046 21 22V12H23V22C23 24.2091 21.2091 26 19 26H13C10.7909 26 9 24.2091 9 22V12H11Z" fill="white" />
        <path d="M14 15C13.4477 15 13 15.4477 13 16V20C13 20.5523 13.4477 21 14 21C14.5523 21 15 20.5523 15 20V16C15 15.4477 14.5523 15 14 15Z" fill="white" />
        <path d="M18 15C17.4477 15 17 15.4477 17 16V20C17 20.5523 17.4477 21 18 21C18.5523 21 19 20.5523 19 20V16C19 15.4477 18.5523 15 18 15Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12 10V9C12 7.34315 13.3431 6 15 6H17C18.6569 6 20 7.34315 20 9V10H23C23.5523 10 24 10.4477 24 11C24 11.5523 23.5523 12 23 12H9C8.44772 12 8 11.5523 8 11C8 10.4477 8.44772 10 9 10H12ZM14 9C14 8.44772 14.4477 8 15 8H17C17.5523 8 18 8.44772 18 9V10H14V9Z" fill="white" />
      </svg>
    );
  }

  renderFinalAssessmentUI = () => {
    return (
      <>
        {this.state.isFinalAssessment &&
          <Box>
            <LessonBlockWrapper>
              <Box className="assessment" mt="0px !important">
                <Box display="flex" alignItems="center">
                  <Box className="diamond" />
                  <Box>{this.state.finalAssessmentQuizData?.title || "Final Assessment"}</Box>
                </Box>
                <Box position="relative">
                  <EditLessonButton
                    data-test-id="edit-final-assessment"
                    onClick={() => this.onCreateFinalAssessment()}
                  >
                    Edit final assessment
                  </EditLessonButton>
                  <DeleteButton
                    data-test-id="delete-final-assessment"
                    sx={{ minWidth: "fit-content", marginLeft: "10px", padding: "0px" }}
                    onClick={() => this.onDeleteFinalAssessment()}
                  >
                    {this.renderTrashIcon()}
                  </DeleteButton>
                </Box>
              </Box>
            </LessonBlockWrapper>
          </Box>
        }
        {!this.state.isFinalAssessment && !this.state.lessonCreationBar.isActive &&
          <AddButton
            data-test-id="add-lesson-button"
            sx={{ padding: "0px" }}
            style={buttonStyle.lessonAddButton}
            onClick={this.handlePlusClick}
          >
            <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="0.5" width="32" height="32" rx="16" fill="#1C1C1C" />
              <path d="M22 17.5H17V22.5C17 23.05 16.55 23.5 16 23.5C15.45 23.5 15 23.05 15 22.5V17.5H10C9.45 17.5 9 17.05 9 16.5C9 15.95 9.45 15.5 10 15.5H15V10.5C15 9.95 15.45 9.5 16 9.5C16.55 9.5 17 9.95 17 10.5V15.5H22C22.55 15.5 23 15.95 23 16.5C23 17.05 22.55 17.5 22 17.5Z" fill="white" />
            </svg>
          </AddButton>
        }
        <CustomMenu
          data-test-id="plus-menu"
          anchorEl={this.state.plusAnchorEl}
          open={!!this.state.plusAnchorEl}
          onClose={this.handlePlusClose}
          PaperProps={{
            style: {
              padding: "0px",
              borderRadius: '4px',
              border: '1px solid black',
              boxShadow: "0px 2px 20px 0px #00000014"
            }
          }}
        >
          <MenuItem onClick={this.setCreationBarActive}>
            Add Lesson
          </MenuItem>
          <MenuItem onClick={this.onFinalAssessmentClick} data-test-id="final-assessment-btn">
            Add Final Assessment
          </MenuItem>
        </CustomMenu>
      </>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper
        data-test-id="wrapper"
      >
        <Loader loading={this.state.isLoading} />
        <CourseCreationHeader
          data-test-id="header"
          onGoBack={this.onGoBack}
          onPreview={this.onPreview}
          onPublish={this.handlePublishCourses}
          navigation={this.props.navigation}
          learningPath={this.state.learningPath}
          requirement={this.state.requirement}
          isCurrentAssessmentFinal={this.state.isCurrentAssessmentFinal}
          currentAssessmentData={this.state.currentAssessmentData}
          isPreview={this.state.isPreview}
          isQuizVisible={this.state.isQuizVisible}
          isLessonVisible={this.state.isLessonVisible}
          maxQuestion={this.state.quizData?.length ?? 0}
          onSaveQuiz={() => this.saveAssessment()}
          onSaveCourse={this.onPublish}
          onSaveRequirements={(values) => this.updateRequirements(values)}
          onSaveCourseRequirements={(hour: string, requirement: string) => this.onSaveCourseRequirements(hour, requirement)}
          updateAssessmentTitle={(value) => this.updateAssessmentTitle(value)}
          isPublishBtnVisible={this.state.isEditingCourse }
          isCoursePublished={this.state.courseStatus ==='published'}
        >
          {!this.state.isPreview && this.state.isLessonVisible && this.renderContentType(this.state.courseData)}
          {this.state.isQuizVisible && this.renderQuizContent()}
          {this.state.isPreview && (
            this.state.isCourseLearning 
              ? this.renderCourseLearning() 
              : this.renderPreview()
          )}
          {this.renderEditDrawer()}
          {this.renderSidebarDrawer()}
          {this.renderErrors()}
          {!this.state.isLessonVisible && !this.state.isQuizVisible && !this.state.isPreview &&
            <>
              <CourseHead>
                {this.state.coverDescImg.image && <img src={this.state.coverDescImg.image} style={webStyles.coverImage}/>}
                <div className="course-wrapper">
                  <CustomEditor
                    key={this.state.titleLoader}
                    data-test-id="course-title"
                    hideBottomBorder={true}
                    placeholder="Enter course title"
                    value={this.state.courseTitle}
                    onChange={this.onHandleCourseTitleChange}
                  />
                  <div className="author">
                    {this.renderTitleAndAuthor({
                      value: this.state.authorName,
                      inputStyles: inputStyle.authorName,
                      onChange: (event) => this.onHandleAuthorNameTitleChange(event),
                      placeholder: "Enter author name"
                    })}
                  </div>
                  {this.renderCourseImage()}
                </div>
              </CourseHead>
              <CourseDescription data-test-id="description-form">
                <PopupMenu ref={this.lessonTypeMenuRef}>
                  <LessonTypeMenu data-test-id="lesson-type-menu">
                    <AddButton style={buttonStyle.lessonListTypeButton} data-test-id="lesson-type-button-empty" onClick={() => this.setLessonListType("grid")}><img src={imageListEmpty} /></AddButton>
                    <AddButton style={buttonStyle.lessonListTypeButton} data-test-id="lesson-type-button-numbered" onClick={() => this.setLessonListType("ordered")}><img src={imageListNumbered} /></AddButton>
                    <AddButton style={buttonStyle.lessonListTypeButton} data-test-id="lesson-type-button-bullet" onClick={() => this.setLessonListType("unordered")}><img src={imageListBulleted} /></AddButton>
                  </LessonTypeMenu>
                </PopupMenu>
                <Box display="flex" width="100%" mb="15px">
                  <Box width="100%" marginLeft="20px">
                    <CustomEditor
                      key={this.state.titleLoader}
                      data-test-id="description"
                      hideBottomBorder={true}
                      type={'disc'}
                      value={this.state.description}
                      onChange={this.onChangeCourseDescription}
                    />
                  </Box>
                </Box>
              </CourseDescription>
              <LessonList>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable droppableId="lessons">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {this.state.lessonList.filter(item=>!item._destroy).length > 0 &&
                          this.state.lessonList.filter(item=>!item._destroy).map((lessonItem, index) => {
                            if (!this.lessonPopupMenuRefs[lessonItem.id]) {
                              this.lessonPopupMenuRefs[lessonItem.id] = React.createRef();
                            }
                            return (
                              <Draggable key={lessonItem.id} draggableId={lessonItem.id.toString()} index={index}>
                                {(provided) => (
                                  <LessonBlockWrapper
                                    key={lessonItem.id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <LessonBlock>
                                    <div style={{ display: "flex", alignItems: "center", gap: 20, width: "100%" }}>
                                      {this.getListStyleType(index + 1)}
                                      <div className="lesson-title">
                                        {this.ChangeTitleRender({
                                          state: lessonItem.lessonTitle,
                                          inputStyles: inputStyle.lessonTitle,
                                          toggleActivity: (val) => this.onHandleSetLessonTitleActive(lessonItem.id, val),
                                          onChange: (event) => this.onHandleLessonTitleChange(event, lessonItem.id),
                                          placeholder: "Enter lesson title"
                                        })}
                                      </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      {this.renderDropdown(lessonItem, index)}
                                      <DeleteButton
                                        data-test-id="delete-button"
                                        sx={{ minWidth: "fit-content", marginLeft: "10px", padding: "0px" }}
                                        onClick={() => this.deleteLesson(lessonItem.id)}
                                      >
                                        {this.renderTrashIcon()}
                                      </DeleteButton>
                                    </div>
                                    </LessonBlock>
                                    {lessonItem.quizData?.length > 0 &&
                                      <Box className="assessment">
                                        <Box display="flex" alignItems="center">
                                          <Box className="diamond" />
                                          <Box>{lessonItem.assessmentData?.title ?? "Assessment"}</Box>
                                        </Box>
                                        <Box position="relative">
                                          <EditLessonButton
                                            data-test-id="edit-assessment-button"
                                            onClick={() => this.onQuizCreation(lessonItem, index)}
                                          >
                                            Edit Assessment
                                          </EditLessonButton>
                                          <DeleteButton
                                            data-test-id="delete-assessment-button"
                                            sx={{ minWidth: "fit-content", marginLeft: "10px", padding: "0px" }}
                                            onClick={() => this.deleteAssessment(lessonItem.id)}
                                          >
                                            {this.renderTrashIcon()}
                                          </DeleteButton>
                                        </Box>
                                      </Box>
                                    }
                                  </LessonBlockWrapper>
                                )}
                              </Draggable>
                            )
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {this.state.lessonCreationBar.isActive &&
                  <LessonCreator>
                    <div style={{
                      ...inputStyle.inputBox,
                    }}>
                      {this.getListStyleType(this.state.lessonList.filter(item=>!item._destroy).length + 1)}
                      <CustomInput
                        fullWidth
                        autoFocus
                        style={inputStyle.lessonCreation}
                        data-test-id="lesson-title"
                        placeholder={configJSON.placeHolderLessonTitle}
                        value={this.state.lessonCreationBar.title}
                        onChange={(event) => {
                          this.setState({ lessonCreationBar: { title: event.target.value, isActive: true } });
                        }}
                        onKeyDown={(event) => this.onHandleEnterPress(event, () => this.addNewLesson(event.target.value))}
                      />
                    </div>
                    <div className="label-submit">{configJSON.labelPressEnterNewLesson}</div>
                  </LessonCreator>
                }
                {this.renderFinalAssessmentUI()}
              </LessonList>
            </>}
        {this.DeleteContentModal()}
        </CourseCreationHeader>
        <div ref={this.bottomRef}></div>
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ContentWrapper = styled(Box)({
  "& .menu-icon": {
    position: "fixed",
    top: "80px",
    left: "10px",
    zIndex: 999999,
  },
  "& .course-wrapper": {
    position: "relative",
    boxShadow: "0px 2px 20px 0px #00000014",
  },
  "& .title": {
    fontSize: "64px",
    lineHeight: "80px",
    marginBottom: "25px",
    fontFamily: 'Crimson Text',
  },
  "& .author": {
    fontFamily: "Heebo-Regular",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1C1C1C",
  }
});

const CustomMenu = styled(Menu)({
  marginTop: "6px",
  marginLeft: "13px",
  "& .MuiPaper-root": {
    width: "270px",
  },
  "& .MuiList-root": {
    padding:"8px",
    gap: "10px",
    display: "flex",
    flexDirection: "column"
  },
  "& .MuiMenuItem-root": {
    fontSize: "16px",
    fontFamily: "Heebo-Regular",
    color: "#1C1C1C",
    padding: '16px',
    backgroundColor: 'rgb(250, 250, 249)',
  }
});

const sharedStyles = ({ isOptionVisible, isPreview }: { isOptionVisible: boolean; isPreview: boolean }) => ({
  position: "relative",
  display: "flex",
  justifyContent: "center",
  borderTop: `1px solid ${isOptionVisible ? "#D7D7D7" : "transparent"}`,
  borderBottom: `1px solid ${isOptionVisible ? "#D7D7D7" : "transparent"}`,
  "& .option-list": {
    display: isOptionVisible ? "block" : "none",
  },
  "& .plus-button": {
    display: "none",
  },
  "&:hover": {
    borderTop: `1px solid ${isPreview ? "transparent" : "#D7D7D7"}`,
    borderBottom: `1px solid ${isPreview ? "transparent" : "#D7D7D7"}`,
    "& .option-list, & .plus-button": {
      display: isPreview ? "none" : "block"
    },
  },
  "& blockquote": {
    margin: "0px"
  }
});

const MainWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "isPreview" && prop !== "isOptionVisible" })<{ isOptionVisible: boolean, isPreview: boolean; }>(({ theme, isOptionVisible, isPreview }) => ({
  ...sharedStyles({ isOptionVisible, isPreview }),
  "& .image": {
    width: "980px",
    objectFit: "fill",
    height: "590px",
  },
  "& .list-content": {
    "& ol": {
      paddingLeft: "12px",
      "& li": {
        marginBottom: "20px",
      }
    },
    "& ul": {
      paddingLeft: "12px",
      "& li": {
        listStyleType: "none !important",
        marginBottom: "20px",
        position: "relative",
      },
      "& li::before": {
        content: "'\u25C6'",
        color: "black",
        fontSize: "25px",
        position: "absolute",
        top: "-6px",
        left: "-36px",
      }
    }
  },
  "& .carousel": {
    padding: "20px 70px 35px",
    backgroundColor: "#FAFAFA",
    boxShadow: "0px 4px 20px 0px #0000001A inset",
    [theme.breakpoints.down('md')]: {
      padding: "10px 9px 15px",
    },
     "& .image": {
       maxHeight: "553px",
       width:'100%',
       objectFit:'fill'
       
     },
    "& .arrow-btn": {
      width: 40,
      height: 40,
      backgroundColor: '#fff',
      boxShadow: "0px 2px 20px 0px #00000014",
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    "& .pagination": {
      gap: '10px',
      marginTop: '20px',
      display: 'flex',
      justifyContent: 'center',
      "& .page": {
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        cursor: 'pointer',
      }
    }
  }
}));

const PreviewComp = styled(Box)({
  "& .image-wrapper": {
    position: 'relative',
    height: '475px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'left',
  },
  "& .cover-img": {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    zIndex: 1,
  },
  "& .backdrop": {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(5px)',
    zIndex: 2,
  },
  "& .text-wrapper": {
    position: 'relative',
    zIndex: 3,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'left',
    gap: 2,
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 10%',
    "& .author-name": {
      fontFamily: 'Heebo-Regular',
      fontSize: '16px',
      color: '#1C1C1C'
    }
  },
  "& .bottom-section": {
    display: "flex",
    justifyContent: "space-evenly",
    padding: "30px 10%",
    "& .lesson-item": {
      display: "flex",
      width: "100%",
      marginRight: '40px',
      "& .item": {
        maxWidth: '1070px',
        width: '100%',
        display: "flex",
        flexDirection: "column",
        gap: "40px",
        "& .item-wrapper": {
          display: "flex",
          flexDirection: "column",
          gap: "16px"
        }
      }
    },
    "& .paper": {
      borderRadius: "4px",
      boxShadow: "0px 2px 20px 0px #00000014"
    },
    "& .description-section": {
      padding: "24px",
      maxWidth:"250px",
      display: "flex",
      flexDirection: "column",
      gap: "40px",
      "& .details": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        width: "80px",
        height: "70px",
        backgroundColor: "#f5f5f5",
        borderRadius: "4px"
      },
      "& .requirements": {
        fontFamily: 'Heebo-Medium',
        fontSize: '14px',
        color: '#141615'
      },
      "& .info": {
        display: "flex",
        flexDirection: "column",
      }
    },
    "& .chapter": {
      display: "flex",
      justifyContent: "space-between",
      textAlign: "left",
      gap: "24px",
      "& .lesson-title": {
        fontFamily: 'Heebo-Medium',
        fontSize: "18px",
      },
      "& .checkbox": {
        padding: 0,
        height: '26px',
        width: '26px',
        color: 'grey',
        cursor: "default"
      }
    }
  }
});

const LearningWrapper = styled(Box)({
  display: 'flex',
  backgroundColor: '#F8FAFC',
  height: "calc(100vh - 77px)",
  width: '100%',
  "& .learning-wrapper": {
    height: "calc(100vh - 117px)",
    overflow: 'auto',
    paddingBottom: '40px',
    '& .lesson-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      maxHeight: '250px',
      position: 'relative',
      borderBottom: '1px solid #9D9D9D',
      "& .menu-icon": {
        cursor: "pointer",
        zIndex: 1000,
        position: 'absolute',
        left: 15,
        top: 15
      },
      "& .course-heading": {
        objectFit: "cover",
        position: 'absolute',
        backgroundColor: '#fff',
        opacity: '60%',
        width: '100%',
        height: '100%',
        maxHeight: '250px',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 0
      },
      "& .content-wrapper": {
        zIndex: 1,
        position: 'relative',
        margin: 'auto 40px',
        "& .lesson": {
          fontStyle: 'italic',
          fontSize: '14px',
          fontFamily: 'Crimson Text'
        },
        "& .title": {
          fontWeight: 'bold',
          fontSize: '40px',
          fontFamily: 'Crimson Text'
        },
        "& .author": {
          fontSize: '14px',
          fontFamily: 'Crimson Text'
        }
      }
    }
  }
});

const CourseSidebar = styled(Box)({
  backgroundColor: '#FAFAFA',
  height: "100%",
  boxShadow: '0px 4px 20px 0px #0000001A inset',
  "& .title-wrapper": {
    color: "#fff",
    wordWrap: "break-word",
    fontSize: "24px",
    backgroundColor: "black",
    padding: '40px 20px',
  },
  "& .list-item": {
    padding: '10px',
    minHeight: "32px",
    color: '#1C1C1C',
    display: 'flex',
    justifyContent: 'space-between'
  },
  "& .progressbar": {
    height: '25px !important',
    width: '25px !important',
    boxShadow: 'inset 0 0 1px 1px #D9d9d9',
    borderRadius: '50%'
  }
});

const DetailWrapper = styled(Box)<{ open: boolean }>(({ theme }) => ({
  flexGrow: 1,
  marginLeft: "-250px",
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [{
    props: ({ open }) => open,
    style: {
      marginLeft: 0,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  }],
}));

const StartButton = styled(Button)({
  backgroundColor: '#000',
  color: '#fff',
  marginTop: '20px',
  width: '250px',
  borderRadius: '4px',
  textTransform: 'none',
  padding: '10px 16px',
  fontFamily: 'Heebo-Bold',
  fontSize: '16px',
  lineHeight: '24px',
  '&:hover': {
    backgroundColor: '#000',
  },
});

const QuizWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isOptionVisible",
})(({ theme }) => ({
  position: "relative",
  display: "flex",
  height: "calc(100% - 109px)",
  minHeight: "calc(100vh - 237px)",
  backgroundColor: "#FAFAFA",
  justifyContent: "center",
  "& .option-list": {
    display: "none",
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  "&:hover .option-list": {
    display: "block",
  },
}));

const StyledTextField = styled(TextField)`
  & input[type='number'] {
    -moz-appearance: textfield;
  }
  
  & input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  & input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const ListDrawer = styled(Box)({
  "& .DraftEditor-editorContainer": {
    "& ul": {
      paddingLeft: "12px",
      "& li": {
        listStyleType: "none !important",
        marginBottom: "20px",
        position: "relative",
      },
      "& li::before": {
        content: "'\u25C6'",
        color: "black",
        fontSize: "25px",
        position: "absolute",
        top: "-6px",
        left: "-36px",
      }
    },
    "& ol": {
      paddingLeft: "12px",
      "& li": {
        marginBottom: "20px",
      }
    },
  }
});

const UploadButton = styled(Button)({
  width: "80px",
  height: "42px",
  border: "1.2px solid #D6D3D1",
  fontSize: "14px",
  borderRadius: '4px',
  textTransform: 'none',
  padding: '8px 16px',
  fontFamily: "Heebo-Bold",
  backgroundColor: 'white',
  color: 'black',
  '&:hover': {
    backgroundColor: '#f5f5f5',
    borderColor: '#D6D3D1',
  },
});

const GrayUploadButton = styled(Button)({
  width: "142px",
  height: "46px",
  fontSize: "16px",
  color: 'black',
  borderRadius: '4px',
  textTransform: 'none',
  padding: '8px 16px',
  fontFamily: "Heebo-Bold",
  backgroundColor: '#E6E6E6',
  border: "1px solid #E6E6E6",
  marginRight: "20px",
  '&:hover': {
    backgroundColor: '#E6E6E6',
  },
});

const UrlInputBase = styled(InputBase)({
  width: "100%",
  padding: "6px 12px",
  borderRadius: "4px",
  fontFamily: "heebo-Regular",
  fontSize: "14px",
  border: "1px solid #D6D3D1",
});

const FormatList = styled(Box)({
  zIndex: "9999999",
  position: "absolute",
  top: "76px",
  right: "25px",
  display: "flex",
  gap: "10px",
  width: "fit-content",
  backgroundColor: "#fff",
  borderRadius: "4px",
  padding: "24px",
  boxShadow: "0px 2px 20px 0px #00000014",
  "& .close-btn": {
    cursor: "pointer"
  },
  "& .format": {
    fontFamily: "Heebo-Bold",
    fontSize: "16px"
  },
  "& .text": {
    marginTop: "20px",
    marginBottom: "20px",
    fontFamily: "Heebo-Regular",
    fontSize: "16px",
  },
  "& .small-text": {
    fontSize: "12px",
    marginTop: "15px",
    marginBottom: "5px",
    fontFamily: "Heebo-Medium",
  }
});

const CustomToggleButton = styled(ToggleButton)({
  borderRadius: '4px !important',
  padding: '5px 20px',
  width: "67px",
  color: "#000",
  background: "#fff",
  fontFamily: "Heebo-Regular",
  border: "1.5px solid #D6D3D1",
  '&.Mui-selected': {
    fontFamily: "Heebo-Bold",
    borderRadius: '4px',
    background: "#fff",
    borderColor: '#000',
    fontWeight: 'bold',
    border: "1.5px solid #1C1C1C"
  },
  "&.MuiToggleButtonGroup-middleButton": {
    margin: "0px 13px",
  },
  '&:not(.Mui-selected)': {
    border: "1.5px solid rgb(214, 211, 209)",
    backgroundColor: 'transparent',
  },
  '&:hover': {
    borderColor: '#000',
  },
});

const CustomSlider = styled(Slider)({
  color: '#000',
  height: 2,
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#000',
    border: '2px solid currentColor',
  },
  '& .MuiSlider-track': {
    height: 2,
  },
  '& .MuiSlider-rail': {
    color: '#ccc',
    height: 2,
  },
});

const PlusLine = styled(Box)({
  marginBottom: "50px",
  cursor: "pointer",
  position: "relative",
  "& div": {
    borderBottom: "1px solid rgb(215, 215, 215)"
  },
  "& img": {
    position: "absolute",
    left: "50%",
    top: "-18px",
    padding: "10px",
    borderRadius: "50%",
    backgroundColor: "white",
    border: "1px solid #D7D7D7",
    boxShadow: "0px 2px 20px 0px #00000014"
  }
});

const Toolbar = styled(Box, { shouldForwardProp: (prop) => prop !== "isVisible" })<{ isVisible: boolean }>(({ theme, isVisible }) => ({
  display: isVisible ? "block" : "none",
  padding: "20px",
  width: "fit-content",
  margin: "20px auto 70px",
  borderRadius: "4px",
  border: "1px solid #A8A29E",
  "& button": {
    cursor: "pointer",
    outline: "none",
    border: "none",
    display: "flex",
    flexDirection: "column",
    padding: "12px 24px",
    alignItems: "center",
    borderRadius: "4px",
    backgroundColor: "transparent",
    "& span": {
      marginTop: "20px",
      fontSize: "18px",
      fontFamily: "Heebo-Regular",
    },
    "&:hover": {
      backgroundColor: "#D7D7D7"
    }
  }
}));

const OptionList = styled(Box)({
  position: "absolute",
  top: "10px",
  right: "25px",
  display: "flex",
  gap: "10px",
  width: "fit-content",
  backgroundColor: "#fff",
  borderRadius: "4px",
  padding: "10px 15px",
  boxShadow: "0px 2px 20px 0px #00000014",
  "& button": {
    padding: "0px",
    border: "none",
    outline: "none",
    background: "transparent"
  },
  "& .up-down": {
    width: "28px",
    height: "28px",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "5px",
    "&:hover, &.active": {
      backgroundColor: "#D7D7D7",
    }
  },
  "& img": {
    cursor: "pointer",
    borderRadius: "4px",
    padding: "5px",
    "&:hover, &.active": {
      backgroundColor: "#D7D7D7",
    }
  }
});

const CustomDrawer = styled(Drawer)({
  "& .title": {
    color: "#6A6A6A",
    fontSize: "16px",
    marginTop: "30px",
    marginBottom: "20px",
    fontFamily: "Heebo-Medium",
  },
  "& .icon": {
    cursor: "pointer",
  },
  "& .margin0": {
    "& h1, h2, h3, h4, p": {
      margin: "0px"
    }
  },
  "& .content-sidebar": {
    width: "250px",
    padding: "16px",
    "& .content-library": {
      fontSize: "24px",
      fontFamily: "Heebo-Bold"
    },
    "& .list-item": {
      cursor: "pointer",
      padding: "10px 0px"
    },
    "& .list-text": {
      fontSize: "16px !important",
      fontFamily: "Heebo-Bold !important"
    }
  },
  "& .side-title": {
    paddingTop: "25px",
    fontSize: "24px",
    fontFamily: "Heebo-Bold"
  },
  "& .border-bottom": {
    position: "absolute",
    left: "-30px",
    right: "-30px",
  }
});

const UploadWrapper = styled(Box)({
  "& .text-regular": {
    fontsize: "16px",
    fontFamily: "heebo-Regular",
  },
  "& .button": {
    display: "flex",
    cursor: "pointer"
  },
  "& .icon": {
    cursor: "pointer",
    color: '#000'
  },
  "& .right-icon": {
    margin: "0px 10px",
    transform: "rotate(90deg)",
    transition: "transform 0.5s ease, top 0.5s ease",
  },
  "& .expand-icon": {
    margin: "0px 10px",
    transform: "rotate(0deg)",
    transition: "transform 0.5s ease, top 0.5s ease",
  },
  "& .img-upload": {
    overflow: "hidden",
    maxWidth: "435px",
    marginTop: "30px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    transition: "opacity 0.5s ease, max-height 0.5s ease, transform 0.5s ease",
  }
});

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    color: 'black',
    padding: '10px',
    fontSize: '14px',
    maxWidth: "100%",
    borderRadius: '9px',
    backgroundColor: '#F0F0F0',
    fontfamily: "Heebo-Regular",
    boxShadow: "0px 4px 8px 0px #00000008",
  },
  "& .MuiTooltip-arrow": {
    color: '#F0F0F0',
  },
}));

const BlackButton = styled((Button))({
  width: "248px",
  background: "#1C1C1C",
  padding: "10px 16px 10px 16px",
  borderRadius: "4px",
  color: "#FFFFFF",
  fontFamily: "Heebo",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "24px",
  textTransform: "none",
  "&:hover": {
      background: "#1C1C1C",
  },
  "&:disabled": {
      color: "#FFFFFF",
      background: "#D7D7D7"
  }
})

const inputStyle = {
  courseCreation: {
    fontFamily: "Crimson Text",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "32px",
    color: "#1C1C1C",
    "&::placeholder": {
      color: "#A8A29E"
    },
  },
  lessonCreation: {
    fontFamily: "Heebo-Medium",
    fontSize: "18px",
    lineHeight: "26.44px",
  },
  inputBox: {
    borderBottom: "1px solid #9D9D9D",
    display: "flex",
    alignItems: "center",
    gap: 20,
    padding: "32px 22px"
  },
  lessonTitle: {
    fontSize: "18px",
    fontFamily: "Heebo-Medium",
    fontWeight: 500,
    lineHeight: "26.44px",
    color: "#1C1C1C",
  },
  courseTitle: {
    height: "auto",
    fontFamily: 'Crimson Text',
    fontSize: "50px",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "80px"
  },
  authorName: {
    fontFamily: "Heebo-Regular",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "23.5px",
    color: "#1C1C1C",
  }
}

const buttonStyle = {
  lessonAddButton: {
    position: "absolute",
    bottom: 0,
    left: "50%",
    transform: "translate(-50%, 50%)",
    "&:hover": {
      backgroundColor: "#1C1C1C",
    }
  },
  lessonListTypeAddButton: {
    boxShadow: "0px 2px 20px 0px #00000014",
  },
  lessonListTypeButton: {
    border: "1px solid #6A6A6A",
  },
  blackButton: {
    textTransform: 'none',
    fontWeight: 'bold',
    backgroundColor: '#1C1C1C',
    width: '100%',
    fontSize: '16px',
    color: "#fff",
    lineHeight:'24px',
    fontFamily:'Heebo-bold',
    height:'56px'
  }
}

const LessonPopupMenu = styled(Box)({
  width: "auto",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 2px 20px 0px #00000014",
  padding: "14px",
  borderRadius: "4px",
  "& .lesson-popup-menu-block": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 32,
    textWrap: "nowrap",
    backgroundColor: "#FAFAF9",
    borderBottom: "1px solid #F5F5F4",
    padding: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "26px",
    color: "#1C1C1C",
    "& img": {
      width: 16,
      height: 16
    }
  }
})

const LessonTypeMenu = styled(Box)({
  boxShadow: "0px 2px 20px 0px #00000014",
  backgroundColor: "#FFFFFF",
  display: 'flex',
  gap: 16,
  padding: "12px 16px 12px 16px",
  top: "50%",
})

const AddButton = styled(Button)({
  width: 32,
  height: 32,
  minWidth: "auto",
  borderRadius: "50%",
})

const EditLessonButton = styled(Button)({
  padding: "4px 16px 4px 16px",
  borderRadius: "4px",
  border: "1px solid #1C1C1C",
  backgroundColor: "#FFFFFF",
  fontFamily: "Heebo-Medium",
  cursor:'pointer',
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "18px",
  color: "#1C1C1C",
  textTransform: "none",
  whiteSpace: "nowrap",
  "& img": {
    width: 24,
    height: 24,
  }
})

const DeleteButton = styled(Button)({
  cursor:'pointer',
  backgroundColor: "transparent"
})

const Circle = styled(Box)({
  width: 12,
  height: 12,
  borderRadius: "50%",
  background: "#1C1C1C",
})

const Square = styled(Box)({
  width: 12,
  height: 12,
  marginTop: "10px",
  background: "#1C1C1C",
});

const LessonCreator = styled(Box)({
  padding: "0 0 32px 0",
  "& .label-submit": {
    textAlign: "right",
    fontFamily: "Heebo-Regular",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "17.63px",
    marginTop: 12,
    color: "#6A6A6A",
  }
})

const LessonList = styled(List)({
  padding: "0 32px",
  maxWidth: "1064px",
  margin: '20px auto 120px auto',
  position: "relative"
})

const LessonBlockWrapper = styled(Box)({
  position: "relative",
  padding: "22px",
  background: "#FFFFFF",
  borderTop: "1px solid #9D9D9D",
  borderBottom: "1px solid #9D9D9D",
  fontSize: "18px",
  fontFamily: "Heebo-Medium",
  fontWeight: 500,
  lineHeight: "26.44px",
  color: "#1C1C1C",
  "& .assessment": {
    marginTop:"20px",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  "& .diamond": {
    width: '12px',
    height: '12px',
    marginRight: "20px",
    backgroundColor: 'black',
    transform: 'rotate(45deg)',
  }
});

const LessonBlock = styled(Box)({
  width:"100%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FFFFFF",
  fontSize: "18px",
  fontFamily: "Heebo-Medium",
  fontWeight: 500,
  lineHeight: "26.44px",
  color: "#1C1C1C",
  "& .lesson-title": {
    width:"100%"
  }
});

const CourseHead = styled(Box)({
  position:'relative',
  boxShadow: "0px 2px 20px 0px #00000014",
  "& .course-wrapper": {
    display: "flex",
    flexDirection: 'column',
    gap: "32px",
    margin: '0 auto',
    padding: "40px 30px 30px 70px",
    justifyContent:'center',
    minHeight:'300px',
    "&>div":{
      backgroundColor:'transparent'
    }
  },
  "& .title": {
    fontFamily: 'Crimson Text',
    fontSize: "64px",
    color: "#000000",
    fontWeight: 400,
    lineHeight: "80px"
  },
  "& .author": {
    fontFamily: "Heebo-Regular",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "23.5px",
    color: "#1C1C1C",
  }
})

const CourseDescription = styled(Box)({
  margin: '0 auto',
  maxWidth: "1440px",
  padding: "55px 32px 0 32px",
  position: "relative",
  display: "flex",
  gap: 8,
  "& .public-DraftStyleDefault-block": {
    margin: "5px 0px !important",
  },
  "& .course-description": {
    fontFamily: "Crimson Text",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "32px",
    color: "#1C1C1C",
    width: "100%",
    "& .editor": {
      position: 'absolute',
      top: 0,
      left: '50%'
    }
  }
});

const CustomInput = styled(InputBase)({
  height: "32px",
  "& input": {
    height: "100%",
  }
});

const Wrapper = styled(Box)({ 
  "& .placeholder": {
    color: "#A8A29E"
  },
  "& .margin0": {
    "& h1, h2, h3, h4, p": {
      margin: "0px"
    }
  }
})

const CustomCheckbox = styled(Checkbox)(() => ({
  '& .MuiSvgIcon-root': {
    width: '32px',
    height: '32px',
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '4px',
  },
  '&:not(.Mui-checked) .MuiSvgIcon-root': {
    color: 'transparent',
  },
  '&.Mui-checked': {
    '& .MuiSvgIcon-root': {
      backgroundColor: 'black',
      borderColor: 'black',
    },
    '& .MuiSvgIcon-root path': {
      d: 'path("M5 12l5 5L20 7")',
      stroke: 'white',
      strokeWidth: 2,
      fill: 'none',
    },
  },
}));

const FlipCard = styled('div')({
  perspective: '1000px',
  width: '200px',
  height: '100%',
  position: 'relative',
  transformStyle: 'preserve-3d',
  transition: 'transform 0.5s',
  "&.flipped": {
    transform: 'rotateY(180deg)',
  },
  '.flip-card-inner': {
    width: '200px',
    height: '240px',
    transformStyle: 'preserve-3d',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  "& .flip-icon": {
    cursor: "pointer"
  },
  '.flip-card-front,.flip-card-back': {
    boxSizing: 'border-box',
    padding: '30px 20px 35px',
    width: '100%',
    position: 'absolute',
    backfaceVisibility: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>img': {
      width: '24px',
      height: '24px',
      position: 'absolute',
      right: '20px',
      bottom: '10px'
    }
  },

  '.flip-card-front': {
    backgroundColor: '#fff',
    color: 'black',
    height: '90%',
    border: '1px solid black',
    borderBottom: '3px solid black'
  },

  '.flip-card-back ': {
    height: '100%',
    backgroundColor: '#1C1C1C',
    color: 'white',
    transform: 'rotateY(180deg)',
  },

  '.card-content': {
    boxSizing: 'border-box',
    overflow: 'scroll',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    "&>div": {
      margin: 'auto'
    }
  }

})
const StyledTable = styled('table')({
  borderCollapse: 'collapse',
  width: '100%',
  '& th, & td ': {
    border: '1px solid #d0d0d0',
    padding: '8px',
    textAlign: 'left',
  },

  ' & th': {
    backgroundColor: '#f1f1f1',
    fontWeight: 'bold',
  },

  '& tr:nth-child(even)': {
    backgroundColor: '#f9f9f9',
  },

  ' & tr:hover': {
    backgroundColor: '#e0e0e0',
  },
})

const CustomDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    maxWidth: '600px',
    width: '100%',
    borderRadius: "8px",
  },
  "& .dialog-title": {
    padding: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0",
    "& .close-icon": {
      cursor: "pointer",
      color: "#334155",
    },
  },
  "& .error-wrapper": {
    backgroundColor: '#FEE2E2',
    borderLeft: "4px solid #DC2626",
    padding: '6px',
    borderRadius: '4px',
    marginTop: '16px',
    "&:first-child": {
      marginTop: '0px',
    },
    "& .text": {
      marginLeft: "3px",
      fontFamily: 'Heebo-Regular',
      fontSize: '15px',
      fontWeight: '600',
      color: '#DC2626',
    }
  },
  "&.delete-popup": {
    "& .MuiPaper-root": {
      maxWidth: '500px',
      borderRadius: "8px 8px 32px 8px",
    },
    "& .dialog-title": {
      borderBottom: "none",
      justifyContent: "end",
    },
    "& .delete-title": {
      lineHeight: "32px",
      letterSpacing: "-0.5px",
      marginBottom: "10px",
      fontSize: "24px",
      fontFamily: "Heebo-Bold",
    },
    "& .delete-description": {
      fontSize: "18x",
      letterSpacing: "-0.5px",
      fontFamily: "Heebo-Regular",
    },
    "& .dialog-action": {
      padding: "20px",
      borderTop: "1px solid #E2E8F0",
    }
  },
}));

const CustomBackBtn = styled(Button)({
  textTransform: "none",
  padding: "6px 16px",
  color: "#1C1C1C",
  fontFamily: "Heebo-Regular",
  fontWeight: 400,
  fontSize: "16px",
  lineHeight: "24px",
  gap: 8,
  "& img": {
    width: 24,
    height: 24,
  }
});

const CancelButton = styled(Button)({
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  color: "black !important",
  fontSize: "16px !important",
  padding: "12px 28px !important",
  backgroundColor: "#F0F0F0",
  borderRadius: "8px !important",
  "&:hover": {
    backgroundColor: "#F0F0F0",
  },
});

const DoneButton = styled(Button)({
  marginLeft: "15px !important",
  textTransform: "none",
  fontFamily: "Heebo-Bold",
  fontSize: "16px !important",
  color: "white !important",
  padding: "12px 28px !important",
  borderRadius: "8px !important",
  backgroundColor: "#FC5555 !important",
  "&:hover": {
    backgroundColor: "#FC5555",
  },
});

const webStyles = {
  coverImage:{
    position:'absolute',
    top:0,
    right:0,
    left:0,
    bottom:0,
    width:'100%',
    height:'100%',
    opacity:'0.6',
    zIndex:'-1'
  },
  containerModal: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 8px 32px 0px #0000000F',
    width: '600px',
    padding: '24px',
    height: '252px',
    borderRadius: '8px 8px 32px 8px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    justifyContent: 'space-between',
  },
  quizBlockQuestion: {
    fontFamily: "Crimson Text",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    color: '#1C1C1C'
  },
  checkboxLabel: {
    fontFamily: "Crimson Text",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "32px",
    color: '#1C1C1C',
    width:'100%'
  },
  submitButton: {
    minWidth: '248px',
    minHeight: '44px',
    borderRadius: '4px',
    padding: '10px 16px',
    backgroundColor: '#1C1C1C',
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '32px'
  },
  quizEditorSubHeading: {
    fontFamily: 'Heebo-Regular',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#1C1C1C'
  },
  quizEditorQuestionHeadings: {
    fontFamily: 'Heebo-Medium',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#6A6A6A'
  },
  quizOptionHeading: {
    fontFamily: 'Heebo-Medium',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#6A6A6A'
  },
  contentBtn: {
    width: '52px',
    minWidth: '0',
    height: '52px',
    borderRadius: '50%',
    border: '2px solid #fff',
    backgroundColor: '#FFFFFF80',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContents: 'center'
  },
  disalbeButton:{
    cursor:'not-allowed',
    opacity:'0.6',
    pointerEvents:'none',
    color:'rgba(0, 0, 0, 0.26)'
  },
  plusIcon: {
    width: '32px',
    height: '32px',
    color: '#000',
    backgroundColor: '#fff',
    borderRadius: '50%',
    padding: '5px',
    boxSizing: 'border-box',
    boxShadow: "0px 2px 8px 0px #00000099",

  },
  labelContent: {
    flexDirection: "column",
    width: 'max-content',
    maxWidth: "225px",
    maxHeight:'300px',
    alignItems: 'flex-start',
    textAlign: 'left',
    backgroundColor: '#fff',
    padding: '20px'
  },
  buttonTable: {
    border: '1px solid black',
    textTransform: 'none',
    padding: '5px 20px',
    width: '100%',
    color: 'black'
  } as React.CSSProperties,
  testCard:{
    boxShadow: '0px 2px 20px 0px #0000001A',

  }
}

const styles = {
  tabBar: {
      wrapper: {
          width: "100%",
          height: "100%",
          border: "1px solid #F8FAFC",
          background: "#FAFAFA",
      },
      header: {
          background: "#1C1C1C",
          fontFamily: "Crimson Text",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "36px",
          color: "#FFFFFF",
          padding: "40px 24px 40px 24px",
          gap: "10px",
          borderBottom: "1px solid #D7D7D7",
      },
      content: {
          list: {
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              padding: "32px 0 0 0"
          }
      },
      dropdown: {
          title: {
              margin: "0 15.5px",
              padding: "4px 8px 8px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid #D7D7D7",
              fontFamily: "Crimson Text",
              fontSize: "14px",
              fontWeight: 600,
              lineHeight: "24px",
              color: "#1C1C1C",

          },
          itemTitle: {
              fontFamily: "Heebo",
              fontSize: "12px",
              fontWeight: 500,
              lineHeight: "17.63px",
          },
          items: {

          },
          item: {
              position: "relative",
              display: "flex",
              justifyContent: "space-between",
              gap: "16px",
              padding: "20px 16px 20px 16px",
          },
          progressImage: {
              width: "26px",
              height: "26px",
          }
      },
  },
  content: {
      paddingBottom: "20px",
      position: "relative",
      "& .menu-button": {
          position: "absolute",
          top: "14px",
          left: 0,
          zIndex: 1,
      },
      background: {
          height: "auto",
          padding: "64px 140px 60px 140px",
          position: "relative",
          color: "#1C1C1C",
          fontFamily: "Crimson Text",
          borderBottom: "1px solid #9D9D9D",
          "& .bg-image": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#FFFFFF99",
              // backgroundImage: `url(${imgBackground})`,
              backgroundRepeat: "no-repeat",
              filter: "blur(6px)",
              backgroundSize: "cover",
              zIndex: -1,
          },
          "& .counter": {
              fontSize: "14px",
              fontStyle: "italic",
              fontWeight: 600,
              lineHeight: "18.2px",
          },
          "& .title": {
              fontSize: "40px",
              fontWeight: 700,
              lineHeight: "40px",
              margin: "12px 0 16px 0"
          },
          "& .author": {
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "23.4px",
          },
      },
      information: {
          padding: "72px 0 100px 0",
          width: "856px",
          color: "#1C1C1C",
          fontFamily: "Crimson Text",
          margin: "0 auto"
      },
      title: {
          fontSize: "32px",
          fontWeight: 600,
          lineHeight: "40px",
          marginBottom: "32px",
      },
      description: {
          fontSize: "20px",
          fontWeight: 400,
          lineHeight: "28px",

      }
  },
  card: {
      boxShadow: "0px 2px 20px 0px #0000001A",
      boxSizing:'border-box',
      width: "70%",
      background: "#FFFFFF",
      header: {
          padding: "40px",
          borderBottom: "1px solid #D7D7D7",
          color: "#1C1C1C",
          fontFamily: "Crimson Text",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "32px",
          display: "flex",
          justifyContent: "space-between"
      },
      content: {
          padding: "40px 72px",
          "& .radio-group": {
              display: "flex",
              flexDiraction: "column",
              gap: "32px"
          },
          "& .radio-label": {
              color: "#1C1C1C",
              fontFamily: "Crimson Text",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "32px",

          }
      }
  },
  alert: {
      width: "70%",
      boxSizing:'border-box',
      paddingTop: "32px",
      paddingBottom: '32px',
      background: `rgb(240, 240, 240)`,
      margin: "24px auto",
      "& .alert-image": {
          width: 80,
          height: 80,
          margin: "0 auto"
      },
      "& .alert-label": {
          textAlign: "center",
          marginTop: "20px",
          color: "#1C1C1C",
          fontFamily: "Crimson Text",
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "32px",
      },
      "& .alert-description": {
          textAlign: "center",
          marginTop: "32px",
          color: "#1C1C1C",
          fontFamily: "Crimson Text",
          fontSize: "18px",
          fontWeight: 400,
          lineHeight: "32px",

      }
  },
  passedTest: {
      width: "70%",
      margin: "4px auto",
      block: {
          borderBottom: "1px solid #D7D7D7",
          display: "flex",
          flexDirection: "column",
          gap: "20px"
      },
      item: {
          display: "flex",
          justifyContent: "space-between",
          "& .passedTest-title": {
              fontFamily: "Crimson Text",
              fontSize: "40px",
              fontWeight: 400,
              lineHeight: "40px",
              color: "#1C1C1C"

          },
          "& .passedTest-default-text": {
              fontFamily: "Crimson Text",
              fontSize: "24px",
              fontWeight: 400,
              lineHeight: "40px",
              color: "#1C1C1C"
          }
      },
      buttons: {
          display: "flex",
          justifyContent: "center",
          gap: "40px",
          padding: "72px 0 0 0"
      }
  },
  endTest: {
      margin: "0 auto",
      width: "70%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      wrapper: {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "56px",
          borderBottom: "1px solid #6A6A6A",
          padding: "0 0 28px 0",
          margin: "0 0 46px 0",
      },
      progress: {
          margin: "0 auto",
          width: 300,
          height: 300,
          "& .progressText": {
              fontFamily: "Crimson Text",
              fontSize: "20px",
              fontWeight: 700,
              lineHeight: "32px",
          }
      }
  }
}
// Customizable Area End
