import React from 'react';

// Customizable Area Start
import {
  Box,
  Typography,
} from '@mui/material';
import Sidebar from '../../../components/src/sidebar/Sidebar.web';
import { KeyboardArrowRight } from '@mui/icons-material';
// Customizable Area End

import ManageClientController, {
  Props,
} from './ManageClientController';


export default class ManageClient extends ManageClientController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    
    return (
        <>
            <Box sx={webStyles.mainWrapper}>
                <Box sx={webStyles.mainBlock}>
                    <Box>
                        <Sidebar current='clients' navigation={this.props.navigation} />
                    </Box>
                    <Box sx={webStyles.rightSection}>
                        <Box sx={{ display: 'flex', padding: '1rem 2rem', alignItems: 'center', columnGap: '10px' }}>
                            <Typography data-test-id='navigation-client' onClick={this.NavigationClient} sx={{ fontSize: '24px' }}>Clients</Typography>
                            <KeyboardArrowRight />
                            <Typography sx={{ fontSize: '24px', fontWeight: 'bolder' }}>{this.state.clientName}</Typography>
                        </Box>
                        <Box sx={{ padding: '4rem 8.6rem' }} >
                            <Box bgcolor={'white'} onClick={this.handleClientContentManagement} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px', borderRadius: '8px', marginBottom: '1rem', cursor: "pointer" }}>
                                <div>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Client content management</Typography>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Customize the client portal</Typography>
                                </div>

                                <KeyboardArrowRight />
                            </Box>

                            <Box bgcolor={'white'} onClick={this.handleClientSignupEmail} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px', borderRadius: '8px', marginBottom: '1rem', cursor: "pointer" }}>
                                <div>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Email Sign up</Typography>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Customize user email</Typography>
                                </div>

                                <KeyboardArrowRight />
                            </Box>

                            <Box data-test-id='course-link' onClick={this.handleCourseClick} bgcolor={'white'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px', borderRadius: '8px', marginBottom: '1rem', cursor: "pointer" }}>
                                <div>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Courses and packages</Typography>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Assign packages and learning paths</Typography>
                                </div>

                                <KeyboardArrowRight />
                            </Box>

                            <Box bgcolor={'white'} onClick={this.handleClients} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px', borderRadius: '8px', marginBottom: '1rem', cursor: "pointer" }}>
                                <div>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Users</Typography>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Manage user access</Typography>
                                </div>

                                <KeyboardArrowRight />
                            </Box>

                            <Box onClick={this.NavigationClientProfile} data-test-id="client-profile" bgcolor={'white'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 20px', borderRadius: '8px', marginBottom: '1rem', cursor: "pointer" }}>
                                <div>
                                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{this.state.clientName}'s Profile</Typography>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Company profile</Typography>
                                </div>

                                <KeyboardArrowRight />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box sx={webStyles.footerBlock}>
                    <Box sx={webStyles.leftFooter}>
                        <Box>Terms and Conditions </Box>
                        <Box>Pravicy Policy</Box>
                        <Box></Box>
                    </Box>
                    <Box>
                        <Box>© 2025 Thinkspike. All Rights Reserved</Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
    mainWrapper: {
        backgroundColor: '#f6f6f6',
        flex: 1,
        flexDirection: 'col',

    },
    mainBlock: {
        height: "calc(100vh - 72px)",
        display: 'flex',
        width:'100%',
        flexDirection: 'row',
        backgroundColor: '#f6f6f6',
    },
    rightSection: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
    },
    footerBlock: {
        display: 'flex',
        padding: '24px 52px 24px 52px',
        justifyContent: 'space-between',
        flexDirection: 'row',
        color: '#fff',
        backgroundColor: '#1C1C1C',
    },
    leftFooter: {
        gap: '24px',
        display: 'flex',
        flexGrow: 1
    },
}
// Customizable Area End
