import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../../packages/framework/src/BlockComponent";
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from 'dayjs';
const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    sortBy: string;
    // Customizable Area Start
    searchQuery: string;
    sortOrder:string,
    filteredAccreditations: any;
    filteredLearningPaths: any;
    selectedAccreditationToAssign: any;
    selectedAccreditation: any;
    userProcessData: any;
    selectedCourses: any;
    selectedLearningPaths: any;
    isViewMode: boolean;
    selectedTab: string;
    anchorElAccrediatation: any;
    anchorElCourse: any;
    showActionPopup: boolean,
    isCreateAccreditationModalOpen: boolean,
    isExportModalOpen: boolean,
    dateRange: any;
    startDate: null;
    selectedDate: Dayjs | null;
    endDate: null;
    isDeleteAccreditationModalOpen: boolean,
    titleError: string,
    updatePackageModal: boolean;
    updatedPackage: any;
    currentPackage: any;
    updatedPackageName: string;
    userAnchorEl: any
    courseSearchQuery: string;
    accreditationSearchQuery: string;
    courseData: any[];
    isCloning: boolean;
    title: string;
    description: string;
    showCourseActionPopup: boolean;
    selectedCourse: any;
    accreditationDropdown: any[];
    isCreatingAccreditation: boolean;
    loading: boolean;
    startPickerOpen: boolean;
    endPickerOpen: boolean;
    unFilteredAccreditations: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminAccreditationController extends BlockComponent<
    Props,
    S,
    SS/*  */
> {
    // Customizable Area Start
    getAccreditationsApiCallId: string;
    getUserProcessApiCallId: string;
    getLearningPathsApiCallId: string;
    downloadCSVApiCallId: string;
    getCoursesApiCallId: string;
    courseSearchQueryApiCallId: string;
    accreditationSearchQueryApiCallId: string;
    createAccreditationApiCallId: string;
    updateAccreditationApiCallId: string;
    deleteAccreditationsApiCallId: string;
    publishCourseApiCallId: string;
    deleteCourseApiCallId: string;
    accreditationUsers = [
        {
            id: 1,
            username: 'John Mehta',
            client: 'MTV',
            date: '24.3.2024',
            status: 90
        },
        {
            id: 2,
            username: 'Danny cork',
            client: 'MTV',
            date: '24.3.2024',
            status: 60
        },
        {
            id: 3,
            username: 'Tom Terra',
            client: 'MTV',
            date: '24.3.2024',
            status: 100
        },
        {
            id: 4,
            username: 'Cork Jef',
            client: 'MTV',
            date: '24.3.2024',
            status: 50
        },
        {
            id: 5,
            username: 'Tommy besof',
            client: 'MTV',
            date: '24.3.2024',
            status: 80
        }
    ]
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            sortBy: 'A-Z',
            // Customizable Area Start
            loading: false,
            searchQuery: '',
            sortOrder:'Relevance',
            filteredAccreditations: [],
            filteredLearningPaths: [],
            accreditationDropdown: [],
            selectedAccreditationToAssign: null,
            selectedAccreditation: null,
            userProcessData: null,
            selectedCourses: [],
            selectedLearningPaths: [],
            isViewMode: false,
            selectedTab: 'about',
            anchorElAccrediatation: {},
            anchorElCourse: {},
            showActionPopup: false,
            isCreateAccreditationModalOpen: false,
            isExportModalOpen: false,
            dateRange: [null, null],
            startDate: null,
            selectedDate: dayjs(),
            endDate: null,
            startPickerOpen: false,
            endPickerOpen: false,
            isDeleteAccreditationModalOpen: false,
            titleError: '',
            updatePackageModal: false,
            updatedPackageName: '',
            currentPackage: null,
            userAnchorEl: null,
            courseSearchQuery: '',
            accreditationSearchQuery: '',
            updatedPackage: null,
            courseData: [],
            isCloning: false,
            title: '',
            description: '',
            showCourseActionPopup: false,
            selectedCourse: null,
            isCreatingAccreditation: false,
            unFilteredAccreditations: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.getAccreditationsApiCallId = '';
        this.getUserProcessApiCallId = '';
        this.getLearningPathsApiCallId = '';
        this.downloadCSVApiCallId = '';
        this.getCoursesApiCallId = '';
        this.courseSearchQueryApiCallId = '';
        this.accreditationSearchQueryApiCallId = '';
        this.createAccreditationApiCallId = '';
        this.updateAccreditationApiCallId = '';
        this.deleteAccreditationsApiCallId = '';
        this.publishCourseApiCallId = '';
        this.deleteCourseApiCallId = '';
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.accreditationSearchQueryApiCallId:
                    case this.getAccreditationsApiCallId: this.handleAccreditationsResponse(responseJson);
                        break;
                    case this.getUserProcessApiCallId: this.handleUserProcessResponse(responseJson);
                        break;
                    case this.getLearningPathsApiCallId: this.handleLearningPathsResponse(responseJson);
                        break;
                    case this.createAccreditationApiCallId: this.handleCreateAccreditationResponse(responseJson);
                        break;
                    case this.updateAccreditationApiCallId: this.handleUpdateAccreditationResponse(responseJson);
                        break;
                    case this.deleteCourseApiCallId:
                    case this.deleteAccreditationsApiCallId: this.handleDelete(responseJson);
                        break;
                    case this.publishCourseApiCallId: this.handlePublishCourseResponse(responseJson);
                        break;
                    case this.downloadCSVApiCallId: this.downloadCsvFile(responseJson);
                    case this.courseSearchQueryApiCallId:
                    case this.getCoursesApiCallId: this.handleGetCoursesResponse(responseJson);
                        break;
                }
            }
            this.setState({ loading: false });
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getAccreditations();
        this.getLearningPaths();
        this.getCourses();
    }

    viewAccreditation = (accreditation: any, showUsers: boolean = false) => {
        this.setState({ selectedTab: showUsers ? 'users' : 'about', isViewMode: true, showActionPopup: false, anchorElAccrediatation: null })
    }

    handleCloseStartPopover() {
        this.setState({
            startPickerOpen: false,
        });
    }

    handleSortChange = (event: any) => {
        const sortedBy = event.target.value as string;
        let sortedData = [...this.state.userProcessData];
        if (sortedBy === 'A-Z') {
            sortedData.sort((a: any, b: any) =>
                a.name.localeCompare(b.name)
            );
        } else if (sortedBy === 'Z-A') {
            sortedData.sort((a: any, b: any) =>
                b.name.localeCompare(a.name)
            );
        } else if (sortedBy === 'status-asc') {
            sortedData.sort((a: any, b: any) =>
                this.getUserStatusValue(a)- this.getUserStatusValue(b)
            );
        } else if (sortedBy === 'status-desc') {
            sortedData.sort((a: any, b: any) =>
                this.getUserStatusValue(b) - this.getUserStatusValue(a)
            )
        } else if (sortedBy === 'old-new') {
            sortedData.sort((a: any, b: any) =>
                new Date(a.data).getTime() - new Date(b.date).getTime()
            );
        } else if (sortedBy === 'new-old') {
            sortedData.sort((a: any, b: any) =>
                new Date(b.date).getTime() - new Date(a.date).getTime()
            );
        }
        this.setState({ userProcessData: sortedData, sortBy:sortedBy });
    };


    handleCloseEndPopover() {
        this.setState({
            endPickerOpen: false,
        });
    }

    handleSortOrderUpdate = (event: any) => {
        this.setState({ sortOrder: event.target.value }, this.updateFilteredAccreditation);
    };

    updateFilteredAccreditation = () => {
        const { filteredAccreditations, sortOrder } = this.state;
        filteredAccreditations.sort((firstAccr: any, secondAccr: any) => {
            switch (sortOrder) {
                case "a-z":
                    return firstAccr.attributes.title.localeCompare(secondAccr.attributes.title);

                case "z-a":
                    return secondAccr.attributes.title.localeCompare(firstAccr.attributes.title);

                case "old-to-new":
                    return dayjs(firstAccr.attributes.created_at).valueOf() - dayjs(secondAccr.attributes.created_at).valueOf();

                case "new-to-old":
                    return dayjs(secondAccr.attributes.created_at).valueOf() - dayjs(firstAccr.attributes.created_at).valueOf();
                case 'low-to-high':
                    return firstAccr.attributes.courses.length - secondAccr.attributes.courses.length; 
                case 'high-to-low':
                    return secondAccr.attributes.courses.length - firstAccr.attributes.courses.length;
                case "Relevance":
                default:
                    return 0;
            }
        });
        if (sortOrder === "Relevance") {
            this.setState({ filteredAccreditations : [... this.state.unFilteredAccreditations] });    
        }else{
        this.setState({ filteredAccreditations });
        }
    };

    showViewMode = () => this.state.selectedAccreditation && this.state.isViewMode;

    onTabSwitch = (tab: string) => this.setState({ selectedTab: tab });

    isSelectedTab = (tab: string) => this.state.selectedTab === tab;

    onBackPress = () => { this.setState({ selectedAccreditation: null, isViewMode: false, title: '', description: '', anchorElAccrediatation: null, updatePackageModal: false, isCreateAccreditationModalOpen: false }) };

    onSaveAccreditation = () => {
        if (this.isSelectedTab('users')) {
            this.openExportModal();
        } else {
            this.updateAccreditation()
        }
    }

    getUserStatusString = (user: any) => user.progress == 'Not Started' ? '0% Completed' : user.progress + '% Completed';

    getUserStatusValue = (user: any) => user.progress == 'Not Started' ? 0 : user.progress;

    formatDate = (dateString: string) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear()).slice(-2);
        return `${day}.${month}.${year}`;
    };

    getCourses = async () => {
        this.setState({ loading: true });
        this.getCoursesApiCallId = await apiCall({
            method: "GET",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `/bx_block_admin/courses`,
        });
    }

    handleGetCoursesResponse = (response: any) => {
        this.setState({
            courseData: response.data.map((course: any) => ({
                id: course.id,
                title: course.attributes.title,
                description: course.attributes.description,
                duration: course.attributes.duration,
                author_name: course.attributes.author_name,
                images: course.attributes.thumbnail_image,
                course_module_count: course.attributes.course_modules.length,
                status: course.attributes.status || null,
            }))
        })
    }

    handleLearningPathsResponse = (response: any) => {
        this.setState({ filteredLearningPaths: response?.data })
    }

    getCourseIds = (courses: any) => courses.map((course: any) => course.id);

    handleLearningPathToggle = (learningPathId: any, courses: any) => {
        let updatedLearningPaths = this.state.selectedLearningPaths;
        let updatedCourses = this.state.selectedCourses;
        if (this.state.selectedLearningPaths?.includes(learningPathId)) {
            updatedLearningPaths = this.state.selectedLearningPaths.filter((item: any) => item != learningPathId);
            updatedCourses = this.state.selectedCourses.filter((item: any) => this.getCourseIds(courses).includes(item));
        } else {
            updatedLearningPaths.push(learningPathId);
            courses.forEach((course: any) => {
                if (!updatedCourses.includes(course.id)) {
                    updatedCourses.push(course.id)
                }
            })
        }
        this.setState({ selectedLearningPaths: updatedLearningPaths, selectedCourses: updatedCourses })
    }

    handleCourseToggle = (learningPathId: any, courseId: any) => {
        let updatedCourses = this.state.selectedCourses;
        if (updatedCourses.includes(courseId)) {
            updatedCourses = this.state.selectedCourses.filter((course_id: any) => course_id != courseId)
        } else {
            updatedCourses.push(courseId);
        }
        this.setState({ selectedCourses: updatedCourses })
    }

    handleAccreditationSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ accreditationSearchQuery: event.target.value }, async () => {
            if (this.state.accreditationSearchQuery !== "") {
                this.accreditationSearchQueryApiCallId = await apiCall({
                    method: "GET",
                    navigation: this.props.navigation,
                    token: localStorage.getItem("token"),
                    endPoint: `/bx_block_profile/accreditations?query=${this.state.accreditationSearchQuery}`,
                })
            } else if (this.state.accreditationSearchQuery === "") {
                this.getAccreditations();
            }
        })
    }

    handleDelete = (responseJson: any) => {
        if (responseJson.message) {
            toast.success(responseJson.message);
            this.setState({ isDeleteAccreditationModalOpen: false });
            this.getAccreditations();
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    onSubmitAccreditation = () => {
        if (this.state.selectedAccreditation) {
            this.updateAccreditation();
        }
        else {
            this.createAccreditation();
        }
    }

    handleAddCourses = () => {
        if (!this.state.title?.length) {
            toast.error('Please add title to the accrediatation');
        } else if (!this.state.description?.length) {
            toast.error('Please add description to the accrediatation');
        } else {
            this.setState({ updatePackageModal: true })
        }
    }

    updateAccreditation = async () => {
        const courseIds: any[] = [];
        this.state.selectedAccreditation.attributes.courses?.forEach((course: any) => {
            courseIds.push(course.id);
        });
        const reqBody = {
            accreditation: {
                title: this.state.title,
                description: this.state.description,
                course_ids: this.state.selectedCourses
            }
        };
        this.updateAccreditationApiCallId = await apiCall({
            method: "PUT",
            navigation: this.props.navigation,
            contentType: 'application/json',
            token: localStorage.getItem("token"),
            body: reqBody,
            endPoint: `/bx_block_profile/accreditations/${this.state.selectedAccreditation.id}`,
        });
    }

    updateDropdownSelected = (path: any) => {
        this.setState({ selectedAccreditationToAssign: path.value })
    }

    handleAccreditationsResponse = (responseJson: any) => {
        if (responseJson.data) {
            this.setState({ filteredAccreditations: responseJson.data,unFilteredAccreditations : [ ... responseJson.data] })        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    handleCreateAccreditationResponse = (responseJson: any) => {
        if (responseJson.data) {
            this.getAccreditations();
            this.setState({ isCreatingAccreditation: false, updatePackageModal: false, isCreateAccreditationModalOpen: false });
            toast.success('Accredditation created successfully !')
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    handleUpdateAccreditationResponse = (responseJson: any) => {
        if (responseJson.data) {
            toast.success('Accreditation updated successfully !');
            this.getAccreditations();
            this.onBackPress();
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    closeCreateAccreditationModal = () => {
        this.setState({ isCreateAccreditationModalOpen: false, isCloning: false })
    }

    openExportModal = () => {
        this.setState({ isExportModalOpen: true })
    }

    onCloseExportModal = () => {
        this.setState({ isExportModalOpen: false })
    }

    openCreateAccreditationModal = () => {
        this.setState({ isCreatingAccreditation: true, isCreateAccreditationModalOpen: true, title: '', description: '', selectedAccreditation: null, selectedCourses: [], selectedLearningPaths: [] });
    }

    openUpdatePackageModal = () => {
        this.setState({ updatePackageModal: true })
    }

    closeUpdatePackageModal = () => {
        this.setState({ updatePackageModal: false, isCloning: false, selectedCourses: [], selectedLearningPaths: [] })
    }

    getAccreditations = async () => {
        this.setState({ loading: true });
        this.getAccreditationsApiCallId = await apiCall({
            method: "GET",
            contentType: "application/json",
            endPoint: `bx_block_profile/accreditations`,
            token: localStorage.getItem("token"),
        });
    }

    getLearningPaths = async () => {
        this.setState({ loading: true });
        this.getLearningPathsApiCallId = await apiCall({
            method: "GET",
            contentType: "application/json",
            endPoint: `bx_block_categories/learning_paths`,
            token: localStorage.getItem("token"),
        });
    }

    exportUsersCSV = async () => {
        if (!this.state.startDate || !this.state.endDate) {
            toast.error('Please select date range');
            return
        }
        this.downloadCSVApiCallId = await apiCall({
            method: "GET",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `bx_block_profile/accreditations/export_user_process_accreditation?accreditation_id=${this.state.selectedAccreditation.id}&from_date=${this.state.startDate}&to_date=${this.state.endDate}`,
        });
    };

    downloadCsvFile = (responseJson: any) => {
        if (responseJson?.csv_url) {
            const link = document.createElement("a");
            link.href = baseURL + responseJson.csv_url;
            link.download = "filename.csv";
            document.body.appendChild(link);
            link.click();
            this.setState({ isExportModalOpen: false });
            toast.success('Report generate successfully !')
            document.body.removeChild(link);
        } else {
            toast.error('Something went wrong !');
        }
    };

    createAccreditation = async () => {
        if (!this.state.title.length) {
            this.setState({ titleError: 'Title can not be blank.' });
            return;
        }
        this.createAccreditationApiCallId = await apiCall({
            method: "POST",
            contentType: "application/json",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `bx_block_profile/accreditations`,
            body: {
                accreditation: {
                    title: this.state.title,
                    description: this.state.description,
                    course_ids: this.state.selectedCourses
                }
            },
        });
    }
    
    /* istanbul ignore next */
    handleAccrediatationPopoverClick = (event: any, accreditation: any) => {
        this.setState({ anchorElAccrediatation: event?.currentTarget, showActionPopup: true, selectedAccreditation: accreditation, title: accreditation.attributes.title, description: accreditation.attributes.description, isCloning: false });
        const courseIds: any[] = [];
        accreditation.attributes.courses.forEach((course: any) => {
            courseIds.push(course.id);
        })
        this.setState({ selectedCourses: courseIds });
        this.setState({ userProcessData: null })
        this.getUserProcess(accreditation);
        event?.stopPropagation?.();
    }
    
    getTabName = () => this.isSelectedTab('about') ? 'About' : 'Users'

    getUserProcess = async (accreditation: any) => {
        this.getUserProcessApiCallId = await apiCall({
            method: "GET",
            contentType: "application/json",
            endPoint: `bx_block_profile/accreditations/user_process_accreditation?accreditation_id=${accreditation?.id}`,
            token: localStorage.getItem("token"),
        });
    }

    handleUserProcessResponse = async (responseJson: any) => {
        if (responseJson.accreditation_id) {
            this.setState({ userProcessData: responseJson.user_data },()=>{
                this.handleSortChange({target:{value:'A-Z'}});
            })
        } else if (responseJson.errors || responseJson.error) {
            toast.error('Something went wrong');
        }
    }

    handleAccrediatationPopoverClose = () => {
        this.setState({ anchorElAccrediatation: null, showActionPopup: false, isCloning: false });
    }

    handleCoursePopoverClick = (event: any, item = {}) => {
        this.setState({ anchorElCourse: event?.currentTarget, showCourseActionPopup: true, selectedCourse: item });
    }

    handleCoursePopoverClose = () => {
        this.setState({ anchorElCourse: {}, showCourseActionPopup: false });
    }

    handlePublishCourseResponse = (responseJson: any) => {
        if (responseJson.message) {
            toast.success(responseJson.message);
            this.getAccreditations();
        } else if (responseJson.errors || responseJson.error) {
            toast.error('Something went wrong');
        }
    }

    handlePublishCourse = async (course: any) => {
        this.handleCoursePopoverClose();
        const updatedBody = {
            "data": {
                "type": "courses",
                "attributes": {
                    "status": "published",
                }
            }
        }
        this.publishCourseApiCallId = await apiCall({
            method: "PATCH",
            body: updatedBody,
            contentType: "application/json",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `/bx_block_admin/courses/${course.id}`,
        })

    }

    handleUpdateCourse = (courseId: number) => {
        this.props.navigation.navigate('UpdateCourse', { path: { courseId } })
        this.handleCoursePopoverClose();
    }

    handleCloneCourse = () => {
        this.handleCoursePopoverClose();
    }

    handleDeleteCourse = async (course: any) => {
        this.handleCoursePopoverClose();
        const id = course.id;
        this.deleteCourseApiCallId = await apiCall({
            method: "DELETE",
            contentType: "application/json",
            endPoint: `bx_block_admin/courses/${id}`,
            token: localStorage.getItem("token"),
        });
    }

    handleAssignCourses = (e: React.MouseEvent<HTMLElement>, accreditation: any) => {
        e?.stopPropagation?.();
        this.openUpdatePackageModal();
        this.setState({
            showActionPopup: false,
        })
    }

    handleDeleteAccrediatation = (e: React.MouseEvent<HTMLElement>, id: any) => {
        e?.stopPropagation?.();
        this.setState({ isDeleteAccreditationModalOpen: true, showActionPopup: false })
    }

    onDeletePathConfirm = async (isDelete: boolean) => {
        if (isDelete) {
            const id = this.state.selectedAccreditation.id;
            this.deleteAccreditationsApiCallId = await apiCall({
                method: "DELETE",
                contentType: "application/json",
                endPoint: `bx_block_profile/accreditations/${id}`,
                token: localStorage.getItem("token"),
            });
        } else {
            this.setState({ isDeleteAccreditationModalOpen: false })
        }
    }

    navigateToCourseCreation = () => {
        this.props.navigation.navigate('CourseCreation')
    }
    // Customizable Area End
}
