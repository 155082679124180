import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
import { createRef, RefObject } from 'react'
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  welcomeContent: any;
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  username: string | null;
  currentPage:number,
  totalPages:number,
  allCourses:any,
  allLearningPath:any,
  selectedLearningPathId:number,
  totalCourses:any,
  isLoadingCourses:boolean
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  welcomeContentApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  allCoursesApiCallId:string = "";
  learningPathDataApiCallId:string='';
  getCoursePaginationApiCallId:string = '';
  private scrollContainerRef: RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      type: "",
      dashboardData: [],
      welcomeContent:{},
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      username: "",
      currentPage:1,
      totalPages:8,
      allCourses:[],
      allLearningPath:[],
      selectedLearningPathId:0,
      totalCourses: null,
      isLoadingCourses:false,
    };
    this.scrollContainerRef = createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.updateUserName();
    this.getWelcomeContent();
    this.getAllCourses()
    this.getLearningPathData()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson) {
        switch (webApiRequestCallId) {
          case this.apiDashboardItemCallId: this.handleDashboardItemCall(webResponseJson);
            break;
          case this.allCoursesApiCallId: this.handleCoursesResponse(webResponseJson)
          break;
          case this.welcomeContentApiCallId:
            return this.handleResponseData(webResponseJson)
          case this.learningPathDataApiCallId:
            return this.handleLearningPathData(webResponseJson)
          case this.getCoursePaginationApiCallId:
            return this.handleGetCoursePagination(webResponseJson)
          default:
            break;
        }
      } else if (webResponseJson.errors) {
        this.setState({
          errorMsg: webErrorReponse,
          loading: false
        });
        toast.error(webErrorReponse);
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleDashboardItemCall = (webResponseJson: any) => {
   
  }
  handleLearningPathData=(res:any)=>{
    this.setState({allLearningPath:res.learning_paths})
  }
  handleGetCoursePagination =(res:any)=>{
    if(res.data){
      this.handleCoursesResponse(res)
    } else if(res.learning_path){
      const currentLearningPath = res.learning_path
      const newCourses = currentLearningPath.courses.map((item:any)=>({id:item.id,attributes:{...item}}))
      const newResponse = {meta:res.learning_path.meta,data:newCourses}
      this.handleCoursesResponse(newResponse)
    }
    this.setState({isLoadingCourses:false})
  }

  handleResponseData = (webResponseJson: any) => {
    const isValid = this.isValidData(webResponseJson)
    if (isValid) {
      this.setState({
        welcomeContent: webResponseJson?.data?.[0].attributes,
        loading: false
      });
    } else {
      toast.error("Invalid API response");
      this.setState({ loading: false });
    }
  }

  isValidData = (webResponseJson: any) => {
      const requiredFields = ['context', 'company_logo_context', 'heading', 'company_logo.url'];
    const isValid = requiredFields.every(field => {
      const keys = field.split('.');
      let value = webResponseJson?.data?.[0]?.attributes;
      for (let key of keys) {
        value = value ? value[key] : undefined;
      }
      return value !== undefined;
    });
    return isValid;
  }

  updateUserName = async () => {
    const username = localStorage.getItem("u");
    this.setState({ username: username })
  }
  handlePageChange = (page:number) => {
    const totalPages = this.getTotalPages(); 
    if (page > 0 && page <= totalPages) {
      this.setState({ currentPage: page },this.getCourseData); 
    }
  };

  getTotalPages = () => {
    return this.state.totalPages
  };
  
  getWelcomeContent = async () => {
    try {
      this.setState({ loading: true });
      this.welcomeContentApiCallId = await apiCall({
        method: "GET",
        contentType: "application/json",
        endPoint: `bx_block_content_management/landing_pages/company_logo_content`,
        token: localStorage.getItem("token"),
      });
    } catch (error) {
      toast.error("Failed to fetch welcome content");
      this.setState({ loading: false });
    }
  }
  
  getAllCourses = () => {
    let token = localStorage.getItem('token')
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      "token":token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage) 
    );
    this.allCoursesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/landing_pages?per_page=5&page=${this.state.currentPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  
  /* istanbul ignore next */
  handleCoursesResponse = (response: any) => {
    this.setState({ totalPages: response.meta?.total_pages });
    const courses = response.data?.map((course: any) => {
      return {
        id: course.id,
        title: course.attributes.title,
        description: course.attributes.description,
        duration: course.attributes.duration,
        imageUrl: course.attributes.thumbnail_image?.url ? course.attributes.thumbnail_image.url : null,
        lessonCount: course.attributes.lesson_count,
        assign_course_id: course.attributes.assign_course_id
      };
    });
    this.setState({ allCourses: courses });

    if (!this.state.totalCourses) {
      this.setState({ totalCourses: response.meta?.total_count ?? 0 })
    }
  }

  getLearningPathData = async ()=>{
    this.learningPathDataApiCallId = await apiCall({
      method:"GET",
      endPoint:`bx_block_content_management/landing_pages/get_courses?per_page=2000&page=1`,
      contentType: 'application/json',
      token:localStorage.getItem('token')
    })
  }

  handleSelectLearningPath = (id:number)=>{
    this.setState({selectedLearningPathId:id})
    this.handlePageChange(1)
  }
  
  getCourseData =async ()=>{
    const {selectedLearningPathId} = this.state
    const endPoint = selectedLearningPathId === 0 ?
      `bx_block_content_management/landing_pages?per_page=5&page=${this.state.currentPage}`
      :
      `bx_block_content_management/landing_pages/get_paginated_courses?learning_path_id=${selectedLearningPathId}&per_page=5&page=${this.state.currentPage}`
    this.getCoursePaginationApiCallId = await apiCall({
      method:"GET",
      endPoint:endPoint,
      contentType: 'application/json',
      token:localStorage.getItem('token')
    })
    this.setState({isLoadingCourses:true})
  }
  handleScroll = (direction: 'left' | 'right')=> () => {
    const container = document.getElementById('learning-path-buttons-container');
    if (container) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  }
  // Customizable Area End
}
