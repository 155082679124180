import React from 'react';

// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  styled,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Chip
} from '@mui/material';
import Sidebar from '../../../components/src/sidebar/Sidebar.web';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CourseThumbnail } from './assets';
import { KeyboardArrowRight,Search,ExpandMore,TimerOutlined } from '@mui/icons-material';
const baseURL = require("../../../framework/src/config.js").baseURL;
import HtmlReactParser from 'html-react-parser';
import DOMPurify from 'dompurify';
// Customizable Area End

import CoursesAndPackagesController, {
  Props,
} from './CoursesAndPackagesController';


export default class CoursesAndPackages extends CoursesAndPackagesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHTML = (data: any) => {
    return data.includes('<p') ? HtmlReactParser(data.replaceAll('<p>', '').replaceAll('</p>', '')) : data;
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    
    return (
      <>
            <Box sx={webStyles.mainWrapper}>
                <Box sx={webStyles.mainBlock}>
                    <Box>
                        <Sidebar current='clients' navigation={this.props.navigation}/>
                    </Box>
                    <Box sx={webStyles.rightSection}>
                        <Box sx={{ display: 'flex', padding: '1rem 2rem', alignItems: 'center', columnGap: '10px', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography data-test-id='nav-client' onClick={this.NavigationClient} sx={{ fontSize: '24px', cursor: "pointer" }}>Clients</Typography>
                                <KeyboardArrowRight />
                                <Typography data-test-id='client-text' onClick ={this.handleClientNavigation} sx={{ fontSize: '24px', cursor: "pointer" }}>{this.state.clientName}</Typography>
                                <KeyboardArrowRight />
                                <Typography sx={{ fontSize: '24px', fontWeight: 'bolder' }}>Courses and Packages</Typography>
                            </div>
                        </Box>

                        <div style={{padding:'1rem 2rem',display:'flex',alignItems:'center',columnGap:'10px',justifyContent:'space-between'}}>
                            <TextField
                                data-test-id='search'
                                value={this.state.searchTerm}
                                onChange={this.handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder='Search packages'
                                sx={{
                                    width: '60%',
                                    backgroundColor: 'white',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '4px',
                                        '& input': {
                                            padding: '12px',
                                        },
                                    },
                                }}
                            />
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography>Filter</Typography>
                                    <FilterListIcon style={{ marginLeft: '8px' }} />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '20px' }}>
                                    <Typography>Sort by:</Typography>
                                    <Select
                                        data-test-id="sortby"
                                        value={this.state.sortOrder}
                                        onChange={this.handleSortChange}
                                        defaultValue="relevance"
                                        disableUnderline
                                        variant='standard'
                                        sx={{
                                            fontWeight:"700",
                                            color:'#334155',
                                            paddingLeft: '10px',
                                            width: '120px',
                                            backgroundColor: 'transparent',
                                            '& .MuiSelect-icon': {
                                                color: 'gray',
                                            },
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: 'transparent',
                                                border: '1px solid #ccc',
                                                '& input': {
                                                    backgroundColor: 'transparent',
                                                    padding: ''
                                                },
                                            },
                                        }}
                                    >
                                        <MenuItem value="z-a">Z-A</MenuItem>
                                        <MenuItem value="a-z">A-Z</MenuItem>
                                        <MenuItem value="relevance">Relevance</MenuItem>
                                    </Select>
                                </div>
                            </div>
                            
                        </div>

                        <div style={{display:'flex',padding:'1rem 2rem',justifyContent:'space-between'}}>
                            <div>
                                <CustomButtons
                                    data-test-id="package-btn"
                                    onClick={this.handlePackagesClick}
                                    sx={{
                                        color: this.state.showingPackages ? 'white' : 'black',
                                        backgroundColor: this.state.showingPackages ? 'black' : 'transparent', 
                                    }}
                                >
                                    Package
                                </CustomButtons>
                                <CustomButtons
                                    data-test-id="learning-path-btn"
                                    onClick={this.handleLearningPathClick}
                                    sx={{
                                        color: this.state.showingLearningPaths ? 'white' : 'black',
                                        backgroundColor: this.state.showingLearningPaths ? 'black' : 'transparent', 
                                    }}
                                >
                                    Learning Paths
                                </CustomButtons>
                                
                            </div>

                            <BlackButton
                                 sx={{
                                    opacity: this.state.isAssignButtonDisable ? 0.2 : 1,
                                    color: 'white !important',
                                  }}
                                disabled={this.state.isAssignButtonDisable}
                                data-test-id='assign-btn'
                                onClick={this.handleAssignPackageClick}
                            >Assign Selected</BlackButton>
                        </div>

                        {this.state.showingPackages && (

                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '1rem', padding: '1rem 2rem' }}>
                                {this.state.filteredPackages.map((pkgs: any) => (
                                    <Box
                                        key={pkgs.id}
                                        bgcolor={'white'}
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            padding: '10px 20px',
                                            borderRadius: '8px',
                                            width: 'calc(50% - 1rem)',
                                            boxSizing: 'border-box',
                                            marginBottom: '0.5rem',
                                        }}
                                    >
                                        <div>
                                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
                                                {pkgs.name}
                                            </Typography>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
                                                • {pkgs.coursesCount} Courses
                                            </Typography>
                                        </div>

                                        <StyledCheckbox
                                            data-test-id='pkgs-check'
                                            checked={this.state.selectedPackages.includes(pkgs.id)}
                                            onChange={() => this.handleCheckboxChange(pkgs.id)}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        )}

                        {this.state.showingLearningPaths && (
                            <Box sx={{ padding: '1rem 2rem' }}>
                                <div>
                                    {this.state.filteredLearningPaths.map((learningPath: any) => (
                                        <StyledAccordion key={learningPath.id}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls={`panel-${learningPath.id}-content`}
                                                id={`panel-${learningPath.id}-header`}
                                                sx={{
                                                    flexDirection: 'row-reverse',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    columnGap: '1rem'
                                                }}
                                            >
                                                <Typography sx={{ flexGrow: 1, display: 'flex', alignItems: 'center',fontWeight:'700' }}>
                                                    {learningPath.attributes.title}
                                                </Typography>
                                                <StyledCheckbox
                                                    data-test-id="learning-path"
                                                    indeterminate={this.state.selectedLearningPaths.some(
                                                        (path: any) =>
                                                          +path.learningPathId === +learningPath.id &&
                                                          path.selectedCourses.length > 0
                                                      )}
                                                      checked={this.state.selectedLearningPaths.some(
                                                        (path: any) =>
                                                          +path.learningPathId === +learningPath.id &&
                                                          path.selectedCourses.length === learningPath.attributes.courses.length
                                                      )}
                                                    onChange={() => this.handleLearningPathToggle(learningPath.id, learningPath.attributes.courses)}
                                                />
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography variant="body2" sx={{ padding: '0rem 2.1rem' }}>
                                                    {this.renderHTML(learningPath.attributes.description)}
                                                </Typography>
                                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '20px', margin: '20px' }}>
                                                    {learningPath.attributes.courses.map((course: any) => (
                                                        <Grid item xs={3} key={course.id} margin={'16px'}>
                                                            <Box sx={webStyles.courseCard}>
                                                                <BoxThumbnail sx={{ position: 'relative' }}>
                                                                 <img src={course.images?.url || CourseThumbnail} alt={course.title} />
                                                                    <Box display="flex"
                                                                        justifyContent="space-between"
                                                                        alignItems={"center"}
                                                                        padding={"8px"}>
                                                                        <Chip style={{ borderRadius: '4px',position:'absolute',zIndex:'2',top: '10px',left:'15px',
                                                                                 backgroundColor: this.statusColor(course) }} label={course.status || 'draft'} color="primary" />

                                                                        {course.status === 'published' && <StyledCheckbox
                                                                            data-test-id={`course-check-${course.id}`}
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '10px',
                                                                                right: '10px',
                                                                                zIndex: '2',
                                                                            }}
                                                                            checked={
                                                                                this.state.selectedLearningPaths.some((path: any) =>
                                                                                    +path.learningPathId === +learningPath.id && path.selectedCourses.includes(course.id)
                                                                                )
                                                                            }
                                                                            onChange={() => this.handleCourseToggle(learningPath.id, course.id)}
                                                                        />}</Box>
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            width: '100%',
                                                                            justifyContent: 'space-between',
                                                                            alignItems: 'center',
                                                                            padding: '10px',
                                                                            height: '65px',
                                                                            boxSizing: 'border-box'
                                                                        }}
                                                                    />
                                                                    <Typography
                                                                        style={{
                                                                            height:'50px',
                                                                            display: 'flex',
                                                                            color: '#fff',
                                                                            padding: '10px',
                                                                            fontSize: '14px',
                                                                            alignItems: 'end',
                                                                            zIndex: '2',
                                                                            boxShadow:'inset 0px -53px 25px -20px rgba(0, 0, 0, 0.82)'
                                                                        }}
                                                                    >
                                                                        <TimerOutlined style={{ marginRight: '5px' }} />
                                                                        {course.duration} Hours
                                                                    </Typography>
                                                                </BoxThumbnail>
                                                                <Box sx={webStyles.courseCardDescription}>
                                                                    <Box sx={{ fontSize: '16px', color: 'black' }}><Typography sx={{
                                                                        display: '-webkit-box',
                                                                        WebkitBoxOrient: 'vertical',
                                                                        overflow: 'hidden',
                                                                        WebkitLineClamp: 2,
                                                                    }}>{this.sanitizeData(course.title)}</Typography></Box>
                                                                    <Box sx={{ fontSize: '12px', color: 'black' }}>{course.lessons} Lessons</Box>
                                                                    <Box sx={{ fontSize: '14px', color: '#8A8A8A' }}>
                                                                        <b style={{ color: '#1A1A1A' }}>You will learn:</b>{' '}
                                                                        <Typography variant="body2" color="text.secondary" sx={{
                                                                            display: '-webkit-box',
                                                                            WebkitBoxOrient: 'vertical',
                                                                            overflow: 'hidden',
                                                                            WebkitLineClamp: 3,
                                                                        }}>
                                                                            {this.sanitizeData(course.description)}
                                                                        </Typography>                                                                    </Box>


                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    ))}

                                                </div>
                                            </AccordionDetails>
                                        </StyledAccordion>
                                    ))}
                                </div>
                            </Box>
                          
                        )}
                    </Box>
                </Box>

                <Box sx={webStyles.footerBlock}>
                    <Box sx={webStyles.leftFooter}>
                        <Box>Terms and Conditions</Box>
                        <Box>Pravicy Policy</Box>
                        <Box></Box>
                    </Box>
                    <Box>
                        <Box>© 2025 Thinkspike. All Rights Reserved</Box>
                    </Box>
                </Box>
            </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledCheckbox = styled(Checkbox)({
    zIndex:10,
    '&.Mui-checked': {
        color: 'black',
        borderRadius: '6px',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
    },
    '&.MuiCheckbox-indeterminate':{
        color:'black'
    }
});
const StyledAccordion = styled(Accordion)({
    border: '1px solid #D6D3D1',
    boxShadow: 'none', 
    marginBottom:'10px'
  });
const BoxThumbnail = styled(Box)({
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '160px',
    "&>img": {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '1'
    }
})

const CustomButtons = styled(Button)({
    color: 'black',
    fontFamily: 'Heebo',
    backgroundColor: 'transparent',
    marginRight: '2rem',
    textTransform: 'none',
    '&:hover': {
        color: 'white',
        backgroundColor: 'black',
    },
})

const BlackButton = styled(Button)({
    color: 'white',
    backgroundColor: 'black',
    fontFamily: 'Heebo',
    textTransform: 'none',
    fontWeight: 400,
    height: 'fit-content',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
    },
  })
const webStyles = {
    courseCard: {
        display: 'flex',
        width: '100%',
        height: '350px',
        maxWidth: '350px',
        gap: '16px',
        flexDirection: 'column',
        boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.08)',
    },
    courseCardDescription: {
        gap: '16px',
        flexDirection: 'column',
        padding: '0px 16px 24px 16px',
        display: 'flex',
    },
    mainWrapper: {
        flex: 1,
        backgroundColor: '#f6f6f6',
        flexDirection: 'col',

    },
    mainBlock: {
        height: "calc(100vh - 72px)",
        display: 'flex',
        width:'100%',
        flexDirection: 'row',
        backgroundColor: '#f6f6f6',
    },
    rightSection: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        overflow: "auto",
    },
    footerBlock: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#1C1C1C',
        justifyContent: 'space-between',
        padding: '24px 52px 24px 52px',
        color: '#fff'
    },
    leftFooter: {
        gap: '24px',
        display: 'flex',
        flexGrow: 1
    },
}
// Customizable Area End
