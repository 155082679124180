// Customizable Area Start
import React from "react";
import { createTheme, styled, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import {
	Box, Button, Typography
} from "@mui/material";
import { imgChevronRight } from './assets';
// Customizable Area End

// Customizable Area Start
import ClientSignupEmailController, {
	Props,
} from "./ClientSignupEmailController";
import Sidebar from "../../../../packages/components/src/sidebar/Sidebar.web";
import AdminFooter from '../../../../packages/components/src/AdminFooter.web';
import CustomEditor from "../../../components/src/CustomEditor.web";
import Loader from "../../../components/src/Loader.web";

const theme = createTheme(
	{
		palette: {
			backgrounds: {
				main: "#FAFAFA",
				light: "#FFFFFF",
				dark: "#000000",
			},
			texts: {
				main: "#0F172A",
				light: "#475569",
				dark: "#334155",
				contrastText: "#1C1C1C",
			},
			danger: {
				main: "#FF7468",
			},
			borders: {
				main: "#D6D3D1",
				light: "#F8FAFC",
			},
		},
	});

const ids = {
	editorTitleContainer: 'editor-title-container',
	editorTitle: 'editor-title',
	editorDescription: 'editor-description',
};
// Customizable Area End


export default class ClientSignupEmail extends ClientSignupEmailController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	// Customizable Area End

	render() {
		// Customizable Area Start
		// Customizable Area End

		return (
			// Customizable Area Start
			<div id="main_block">
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={theme}>
						<Box
							sx={{
								height: "calc(100vh - 72px)",
								display: 'flex',
								width:'100%',
								flexDirection: 'row',
								backgroundColor:'#fafafa',
							}}
						>
							<Box>
								<Sidebar navigation={this.props.navigation} current="clients"/>
							</Box>
							<Main data-test-id="main">
								<Box display="flex" justifyContent="space-between">
									<Box display="flex" alignItems="center" className="breadcrum">
										<Box
											className="client-title"
											data-test-id="client-btn"
											onClick={this.onRedirect}
										>
											Client
										</Box>
										<img src={imgChevronRight} alt="rightAngle" />
										<Box
											className="client-title"
											data-test-id="client-name-btn"
											onClick={this.onRedirectToClient}
										>
											{this.state.clientName}
										</Box>
										<img src={imgChevronRight} alt="rightAngle" />
										<Box className="last-title">Email sign up</Box>
									</Box>
									<Box>
										<SaveButton data-test-id="save-btn" onClick={this.updateContentApi}>Save</SaveButton>
									</Box>
								</Box>
								<Box className="wrapper">
									<Box className="box">
										<Box margin="40px 24px 24px 24px">
											<Box className="logo">
												{this.state.logo && <img src={this.state.logo} alt="ThinkSpike" />}
											</Box>
											<Box className="editor-container">
												{!this.state.loading &&
													<>
														<CustomEditor
															data-test-id="title"
															value={this.state.title}
															placeholder="Enter title here"
															hideBottomBorder={true}
															onChange={this.onTitleChange}
														/>
														<CustomEditor
															data-test-id="description"
															value={this.state.description}
															placeholder="Enter description here"
															hideBottomBorder={true}
															onChange={this.onDescriptionChange}
														/>
													</>
												}
											</Box>
										</Box>
										<Box className="credentials-container">
											<Box className="item">
												<CredentialsTitle>Your email</CredentialsTitle>
												<CredentialsDescription>test@email.com</CredentialsDescription>
											</Box>
											<Box className="item">
												<CredentialsTitle>Temporary password</CredentialsTitle>
												<CredentialsDescription>Password1234</CredentialsDescription>
											</Box>
										</Box>
										<Box className="below-section">
											<Box>
												<Box className="question-text">If you have additional questions please<span>click here</span></Box>
												<Box display="flex" gap="10px">
													<Box className="last-text">THINKSPIKE</Box>
													<Box className="last-text">Privacy Policy</Box>
													<Box className="last-text">Terms & Conditions</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Main>
						</Box>
						<AdminFooter />
					</ThemeProvider>
				</StyledEngineProvider>
			</div>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
const Main = styled(Box)(({ theme }) => ({
	backgroundColor: "#fafafa",
	padding: "20px 30px 10px 15px",
	width: "100%",
	display: "flex",
	flexDirection: "column",
	gap: "20px",
	maxWidth: "calc(100vw - 338px)",
	overflowY: "auto",
	"& .breadcrum": {
		"& img": {
			margin: "0px 10px",
			[theme.breakpoints.down('md')]: {
				margin: "0px 5px",
			}
		}
	},
	"& .client-title": {
		fontFamily: "Heebo-Regular",
		color: "#475569",
		fontSize: "24px",
		cursor: "pointer",
		letterSpacing: "-0.5px",
		[theme.breakpoints.down('md')]: {
			fontSize: "16px",
		}
	},
	"& .last-title": {
		color: "#0F172A",
		fontSize: "24px",
		letterSpacing: "-0.5px",
		fontFamily: "Heebo-Bold",
		[theme.breakpoints.down('md')]: {
			fontSize: "16px",
		}
	},
	"& .wrapper": {
		display: "flex",
		padding: "40px",
		justifyContent: "center",
		alignItems: "center",
		"& .box": {
			backgroundColor: "#fff",
			width: "640px",
			borderRadius: "8px 8px 32px 8px"
		},
		"& .logo": {
			display: "flex",
			justifyContent: "center",
			marginBottom: "40px",
			"& img": {
				maxWidth: "150px",
				maxHeight: "100px",
				objectFit: "cover"
			}
		},
		"& .editor-container": {
			display: 'flex',
			flexDirection: 'column',
			gap: '15px',
		},
		"& .credentials-container": {
			display: 'flex',
			flexDirection: 'column',
			gap: '8px',
			padding: '10px 24px',
			backgroundColor: "#F8FAFC",
			"& .item": {
				display: 'flex',
				justifyContent: 'space-between',
				gap: '24px',
			}
		},
		"& .below-section": {
			display: "flex",
			justifyContent: "center",
			padding: "20px 0px 30px",
			"& .question-text": {
				fontFamily: "Heebo-Regular",
				fontSize: "12px",
				color: "#1C1C1C",
				marginBottom: "20px",
				textAlign: "center",
				"& span": {
					marginLeft: "7px",
					fontFamily: "Heebo-Bold",
				}
			},
			"& .last-text": {
				color: "#64748B",
				fontFamily: "Heebo-Bold",
				fontSize: "14px"
			}
		}
	}
}));

const TextButton = styled(Button)({
	fontFamily: "Heebo-Bold",
	textTransform: "none",
	fontSize: "16px !important",
	color: "black !important",
	backgroundColor: "#FFF",
	padding: "7px 16px !important",
	borderRadius: "4px !important",
	"&:hover": {
		backgroundColor: "#FFF",
	},
});

const SaveButton = styled(Button)({
	marginLeft: "15px !important",
	textTransform: "none",
	fontFamily: "Heebo-Bold",
	color: "white !important",
	fontSize: "16px !important",
	padding: "7px 16px !important",
	backgroundColor: "black",
	borderRadius: "4px !important",
	"&:hover": {
		backgroundColor: "black",
	},
});

const StyledEditorTitle = styled('div')({
	maxWidth: '100%',
	'& .ProseMirror-focused': {
		border: 'none',
		outline: 'none',
	},
	'& .ProseMirror > p': {
		margin: '0px',
	},
	'& .ProseMirror > .is-editor-empty::before': {
		content: 'attr(data-placeholder)',
		color: '#aaa',
		float: 'left',
		height: '0',
		pointerEvents: 'none',
	},
});

const StyledEditorContent = styled('div')({
	maxWidth: '100%',
	'& .ProseMirror-focused': {
		border: 'none',
		outline: 'none',
	},
	'& .ProseMirror > p': {
		margin: '0px',
	},
	'& .ProseMirror > .is-editor-empty::before': {
		content: 'attr(data-placeholder)',
		color: '#aaa',
		float: 'left',
		height: '0',
		pointerEvents: 'none',
	},
});

const CredentialsTitle = styled(Typography)({
	fontSize: '14px',
	fontWeight: '400',
	color: theme.palette.texts.main,
	fontFamily: "Heebo-Regular",
});

const CredentialsDescription = styled(CredentialsTitle)({
	fontWeight: '700',
	fontFamily: "Heebo-Bold",
});

const Footer = styled(Box)({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	gap: '8px',
});

const FooterTitle = styled(Typography)({
	fontSize: '14px',
	fontWeight: '700',
	color: theme.palette.texts.light,
});
// Customizable Area End
