import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const baseURL = require("../../../framework/src/config.js").baseURL;
// Customizable Area Start

import React from 'react';
import { Editor } from '@tiptap/react';
import { toast } from "react-toastify";
import { Position } from "../../termsconditions/src/HelpCenterAdminPartTypes";
import { EditorTypes, handleEditorBlur, handleOutsideEditorClick, initEditor, updateToolbarPosition } from "../../termsconditions/src/EditorUtils";
import { apiCall } from '../../../components/src/ApiCall.web';

export const crypto = require('crypto');

export interface ApiCallInterface {
	contentType?: string,
	method?: string,
	endPoint?: string,
	body?: object
}

export interface Content {
	id: string;
	type: string;
	attributes: {
		subject: string;
		title: string;
		description: string;
		admin_id?: number;
		created_at?: Date;
		updated_at?: Date;
		your_email?: string;
		temporary_password?: string;
		client: {
			id: number;
			company_name: string;
			company_logo: {
				small:string;
				medium: string;
				large: string;
			}
		},

	};
};

export interface UpdateContent {
	title?: string;
	description?: string;
}

export type EditorType = 'title' | 'description';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
	// Customizable Area Start
	navigation: {
		navigate: (path: string) => void;
	};
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	title: string,
	description: string;
	originalTitle: string;
	originalDescription: string;
	logo: string | null;
	isEditing: boolean;
	toolbarPosition: Position;
	activeEditor: EditorType | null;
	clientName: string;
	isLoading: boolean;
	loading: boolean;
	isDataAvailable: boolean;
	// Customizable Area End
}

interface SS {
	// Customizable Area Start
	id: any;
	// Customizable Area End
}

export default class ClientSignupEmailController extends BlockComponent<
	Props,
	S,
	SS
>{
	// Customizable Area Start
	apiGetEmailContentById: string = "";
	apiPatchEmailContentById: string = "";
	getClientInfoApiCallId: string = "";
	editorTitle: Editor | null = null;
	editorDescription: Editor | null = null;
	editorTitleRef = React.createRef<HTMLDivElement>();
	editorDescriptionRef = React.createRef<HTMLDivElement>();
	toolbarRef = React.createRef<HTMLDivElement>();
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		// Customizable Area End
		this.subScribedMessages = [
			getName(MessageEnum.RestAPIResponceMessage),
			// Customizable Area Start
			// Customizable Area End
		];

		this.state = {
			// Customizable Area Start
			title: '',
			description: ``,
			originalTitle: '',
			originalDescription: ``,
			logo: null,
			isEditing: false,
			toolbarPosition: { top: 0, left: 0 },
			activeEditor: null,
			clientName: "",
			isLoading: false,
			loading: false,
			isDataAvailable: true
			// Customizable Area End
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

		// Customizable Area Start

		this.initEditors();

		this.handleOutsideClick = this.handleOutsideClick.bind(this);
		// Customizable Area End
	}

	initEditors() {
		this.editorTitle = initEditor(
			'title',
			this.handleEditorUpdate,
			this.handleSelectionUpdate,
			this.onFocus,
			this.handleTitleBlur,
			'Enter title here',
		);

		this.editorDescription = initEditor(
			'description',
			this.handleEditorUpdate,
			this.handleSelectionUpdate,
			this.onFocus,
			this.handleDescriptionBlur,
			'Enter description here',
		);
	}

	handleEditorUpdate = (type: EditorTypes, editor: Editor) => {
		const content = editor.getHTML();
		this.updateEditorContent(type, content);
	}

	handleSelectionUpdate = (type: EditorTypes, editor: Editor) => {
		updateToolbarPosition(
			editor,
			this.toolbarRef,
			(position) => this.setState({ toolbarPosition: position, isEditing: true, activeEditor: type as EditorType }),
			(isEditing) => {},
			this.state.isEditing
		);
	};

	handleTitleBlur = (editor: Editor) => {
		handleEditorBlur(editor, '<p></p>', (content: string) =>
			this.updateEditorContent('title', content)
		);
		const selection = editor?.state?.selection;
		if(selection?.empty){
		this.setState({isEditing:false});
		}
	};

	handleDescriptionBlur = (editor: Editor) => {
		handleEditorBlur(editor, '<p></p>', (content: string) =>
			this.updateEditorContent('description', content)
		);
		const selection = editor?.state?.selection;
		if(selection?.empty){
		this.setState({isEditing:false});
		}
	};

	onFocus = (type: EditorTypes, editor: Editor) => {
		updateToolbarPosition(
			editor,
			this.toolbarRef,
			(position) => this.setState({ toolbarPosition: position, isEditing: true, activeEditor: type as EditorType }),
			(isEditing) => this.setState({ isEditing }),
			this.state.isEditing,
			true
		);
	}

	updateEditorContent = (type: EditorTypes, text: string) => {
		this.setState(prev => ({ ...prev, [type as EditorType]: text }));
	}

	async receive(from: string, message: Message) {
		// Customizable Area Start
		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (apiRequestCallId === this.apiGetEmailContentById) {
				if (responseJson.data) {
					this.handleGetConnectRes(responseJson.data);
				} else {
					this.getClientInfo();
				}
			} else if (apiRequestCallId === this.apiPatchEmailContentById) {
				if (responseJson.meta?.message) {
					toast.success(responseJson.meta?.message);
					this.setState({ isLoading: false, isDataAvailable: true });
				} else {
					toast.error("Something went wrong!");
					this.setState({ isLoading: false })
				}
			} else {
				this.handleResponse(apiRequestCallId, responseJson);
			}
		}
		// Customizable Area End
	}

	// Customizable Area Start
	handleResponse = (apiRequestCallId: string, responseJson: any) => {
		if (apiRequestCallId === this.getClientInfoApiCallId) {
			if (responseJson.data) {
				this.setState({
					clientName: responseJson.data.attributes.company_name,
					logo: responseJson.data.attributes.company_logo_large,
					isLoading: false,
					loading: false,
					isDataAvailable: false
				});
			}
		}
	}

	async componentDidMount() {
		this.getEmailContentByIdApi();
		window.addEventListener('mousedown', this.handleOutsideClick);
	}

	async componentWillUnmount() {
		window.removeEventListener('mousedown', this.handleOutsideClick);
		this.editorTitle?.destroy();
		this.editorDescription?.destroy();
	}

	onTitleChange = (title: string) => {
		this.setState({ title });
	}

	onDescriptionChange = (description: string) => {
		this.setState({ description });
	}

	onRedirect = () => {
		this.props.navigation.navigate("Clients");
	}

	onRedirectToClient = () => {
		this.props.navigation.navigate("ManageClient");
	}

	getClientInfo = async () => {
		this.setState({ isLoading: true });
		let clientId = localStorage.getItem('SelectedClientID');
		this.getClientInfoApiCallId = await apiCall({
			method: "GET",
			token: localStorage.getItem("token"),
			contentType: 'application/json',
			endPoint: `/bx_block_account_groups/clients/${clientId}`,
		});
	};

	getEmailContentByIdApi = async () => {
		this.setState({ isLoading: true, loading: true });
		let clientId = localStorage.getItem('SelectedClientID');
		this.apiGetEmailContentById = await apiCall({
			method: "GET",
			token: localStorage.getItem("token"),
			contentType: 'application/json',
			endPoint: `/bx_block_content_management/client_content_managements/client_content_show?client_id=${clientId}`,
		});
	};

	updateContentApi = async () => {
		this.setState({ isLoading: true });
		let clientId = localStorage.getItem('SelectedClientID');
		this.apiPatchEmailContentById = await apiCall({
			contentType: 'application/json',
			token: localStorage.getItem("token"),
			method: this.state.isDataAvailable ? "PATCH" : "POST",
			endPoint: this.state.isDataAvailable ? `/bx_block_content_management/client_content_managements/update_client_content?client_id=${clientId}` : `/bx_block_content_management/client_content_managements`,
			body: {
				"client_content_management": {
				"title": this.state.title,
				"description": this.state.description,
				"client_id": this.state.isDataAvailable ? undefined : clientId
				}
			}
		});
	};

	handleGetConnectRes = (emailContent: Content) => {
		this.setState({
			isLoading: false,
			loading: false,
			isDataAvailable: true,
			title: emailContent.attributes.title,
			description: emailContent.attributes.description,
			originalTitle: emailContent.attributes.title,
			originalDescription: emailContent.attributes.description,
			logo: emailContent.attributes.client.company_logo.small,
			clientName: emailContent.attributes.client.company_name,
		}, () => {
			this.editorTitle?.commands.setContent(this.state.title)
			this.editorDescription?.commands.setContent(this.state.description)
		});
	}

	isSaveButtonDisabled = () => {
		const { title, description, originalTitle, originalDescription } = this.state;

		const hasChanges = (JSON.stringify(title) !== JSON.stringify(originalTitle)
			|| JSON.stringify(description) !== JSON.stringify(originalDescription));

		return !hasChanges;
	};

	handleOutsideClick = (event: MouseEvent) => {
		handleOutsideEditorClick(
			event,
			[this.editorDescriptionRef, this.editorTitleRef],
			this.toolbarRef,
			() => this.setState({ activeEditor: null })
		);
	};
	// Customizable Area End
}