import React from 'react';

// Customizable Area Start
import {
  TextField,
  Box,
  Button,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  IconButton,
  InputLabel,
  Popover,
  List,
  ListItem,
  Tab,
  Checkbox,
  Tabs,
  ThemeProvider,
} from '@mui/material';


import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Close, Search, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Sidebar from '../../../components/src/sidebar/Sidebar.web';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { format } from "date-fns";
import dayjs from 'dayjs';
import { GroupAdd, BlackArrow, CalendarIcon, CopyIcon, Location, CompanyIcon } from './assets';


// Customizable Area End

import EventManagementController, {
  Props,
} from './EventManagementController.web';


export default class EventManagement extends EventManagementController {
  dayHeaderContent(arg: { view: { type: string; }; date: Date; }): any {
    throw new Error("Method not implemented.");
  }
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const timeZones = [
      { value: 'UTC', label: 'UTC' },
      { value: 'GMT', label: 'GMT' },
      { value: 'EST', label: 'Eastern Standard Time (UTC-5)' },
      { value: 'CST', label: 'Central Standard Time (UTC-6)' },
      { value: 'MST', label: 'Mountain Standard Time (UTC-7)' },
      { value: 'PST', label: 'Pacific Standard Time (UTC-8)' },
      { value: 'CET', label: 'Central European Time (UTC+1)' },
      { value: 'EET', label: 'Eastern European Time (UTC+2)' },
      { value: 'IST', label: 'Indian Standard Time (UTC+5:30)' },
      { value: 'JST', label: 'Japan Standard Time (UTC+9)' },
      { value: 'AEDT', label: 'Australian Eastern Daylight Time (UTC+11)' },
    ];

    const { anchorEl, clientSearch, showError } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const filteredClients = this.state.ClientList.filter((client: any) =>
      client.companyName.toLowerCase().includes(clientSearch.toLowerCase())
    );
    return (
      <>

        <Box sx={webStyles.mainWrapper}>
          <Box sx={webStyles.mainBlock}>
            <Box>
              <Sidebar navigation={this.props.navigation} current='event_management' />
            </Box>
            <div style={{ width: '100%' }}>
              <Box sx={webStyles.topHeader}>
                <Typography style={{ fontFamily: 'Heebo-Bold', fontSize: '24px', }}>Event Management</Typography>
                <BlackButton data-test-id="add-new-button" onClick={this.handleShowEventDetailsDialog}> <img style={{ marginRight: '5px' }} src={GroupAdd} />
                  <Typography sx={{
                    fontFamily: 'Heebo-Regular',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'left'
                  }}
                  >Add new event</Typography></BlackButton>
              </Box>
              <div style={{ columnGap: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '1rem 1rem', height: 'fit-content' }}>
                <CalendarWrapper>

                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2 style={{ fontFamily: 'Heebo-Bold', fontSize: '20px', lineHeight: '28px', }}>{this.state.currentMonthYear}</h2>
                    <div style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
                      <ArrowBackIos data-test-id="prev-btn" fontSize='small' style={{ padding: '14px', backgroundColor: '#F1F5F9', borderRadius: '4px' }} onClick={this.handlePrevClick} />
                      <ArrowForwardIos data-test-id="next-btn" fontSize='small' style={{ padding: '14px', backgroundColor: '#F1F5F9', borderRadius: '6px' }} onClick={this.handleNextClick} />

                      <div>
                        <StyledTabs
                          TabIndicatorProps={{
                            style: {
                              display: 'none'
                            }
                          }}
                          value={this.state.selectedTab}
                          onChange={this.handleTabChange}
                          centered
                        >
                          <StyledTab style={{ fontFamily: "Heebo-Bold", fontSize: "16px", lineHeight: "24px" }} label="Day" value={0} />
                          <StyledTab label="Week" value={1} />
                          <StyledTab label="Month" value={2} />
                        </StyledTabs>
                      </div>
                    </div>
                  </div>

                  <FullCalendar
                    data-test-id="full-calendar"
                    firstDay={1}
                    ref={this.state.calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    events={this.state.events}
                    headerToolbar={false}
                    dayMaxEventRows={true}
                    dayMaxEvents={1}
                    eventClick={this.handleEventClick}
                    dayHeaderContent={(arg) => {
                      if (arg.view.type === 'dayGridMonth') {
                        return arg.date.toLocaleDateString('en-US', { weekday: 'short' });
                      }
                      if (arg.view.type === 'timeGridWeek') {
                        let date = arg.date;
                        let day = date.getDate();
                        let dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
                        let ordinal = this.getOrdinalSuffix(day);
                        return `${dayName} ${day}${ordinal}`;
                      }
                      if (arg.view.type === 'timeGridDay') {
                        let date = arg.date;
                        let day = date.getDate();
                        let dayName = date.toLocaleDateString('en-US', { weekday: 'short' });
                        let ordinal = this.getOrdinalSuffix(day);
                        return `${dayName} ${day}${ordinal}`;
                      }
                      return format(arg.date, "EEE ddd");
                    }}
                  />

                </CalendarWrapper>
                <div style={{flex:1,marginLeft:'20px'}}>

                  <div >
                    <Typography style={{ fontFamily: "Heebo-Bold", fontSize: '12px', lineHeight: "18px", marginBottom: '10px', marginLeft: "20px", color: "#64748B" }}>UPCOMING EVENTS</Typography>
                    <div style={{ maxHeight: '450px', overflowY: 'scroll' }}>
                      {this.state.upcomingEvents.map((event: any, index: number) => {
                        return (<div
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderLeft: '4px solid black',
                            padding: '1rem',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                            alignItems: 'start',
                            marginBottom: '8px',

                          }}
                        >
                          <div>
                            <Typography style={{ color: "#1C1C1C", fontFamily: "Heebo-Bold", marginBottom: '0.5rem', fontSize: '16px', lineHeight: "24px" }}>
                              {event.title}
                            </Typography>
                            <Typography style={{ fontSize: '14px', lineHeight: "22px", color: "#1C1C1C" }}>{event.host || 'Host Name'}</Typography>
                            <Typography style={{ fontSize: '14px', lineHeight: "22px", color: "#1C1C1C" }}>
                              {this.formatTime(event.start_time)} - {this.formatTime(event.end_time)}, {event.date}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              style={{
                                padding: '8px 12px',
                                backgroundColor: '#f0f0f0',
                                borderRadius: '20px',
                                fontFamily: "Heebo-Bold",
                                lineHeight: "22px",
                                textAlign: 'center',
                                textTransform: "uppercase",
                                fontSize: '14px',
                                color: "#6A6A6A"
                              }}
                            >
                              {event.total_attendees} Attendees
                            </Typography>
                          </div>
                        </div>)
                      })}
                    </div>
                  </div>

                  <div >
                    <Typography style={{ fontFamily: "Heebo-Bold", fontSize: '12px', lineHeight: "18px", margin: '8px 0px 8px 20px', color: "#64748B" }}>PAST EVENTS</Typography>
                    <div style={{ maxHeight: '325px', overflowY: 'scroll' }}>
                      {this.state.pastEvents.map((event: any, index: number) => (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderLeft: '4px solid #646464',
                            padding: '1rem',
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                            alignItems: 'start',
                            marginBottom: '8px',

                          }}
                        >
                          <div>
                            <Typography style={{ fontFamily: "Heebo", fontWeight: '700', marginBottom: '0.5rem', fontSize: '16px', color: '#0F172A' }}>
                              {event.title}
                            </Typography>
                            <Typography style={{ fontFamily: "Heebo-Regular", fontSize: '14px', color: '#1C1C1C' }}>{event.host || 'Host Name'}</Typography>
                            <Typography style={{ fontFamily: "Heebo-Regular", fontSize: '14px', color: '#1C1C1C' }}>
                              {this.formatTime(event.start_time)} - {this.formatTime(event.end_time)},{event.date}
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              style={{
                                padding: '8px 12px',
                                backgroundColor: 'white',
                                borderRadius: '20px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                textTransform: "uppercase",
                                fontSize: '14px',
                                color: "white",
                                visibility: 'hidden'
                              }}
                            >
                              {"Attentdees"}
                            </Typography>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </Box>




          <Box sx={webStyles.footerBlock}>
            <Box sx={webStyles.leftFooter}>
              <Box>Terms and Conditions</Box>
              <Box>Pravicy Policy</Box>
              <Box></Box>
            </Box>
            <Box>
              <Box>© 2025 Thinkspike. All Rights Reserved</Box>
            </Box>
          </Box>
        </Box>

        <Dialog data-test-id='dialog-mui' fullWidth onClose={this.handleCloseEventDetailsDialog} aria-labelledby="customized-dialog-title" open={this.state.showEventDetailsDialog}>
          <StyledDialogTitle >
            <Typography sx={{
              fontFamily: 'Heebo',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '28px',
              textAlign: 'left'
            }}>Add new event</Typography>
            <IconButton data-test-id="event-close-btn" aria-label="close" onClick={this.handleCloseEventDetailsDialog} className="MuiIconButton-root">
              <Close />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '1rem' }}>
              <Typography sx={{
                backgroundColor: 'black',
                color: 'white',
                width: '32px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                margin: '0',
                fontWeight: 700
              }}>
                1
              </Typography>
              <Typography sx={{
                fontFamily: 'Heebo',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '24px',
                textAlign: 'left'
              }}>Event Details</Typography>
            </Box>

            <CommonInputLable>Title</CommonInputLable>
            <CommonTextField
              data-test-id="titletest"
              value={this.state.eventTitle}
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="outlined"
              onChange={(e) => this.setState({ eventTitle: e.target.value, showError: false })}
              error={this.state.showError && !this.state.eventTitle}
              helperText={this.state.showError && !this.state.eventTitle ? "Title is required." : ""}
            />

            <CommonInputLable>Date</CommonInputLable>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                data-test-id='event-date-picker'
                value={this.state.eventDate}
                onChange={this.handleDateChange}
                open={this.state.open}
                onOpen={() => this.setState({ open: true })}
                onClose={() => this.setState({ open: false })}
                format="DD/MM/YYYY"
                slots={{
                  textField: (params) => (
                    <CommonTextField
                      error={false}
                      {...params}
                      sx={{
                        width: '100%', marginBottom: '1rem',

                        '& .MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#9D9D9D !important',
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={CalendarIcon}
                              alt="Calendar Icon"
                              style={{
                                width: '24px',
                                height: '24px',
                                cursor: 'pointer',
                                color: '#6A6A6A'
                              }}
                              onClick={this.toggleDatePicker}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: null,
                      }}
                    />
                  ),
                }}
              />
            </LocalizationProvider>

            <TimeContainer>
              <TimeFieldContainer>
                <CommonInputLable>Time start</CommonInputLable>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    data-test-id='time-start'
                    value={this.state.timeStart}
                    onChange={(event) => this.handleTimeChange(event, "time-start")}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: this.state.showError && !this.state.timeStart,
                        helperText: this.state.showError && !this.state.timeStart ? "Start time is required." : "",
                      },
                    }}
                  />
                </LocalizationProvider>
              </TimeFieldContainer>
              <Box sx={{ justifySelf: 'center', justifyContent: 'center', alignSelf: 'center', width: '24px', marginTop: '30px' }}>
                <img src={BlackArrow} style={{ width: '24px', height: '24px', color: '#6A6A6A' }} />
              </Box>
              <TimeFieldContainer>
                <CommonInputLable>Time end</CommonInputLable>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <TimePicker
                    data-test-id='time-end'
                    value={this.state.timeEnd}
                    onChange={(event) => this.handleTimeChange(event, "time-end")}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: this.state.showError && !this.state.timeEnd,
                        helperText: this.state.showError && !this.state.timeEnd ? "End time is required." : "",
                      },
                    }}
                  />
                </LocalizationProvider>
              </TimeFieldContainer>
            </TimeContainer>
            <CommonInputLable>Time Zone</CommonInputLable>
            <CustomSelect

              fullWidth
              labelId="time-zone-select-label"
              value={this.state.selectedTimeZone}
              onChange={(event) => this.handleSelectTimeZone(event)}
              style={{ borderRadius: '8px', marginBottom: '1rem' }}
            >
              {timeZones.map((timeZone) => (
                <MenuItem key={timeZone.value} value={timeZone.value}>
                  {timeZone.label}
                </MenuItem>
              ))}
            </CustomSelect>

            <TimeContainer>
              <TimeFieldContainer>
                <CommonInputLable>Event link</CommonInputLable>
                <CommonTextField
                  data-test-id="event-link"
                  value={this.state.eventLink}
                  onChange={(event) => this.handleChange(event, 'event-link')}
                  fullWidth type="text"
                  variant="outlined"
                  error={this.state.showError && !this.state.eventLink && !this.state.eventLocation}
                  helperText={this.state.showError && !this.state.eventLink && !this.state.eventLocation ? "Either Location or Event Link is required." : ""}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      color: 'blue',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ContentCopyIcon sx={{ width: '24px', height: '24px', color: '#6A6A6A' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </TimeFieldContainer>

              <TimeFieldContainer>
                <CommonInputLable>Location</CommonInputLable>
                <CommonTextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Location} />
                      </InputAdornment>
                    ),
                  }}
                  value={this.state.eventLocation}
                  fullWidth
                  data-test-id="location"
                  type="text"
                  variant="outlined"
                  onChange={(event) => this.handleChange(event, "location")}
                  error={this.state.showError && !this.state.eventLink && !this.state.eventLocation}
                  helperText={this.state.showError && !this.state.eventLink && !this.state.eventLocation ? "Either Location or Event Link is required." : ""}
                />
              </TimeFieldContainer>
            </TimeContainer>
            <CommonInputLable>Notification</CommonInputLable>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                data-test-id='time-notification'
                value={this.state.notificationTime}
                onChange={(event) => this.handleTimeChange(event, 'notification-time')}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginTop: '5px', marginBottom: '1rem' }}>
              <Typography sx={{
                backgroundColor: '#D7D7D7',
                color: 'white',
                width: '32px',
                height: '32px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                margin: '0',
                fontWeight: 400
              }}>
                2
              </Typography>
              <Typography sx={{
                fontFamily: 'Heebo',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '24px',
                textAlign: 'left',
                color: '#6A6A6A'
              }}>Select Attendees</Typography>
            </Box>


          </StyledDialogContent>
          <StyledDialogActions>
            <LightButton autoFocus onClick={this.handleCloseEventDetailsDialog} color="primary">
              Cancel
            </LightButton>
            <DarkButton data-test-id="save-btn" autoFocus onClick={this.handleEventDetailsSave} color="primary">
              Save
            </DarkButton>
          </StyledDialogActions>
        </Dialog>

        <Dialog fullWidth onClose={this.handleCloseSelectAttendeeDialog} aria-labelledby="customized-dialog-title" open={this.state.showSelectAttendeesDialog}>
          <StyledDialogTitle >
            <Typography sx={{
              fontFamily: 'Heebo',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '28px',
              textAlign: 'left'
            }}>Select Attendees</Typography>
            <IconButton aria-label="close" onClick={this.handleCloseSelectAttendeeDialog} className="MuiIconButton-root">
              <Close />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>

            <div style={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '1rem' }}>
              <CheckCircleIcon sx={{ width: '32px', height: '32px', color: '#34D399', gap: '1px' }} />
              <Typography sx={{
                fontFamily: 'Heebo',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '24px',
                textAlign: 'left'
              }}>Event Details</Typography>
            </div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', marginBottom: '1rem' }}>
              <Box style={{
                backgroundColor: 'black',
                color: 'white',
                width: '32px',
                height: '32px',
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                margin: '0px',
                //padding:'24px',
                fontWeight: 700
              }}>
                2
              </Box>
              <Typography sx={{
                fontFamily: 'Heebo',
                fontSize: '16px',
                fontWeight: 700,
                //lineHeight: '24px',
                textAlign: 'left'
              }}>Select Attendees</Typography>
            </Box>
            <CommonInputLable>Client</CommonInputLable>

            <div style={{ marginBottom: '1rem' }}>
              <CommonTextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={CompanyIcon} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
                placeholder='Add client'
                onClick={this.handleAddClientClick}
                value={
                  this.state.selectedClients.length > 0
                    ? [...new Set(this.state.selectedClients)]
                      .map((id: any) =>
                        this.state.ClientList.find((client: any) => client.companyId === id)
                      )
                      .map((client: any) => client ? client.companyName : '')
                      .join(', ')
                    : ''
                }
              />
              <Popover
                style={{ maxHeight: '250px' }}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleAddClientClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <div style={{ padding: 16, width: '32.4rem' }}>
                  <TextField
                    placeholder="Search..."
                    variant='standard'
                    fullWidth
                    onChange={this.handleInputChange}
                    InputProps={{
                      disableUnderline: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search htmlColor='#d7d7d7' />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <Close htmlColor='#d7d7d7' onClick={this.handleAddClientClose} style={{ cursor: 'pointer' }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <List>
                    {filteredClients.map((client: any) => (
                      <ListItem
                        style={{ padding: '8px' }}
                        data-test-id="list-item"
                        key={client.companyId}
                        onClick={() => this.handleClientToggle(client)}
                      >
                        <StyledCheckbox
                          checked={this.state.selectedClients.includes(client.companyId)}
                          onChange={() => this.handleClientToggle(client)}
                        />
                        {client.companyName}
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Popover>
            </div>
            <CommonInputLable>Job Role</CommonInputLable>
            <CustomSelect
              startAdornment={
                <InputAdornment position="start">
                  <GroupAddIcon sx={{ width: '24px', height: '24px', color: '#6A6A6A' }} />
                </InputAdornment>
              }
              multiple
              placeholder="Select role"
              fullWidth
              labelId="job-role-select-label"
              value={this.state.selectedJobRoles}
              onChange={this.handleSelectJobRole}
              renderValue={(selected: any) => selected.join(', ')}
              style={{ borderRadius: '8px', marginBottom: '1rem' }}
            >
              {this.state.jobRoles.map((jobRole) => (
                <MenuItem key={jobRole} value={jobRole}>
                  <StyledCheckbox
                    checked={this.state.selectedJobRoles.indexOf(jobRole) > -1}
                  />
                  {jobRole}
                </MenuItem>
              ))}
            </CustomSelect>


            <CommonInputLable>Users</CommonInputLable>
            <CustomSelect
              startAdornment={
                <InputAdornment position="start">
                  <GroupAddIcon sx={{ width: '24px', height: '24px', color: '#6A6A6A' }} />
                </InputAdornment>
              }
              placeholder='Add user'
              fullWidth
              data-test-id="userSelect"
              labelId="time-zone-select-label"
              value={this.state.selectedUsers}
              onChange={(event) => this.handleSelectUser(event)}
              renderValue={(selected: any) => {
                return selected.map((userId: any) => {
                  const user = this.state.filteredUsers.find((u: any) => u.id === userId);
                  return user ? `${user.first_name} ${user.last_name}` : '';
                }).join(', ');
              }}
              style={{ borderRadius: '8px', marginBottom: '1rem' }}
            >
              {this.state.filteredUsers.map((user: any) => (
                <MenuItem key={user.id} value={user.id}>
                  <StyledCheckbox
                    checked={this.state.selectedUsers.includes(user.id)}
                    onChange={(event) => this.handleUserToggle(event, user.id)}
                  />
                  {`${user.first_name} ${user.last_name}`}
                </MenuItem>
              ))}
            </CustomSelect>



          </StyledDialogContent>
          <StyledDialogActions>
            <LightButton data-test-id="back-button" autoFocus onClick={this.handleShowEventDetailsDialog} color="primary">
              Back
            </LightButton>
            <DarkButton data-test-id="publish-btn" autoFocus onClick={this.handlePublishEventClick} color="primary">
              Publish
            </DarkButton>
          </StyledDialogActions>
        </Dialog>

        <Dialog fullWidth onClose={this.handleCloseEditEvent} aria-labelledby="customized-dialog-title" open={this.state.showEditEvent}>
          <StyledDialogTitle >
            <Typography sx={{
              fontFamily: 'Heebo',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '28px',
              textAlign: 'left'
            }}
            >{this.state.selectedEvent?.title}</Typography>
            <IconButton aria-label="close" onClick={this.handleCloseEditEvent} className="MuiIconButton-root">
              <Close />
            </IconButton>
          </StyledDialogTitle>
          <StyledDialogContent dividers>
            <CommonInputLable>Date</CommonInputLable>
            <CommonTextField
              value={this.state.selectedEvent?.start ? dayjs(this.state.selectedEvent.start).format('DD/MM/YYYY') : ''}
              type='text'
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="standard"
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <CalendarTodayIcon sx={{ width: '24px', height: '24px', color: '#6A6A6A' }} />
                  </InputAdornment>
                ),
                endAdornment: null,
              }}
            />
            <TimeContainer>
              <TimeFieldContainer>
                <CommonInputLable>Time start</CommonInputLable>
                <CommonTextField
                  value={this.formatTime(this.state.selectedEvent?.startTime) + ` (${this.state.selectedEvent?.timeZone})`}
                  fullWidth
                  type="text"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon sx={{ width: '24px', height: '24px', color: '#6A6A6A' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </TimeFieldContainer>
              <Box sx={{ justifySelf: 'center', justifyContent: 'center', alignSelf: 'center', width: '24px', marginTop: '30px' }}>
                <img src={BlackArrow} style={{ width: '24px', height: '24px', color: '#6A6A6A' }} />
              </Box>
              <TimeFieldContainer>
                <CommonInputLable>Time end</CommonInputLable>
                <CommonTextField InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccessTimeIcon sx={{ width: '24px', height: '24px', color: '#6A6A6A' }} />
                    </InputAdornment>
                  ),
                }} fullWidth
                  type="text"
                  variant="standard"
                  value={this.formatTime(this.state.selectedEvent?.endTime) + ` (${this.state.selectedEvent?.timeZone})`}
                />
              </TimeFieldContainer>
            </TimeContainer>
            <CommonInputLable>Notification</CommonInputLable>
            <CommonTextField
              value={this.formatTime(this.state.selectedEvent?.notification) + ` (${this.state.selectedEvent?.timeZone})`}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <ContentCopyIcon sx={{ width: '24px', height: '24px', color: '#6A6A6A' }} />
                  </InputAdornment>
                ),
              }}
              style={{ marginBottom: '1rem' }}
              fullWidth
              variant="standard"
            />
            <CommonInputLable>Event link</CommonInputLable>
            <CommonTextField
              value={this.state.selectedEvent?.url}
              style={{ marginBottom: '1rem', color: 'blue' }}
              fullWidth type="text"
              variant="standard"
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                style: { color: 'blue' },
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={CopyIcon} />
                  </InputAdornment>
                ),
              }}
            />
            <TimeContainer>
              <TimeFieldContainer>
                <CommonInputLable>Client</CommonInputLable>
                <CommonTextField
                  value={this.state.selectedEvent?.clients}
                  fullWidth
                  type="text"
                  variant="standard"
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
              </TimeFieldContainer>
              <TimeFieldContainer>
                <CommonInputLable>User</CommonInputLable>
                <CommonTextField InputProps={{
                  readOnly: true,
                  disableUnderline: true,

                }} fullWidth
                  type="text"
                  variant="standard"
                  value={this.state.selectedEvent?.totalUser}
                />
              </TimeFieldContainer>
            </TimeContainer>

          </StyledDialogContent>
          <StyledDialogActions>
            <LightButton data-test-id='edit-btn' onClick={this.handleEditEventClick} color="primary">
              Edit
            </LightButton>
            <DarkButton data-test-id='delete-event-btn' onClick={this.deleteEvent23} style={{ backgroundColor: '#FC5555' }} color="primary">
              Delete
            </DarkButton>
            <Dialog
              data-test-id="dailoge1"
              open={this.state.showDeleteConfirmation}
              onClose={() => this.setState({ showDeleteConfirmation: false })}
            >
              <DialogTitle>Are you sure you want to delete?</DialogTitle>
              <DialogActions>
                <Button data-test-id="no-delete-btn"
                  onClick={() => this.setState({ showDeleteConfirmation: false })}
                  sx={{
                    backgroundColor: '#D3D3D3',
                    color: '#000', textTransform: 'none',
                    '&:hover': {
                      backgroundColor: '#C0C0C0',
                    },
                  }}
                >
                  No
                </Button>
                <Button data-test-id="yes-delete-btn"
                  onClick={this.deleteEvent}
                  sx={{
                    backgroundColor: '#FC5555', color: '#FFF', textTransform: 'none',
                    '&:hover': { backgroundColor: '#E64545', },
                  }} autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

          </StyledDialogActions>
        </Dialog>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledCheckbox = styled(Checkbox)({
  '&.Mui-checked': {
    color: 'black',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
});
const CalendarWrapper = styled('div')({
  width: '100%',
  flex:3,
  overflow:"scroll",
  scrollbarWidth:'none',
  boxSizing:'border-box',
  backgroundColor: 'white',
  padding: "1rem",
  borderRadius: '8px',
  height: 'fit-content',
  '& .fc-col-header-cell': {
    borderLeft: 'none !important',
    borderRight: 'none !important',
  },
  '& .fc .fc-scrollgrid-liquid': {
    borderTop: 'none !important',
    border: 'none !important'
  },
  '& .fc .fc-col-header-cell-cushion': {
    padding: '2rem 0rem',
    color: "#64748B",
    FontFamily: 'Heebo'
  },


  '& .fc .fc-scrollgrid-section > *': {
    borderRightWidth: '0px !important',
  },
  '& .fc .fc-scroller-harness-liquid': {
    borderRight: '1px solid #DDDDDD',
  },
  '& .fc-event-time': {
    display: 'none'
  },
  '& .fc-event-title .fc-sticky': {
    color: 'black !important'
  },
  '& .fc .fc-daygrid-event': {
    backgroundColor: '#F6F0FF',
    borderRadius: '4px',
    padding: '5px',
    color: 'black',
    fontSize: '0.9em',
    fontFamily: 'Heebo',
  },




});
const StyledTab = styled(Tab)({
  textTransform: 'none',
  fontWeight: 'bolder',
  fontFamily: 'Heebo-bold',
  color: '#64748B',
  '&$selected': {
    color: 'white',
    backgroundColor: "black",
    borderRadius: '25px',
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: "black",
    borderRadius: '25px',
    border: 'none'
  },

})
const StyledTabs = styled(Tabs)({
  backgroundColor: '#F1F5F9',
  borderRadius: '25px',
  width: 'fit-content',
  padding: '5px'
})
const StyledDialogTitle = styled(DialogTitle)({
  margin: 0,
  padding: '16px 25px',
  position: 'relative',
  '& .MuiIconButton-root': {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: '#757575',
  },
});
const CommonInputLable = styled(InputLabel)({
  marginBottom: '5px',
  color: '#334155',
  fontWeight: 700
})
const CommonTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px'
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px 14px'
  }
})
const CustomTimePicker = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    //borderRadius: '8px'
    border: 'none',
    boxShadow: 'none',
    outline: 'none'
  },
  '& .MuiOutlinedInput-input': {
    padding: '14px 14px'
  },
  '& input[type="time"]::-webkit-calendar-picker-indicator': {
    display: 'none',
  },
  '& input[type="time"]::-webkit-clear-button': {
    display: 'none',
  },
  '& input[type="time"]::-moz-clear': {
    display: 'none',
  },
  '& input[type="time"]::-ms-clear': {
    display: 'none',
  },
})
const CustomSelect = styled(Select)({
  '& .MuiSelect-outlined.MuiSelect-outlined': {
    padding: '14px !important'
  }
})
const StyledDialogContent = styled(DialogContent)({
  padding: '25px',
});

const StyledDialogActions = styled(DialogActions)({
  margin: 0,
  padding: '16px 25px',
});

const TimeContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  marginBottom: '1rem',
});

const TimeFieldContainer = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});
const LightButton = styled(Button)({
  backgroundColor: '#d7d7d7',
  color: 'black',
  textTransform: 'none',
  padding: '10px 20px',
  fontWeight: 'bold',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: '#d7d7d7',
    color: 'black',
  },
})
const DarkButton = styled(Button)({
  backgroundColor: 'black',
  color: 'white',
  textTransform: 'none',
  padding: '10px 20px',
  fontWeight: 'bold',
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: 'black',
    color: 'white',
  },
})


const BlackButton = styled(Button)({
  backgroundColor: 'black',
  color: 'white',
  textTransform: 'none',
  fontFamily: 'Heebo',
  fontWeight: 400,
  padding: '16px 10px',
  height: '44px',
  opacity: '0px',
  '&:hover': {
    backgroundColor: 'black',
    color: 'white',
  },
})
const webStyles = {
  mainBlock: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#f6f6f6',
  },
  mainWrapper: {
    flex: 1,
    flexDirection: 'col',
    backgroundColor: '#f6f6f6'
  },
  topHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 'fit-content',
    paddingLeft: '1rem',
    paddingRight: '2rem',
    alignItems: 'center',
    marginTop: "20px"
  },

  footerBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '24px 52px 24px 52px',
    backgroundColor: '#1C1C1C',
    color: '#fff',
  },
  leftFooter: {
    display: 'flex',
    gap: '24px',
    flexGrow: 1
  },

  stepNumber: {
    backgroundColor: 'black',
    color: 'white',
    padding: '5px',
    borderRadius: '50%',
    marginRight: '0.5rem',
  },
  timeContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '1rem',
    marginBottom: '1rem',
  },
  timeInput: {
    flex: 1,
    minWidth: '120px',
  },
  formControl: {
    marginBottom: '1rem',
  },
  eventLinkContainer: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '1rem',
    marginBottom: '1rem',
    justifyContent: 'space-between',
  },
  textField: {
    flex: 1,
    minWidth: '120px',
  },
  upcomingContainer: {
    marginBottom: '10px'
  },
  upcomingEventCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderLeft: '4px solid black',
    padding: '1rem',
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    alignItems: 'start',
    marginBottom: '8px'
  },
  upcomingTitle: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    fontSize: '16px'
  },
  upcomingHost: {
    fontSize: '14px'
  },
  upcomingTime: {
    fontSize: '14px'
  },
  upcomingAttendees: {
    padding: '8px 12px',
    backgroundColor: '#f0f0f0',
    borderRadius: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: "uppercase",
    fontSize: '14px',
    color: "#6A6A6A"
  }
};
// Customizable Area End
