import MessageEnum, { getName } from "../../../../packages/framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { BlockComponent } from "../../../../packages/framework/src/BlockComponent";
import { apiCall } from "../../../components/src/ApiCall.web";
import { toast } from "react-toastify";
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    searchQuery:string;
    filteredLearningPaths:any;
    selectedLearningPathToAssign:any;
    selectedLearningPath:any;
      sortOrder:string,
    anchorEl:any;
    anchorElCourse:any;
    showActionPopup:boolean,
    isCreateLearningPathModalOpen:boolean,
    isDeleteLearningPathModalOpen:boolean,
    titleError:string,
    updatePackageModal:boolean;
    updatedPackage:any;
    currentPackage:any;
    updatedPackageName:string;
    userAnchorEl: any
    courseSearchQuery:string;
    learningPathSearchQuery:string;
    courseData:any[];
    unFilteredLearningPaths:any;
    isCloning:boolean;
    title:string;
    description:string;
    showCourseActionPopup:boolean;
    selectedCourse:any;
    learningPathDropdown:any[];
    isCreatingLearningPath:boolean;
    isDeleteCourseModalOpen:boolean;
    loading:boolean;
    courseLoading:boolean;
    currentFilter:string;
    // For lazy loading
    page: number;
    itemsPerPage: number;
    hasMore: boolean;
    pageCourseData: any[];
    // Accordion
    expanded: any[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AdminContentManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getLearningPathsApiCallId: string;
    getCoursesApiCallId: string;
    courseSearchQueryApiCallId: string;
    learningPathSearchQueryApiCallId: string;
    createLearningPathApiCallId: string;
    updateLearningPathApiCallId: string;
    deleteLearningPathsApiCallId: string;
    publishCourseApiCallId: string;
    deleteCourseApiCallId: string;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            sortOrder:'Relevance',
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            loading: false,
            courseLoading: false,
            searchQuery: '',
            filteredLearningPaths: [],
            learningPathDropdown: [],
            selectedLearningPathToAssign: null,
            selectedLearningPath:null,
            anchorEl: {},
            anchorElCourse: {},
            showActionPopup: false,
            isCreateLearningPathModalOpen: false,
            isDeleteLearningPathModalOpen: false,
            titleError: '',
            updatePackageModal: false,
            updatedPackageName: '',
            currentPackage: null,
            userAnchorEl: null,
            courseSearchQuery: '',
            learningPathSearchQuery: '',
            updatedPackage: null,
            courseData: [],
            isCloning: false,
            title: '',
            description: '',
            showCourseActionPopup: false,
            selectedCourse: null,
            isDeleteCourseModalOpen: false,
            isCreatingLearningPath: false,
            currentFilter:'all',
            unFilteredLearningPaths:[],
            // For lazy loading
            page: 0,
            itemsPerPage: 9,
            hasMore: true,
            pageCourseData: [],
            // Accordion
            expanded: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.getLearningPathsApiCallId = '';
        this.getCoursesApiCallId = '';
        this.courseSearchQueryApiCallId = '';
        this.learningPathSearchQueryApiCallId = '';
        this.createLearningPathApiCallId = '';
        this.updateLearningPathApiCallId = '';
        this.deleteLearningPathsApiCallId = '';
        this.publishCourseApiCallId = '';
        this.deleteCourseApiCallId = '';
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                switch (apiRequestCallId) {
                    case this.learningPathSearchQueryApiCallId: this.handleSearchLearningPathResponse(responseJson);
                        break;
                    case this.getLearningPathsApiCallId: this.handleLearningPathsResponse(responseJson);
                        break;
                    case this.courseSearchQueryApiCallId:
                    case this.getCoursesApiCallId: this.handleGetCoursesResponse(responseJson);
                        break;
                    case this.createLearningPathApiCallId: this.handleCreateLearningpathResponse(responseJson);
                        break;
                    case this.updateLearningPathApiCallId: this.handleUpdateLearningPathResponse(responseJson);
                        break;
                    case this.deleteLearningPathsApiCallId: this.handleDeleteLearningPath(responseJson);
                        break;
                    case this.deleteCourseApiCallId:   this.handleDeleteCourseResponse(responseJson);
                        break;
                    case this.publishCourseApiCallId: this.handleCourseActionResponse(responseJson);
                        break
                }
            }
            if (apiRequestCallId !== this.getLearningPathsApiCallId) {
                this.setState({ loading: false, courseLoading: false });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getLearningPaths();
        this.getCourses();
    }

    getCourses = async () => {
        this.setState({ courseLoading: true });
        this.getCoursesApiCallId = await apiCall({
            method: "GET",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `/bx_block_admin/courses`,
        });
    }
    convertToLearningPathdata = (data2 :any) => {
        return {
          data: data2.data.map((searchPath:any) => {
            const attributes = searchPath.attributes;
      
            return {
              id: attributes.learning_paths.id.toString(),
              type: "learning_path",
              attributes: {
                title: attributes.learning_paths.title,
                description: attributes.learning_paths.description,
                created_at: attributes.learning_paths.created_at,
                updated_at: attributes.learning_paths.updated_at,
                courses: attributes.courses.map((course:any) => ({
                  id: course.id,
                  title: course.title, 
                  duration: course.duration || null,
                  description: course.description || "",
                  passing_percentage: course.passing_percentage || null,
                  status: course.status || null,
                  lessons: course.lessons || null,
                  images: course.images || "", 
                })),
              },
            };
          }),
        };
      }
    handleSearchLearningPathResponse=(response:any) =>{
        if(response.data){
            const convertData = this.convertToLearningPathdata(response)
            this.handleLearningPathsResponse(convertData)
            this.setState({loading:false})
        }else if (response.errors || response.error) {
            this.setState({filteredLearningPaths:[]})
        }
    }

    handleGetCoursesResponse = (response: any) => {
        this.setState({
            courseData: response.data.map((course: any) => ({
                id: course.id,
                title: course.attributes.title,
                description: course.attributes.description,
                duration: course.attributes.duration,
                author_name: course.attributes.author_name,
                images: course.attributes.thumbnail_image,
                course_module_count: course.attributes.course_modules.length,
                status: course.attributes.status || null,
            }))
        })
        this.setState({courseLoading: false});
    }

    handleCourseActionResponse = (responseJson: any) => {
        if (responseJson.message) {
            toast.success(responseJson.message);
            this.setState({ isDeleteLearningPathModalOpen: false, anchorElCourse: {} });
            this.getLearningPaths();
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    handleCourseSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ courseSearchQuery: event.target.value }, async () => {
                if ( event.target.value.trim().length > 3 ) {
                    this.setState({ courseLoading: true });
                    this.courseSearchQueryApiCallId = await apiCall({
                        method: "GET",
                        navigation: this.props.navigation,
                        token: localStorage.getItem("token"),
                        endPoint: `/bx_block_admin/courses/search_packages?course=${this.state.courseSearchQuery}`,
                    })
                }    
        })
    }
    debounceSearchLearningPath = debounce( async (query:string) => {
        if (query !== "") {
            this.learningPathSearchQueryApiCallId = await apiCall({
                method: "GET",
                navigation: this.props.navigation,
                token: localStorage.getItem("token"),
                endPoint: `/bx_block_categories/learning_paths/search_learning_path?query=${query}`,
            })
        } else if (query === "") {
            this.getLearningPaths();
        }
    },500)
    handleLearningPathSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ learningPathSearchQuery: event.target.value })
        this.debounceSearchLearningPath(event.target.value)
    }

    handleDeleteLearningPath = (responseJson: any) => {
        if (responseJson.message) {
            toast.success(responseJson.message);
            this.setState({ isDeleteLearningPathModalOpen: false });
            this.getLearningPaths();
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    onSubmitLearningPath = (courseData: any) => {
        if (!courseData.length) {
            this.navigateToCourseCreation()
        }else {
            this.updateLearningPath()
        }
    }

    handleUpdateCourseSelection = (courseId: string) => {
        this.setState((prevState) => {
            const { updatedPackage } = prevState;

            if (!updatedPackage) {
                return {
                    updatedPackage: {
                        id: '',
                        name: '',
                        addCourse: [courseId.toString()],
                        removeCourse: [],
                    }
                };
            }

            const { addCourse = [], removeCourse = [] } = updatedPackage;
            if (addCourse.includes(courseId.toString())) {
                return {
                    updatedPackage: {
                        ...updatedPackage,
                        addCourse: addCourse.filter((id: any) => id.toString() != courseId.toString()),
                        removeCourse: [...removeCourse, courseId.toString()],
                    }
                };
            } else {
                return {
                    updatedPackage: {
                        ...updatedPackage,
                        addCourse: [...addCourse, courseId.toString()],
                        removeCourse: removeCourse.filter((id: any) => id.toString() != courseId.toString()),
                    }
                };
            }
        });
    };

    prepareRequestBody = (updatedPackage: any, isCloning: boolean) => {
        let learning_path: any = {};
        learning_path['add_course'] = updatedPackage?.addCourse;
        learning_path['remove_course'] = updatedPackage?.removeCourse;
        if (!isCloning) {
            learning_path['tite'] = updatedPackage?.title;
            learning_path['description'] = updatedPackage?.description;
        }
        const body = {
            "learning_path": learning_path
        }
        return body;
    }

    detailsBody = () => {
        let learning_path: any = {};
        learning_path['title'] = this.state.title;
        learning_path['description'] = this.state.description;
        return learning_path;
    }

    updateLearningPath = async (isOnlyDetails:boolean=false) => {
        const { updatedPackage, isCloning } = this.state
        if (isCloning && !this.state.selectedLearningPathToAssign) {
            toast.error('Please select learning path to assign.');
            return;
        }
        const reqBody = isOnlyDetails ? this.detailsBody() : this.prepareRequestBody(updatedPackage, isCloning);
        this.updateLearningPathApiCallId = await apiCall({
            method: "PATCH",
            navigation: this.props.navigation,
            contentType: 'application/json',
            token: localStorage.getItem("token"),
            body: reqBody,
            endPoint: `/bx_block_categories/learning_paths/${this.state.isCloning ? this.state.selectedLearningPathToAssign : updatedPackage?.id}`,
        });
    }

    createDropdownData = (data: any) => {
        const dropdownList: any[] = [];
        data.forEach((path: any) => {
            dropdownList.push({
                label: path.attributes.title,
                value: path.id
            })
        });
        return dropdownList;
    }

    updateDropdownSelected = (path: any) => {
        this.setState({ selectedLearningPathToAssign: path.value })
    }

    handleLearningPathsResponse = (responseJson: any) => {
        if (responseJson.data) {
            this.setState({ learningPathDropdown: this.createDropdownData(responseJson.data) })
            this.setState({ filteredLearningPaths: responseJson.data,loading:false ,unFilteredLearningPaths : [... responseJson.data]})          
            this.setState({expanded:responseJson.data.map((data:any) => +data.id)});
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    handleCreateLearningpathResponse = (responseJson: any) => {
        if (responseJson.data) {
            this.setState({ isCreatingLearningPath: true, currentPackage: responseJson.data });
            this.handlePackageOption(responseJson.data);
            this.closeCreateLearningPathModal();
            this.openUpdatePackageModal();
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    handleUpdateLearningPathResponse = (responseJson: any) => {
        if (responseJson.message) {
            toast.success(responseJson.message);
            this.getLearningPaths();
            this.closeUpdatePackageModal();
            this.setState({ anchorEl: null, showActionPopup: false, isCreateLearningPathModalOpen: false, isCreatingLearningPath: false, title: '', description: '' });
            if (this.state.isCreatingLearningPath) {
                this.getLearningPaths();
            }
        } else if (responseJson.errors || responseJson.error) {
            toast.error(responseJson.errors);
        }
    }

    closeCreateLearningPathModal = () => {
        this.setState({ isCreateLearningPathModalOpen: false, isCloning: false})
    }

    openCreateLearningPathModal = () => {
        this.setState({ isCreateLearningPathModalOpen: true });
    }

    openUpdatePackageModal = () => {
        this.setState({ updatePackageModal: true })
    }

    modalTitle = () => this.state.updatedPackage ? 'Update learning path' : 'Create new learning path';
    submitText = () => this.state.updatedPackage ? 'Update' : 'Add Courses';

    closeUpdatePackageModal = () => {
        this.setState({ updatePackageModal: false, isCloning: false })
    }

    handleFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const filter = event.target.value as string;
        this.setState({ currentFilter: filter })
    }

    isFilteredCourse = (course: any) => {
        if (course.status === 'published' && this.state.currentFilter === 'published') {
            return true;
        } else if (this.state.currentFilter === 'unpublished' && course.status !== 'published') {
            return true;
        } else if (this.state.currentFilter === 'all') {
            return true;
        } else {
            return false;
        }
    }

    getLearningPaths = async () => {
        this.setState({ loading: true });
        this.getLearningPathsApiCallId = await apiCall({
            method: "GET",
            contentType: "application/json",
            endPoint: `bx_block_categories/learning_paths`,
            token: localStorage.getItem("token"),
        });
    }

    createLearningPath = async () => {
        if (!this.state.title.length) {
            this.setState({ titleError: 'Title can not be blank.' });
            return;
        }
        this.createLearningPathApiCallId = await apiCall({
            method: "POST",
            contentType: "application/json",
            navigation: this.props.navigation,
            token: localStorage.getItem("token"),
            endPoint: `bx_block_categories/learning_paths`,
            body: {
                "learning_path": {
                    "title": this.state.title,
                    "description": this.state.description
                }
            },
        });
    }

    handlePopoverClick = (event: any, item = {}) => {
        event?.stopPropagation?.();
        this.setState({ anchorEl: event?.currentTarget, showActionPopup: true, selectedLearningPathToAssign: null, isCloning: false });
        this.handlePackageOption(item);
    }

    handlePopoverClose = () => {
        this.setState({ anchorEl: {}, showActionPopup: false, isCloning: false });
    }

    handleCoursePopoverClick = (event: any, item = {}) => {
        this.setState({ anchorElCourse: event?.currentTarget, showCourseActionPopup: true, selectedCourse: item });
    }

    handleCoursePopoverClose = () => {
        this.setState({ anchorElCourse: {}, showCourseActionPopup: false });
    }

    handlePublishCourse = async (course: any) => {
        this.handleCoursePopoverClose();
        const updatedBody =  {
          "data": {
            "type": "courses",
            "attributes": {
              "status":"published",
            }
          }
        }
        this.publishCourseApiCallId = await apiCall({
          method: "PATCH",
          body: updatedBody,
          contentType: "application/json",
          navigation: this.props.navigation,
          token: localStorage.getItem("token"),
          endPoint: `/bx_block_admin/courses/${course.id}`,
        })
       
    }

    handleUpdateCourse = (courseId:number) => {
        this.props.navigation.navigate('UpdateCourse',{path:{courseId}})
        this.handleCoursePopoverClose();
    }

    handleCloneCourse = () => {
        this.handleCoursePopoverClose();
    }

    handleUpdateLearningPath = (e: any, learningPath: any) => {
            this.openCreateLearningPathModal();
    }

    isLearningPathNameDuplicate = (val: string) => {
        return this.state.filteredLearningPaths.find((lp: any) => lp.attributes.title === val);
    }

    onChangeTitle = (e: any) => {
        this.setState({ title: e.target.value });
        if(this.isLearningPathNameDuplicate(e.target.value.trim())){
            this.setState({ titleError: 'The Learning Path name has already been taken. Please try another name' })
        }else{
            this.setState({titleError:''});
        }
    }

    handleDeleteCourse = async (confirmDelete: any) => {
        if (confirmDelete) {
            const courseId = this.state.selectedCourse.id;
            this.deleteCourseApiCallId = await apiCall({
                method: 'DELETE',
                contentType: 'application/json',
                endPoint: `bx_block_admin/courses/${courseId}`,
                token: localStorage.getItem('token'),
            });
            this.handleCoursePopoverClose();
            this.handleCloseDeleteModal();
        }
    };
    handleDeleteCourseResponse = (responseJson: any) => {
        if (responseJson.message) {
            toast.success(responseJson.message);
            this.setState({ isDeleteLearningPathModalOpen: false, anchorElCourse: {} });
            this.getLearningPaths();
        }
    }
    handleOpenDeleteModal = () => {
        this.setState({ isDeleteCourseModalOpen: true });
    };

    handleCloseDeleteModal = () => {
        this.setState({ isDeleteCourseModalOpen: false });
    };
    handlePackageOption = (learningPath: any) => {
        this.setState({
            currentPackage: learningPath,
            title: learningPath.attributes.title,
            description: learningPath.attributes.description,
            updatedPackage: {
                id: learningPath.id.toString(),
                title: learningPath.attributes.title,
                description: learningPath.attributes?.description || '',
                addCourse: learningPath.attributes.courses.map((c: any) => c.id.toString()),
                removeCourse: [],
            },
        });
    };

    handleAssignCourses = (e: React.MouseEvent<HTMLElement>, learningPath: any) => {
        e?.stopPropagation?.();
        this.openUpdatePackageModal();
        this.setState({
            showActionPopup: false,
            updatedPackageName: this.state.currentPackage?.attributes.name
        })
    }

    handleClone = (e: React.MouseEvent<HTMLElement>, learningPath: any) => {
        e?.stopPropagation?.();
        this.openUpdatePackageModal();
        this.setState({ courseData: this.state.currentPackage?.attributes.courses, isCloning: true, showActionPopup: false })
    }

    handleDelete = (e: React.MouseEvent<HTMLElement>, id: any) => {
        e?.stopPropagation?.();
        this.setState({ isDeleteLearningPathModalOpen: true, showActionPopup: false })
    }

    onDeletePathConfirm = async (isDelete: boolean) => {
        if (isDelete) {
            const id = this.state.currentPackage.id;
            this.deleteLearningPathsApiCallId = await apiCall({
                method: "DELETE",
                contentType: "application/json",
                endPoint: `bx_block_categories/learning_paths/delete_learning_path?id=${id}`,
                token: localStorage.getItem("token"),
            });
        } else {
            this.setState({ isDeleteLearningPathModalOpen: false })
        }
    }

    navigateToCourseCreation = () => {
        this.props.navigation.navigate('CourseCreation')
    }
    handleSortOrderChange = (event:any) => {
        this.setState({ sortOrder: event.target.value },this.updateFilteredPackages);
      };
    updateFilteredPackages = () => {
        const { filteredLearningPaths, sortOrder } = this.state;
        filteredLearningPaths.sort((firstLearning: any, secondLearning: any) => {
            switch (sortOrder) {
                case "a-z":
                    return firstLearning.attributes.title.localeCompare(secondLearning.attributes.title);

                case "z-a":
                    return secondLearning.attributes.title.localeCompare(firstLearning.attributes.title);

                case "old-to-new":
                    return dayjs(firstLearning.attributes.created_at).valueOf() - dayjs(secondLearning.attributes.created_at).valueOf();

                case "new-to-old":
                    return dayjs(secondLearning.attributes.created_at).valueOf() - dayjs(firstLearning.attributes.created_at).valueOf();
                case 'low-to-high':
                    return firstLearning.attributes.courses.length - secondLearning.attributes.courses.length; 
                case 'high-to-low':
                    return secondLearning.attributes.courses.length - firstLearning.attributes.courses.length;
                case "Relevance":
                default:
                    return 0;
            }
        });
        if (sortOrder === "Relevance") {
            this.setState({ filteredLearningPaths : [... this.state.unFilteredLearningPaths] });    
        }else{
        this.setState({ filteredLearningPaths });
        }
    };
    // Customizable Area End
}
