import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { apiCall } from "../../../../packages/components/src/ApiCall.web";
import { SelectChangeEvent } from '@mui/material/Select';
import React from "react";
import { toast } from "react-toastify";
import moment from "moment";
const { baseURL } = require("../../../framework/src/config.js");
import DOMPurify from 'dompurify';

const devices = [
  { id: 1, value: "desktop", label: "Desktop" },
  { id: 2, value: "mobile", label: "Mobile" },
  { id: 3, value: "tablet", label: "Tablet" },
  { id: 4, value: "other", label: "Other" }
];
const accreditationStatus = [
  { id: 123, value: "in_progress", label: "In Progress" },
  { id: 4214, value: "completed", label: "Completed" },
]
interface Course {
  id: string,
  name: string,
  feedbackForm:any,
}

interface Question {
  question: string,
  required: boolean,
  type: string
}

type QuestionField = 'question' | 'required' | 'type';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  value: number,
  txtInputValue: string,
  reviewModal: boolean,
  courseData: Course[],
  selectedCourse: Course,
  filteredForms:any,
  feedbackFormName: string,
  feedbackDescription:string,
  questions: Question[],
  filterCategory: string,
  sortBy: string,
  duration:string,
  feedbackForms: any,
  summaryModal: boolean,
  questionSummaryModal:boolean,
  feedback:any,
  reviewSummary:any,
  feedbackName:any,
  searchFeedback:any,

  //ReportAnalytics
  anchorEl: HTMLElement | null,
  menuRowId: null | number,
  page: number,
  rowsPerPage: number,
  Reportdata:any,
  reportModal:any,
  isEdit:boolean,
  metaData: {
    total_pages: number;
    current_page: number;
    total_count: number;
    page:number;
  };
  openReportTable:boolean;
  startDate: string | null;
  endDate: string | null;
  emailStartDate:string | null;
  emailEndDate:string | null;
  filterStartDate:string | null;
  filterEndDate:string | null;

  //createReport
  reportId:number;
  templateName:string;
  title: string;
  reportingArea: string;
  selectedColumns: any[];
  availableColumns: any[];
  arrangedColumns: any[];
  draggedIndex: number | null;
  selectedFilter: string;
  subFilter: any;
  availableFilters: any[];
  availableSubFilters: any[];
  emailOptions: string[],
  selectedEmails: string[],
   dateRange: string,
   reportPreview:any,
   openDialog:boolean,
   openTemplateDialog:boolean,
   openRunReport:boolean,
   startPickerOpen: boolean,
   endPickerOpen: boolean,
   picker:""
   isLoadingPage:boolean,
   isLoadingSubFilter:boolean,
   allClients:any,
   filterSelected:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AnalyticsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCoursesApiCallId: string = ""
  saveApiCallId: string = ""
  getAllFeedbackApiCallId:string =""
  getSummaryFeedbackApiCallId:string =""
  getSummaryQuestionApiCallId:string =""

  //reports
  getRunreportApiCallId:string =""
  getExportreportApiCallId:string =""
  deletereportApiCallId:string =""
  clonereportApiCallId:string =""
  saveAsTemplatereportApiCallId:string =""
  getReportApiCallId:string =""
  getClientNameListApiCallId:string=""
  getAccreditationsNameApiCallId:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area Start
      , getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      filterSelected:[],
      allClients:[],
      isLoadingSubFilter:false,
      value: 3,
      txtInputValue: "",
      reviewModal: false,
      courseData: [],
      selectedCourse: {
        id: "0",
        name: "All Courses",
        feedbackForm:[]
      },
      filteredForms:[],
      feedbackFormName: "",
      feedbackDescription:"",
      questions: [{ question: '', required: true, type: 'rating' }],
      filterCategory: "All Courses",
      sortBy: "Sort by Responses",
      duration:"Published",
      feedbackForms: [],
      feedbackName:"",
      summaryModal: false,
      questionSummaryModal:false,
      feedback: [],
      reviewSummary:[],
      searchFeedback:"",
    
    //Report Analytics
     anchorEl: null,
     menuRowId: null,
     page: 0,
     rowsPerPage: 10,
     metaData: {
      total_pages: 0, 
      current_page: 1,
      total_count: 0,
      page:0,
    },
    openReportTable:false,
     Reportdata: [],
     reportModal:false,
     isEdit:false,
     startDate: null,
     endDate: null,
     emailStartDate:null,
     emailEndDate: null,
     filterStartDate: null,
     filterEndDate: null,

     //CreateReport 
     reportId:0,
     templateName:"",
     title: "",
     reportingArea: "course_progress",
     selectedColumns: [],
     availableColumns: [
      { id: 1, label: "Average Total Time on the Platform" },
      { id: 2, label: "Device Usage Breakdown" },
      { id: 3, label: "Geolocation Breakdown" },
      { id: 4, label: "Average Assessment Attempts" },
      { id: 5, label: "Number of Users Who Completed the Course" },
      { id: 6, label: "Number of Users Currently Taking the Course" },
      { id: 7, label: "Number of Courses Assigned" },
      { id: 8, label: "Number of Courses Completed" },
    ],
     arrangedColumns: [],
     draggedIndex: null,
     selectedFilter: 'Select filter',
     subFilter: [],
     availableFilters: [
      { id: 1, value: "course_title", label: "Course Title" },
      { id: 2, value: "accreditation_status", label: "Accreditation Status" },
      { id: 3, value: "accreditation_name", label: "Accreditation Name" },
      { id: 4, value: "location", label: "Location" },
      { id: 5, value: "client_name", label: "Client Name" },
      { id: 6, value: "devices", label: "Devices" }
    ],
     availableSubFilters: [],
     emailOptions: ['Jahin Karim', 'Damodar Nagina'],
     selectedEmails: ['Jahin Karim', 'Damodar Nagina'],
    dateRange: '',
    reportPreview:[],
    openDialog: false,
    openTemplateDialog:false,
    openRunReport:false,
    startPickerOpen: false,
    endPickerOpen: false,
    picker:"",
    isLoadingPage:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
        this.handleCloseStartPopover = this.handleCloseStartPopover.bind(this);
        this.handleCloseEndPopover = this.handleCloseEndPopover.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    
    // Customizable Area End
    if (firebase.apps.length !== 0) {
      const defaultAnalytics = firebase.app().analytics();
      defaultAnalytics.logEvent("Analytics::Web::Load");
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
   
    if (apiRequestCallId === this.getAllFeedbackApiCallId) {
      this.handleGetAllFeedbackResponse(responseJson)
    }
    else if (apiRequestCallId === this.getCoursesApiCallId) {
      this.handleGetCoursesResponse(responseJson)
    }
    else if (apiRequestCallId === this.getSummaryFeedbackApiCallId){
      if (responseJson.error) {
        toast.error(responseJson.error)
      } else {
        this.handleGetSummaryFeedbackResponse(responseJson)
      }
    }else if (apiRequestCallId === this.getSummaryQuestionApiCallId){
      if (responseJson.error) {
        toast.error(responseJson.error)
      } else {
        this.handleGetSummaryQuestionResponse(responseJson)
      }
    }else if (apiRequestCallId === this.saveApiCallId) {
      this.handleReviewModalClose()
      if (responseJson.errors) {
        toast.error(responseJson.errors[0])
      } else {
        toast.success("Feedback form created successfully")
        this.getAllFeedbackforms()
      }
    }else if(apiRequestCallId === this.getRunreportApiCallId){
      if (responseJson.error) {
        this.setState({reportPreview:[]})
      } else {
        this.handleGetRunReportResponse(responseJson)
      }  
    }else if(apiRequestCallId === this.getExportreportApiCallId){
      if (responseJson.error) {
        toast.error(responseJson.error)
      } else {
        this.handleGetExportReportResponse(responseJson)
        toast.success("CSV file Exported successfully!")
      }
    }else if(apiRequestCallId === this.deletereportApiCallId){
      this.handleDeleteReportResponse(responseJson)
      if (responseJson.errors) {
        toast.error(responseJson.errors[0])
      } else {
        toast.success("Report deleted successfully!")
      }
    }else if(apiRequestCallId === this.clonereportApiCallId){
      this.handleCloneReportResponse(responseJson)
      if (responseJson.errors) {
        toast.error(responseJson.errors[0])
      } else {
        toast.success("Report cloned successfully")
        this.getReport()
      }
    }else if(apiRequestCallId === this.saveAsTemplatereportApiCallId){
      this.handlesaveAsTemplateReportResponse(responseJson)
      if (responseJson.error) {
        toast.error("Report Not Found")
      } else {
        toast.success("Report saved as template")
      }
    }else if(apiRequestCallId === this.getReportApiCallId){
      this.handleGetReportResponse(responseJson)
    }
    switch (apiRequestCallId) {
      case this.getClientNameListApiCallId:
          this.handleGetAllClientsResponse(responseJson)
        break;
      case this.getAccreditationsNameApiCallId:
        this.handleGetAccreditationNameResponse(responseJson)
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  async doButtonPressed() {
    const defaultAnalytics = firebase.app().analytics();
    defaultAnalytics.logEvent("Analytics::Web::button_clicked");
  }

  // Customizable Area Start
  handleGetAccreditationNameResponse=(res:any)=>{
    if(res.data){
      this.setState({
        isLoadingSubFilter:false,
        availableSubFilters:res.data.map((item:any)=>({id:item.id,label:item.attributes.title,value:item.id}))
      })

    }
  }
  handleGetAllClientsResponse =(res:any)=>{
    if(res.clients.data){
      this.setState({
        isLoadingSubFilter:false,
        allClients:res.clients.data.map((item:any)=>({id:item.id,label:item.name,value:item.id})),
        availableSubFilters:res.clients.data.map((item:any)=>({id:item.id,label:item.name,value:item.id}))
      })
    }
  }

  getAccreditations = async () => {
    this.getAccreditationsNameApiCallId = await apiCall({
        method: "GET",
        contentType: "application/json",
        endPoint: `bx_block_profile/accreditations`,
        token: localStorage.getItem("token"),
    });
}
  getAllClients = async () => {
    this.getClientNameListApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/clients/client_list`,
    });
  }
  /* istanbul ignore next */
  async componentDidMount(): Promise<void> {
    this.getCourses()
    this.getAllFeedbackforms()
    this.getReport()
    document.addEventListener("scroll", this.handleScroll, true);
    document.removeEventListener("click", this.handleOutsideClick);
  }

  /* istanbul ignore next */
  async componentWillUnmount(): Promise<void> {
    document.removeEventListener("scroll", this.handleScroll, true);
    document.removeEventListener("click", this.handleOutsideClick);
  }

  handleCloseStartPopover() {
    this.setState({
      startPickerOpen: false,
    });
  }
  handleCloseEndPopover() {
    this.setState({
      endPickerOpen: false,
    });
  }
  /* istanbul ignore next */
  handleOutsideClick(event:any) {
    const target = event.target;
    if (!target.closest(".MuiPickersPopper-root")) {
      this.handleCloseEndPopover();
      this.handleCloseStartPopover();

    }
  }
  handleScroll() {
    this.handleCloseEndPopover();
    this.handleCloseStartPopover();
  }


  /* istanbul ignore next */
  handleChangeTabAnalytics=(value:string)=>{
    this.props.navigation.navigate(value)
  }

  /* istanbul ignore next */
  handlefilterChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>,
    fieldName: string
  ) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
      summaryModal:false,
      questionSummaryModal:false,
      feedbackName:""
    }));
    const sortedForms = [...this.state.feedbackForms];

  if (value === "Sort by Name") {
    sortedForms.sort((a, b) => a.name.localeCompare(b.name));
  } else if (value === "Sort by Responses") {
    sortedForms.sort((a, b) => b.number_of_responses - a.number_of_responses);
  } else if (value === "Sort by Questions") {
    sortedForms.sort((a, b) => b.number_of_questions - a.number_of_questions);
  } else {
    console.error("Unknown sorting criteria");
  }
  this.setState({feedbackForms:sortedForms})
  };

  /* istanbul ignore next */
  handleSearchFeedback =( e:any) =>{
    const { value } = e.target;
    this.setState({searchFeedback:value})
  }

  /* istanbul ignore next */
  handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      this.getAllFeedbackforms();
    }
  }
  handleCloseFeedback =()=>{
    this.setState({ 
      summaryModal:false,
      questionSummaryModal:false,
      feedbackName:""
     })
  }

  handleCloseQuestion =()=>{
    this.setState({ 
      questionSummaryModal:false,
      summaryModal:true,
     })
  }
  
  handleReviewModalOpen = () => {
    this.setState({ reviewModal: true })
  }

  handleReviewModalClose = () => {
    this.setState({
      reviewModal: false,
      feedbackFormName: "",
      feedbackDescription:"",
      questions: [{ question: "", required: false, type: "rating" }],
      selectedCourse: { id: '0', name: "All Courses",feedbackForm:[] }
    })
  }

  /* istanbul ignore next */
  handlefeedbackFormOpen = async(id:any,name:any) => {
        this.getSummaryFeedbackApiCallId = await apiCall({
        method: "GET",
        navigation: this.props.navigation,
        token: localStorage.getItem("token"),
        endPoint: `/bx_block_profile/feedback_forms/feedback_summary?feedback_form_id=${id}`,
      });
      this.setState({ summaryModal: true,feedbackName:name})
  }

  /* istanbul ignore next */
  handlefeedbackQuestionOpen = async(id:any,feedbackId:any) => {
    this.getSummaryQuestionApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_profile/feedback_forms/feedback_summary?question_id=${id}&feedback_form_id=${feedbackId}`,
    });
}
  handleSummaryModalClose = () => {
    this.setState({ summaryModal: false });
  };

  getAllFeedbackforms = async () => {
    const {searchFeedback}=this.state
    this.getAllFeedbackApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_profile/feedback_forms/user_review?name=${searchFeedback.trim()}`,
    });
  }


  getCourses = async () => {
   this.setState({isLoadingPage:true}) 
    this.getCoursesApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/courses/list_courses_and_learning_path`,
    });
  }

  getRunReport = async (id:any,startDate:any, endDate:any) => {
    const start=moment(startDate).format("YYYY-MM-DD")
    const end=moment(endDate).format("YYYY-MM-DD")

    this.getRunreportApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_projectreporting/report_analytics/${id}?startDate=${start}&endDate=${end}`,
    });
  }

  /* istanbul ignore next */
  getExportRunReport = async (id:any) => {
    this.getExportreportApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_projectreporting/report_analytics/${id}/export`,
    });
  }

  /* istanbul ignore next */
  cloneReport = async (id:any) => {
    this.clonereportApiCallId = await apiCall({
      method: "PATCH",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_projectreporting/report_analytics/${id}/clone_report`,
    });
  }
  
  /* istanbul ignore next */
  saveAsTemplateReport = async (id:any) => {
    const body={
      template_name:this.state.title
    }
    this.saveAsTemplatereportApiCallId = await apiCall({
      method: "PATCH",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_projectreporting/report_analytics/${id}/save_as_template`,
      body: body
    });
  }

  /* istanbul ignore next */
  deleteReport = async (id:any) => {
    this.deletereportApiCallId = await apiCall({
      method: "DELETE",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_projectreporting/report_analytics/${id}`,
    });
  }

  handleGetCoursesResponse = (response: any) => {
    this.setState({
      isLoadingPage: false,
      courseData: response?.map((course: any) => ({
        id: course.id,
        name: course.title,
        feedbackForm:course.feedback_forms
      }))
    })
  }
  
  handleGetAllFeedbackResponse = (response: any) => {
    console.log(response,"response11");
    this.setState({ feedbackForms:response?.feedback_forms})
  }
  handleGetSummaryFeedbackResponse = (response: any) => {
    this.setState({ summaryModal: true })
    this.setState({ feedback :response})
  }
  handleGetSummaryQuestionResponse = (response: any) => {
    this.setState({ questionSummaryModal: true ,summaryModal:false})
    this.setState({ reviewSummary :response})
  }

  /* istanbul ignore next */
  handleGetRunReportResponse = (response: any) => {
    const data = response.map((item:any)=>{
      if (item.course_id) {
        return {
          name: item.course_name,
          companyName: item.course_id,
          courseAssigned: item.assigned_users_count,
          courseCompleted: item.completed_users_count ,
          accreditationAchieved: item.not_completed_users_count,
          avgCompletionRate: item.avg_time_to_complete,
          totalLearningPathCompleted: item.total_learning_hours,
          totalLearningHours: item.total_learning_hours,
    
    };
      } else if (item.user_id) {
        return {
          name: item.name,
          companyName: item.company_name,
          courseAssigned: item.number_of_courses_assigned,
          courseCompleted: item.number_of_courses_completed ,
          accreditationAchieved: item.number_of_courses_left_to_incomplete,
          avgCompletionRate: item.average_assessment_attempts,
          totalLearningPathCompleted: item.total_learning_time,
          totalLearningHours: item.average_total_time_on_platform,
          
        };
      } else if (item.client_id) {
        return {
          name: item.company_name,
          companyName: item.total_users,
          courseAssigned: item.course_assigned,
          courseCompleted: item.course_completed ,
          accreditationAchieved: item.average_user_completion_rate,
          avgCompletionRate: item.average_user_completion_rate,
          totalLearningPathCompleted: item.total_learning_path_completed,
          totalLearningHours: item.total_learning_hours,
          
        };
      }
      
    })
    this.setState({reportPreview:data})
  }

  handleGetExportReportResponse = (response: any) => {
    window.open(response.csv,'_blank')
  }

  handleDeleteReportResponse = (response: any) => {
    this.handleMenuClose();
    this.getReport()
  }

  handleCloneReportResponse = (response: any) => {
    this.handleMenuClose();
  }

  handlesaveAsTemplateReportResponse = (response: any) => {
    this.handleCloseTemplateModal()
    this.handleCloseReport()
  }

  handleGetReportResponse =(response: any) => {
    console.log(response);
    this.setState({
      Reportdata:response.data,
      metaData:{
        ...this.state.metaData,
        current_page: response.meta.current_page,
        total_pages: response.meta.total_pages,
        total_count: response.meta.total_count,
      } 
     })
    
  }

  /* istanbul ignore next */
  handleFilterSelectedCourse = async (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const selectedId = event.target.value
    const selectedClient = this.state.courseData.find(c => c.id === selectedId);

    if (selectedClient) {
      this.setState({
        selectedCourse: {
          id: selectedClient.id,
          name: selectedClient.name,
          feedbackForm:selectedClient.feedbackForm,
        },
        summaryModal:false,
        questionSummaryModal:false,
        feedbackName:""
      });
      const filteredForms = this.state.feedbackForms.filter((form:any) => selectedClient.feedbackForm.includes(form.id)); 
      this.setState({filteredForms:filteredForms})
    }else{
      this.setState({
        selectedCourse:  {
          id: "0",
          name: "All Courses",
          feedbackForm:[]
        },
        filteredForms:[],
        summaryModal:false,
        questionSummaryModal:false,
        feedbackName:""
      })
      this.getAllFeedbackforms()
    }
  };
  handleSelectedCourse = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    const selectedId = event.target.value
    const selectedClient = this.state.courseData.find(c => c.id === selectedId);

    if (selectedClient) {
      this.setState({
        selectedCourse: {
          id: selectedClient.id,
          name: selectedClient.name,
          feedbackForm:selectedClient.feedbackForm
        },
      });
    }
  };

  handleFeedbackFormName = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ feedbackFormName: event.target.value })
  }
  

  handleAddQuestion = () => {
    this.setState(prevState => ({
      questions: [...prevState.questions, { question: '', required: true, type: 'rating' }]
    }));
  };
  handleQuestionChange = (index: number, field: keyof Question, value: string | boolean) => {
    const questions = [...this.state.questions];
    const newValue = value
    if (
      (field === "question" && typeof value === "string") ||
      (field === "type" && typeof value === "string")
    ) {
      questions[index][field] = value;
    } else if (
      (field === "required" && typeof value === "boolean")) {
      questions[index][field] = newValue as boolean;
    }

    this.setState({ questions });
  };

  handleDeleteQuestion = (index: number) => {
    const questions = this.state.questions.filter((_, i) => i !== index);
    this.setState({ questions });
  };

  getQuestionType = (type: string) => {
    const typeMapping: { [key: string]: number } = {
      'rating': 0,
      'free-text': 1,
    };
    return typeMapping[type];
  }

  handleSave = async () => {
    const { selectedCourse, feedbackFormName, questions , feedbackDescription } = this.state;
    // "admin_user": 1,

    const body = {
      "feedback_form": {
        "course_id": selectedCourse.id,
        "name": feedbackFormName,
        "description":feedbackDescription,
        "feedback_questions_attributes": questions.map((q) => ({
          "question": q.question,
          "question_type": this.getQuestionType(q.type),
        })),
      },
    }

    this.saveApiCallId = await apiCall({
      method: "POST",
      contentType: 'application/json',
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_profile/feedback_forms`,
      body: body
    });
  }

  //Report Analytics

  handleMenuOpen = (event: any, id: number) => {
    this.setState({ anchorEl: event.currentTarget, menuRowId: id });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, menuRowId: null });
  };

  handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.setState({ page: newPage });
  };

  /* istanbul ignore next */
  handlePageChange = async (event: React.ChangeEvent<unknown>, value: number) => {
    const { rowsPerPage } = this.state;
    this.setState({
      metaData: {
        ...this.state.metaData,
        current_page: value,
        page: value - 1
      },
    });
    
    this.getReportApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_projectreporting/report_analytics?page=${value}&per_page=10`,
    });
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };


  //createReports
   handleCreateReport = (data:any,Edit:any) => {
    if(Edit === "Edit"){
      const {attributes} =data
      this.setState({ 
        reportModal :true,
        isEdit:true,
        title:attributes?.name ??'', 
        reportingArea:attributes?.report_type,
        startDate:attributes?.start_date,
        endDate:attributes?.end_date,
        reportId:data.id,
      });
    }else{
      this.setState({ reportModal :true ,isEdit:false});
    }
  };


  getReport = async()=>{
    this.getReportApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_projectreporting/report_analytics?page=${this.state.page}&per_page=10`,
    });
  }

  /* istanbul ignore next */
  handleSaveReport = async (isEdit:any) =>{
    const {title,reportingArea,filterStartDate,filterEndDate,reportId}=this.state
    const start = moment(filterStartDate).format("YYYY-MM-DD")
    const end = moment(filterEndDate).format("YYYY-MM-DD")

    const formData = new FormData();
    formData.append("[report][name]", title);
    formData.append("[report][report_type]", reportingArea);
    formData.append("[report][filters]", JSON.stringify([]));
    formData.append("[report][start_date]", start);
    formData.append("[report][end_date]", end);

      if(isEdit){
        fetch(`${baseURL}/bx_block_projectreporting/report_analytics/${reportId}`, {
          headers:{
           token:localStorage.getItem("token") || "",
          },
          body:formData,
          method:"PATCH"
        })
       .then(res=>res.json())
       .then(res=>{
         if (res.error) {
           toast.error("Report Not Updated")
         } else {
           toast.success("Report Updated SuccessFully")
           this.handleCloseReport()
           this.getReport()
         }
       })
       }else{
        fetch(`${baseURL}/bx_block_projectreporting/report_analytics`, {
          headers:{
           token:localStorage.getItem("token") || "",
          },
          body:formData,
          method:"POST"
        })
       .then(res=>res.json())
       .then(res=>{
         if (res.error) {
           toast.error("Report Not Found")
         } else {
           toast.success("Report Created SuccessFully")
           this.handleCloseReport()
           this.getReport()
         }
       })
       }
  }

  handleCloseReport = () => {
      this.setState({ 
        reportModal :false,
        isEdit:false,
          title:"",
          reportingArea:"course_progress",
          startDate:null,
          endDate:null,
          reportId:0,
          emailStartDate:null,
          emailEndDate:null,
      });
  };

  handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: event.target.value });
  };

  handleTemplateTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ templateName: event.target.value });
  };

  handleFilterChange = (event: any) => {
    this.setState({ selectedFilter: event.target.value,subFilter:[],isLoadingSubFilter:true });
    switch (event.target.value) {
      case "course_title":
        this.setState({
          isLoadingSubFilter:false,
          availableSubFilters:this.state.courseData.map(item=>({id:item.id,label:this.sanitizeText(item.name),value:item.id}))
        });
        break;
      case "accreditation_status":
        this.setState({
          isLoadingSubFilter:false,
          availableSubFilters:accreditationStatus
        })
        break;
      case "accreditation_name":
        this.getAccreditations()
        break;
      case "location":
        return "Location";
      case "client_name":
        this.state.allClients.length>0?
        this.setState({availableSubFilters:this.state.allClients,isLoadingSubFilter:false})
        :this.getAllClients();
        break;
      case "devices":
        this.setState({availableSubFilters:devices,isLoadingSubFilter:false})
        break
      default:
        break;
    }
  };

  handleSubFilterChange = (event:any) => {
    this.setState({ subFilter: event.target.value as string[] });
  };

  /* istanbul ignore next */
  handleEmailChange = (event:any) => {
    const { value } = event.target;
    this.setState({ selectedEmails: value });
  };
  /* istanbul ignore next */
  handleChipDelete = (emailToDelete:any) => {
   this.setState((prevState) => ({
      selectedEmails: this.state.selectedEmails.filter(
        (email) => email !== emailToDelete
      ),
    }));
  };

  handleSelectColoumn =(e:any,column:any)=>{
    /* istanbul ignore next */
    const isChecked = e.target.checked;
   
    /* istanbul ignore next */
    this.setState((prevState) => {
      const updatedSelectedColumns = isChecked
        ? [...prevState.selectedColumns, column]
        : prevState.selectedColumns.filter((c) => c !== column);
      return {
        selectedColumns: updatedSelectedColumns,
        arrangedColumns: prevState.availableColumns.filter((col) =>
          updatedSelectedColumns.includes(col.id)
        ),
      };
    });
  }

  handleDragStart = (index: number) => {
    this.setState({ draggedIndex: index });
  };

  handleDragOver = (e: any) => {
    e.preventDefault();
  };

  handleDrop = (index: number) => {
    const { draggedIndex, arrangedColumns } = this.state;
    
    /* istanbul ignore next */
    if (draggedIndex !== null && draggedIndex !== index) {
      const newItems = [...arrangedColumns];
      const [movedItem] = newItems.splice(draggedIndex, 1);
      newItems.splice(index, 0, movedItem);
      this.setState({ arrangedColumns: newItems, draggedIndex: null });
    }
  };

  handleDragEnd = () => {
    this.setState({ draggedIndex: null });
  };

  handleDelete = (index: number) => {
    const newItems = this.state.arrangedColumns.slice();
    newItems.splice(index, 1);
    const { arrangedColumns, selectedColumns } = this.state;
    const updatedArranged = [...arrangedColumns];
    const removedColumn = updatedArranged.splice(index, 1)[0];
    
    this.setState({ 
    arrangedColumns: newItems,
    selectedColumns: selectedColumns.filter((colId) => colId !== removedColumn.id) });
  };

  isValidDate = (date: string) => {
    // Check if the date matches the format YYYY-MM-DD
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    return regex.test(date);
  };

  handleOpenPreviewReport = () => {
    const {reportId,startDate, endDate}=this.state
    this.setState({ openDialog: true });
    this.getRunReport(reportId,startDate, endDate)
  };
  
  handleOpenRunReport = (id:any) => {
    const {startDate, endDate}=this.state
    this.setState({ openRunReport: true,reportId:id,openDialog:true });
    this.getRunReport(id,startDate, endDate)

  };

  handleOpenTemplateModal = () => {
    this.setState({ openTemplateDialog: true ,openDialog:false,templateName:this.state.title});
  };

  handleCloseTemplateModal = () => {
    this.setState({ openTemplateDialog: false,templateName:"" })
  };
  handleOpenreportTable =()=>{
    this.setState({openReportTable:true});
  }

  handleExportReport = (id:any)=>{
   this.getExportRunReport(id)
  }
  previewReportClose =()=>{
    this.setState({ openDialog: false,openRunReport:false,startDate:null,endDate:null })
  }

  /* istanbul ignore next */
  handleStartDateChange = (date : any,range:any) => {
    if(range==="filter"){
    this.setState({ filterStartDate: date  });
    }else if(range==="email"){
      this.setState({ emailStartDate: date  });
    }else {
      this.setState({ startDate: date  });
    }
  };
  
  /* istanbul ignore next */
  handleChangereportingArea = (event: any) => {
    this.setState({reportingArea :event.target.value});
  };
/* istanbul ignore next */
  handleEndDateChange = (date : any,range:any) => {
    if(range==="filter"){
      this.setState({ filterEndDate: date  });
    }else if(range ==="email"){
      this.setState({ emailEndDate: date  })
    }else{
      this.setState({ endDate: date  });
    }
  };
  sanitizeText = (htmlString:string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  };
  handleAddFilterClick = () => {
    const { subFilter, availableSubFilters, filterSelected } = this.state;
  
    const selectedObjects = availableSubFilters.filter(item =>
      subFilter.includes(item.id)
    );
  
    const updatedFilterSelected = filterSelected.filter(
      (item:any) => !selectedObjects.some(selected => selected.id === item.id)
    );
  
    selectedObjects.forEach(selected => {
      if (!filterSelected.some((item:any) => item.id === selected.id)) {
        updatedFilterSelected.push(selected);
      }
    });
  
    this.setState({ filterSelected: updatedFilterSelected,selectedFilter:'Select filter',availableSubFilters:[] })
  };
  handleDelteFilter = (id:any)=>{
    const newFilterSelected = this.state.filterSelected.filter((item:any)=>item.id!==id)
    this.setState({filterSelected:newFilterSelected})
  }
  // Customizable Area End
}
