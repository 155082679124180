import React, { useState,useEffect } from "react";
import { DragDropContext, Droppable, Draggable, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import { v4 as uuidv4 } from 'uuid';
import {Box} from '@mui/material'
import { Menu, CheckCircle, Cancel } from "@mui/icons-material";

type Item = {
  id: any;
  title: any;
  category_id: any;
  _destroy?:boolean
};

type Category = {
  id: any;
  title: any;
  items: Item[];
  _destroy?:boolean
};


type StateType = {
  main:Item[],
  dropZones:Category[]
}
const shuffleArray = (array: Item[]) => {
  const shuffled = [...array]; 
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); 
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]]; 
  }
  return shuffled;
};

// Move function
const move = (source: any[] = [], destination: any[] = [], droppableSource: any, droppableDestination: any, item: any) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);

  // Remove from source
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(0, 0, removed);

  const result: any = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

// Styling for draggable items
const getItemStyle: any = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined, index: number) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: "#FFF",
  borderRadius: "4px",
  boxShadow: isDragging ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "0 2px 4px rgba(0, 0, 0, 0.1)",
  width: "80%",
  height: "80%", 
  position: "absolute", 
  zIndex:100-index, 
  transition: "opacity 0.3s ease",
  ...draggableStyle,
});

// Styling for droppable areas
const getListStyle: any = (isDraggingOver: boolean,isDraggingFromMain:any) => {
  return ({
    padding: grid,
    width: '200px',
    height: '200px',
    borderRadius: "4px",
    border: "2px dashed #ccc",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: 'hidden',
    position: "relative",
    boxSizing: 'border-box',
    '&>div': {
      transform: isDraggingOver&&!isDraggingFromMain && 'translate(0) !important'
    },
    '& .dropzone-title':{
                            transform: 'translate(-50%, -50%) !important',
    }
  });
};

const SortingType = ({data}:{data:Category[]}) => {
  const [state, setState] = useState<StateType>({
    main: [
      {
        id: uuidv4(),
        title: 'Item 1',
        category_id: 'drop-1',
      },
      {
        id: uuidv4(),
        title: 'Item 2',
        category_id: 'drop-1',
      },
    ],
    dropZones: [
      {
        id: 'drop-1',
        title: 'Category 1',
        items: []
      },
      {
        id: 'drop-2',
        title: 'Category 2',
        items: []
      },
    ],
  });

  useEffect(() => {
    const dropZoneArr:Category[] =data.map(item=>({...item,items:[]}))
    const itemArr: Item[] = shuffleArray(data.flatMap(category => category.items))

    setState(prev=>({...prev,main:itemArr,dropZones:dropZoneArr}))
  }, [data])
  
  // Helper function to find a drop zone by ID
  const findDropZoneById = (id: any) => state.dropZones.find((zone: any) => `${zone.id}` === `${id}`);

  // Helper function to update a drop zone
  const updateDropZone = (dropZoneId: any, newItems: any) => {
    setState((prevState: any) => ({
      ...prevState,
      dropZones: prevState.dropZones.map((zone: any) =>
        `${zone.id}` === `${dropZoneId}` ? { ...zone, items: newItems } : zone
      ),
    }));
  };

  const onDragEnd = (result: any) => {
    const { source, destination, draggableId } = result;
    // Dropped outside the list
    if (!destination) {
      return;
    }

    const sInd = `${source.droppableId}`;
    const dInd = `${destination.droppableId}`;
    // // Find the item being dragged
    const item =
      sInd === "main"
        ? state.main.find((i: any) => `${i.id}` === `${draggableId}`)
        : findDropZoneById(sInd)?.items.find((i: any) => `${i.id}` === `${draggableId}`);

    if (!item) return;

    if (sInd === dInd) {
     return
    } else {
      // Move between lists
      const sourceItems = sInd === "main" ? state.main : findDropZoneById(sInd)?.items || [];
      const destinationItems = dInd === "main" ? state.main : findDropZoneById(dInd)?.items || [];

      const result = move(sourceItems, destinationItems, source, destination, item);
      if (sInd === "main") {
        setState({ ...state, main: result[sInd] });
      } else {
      
        updateDropZone(sInd, result[sInd]);
      }

      if (dInd === "main") {
        setState({ ...state, main: result[dInd] });
        updateDropZone(sInd, result[sInd]);
      } else {
        
        updateDropZone(dInd, result[dInd]);
      }
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px", padding: "20px"}}>
      {/* Main Drop Zone */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="main" type="NO_REORDER">
          {(provided, snapshot) => {
            const isDraggingFromMain = state.main.findIndex(item=>`${item.id}`===`${snapshot.draggingOverWith}`) !== -1
            return (
              <Box
                ref={provided.innerRef}
                sx={{
                  ...getListStyle(snapshot.isDraggingOver, isDraggingFromMain),
                  border: 'none',
                  marginBottom: '30px'
                }}
                {...provided.droppableProps}
              >
                {state.main.length === 0 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                            color: '#666',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                          }}
                        >
                          {'Empty'}
                        </Box>
                      )}
                {state.main.filter(item=>!item._destroy).map((item, index) => (
                  <Draggable key={`${item.id}`} draggableId={`${item.id}`} index={index}>
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...getItemStyle(snapshot.isDragging, provided.draggableProps.style, index),
                          borderWidth: '1px 1px 4px 1px',
                          borderStyle: 'solid',
                          borderColor: '#1C1C1C',
                          backgroundColor: "#fff",
                          margin: 0
                        }}
                      >
                        <Box
                          width='100%'
                          height='100%'
                          position={'relative'}
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <div></div>
                          <p>{item.title}</p>
                          <Menu />
                        </Box>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            );
          }}
        </Droppable>

        {/* Drop Zones */}
        <div style={{ display: "flex", gap: "20px",width:'100%',justifyContent:'space-around' }}>
          {state.dropZones.filter(item=>!item._destroy).map((dropZone: Category) => (
            <div key={`${dropZone.id}`}>
              <Droppable droppableId={`${dropZone.id}`} type="NO_REORDER">
                {(provided, snapshot) => {
            const isDraggingFromMain = dropZone.items.findIndex(item=>`${item.id}`===`${snapshot.draggingOverWith}`) !== -1
                return (
                    <Box
                      ref={provided.innerRef}
                      sx={{
                        ...getListStyle(snapshot.isDraggingOver,isDraggingFromMain),
                        background: '#F0F0F0',
                        padding: '20px'
                      }}
                      {...provided.droppableProps}
                    >
                      {dropZone.items.length === 0 && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            textAlign: 'center',
                            color: '#666',
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                          }}
                          className='dropzone-title'
                        >
                          {dropZone.title}
                        </Box>
                      )}
                      {dropZone.items.filter(item=>!item._destroy).map((item, idx) => (
                        <Draggable key={`${item.id}`} draggableId={`${item.id}`} index={idx}>
                          {(provided, snapshot) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...getItemStyle(snapshot.isDragging, provided.draggableProps.style, idx),
                                padding: 0,
                              }}
                            >
                              <Box
                                display={'flex'}
                                height='100%'
                                width='100%'
                                position={'relative'}
                                padding={0}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}>
                                {item.title}
                                <div style={{ position: 'absolute', left: '-10px', top: '-10px' }}>
                                  {`${item.category_id}` === `${dropZone.id}` ? <CheckCircle style={{ color: '#059669' }} /> : <Cancel style={{ color: '#FC5555' }} />}
                                </div>
                              </Box>
                            </Box>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  );
                }}
              </Droppable>
            </div>
          ))}
        </div>
      </DragDropContext>
    </div>
  );
};

export default SortingType;