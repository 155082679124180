import React, { ReactNode } from "react";
import { BlockComponent } from "../../framework/src/BlockComponent";
import {
  Hidden, Box, Button, Typography, Drawer, Menu, MenuItem,
  List, ListItem, ListItemText, ListItemAvatar,
} from "@mui/material";
import clsx from 'clsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { styled } from "@mui/material/styles";
import Contactus from "../../blocks/contactus/src/ContactPage.web";
import MobileHeader from "./MobileHeader.web";
import { toast } from "react-toastify";
import { MyContext } from './NavigationRouteWrapper/Web';

interface Props {
  navigation: any;
  supportpagename?: string;
  islogin?: boolean;
  isHeader?: boolean;
  children: ReactNode;
  isFooterUnVisible?: boolean;
  isMenuItemsUnVisible?: boolean;
  isLogoUnVisible?: boolean;
  goBackLabel?: string;
  goBackFunction?:()=>void;
  isloginscreen?:boolean;
}

interface S {
  loading: boolean;
  isOpen: boolean;
  anchorEl: null | HTMLElement;
}

interface SS {
  navigation: string;
}

interface IWrapper {
  islogin?: boolean;
  supportpagename?: string;
}

const items = [
  {
    key: 'account',
    primary: 'Account',
    secondary: 'Settings related to your personal information and account credentials',
    Icon: <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M12 4C12 6.21 10.21 8 8 8C5.79 8 4 6.21 4 4C4 1.79 5.79 0 8 0C10.21 0 12 1.79 12 4ZM10 4C10 2.9 9.1 2 8 2C6.9 2 6 2.9 6 4C6 5.1 6.9 6 8 6C9.1 6 10 5.1 10 4ZM8 9C5.33 9 0 10.34 0 13V15C0 15.55 0.45 16 1 16H15C15.55 16 16 15.55 16 15V13C16 10.34 10.67 9 8 9ZM2 13.01V14H14V13C13.8 12.29 10.7 11 8 11C5.3 11 2.2 12.29 2 13.01Z" fill="black" /></svg>,
    navigateTo: 'UserProfile',
  },
  {
    key: 'help-center',
    primary: 'Help Center',
    secondary: "View and manage FAQ's",
    Icon: <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1 0H17C17.55 0 18 0.45 18 1V1.01C18 1.56 17.55 2.01 17 2.01H1C0.45 2.01 0 1.56 0 1.01V1C0 0.45 0.45 0 1 0ZM17 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7H17C17.55 7 18 6.55 18 6C18 5.45 17.55 5 17 5ZM1 12H11C11.55 12 12 11.55 12 11C12 10.45 11.55 10 11 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12Z" fill="black" /></svg>,
    navigateTo: 'HelpCenter',
  },
];

const menuItems = [
  { label: 'Dashboard', route: 'LandingPage', path: ['/'] },
  { label: 'My Learning', route: 'MyLearning', path: ['/my-learning','/learninginsights'] },
  { label: 'Support', route: 'HelpCenter', path: ['/help-center', '/user-profile'] }
];

export default class Header extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      isOpen: false,
      anchorEl: null
    };
    Header.contextType = MyContext;
  }
  async componentDidMount () {
    const routeName = this.props.navigation.state.routeName
    if(routeName=== '/sign-in/contact-us'){
      this.contactPage()
    }
  }

  getUrl = () => {
    return this.props.navigation.getPath()?.toLowerCase();
  }

  contactPage = () => {
    this.setState({ isOpen: true });
  }

  onClose = () => {
    this.setState({ isOpen: false });
  }

  privacyPage = () => {
    this.props.navigation.navigate("PrivacyPolicyUserPart");
  }

  termPage = () => {
    this.props.navigation.navigate("TermsConditionsUserPart");
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onSettingClick = () => {
    this.props.navigation.navigate("UserProfile");
  }

  onLogout = () => {
    const { setAccessPermission } = this.context as any;
    setAccessPermission?.(false);
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("p");
    localStorage.removeItem("u");
    this.props.navigation.replace("EmailAccountRegistration");
  }

  signIn = () => { 
    this.props.navigation.navigate('EmailAccountRegistration') 
  } 

  renderContactPage = () => {
    return (
      <Contactus
        id=""
        isUserLogin={false}
        navigation={this.props.navigation}
        onClose={this.onClose}
      />
    );
  }

  renderWebHeader = (islogin: boolean | undefined) => {
    return (
      <Wrapper islogin={islogin} supportpagename={this.props.supportpagename}>
        {this.state.isOpen && this.renderContactPage()}
        <header>{this.renderTopBar(islogin)}</header>
        <main>
          {this.props.supportpagename
            ? <>{this.renderSideBar()}</>
            : <>{this.props.children}</>
          }
        </main>
        {!this.props.isFooterUnVisible && <footer>{this.renderFooter()}</footer>}
      </Wrapper>
    );
  }

  renderTopBar = (islogin: boolean | undefined) => {
    return (
      <>
        {!this.props.isLogoUnVisible &&
          <Box>
            <img src="/logo.svg" alt="logo" className="logo" />
          </Box>
        }
        {this.props.goBackLabel && 
  <Box className="goBackButton" onClick={this.props.goBackFunction}>
    <KeyboardArrowLeftIcon/>
    <span style={{cursor:"pointer"}}>{this.props.goBackLabel}</span>
  </Box>
}
        {islogin && (
          <Box className="parent">
            {!this.props.isMenuItemsUnVisible &&
              <Box>
                {menuItems.map((item) => (
                  <HeaderButton
                    key={item.route}
                    isActive={item.path.includes(this.getUrl())}
                    onClick={() => !item.path.includes(this.getUrl()) && this.props.navigation.navigate(item.route)}
                  >
                    {item.label}
                  </HeaderButton>
                ))}
              </Box>
            }
          </Box>
        )}
        <Box>
          {islogin ? (
            <Box display="flex" alignItems="center">
              <button
                className="user-name"
                onClick={(event) => {
                  this.setState({ anchorEl: event.currentTarget });
                }}
              >
                <p>{localStorage.getItem("u")}</p>
                <KeyboardArrowDownIcon />
              </button>
              <CustomMenu
                disableScrollLock={true}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleClose}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      padding: '0',
                      minWidth: '253px',
                      borderRadius: "8px",
                      border: "1px solid #F0F0F0",
                      boxShadow: "0px 8px 32px 0px #0000000F",
                    }
                  }
                }}
              >
                <MenuItem className="menu-item"  onClick={this.onSettingClick}>Settings</MenuItem>
                <MenuItem className="menu-item" sx={{ color: "#DC2626" }} onClick={this.onLogout}>
                  Log out
                </MenuItem>
              </CustomMenu>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', gap: '10px' }}>  
            <CustomButton onClick={this.contactPage}>Contact us</CustomButton>
            {!this.props.isloginscreen &&
            <CustomButton onClick={this.signIn}> sign in</CustomButton> 
            }    
            </Box> 
          )}
        </Box>
      </>
    );
  }

  renderSideBar = () => {
    return (
      <Box display='flex'>
        <Drawer
          className="sidebar-drawer"
          sx={{
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 314,
              marginTop: "70px",
              borderRight: "none",
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Box p="24px 10px">
            <Typography className="title">Support</Typography>
            <List>
              {items.map((item) => (
                <ListItem
                  key={item.key}
                  alignItems="flex-start"
                  className={clsx({ active: this.props.supportpagename === item.key })}
                  onClick={() => this.props.supportpagename !== item.key && this.props.navigation.navigate(item.navigateTo)}
                >
                  <ListItemAvatar>
                    {item.Icon}
                  </ListItemAvatar>
                  <ListItemText primary={item.primary} secondary={item.secondary} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box ml="314px" width="100%">
          {this.props.children}
        </Box>
      </Box>
    );
  }

  getFontFamilyForPage = (path: string) => {
    return this.getUrl() === path ? 'Heebo-Black' : 'Heebo-Regular';
  };

  renderFooter = () => {
    const links = [
      { path: '/terms-and-condition', label: 'Terms & Conditions', onClick: this.termPage },
      { path: '/privacy-policy', label: 'Privacy Policy', onClick: this.privacyPage },
    ];
    return (
      <>
        <Box className="link-wrapper">
          {links.map((link, index) => (
            <Box
              key={index}
              component="button"
              sx={{ fontFamily: this.getFontFamilyForPage(link.path) }}
              onClick={link.onClick}
              className={index === 0 ? 'first' : ''}
            >
              {link.label}
            </Box>
          ))}
        </Box>
        <Box className="rights">© {new Date().getFullYear()} Thinkspike. All Rights Reserved</Box>
      </>
    );
  }

  render() {
    const islogin = this.props.islogin;
    return (
      <>
        {islogin ? (
          <Hidden mdDown>{this.renderWebHeader(islogin)}</Hidden>
        ) : (
          <Box>{this.renderWebHeader(islogin)}</Box>
        )}
        {islogin && (
          <Hidden mdUp>
            <MobileHeader
              children={this.props.children}
              isHeader={this.props.isHeader}
              navigation={this.props.navigation}
            />
          </Hidden>
        )}
      </>
    );
  }
}

// Customizable Area Start
const Wrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "islogin" })<IWrapper>(({ theme, islogin, supportpagename }) => ({
  flexDirection: "column",
  display: "flex",
  minHeight: "100vh",
  width: "100%",
  "& header": {
    top: "0px",
    height: "70px",
    display: "flex",
    zIndex: "99",
    minHeight: "70px",
    position: "sticky",
    alignItems: "center",
    justifyContent: "space-between",
    padding: islogin ? "0px 30px" : "0px 80px",
    backgroundColor: supportpagename ? "#FAFAFA" : "#fff",
    borderBottom: supportpagename ? "none" : `1px solid ${islogin ? "#E7E5E4" : "#D6D3D1"}`,
    [theme.breakpoints.down('sm')]: {
      padding: "0px 20px",
      "& .logo": {
        height: "20px"
      }
    },
    "& .builder-logo": {
      height: "18px",
      marginRight: "15px"
    },
    "& .parent": {
      [theme.breakpoints.down('md')]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }
    },
    "& .goBackButton": {
      display: "flex",
      alignItems: "center",
      gap: "8px"
    }
  },
  "& .user-name": {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Heebo-Regular",
    padding: "8px 0px 8px 15px",
    background: "#fff",
    borderRadius: "4px",
    border: "none",
    "& p": {
      margin: "0px",
      width: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
        width: '70px',
      },
    }
  },
  "& main": {
    flex: 1,
    "& .sidebar-drawer": {
      "& .title": {
        marginLeft: "10px",
        fontSize: "20px",
        fontFamily: "Heebo-Bold",
        color: "#64748B"
      },
      "& .MuiListItem-root": {
        cursor: "pointer",
        margin: "3px 0px",
        padding: "10px",
        marginBottom: "10px",
        "& svg": {
          marginRight: "15px"
        },
        "&:hover, &.active": {
          background: "#F5F5F4",
          borderRadius: "8px"
        }
      },
      "& .MuiListItemAvatar-root": {
        minWidth: "20px",
        marginTop: "11px",
      },
      "& .MuiListItemText-primary": {
        fontSize: "18px",
        color: "#0F172A",
        marginBottom: "8px",
        fontFamily: "Heebo-Bold",
      },
      "& .MuiListItemText-secondary": {
        fontSize: "14px",
        fontFamily: "Heebo-Regular",
        color: "#334155"
      },
    }
  },
  "& footer": {
    color: "#fff",
    zIndex: "999999",
    padding: "20px 33px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#1C1C1C",
    [theme.breakpoints.down('sm')]: {
      padding: "20px 20px",
    },
    "& .link-wrapper": {
      [theme.breakpoints.down('sm')]: {
        width: "50vw",
        display: "flex",
        flexDirection: "column",
      }
    },
    "& button": {
      color: "#fff",
      border: "none",
      margin: "0px",
      fontSize: "14px",
      cursor: "pointer",
      padding: "0px",
      textAlign: "left",
      width: "max-content",
      backgroundColor: "transparent",
      [theme.breakpoints.down('sm')]: {
        fontSize: "12px",
        lineHeight: "22px",
        '&::before': {
          marginRight: '8px',
          content: '"•"',
        },
      },
    },
    "& .first": {
      marginRight: "30px",
      [theme.breakpoints.down('sm')]: {
        marginRight: "0px",
      }
    },
    "& .rights": {
      fontSize: "16px",
      textAlign: "end",
      [theme.breakpoints.down('sm')]: {
        width: "50vw",
        fontSize: "12px",
        lineHeight: "22px"
      },
    }
  }
}));

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Heebo-Regular",
  color: "white !important",
  fontSize: "16px !important",
  padding: "6px 35px !important",
  backgroundColor: "#1C1C1C",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "#1C1C1C",
  },
  [theme.breakpoints.down('sm')]: {
    padding: "6px 15px !important"
  },
  "&.Mui-disabled": {
    backgroundColor: "rgb(83 78 78)",
  }
}));

const HeaderButton = styled(CustomButton, { shouldForwardProp: (prop) => prop !== "isActive" })<{ isActive?: boolean; }>(({ theme, isActive }) => ({
  textTransform: "none",
  fontFamily: `${isActive ? "Heebo-Bold" : "Heebo-Regular"} !important`,
  color: `${isActive ? "while" : "black"} !important`,
  fontSize: "16px !important",
  padding: "6px 16px !important",
  backgroundColor: `${isActive ? "#1C1C1C" : "white"} !important`,
  borderRadius: "4px !important",
  margin: "0px 5px",
  whiteSpace: "noWrap",
  "&:hover": {
    color: "white !important",
    backgroundColor: "#1C1C1C !important",
  },
  [theme.breakpoints.down('sm')]: {
    padding: "6px 15px !important"
  },
}));

const CustomMenu = styled(Menu)({
  "& .menu-item": {
    fontSize: "14px",
    margin: "0px 8px",
    borderRadius: "4px",
    fontFamily: "Heebo-Regular",
  },
});
// Customizable Area End