import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  InputBase,
  IconButton,
  InputAdornment,
  CircularProgress,
  ListItemText,
  ListItem,
  List,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { styled } from "@mui/material/styles";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import PasswordResetController from "./PasswordResetController.web";
import Header from "../../../components/src/Header.web";
// Customizable Area End

export default class PasswordReset extends PasswordResetController {
  // Customizable Area Start
  resetPasswordSchema = () => {
    return Yup.object().shape({
      password: Yup.string()
        .min(8, '*New password must be at least 8 characters long')
        .matches(/[a-z]/, '*New password must contain at least one lowercase letter')
        .matches(/[A-Z]/, '*New password must contain at least one uppercase letter')
        .matches(/\d/, '*New password must contain at least one digit')
        .matches(/^(?=.*[!@#$%^&*(),.?":{}|<>=]).+$/, '*New password must contain at least one special character')
        .required('*New password is required'),
      confirmPassword: Yup.string()
        .required("*Confirm password is required")
        .oneOf([Yup.ref("password"), null], "*Password do not match. Please re-enter password."),
    });
  }

  renderPasswordRules = () => {
    const valLength = this.state.password.length >= 8;
    const valLowerCase = /[a-z]/.test(this.state.password);
    const valUpperCase = /[A-Z]/.test(this.state.password);
    const valDigit = /\d/.test(this.state.password);
    const valSpecialChar = /[!@#$%^&*(),.?":{}|<>=]/.test(this.state.password);
    return (
      <List sx={{ padding: '0px' }}>
        <Typography sx={{fontSize:'12px',color:'#64748B'}} fontWeight={500} >Password must include:</Typography>
        <ListItem sx={webStyles.listItemContainer}>
          {valLength ? <CheckCircleIcon style={webStyles.greenIcon} /> : <CloseIcon style={webStyles.redIcon} />}
          <ListItemText sx={webStyles.listItemText} primary={'At least 8 characters'}
            primaryTypographyProps={{ color: valLength ? 'green' : 'rgb(157, 157, 157)', fontSize: '12px' }}
          />
        </ListItem>

        <ListItem sx={webStyles.listItemContainer}>
          {valLowerCase ? <CheckCircleIcon style={webStyles.greenIcon}  /> : <CloseIcon style={webStyles.redIcon} />}
          <ListItemText sx={webStyles.listItemText} primary={' At least one lowercase letter'}
            primaryTypographyProps={{ color: valLowerCase ? 'green' : 'rgb(157, 157, 157)', fontSize: '12px' }}
          />
        </ListItem>

        <ListItem sx={webStyles.listItemContainer}>
          {valUpperCase ? <CheckCircleIcon style={webStyles.greenIcon}  /> : <CloseIcon style={webStyles.redIcon}/>}
          <ListItemText sx={webStyles.listItemText} primary={' At least one uppercase letter'}
            primaryTypographyProps={{ color: valUpperCase ? 'green' : 'rgb(157, 157, 157)', fontSize: '12px' }}
          />
        </ListItem>

        <ListItem sx={webStyles.listItemContainer}>
          {valDigit ? <CheckCircleIcon style={webStyles.greenIcon}  /> : <CloseIcon style={webStyles.redIcon}/>}
          <ListItemText sx={webStyles.listItemText} primary={' At least one digit'}
            primaryTypographyProps={{ color: valDigit ? 'green' : 'rgb(157, 157, 157)', fontSize: '12px' }}
          />
        </ListItem>

        <ListItem sx={webStyles.listItemContainer}>
          {valSpecialChar ? <CheckCircleIcon style={webStyles.greenIcon} /> : <CloseIcon style={webStyles.redIcon} />}
          <ListItemText sx={webStyles.listItemText} primary={' At least one special character'}
            primaryTypographyProps={{ color: valSpecialChar ? 'green' : 'rgb(157, 157, 157)', fontSize: '12px' }}
          />
        </ListItem>
      </List>
    )
  }

  renderPasswordReset = () => {
    return (
        <Box height='100%'>
      <Box display="flex" height='100%' alignItems="center" justifyContent="center">
          <Box className="form">
            <Box textAlign="center">
              {this.state.token
                ? <Box fontSize="24px" className="title">Reset password to continue</Box>
                : <Box className="title" textAlign="left" fontSize="18px">
                  <div>For your security, please change the temporary password provided to you.</div>
                  <div>Once updated, you'll be ready to access your account.</div>
                </Box>
              }
            </Box>
            <Formik
              data-test-id="formik"
              initialValues={{
                password: this.state.password,
                confirmPassword: this.state.confirmPassword
              }}
              validationSchema={this.resetPasswordSchema}
              onSubmit={(values) => {
                this.resetPasswordApi();
              }}
            >
              {({ errors, touched, setFieldValue, handleSubmit }) => (
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  autoComplete="off"
                  className="form"
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Box mb="20px">
                        <label>New password</label>
                        <CustomInput
                          fullWidth
                          data-test-id="password"
                          placeholder="Enter new password"
                          type={this.state.isPasswordShow ? "text" : "password"}
                          value={this.state.password}
                          onChange={(event) => {
                            setFieldValue("password", event.target.value.trim());
                            this.setState({ password: event.target.value.trim() })
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id="visibility"
                                onClick={() => {
                                  this.onShowPassword();
                                }}
                                edge="end"
                                style={{ color: "#A2A2A2" }}
                              >
                                {this.state.isPasswordShow ? (
                                  <VisibilityOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {touched.password && errors.password &&
                          <div className="text-danger">{errors.password}</div>
                        }
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box mb="10px">
                        <label>Confirm New password</label>
                        <CustomInput
                          fullWidth
                          data-test-id="confirm-password"
                          placeholder="Enter confirm password"
                          type={this.state.isConfirmPasswordShow ? "text" : "password"}
                          value={this.state.confirmPassword}
                          onChange={(event) => {
                            setFieldValue("confirmPassword", event.target.value.trim());
                            this.setState({ confirmPassword: event.target.value.trim() })
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id="visibility"
                                onClick={() => {
                                  this.onShowConfirmPassword();
                                }}
                                edge="end"
                                style={{ color: "#A2A2A2" }}
                              >
                                {this.state.isConfirmPasswordShow ? (
                                  <VisibilityOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {touched.confirmPassword && errors.confirmPassword &&
                          <div className="text-danger">{errors.confirmPassword}</div>
                        }
                      </Box>
                    </Grid>
                     {this.renderPasswordRules()}
                    <Grid item xs={12}>
                      <CustomButton
                        fullWidth
                        data-test-id="submit"
                        disabled={this.state.loading}
                        type="submit"
                        sx={{
                          height: "45px",
                          marginTop: "15px",
                        }}
                      >
                        {this.state.loading && <CircularProgress size={15} sx={{ color: "#fff", marginRight: "10px", marginBottom: "2px" }} />}
                        Change Password
                      </CustomButton>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    );
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Wrapper data-test-id="wrapper">
        {!this.state.isMobile ? (
          <Header navigation={this.props.navigation}>
          {this.renderPasswordReset()}
        </Header>
        ) : (
          <Container>
            {this.renderPasswordReset()}
          </Container>
        )}
      </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: "100vh",
  width: "100%",
});

const webStyles = {
  listItemContainer: {
    padding: '0px'
  },
  listItemText: {
    fontSize: '10px',
    marginLeft: '6px'
  },
  greenIcon: {
    color: 'green',
    fontSize: 'small'
  },
  redIcon: {
    color: 'red',
    fontSize: 'small'
  }
}

const Wrapper = styled(Box)(({ theme }) => ({
  "& .form": {
    maxWidth: "440px",
    margin: "20px 0px",
    marginBottom: "8px",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
      padding: "0px 10px"
    },
    "& .logo": {
      textAlign: "center"
    },
    "& .title": {
      margin: "17px 0px 35px",
      fontFamily: 'Heebo-Light',
      color: "181818",
      "& span": {
        fontFamily: 'Heebo-Light'
      }
    },
    "& label": {
      color: "#9D9D9D",
      fontFamily: "Heebo-Medium",
    },
    "& .remember": {
      color: "#9D9D9D",
      fontSize: "14px",
    },
    "& .forgot": {
      cursor: "pointer",
      fontSize: "14px"
    }
  }
}));

const CustomInput = styled(InputBase)({
  border: "1px solid #9D9D9D",
  backgroundColor: "#fff",
  borderRadius: "4px",
  marginTop: "3px",
  "& input": {
    padding: "11px 14px",
    borderRadius: "4px",
  },
  "& button": {
    marginRight: "4px",
  }
});

const CustomButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontFamily: "Heebo-Regular",
  color: "white !important",
  fontSize: "16px !important",
  padding: "6px 35px !important",
  backgroundColor: "#1C1C1C",
  borderRadius: "4px !important",
  "&:hover": {
    backgroundColor: "#1C1C1C",
  },
  [theme.breakpoints.down('sm')]: {
    padding: "6px 15px !important"
  },
  "&.Mui-disabled": {
    backgroundColor: "rgb(83 78 78)",
  }
}));
// Customizable Area End