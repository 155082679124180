import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { MyContext } from '../../../components/src/NavigationRouteWrapper/Web';
import { apiCall } from "../../../components/src/ApiCall.web";
import Papa from "papaparse"
import { SelectChangeEvent } from '@mui/material/Select';
import DOMPurify from 'dompurify';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs'

export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}

export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}

export interface IUserProfile {
  first_name: string | null;
  last_name: string | null;
  email: string | null;
  job_title: string | null;
  company_name: string | null;
}

export interface IErrors {
  profile: string;
  token: string;
}
export interface IResponse {
  message: string;
  errors: IErrors[];
  status: number;
  error: string;
  csv_url?: string;
}

interface CSVData {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  fullPhoneNumber: string;
  jobTitle: string;
  companyName: string;
  courseName?: string;
  roleType:string;
}

interface NewUserData {
  firstName: string,
  lastName: string,
  jobTitle: string,
  email: string,
}

interface EditUserData {
  firstName: string,
  lastName: string,
  jobTitle: string,
  email: string,
  id: string
}

interface BulkFile {
  id: number;
  date: string;
  url: string;
}

interface Attributes {
  bulk_file: BulkFile;
}

interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

interface ExampleFile {
  data: Data;
}

interface CompanyLogo {
  id: number;
  date: string;
  url: string;
}

interface Account {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  job_title: string;
  status: string;
  packages_courses_count?:string;
  created_at: string;
  updated_at: string;
}

interface ClientAttributes {
  company_name: string;
  contact_name: string;
  admin_user: string;
  contact_email: string;
  contact_job_title: string;
  contact_phone: string;
  status: string;
  created_at: string;
  updated_at: string;
  company_logo: CompanyLogo;
  accounts_count: number;
  accounts: Account[];
}

interface ClientData {
  id: string;
  type: string;
  attributes: ClientAttributes;
}

interface ClientResponse {
  data: ClientData;
}



// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  first_name: string;
  lastName: string;
  fullName: string;
  email: string;
  phoneNumber: string;
  city: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];

  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  loading: boolean;
  openDialog: string | null;
  userDetail: IUserProfile | null;
  file: File | null,
  csvData: CSVData[],
  csvModal: boolean,
  bulkAssignCourseModal:boolean,
  csvDataUpload: boolean
  isDoneClicked: boolean,
  isValid: boolean,
  anchorEl: any;
  anchorElSort: any;
  bulkAnchorEl:any;
  currentPage: number
  totalPages: number
  userData: Account[];
  allUserdata: Account[];
  addNewUserModal: boolean
  clientInfo: ClientAttributes | null
  selectedClient: {
    clientId: string
    clientName: string
  }
  viewUserModal: boolean;
  deleteUserModal: boolean
  newUserData: NewUserData
  currentSortKey: string
  userOption: boolean
  currentUser: Account | null
  editUserModal: boolean,
  userAnchorEl: any
  editUser: EditUserData
  deleteArr: string[]
  searchQuery: string | null,
  clientName: string
  emailError: string;
  editEmailError: string;
  learningPathData:any;
  originalLearningPathData:any;
  selectedLearningPathId:number;
  searchCourseQuery:string,
  selectedCourses:any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ClientManagementController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;

  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";

  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  userDetailApiCallId: string = "";
  resetPasswordApiCallId: string = "";
  exportApiCallId: string = "";
  csvFileUploadApiCallId: string = ""
  getUserIndexApiCallId: string = ""
  getClientNamesApiCallId: string = ""
  addNewUserDoneApiCallId: string = ""
  exampleFileApiCallId: string = ""
  editUserDoneApiCallId: string = ""
  deleteUserDoneApiCallId: string = ""
  bulkdeleteUserDoneApiCallId: string = ""
  searchQueryApiCallId: string = ""
  getLearningPathByClientApiCallId:string =""
  bulkAssignCoursesToUsersApiCallId:string=""

  sortKeys = [
    { key: 'nameAsc', label: 'Name (A-Z)' },
    { key: 'nameDesc', label: 'Name (Z-A)' },
    { key: 'newest', label: 'Newest to Oldest' },
    { key: 'oldest', label: 'Oldest to Newest' },
  ];

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      first_name: "",
      lastName: "",
      fullName: "",
      email: "",
      city: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      phoneNumber: "",
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",

      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      loading: false,
      openDialog: null,
      userDetail: null,
      file: null,
      csvData: [],
      csvModal: false,
      bulkAssignCourseModal:false,
      csvDataUpload: false,
      isDoneClicked: false,
      isValid: true,
      anchorEl: null,
      anchorElSort: null,
      bulkAnchorEl:null,
      totalPages: 0,
      currentPage: 1,
      userData: [],
      allUserdata: [],
      addNewUserModal: false,
      clientInfo: null,
      selectedClient: {
        clientId: "",
        clientName: ""
      },
      deleteUserModal: false,
      viewUserModal: false,
      newUserData: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
      },
      currentSortKey: "Relevance",
      userOption: false,
      currentUser: null,
      editUserModal: false,
      userAnchorEl: null,
      editUser: {
        firstName: "",
        lastName: "",
        jobTitle: "",
        email: "",
        id: ""
      },
      deleteArr: [],
      searchQuery: null,
      clientName: "",
      emailError: "",
      learningPathData:[],
      editEmailError: "",
      selectedLearningPathId:0,
      selectedCourses:[],
      searchCourseQuery:'',
      originalLearningPathData:[],
      // Customizable Area End
    };
    // Customizable Area Start
    ClientManagementController.contextType = MyContext;
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getUserIndex();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    this.handleResponse(apiRequestCallId, responseJson);
    this.handleApiResponse(apiRequestCallId, responseJson)
    this.handleUserManagementResponse(message)

    // Customizable Area End
  }

  // Customizable Area Start
  handleResponse = (apiRequestCallId: string, responseJson: IResponse) => {
    if (apiRequestCallId === this.exportApiCallId) {
      if(responseJson.csv_url) {
        this.downloadCsvFile(responseJson);
      } else {
        toast.error(responseJson.error);
      }
      this.setState({ loading: false });
    }
    else if (apiRequestCallId === this.searchQueryApiCallId) {
      this.handleSearchResponse(responseJson)
    }
  }

  handleApiResponse = (apiRequestCallId: string, responseJson: ClientResponse) => {
    if (apiRequestCallId === this.getUserIndexApiCallId) {
      this.handleGetUserIndexResponse(responseJson)
    }
  }

  handleUserManagementResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.exampleFileApiCallId:
        this.downloadTemplateFile(responseJson as ExampleFile)
        break;

      case this.addNewUserDoneApiCallId:
        if(responseJson.errors && Array.isArray(responseJson.errors) && responseJson.errors[0].account){
          this.setState({ emailError: responseJson.errors[0].account[0], loading: false });
        } else if (responseJson.errors && Array.isArray(responseJson.errors) && responseJson.errors[0]) {
          toast.error(responseJson.errors?.[0]);
          this.setState({ loading: false });
        } else {
          toast.success(responseJson.message);
          this.handleAddNewUserModalClose();
          this.handleIndex();
        }
        break;
      case this.deleteUserDoneApiCallId:
        this.handleIndex();
        toast.success(responseJson.message);
        break;
      case this.editUserDoneApiCallId:
        if(responseJson.errors && Array.isArray(responseJson.errors) && responseJson.errors[0].account){
          this.setState({ editEmailError: responseJson.errors[0].account[0], loading: false });
        } else {
          toast.success(responseJson.message);
          this.handleEditUserModalClose();
          this.handleIndex();
        }
        break;

      case this.bulkdeleteUserDoneApiCallId:
        if(responseJson)
        toast.success(responseJson.message)
        this.handleIndex()
        break;
      case this.getLearningPathByClientApiCallId:
       return this.handleGetLearningPathByClient(responseJson)
       case this.bulkAssignCoursesToUsersApiCallId:
        return this.handleBulkAssignCoursesResponse(responseJson)
      case this.csvFileUploadApiCallId:
        return this.handleBulkUploadUserResp(responseJson)
    }
  }
  handleBulkUploadUserResp = (responseJson: any) => {
    if (responseJson.message) {
      toast.success(responseJson.message);
    } else {
      toast.error(responseJson.errors || responseJson.error||'Something went wrong')
    }
    this.setState({loading:false,csvModal:false,csvData:[],isDoneClicked:false,file:null})
    this.getUserIndex()
  }
  handleBulkAssignCoursesResponse=(res:any)=>{
    if(res.error){
      toast.error(res.error)
    }else{
      toast.success("Bulk assigned courses to users success!")
    }
    this.handleModalClose()

  }
  handleGetLearningPathByClient=(res:any)=>{
    if(res){
      this.setState({learningPathData:res?.learning_paths,originalLearningPathData:res?.learning_paths})
    }
  }

  handleBackClient = () => {
    this.props.navigation.navigate('ManageClient')
  }

  handleClientClick = () => {
    this.props.navigation.navigate('Clients');
  }

  handleIndex = () => {
    this.getUserIndex()
  }


  handleDragOver = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
  };

  handleDrop = (event: React.DragEvent<HTMLElement>) => {
    event.preventDefault();
    if (event.dataTransfer.files) {
      const file = event.dataTransfer.files[0];
      this.handlCSVFile(file)
    }
  };

  handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event?.target?.files) {
      const file = event.target.files[0];
      this.handlCSVFile(file)
    }
  };

  handleDoneClick = () => {
    const isValid = this.validateCSVData(this.state.csvData);
    this.setState({
      isDoneClicked: true,
      isValid: isValid,
    });
  };

  validateCSVData = (data: CSVData[]): boolean => {
    const requiredFields: (keyof CSVData)[] = [
      'firstName',
      'lastName',
      'email',
      'jobTitle',
    ];

    for (const row of data) {
      for (const field of requiredFields) {
        if (!row[field]) {
          this.setState({ isValid: false });
          return false;
        }
      }
    }

    this.setState({ isValid: true });
    return true;
  };

  handlCSVFile = (file: File) => {
    const fileExtension = file?.name?.split(".")[1]
    if (fileExtension === "csv") {
      this.setState({ file }, () => {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
          const csv = event.target?.result;
          if (csv) {
            Papa.parse(csv.toString(), {
              header: true,
              skipEmptyLines: true,
              complete: (results) => {
                const data = this.transformDataFromCSV(results.data)
                  this.setState({ csvData: data }, () => {
                  this.setState({ csvDataUpload: true })
                });
              },
            });
          }
        };
        reader.readAsText(file);
      });
    }
  }

  transformDataFromCSV = (data: any[]): CSVData[] => {
    const mapHeader: { [key: string]: keyof CSVData } = {
      'lastName': 'lastName',
    'jobTitle': 'jobTitle',
    'firstName': 'firstName',
    'email': 'email',
    };

    return data.map(row => {
      const updatedRow: Partial<CSVData> = {};
      for (const header in mapHeader) {
        const field = mapHeader[header];
        updatedRow[field] = row[header];
      }
      return updatedRow as CSVData;
    });
  };

  handleCSVFileUpload = async () => {
    this.setState({ loading: true });
    let clientId = localStorage.getItem('SelectedClientID')
    const formattedData = {
      client_id:clientId,
      users: this.state.csvData.map((user) => ({
        first_name: user.firstName.trim(),
        last_name: user.lastName.trim(),
        email: user.email.trim(),
        job_title: user.jobTitle,
      }))
    };

    this.csvFileUploadApiCallId = await apiCall({
      method: "POST",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/clients/bulk_upload_for_clients`,
      body: formattedData
    });
  };


  exportCSV = async () => {
    this.setState({ loading: true });
    let clientId = localStorage.getItem('SelectedClientID');
    this.exportApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_account_groups/clients/${clientId}/export_users_for_client`,
    });
  };

  handleModalOpen = () => this.setState({ csvModal: true });

  handleModalClose = () => this.setState({
    csvModal: false,
    file: null,
    csvDataUpload: false,
    isDoneClicked: false,
    isValid: true,
    bulkAssignCourseModal:false,
    selectedCourses:[],
    searchCourseQuery:'',
    selectedLearningPathId:0
  });

  handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState({ currentPage: page, searchQuery: "" }, () => {
      this.getUserIndex()
    });
  };

  downloadCsvFile = (responseJson: any) => {
    const link = document.createElement("a");
    link.href = responseJson.csv_url;
    link.download = "filename.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  downloadExampleFile = async () => {
    this.exampleFileApiCallId = await apiCall({
      method: "GET",
      navigation: this.props.navigation,
      token: localStorage.getItem("token"),
      endPoint: `bx_block_admin/user_managements/download_template`,
    });
  }

  downloadTemplateFile = (responseJson: ExampleFile) => {
    const templateLink = document.createElement("a");
    templateLink.href = responseJson.data.attributes.bulk_file.url;
    templateLink.download = "example.csv";
    document.body.appendChild(templateLink);
    templateLink.click();
    document.body.removeChild(templateLink);
  }

  getUserIndex = async () => {
    this.setState({ loading: true });
    let clientId = localStorage.getItem('SelectedClientID')
    this.getUserIndexApiCallId = await apiCall({
      method: "GET",
      contentType: "application/json",
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_account_groups/clients/${clientId}`,
    });
  }

  handleGetUserIndexResponse = (responseJson: ClientResponse) => {
    const data = responseJson.data
    const clientAttributes = data.attributes
    this.setState({
      loading: false,
      userData: clientAttributes.accounts,
      allUserdata: clientAttributes.accounts,
      clientName: clientAttributes.company_name
    });
  }

  handleSearchResponse = (responesJson: any) => {
    let clientId = localStorage.getItem('SelectedClientID')
    if (responesJson.data && responesJson.data.attributes.client_id===clientId) {
      this.setState({
        userData: responesJson.data
      })
    }
    else {
      this.setState({ userData: [] })
    }
  }

  convertDate = (isoString: string) => {
    const date = new Date(isoString);

    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const formattedDate = `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;

    return formattedDate;
  }


  handleAddNewUserModalClose = () => {
    this.setState({
      addNewUserModal: false,
      emailError: "",
      newUserData: {
        firstName: "",
      lastName: "",
      jobTitle: "",
      email: "",
      }
    })
  }

  handleAddNewUserModalOpen = () => {
    this.setState({
      addNewUserModal: true,
    })
  }

  handleUserDataChange = (field: keyof NewUserData, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState((prevState) => ({
      newUserData: {
        ...prevState.newUserData,
        [field]: event.target.value,
      },
    }));
    if(field === "email"){
      this.setState({ emailError: "" });
    }
  };

  handleAddNewUserDoneClick = async () => {
    this.setState({ loading: true });
    const { newUserData } = this.state;
    const clientId = localStorage.getItem('SelectedClientID')
    const dataToSubmit = {
      data: {
        first_name: newUserData.firstName,
        last_name: newUserData.lastName,
        email: newUserData.email,
        job_title: newUserData.jobTitle,
        client_id: clientId,
      },
    };

    this.addNewUserDoneApiCallId = await apiCall({
      method: "POST",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      endPoint: `bx_block_admin/user_managements`,
      body: dataToSubmit,
    });
  };

  handleUserOptionClick = (event: React.MouseEvent<HTMLButtonElement>, user: Account) => {
    this.setState({
      userAnchorEl: event.currentTarget,
      currentUser: user,
      userOption: true,
      editUser: {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        jobTitle: user.job_title,
        id: user.id
      }
    });
  };

  handleViewUserModalClose = () => {
    this.setState({ viewUserModal: false })
  }

  handleViewUserModalOpen = () => {
    localStorage.setItem('selectedUserId', this.state.currentUser?.id.toString() || '');
    this.props.navigation.navigate('ClientUserManagement');
    this.handlePopoverClose();
  }

  handleDeleteUserModalClose = () => {
    this.setState({ deleteUserModal: false })
  }

  handleDeleteUserModalOpen = () => {
    this.setState({ deleteUserModal: true })
    this.handlePopoverClose();
  }

  handleEditUserModalClose = () => {
    this.setState({ editUserModal: false, editEmailError: "" })
  }

  handleEditUserModalOpen = () => {
    this.setState({ editUserModal: true })
    this.handlePopoverClose();
  }

  sortByDate = (list: Account[], isNewest = true) => {
    return list.sort((a, b) => {
      const dateA = new Date(a.created_at ?? 0).getTime();
      const dateB = new Date(b.created_at ?? 0).getTime();
      return isNewest ? dateB - dateA : dateA - dateB;
    });
  };

 handleSortOrderChange=(event:any)=>{
    this.setState({ currentSortKey: event.target.value },this.handleSort);
  }

  handleSort = () => {
    let sortedData = this.state.userData;
    sortedData.sort((first: any, second: any) => {
      const fullNameA = `${first.first_name} ${first.last_name}`.toLowerCase();
      const fullNameB = `${second.first_name} ${second.last_name}`.toLowerCase();
      switch (this.state.currentSortKey) {
        case "a-z":
          return fullNameA.localeCompare(fullNameB);
        case "z-a":
          return fullNameB.localeCompare(fullNameA);

        case "old-to-new":
          return dayjs(first.created_at).valueOf() - dayjs(second.created_at).valueOf();

        case "new-to-old":
          return dayjs(second.created_at).valueOf() - dayjs(first.created_at).valueOf();
        case 'low-to-high':
          return first.packages_courses_count - second.packages_courses_count;
        case 'high-to-low':
          return second.packages_courses_count - first.packages_courses_count;
        case "Relevance":
        default:
          return 0;
      }
    });
    this.setState({
      userData: sortedData,
      anchorElSort: null,
    });
  }

  handlePopoverClick = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorElSort: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorElSort: null, userAnchorEl: null ,bulkAnchorEl:null});
  };

  isSortPopoverOpen = () => Boolean(this.state.anchorElSort);

  handleCloseUserOption = () => Boolean(this.state.userAnchorEl);

  handleEditUserDoneClick = async () => {
    this.setState({ loading: true });
    const { editUser } = this.state;
    const clientId = localStorage.getItem('SelectedClientID')

    const dataToSubmit = {
      data: {
        first_name: editUser.firstName,
        last_name: editUser.lastName,
        email: editUser.email,
        job_title: editUser.jobTitle,
        client_id: clientId
      },
    };

    this.editUserDoneApiCallId = await apiCall({
      method: "PATCH",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/user_managements/${this.state.editUser.id}`,
      body: dataToSubmit,
    });

    this.handlePopoverClose()
  }

  handleInputChange = (field: keyof EditUserData, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState((prevState) => ({
      editUser: {
        ...prevState.editUser,
        [field]: event.target.value,
      },
    }));
    if(field === "email"){
      this.setState({ editEmailError: " "});
    }
  };

  handleEditChangeClient = (event: SelectChangeEvent<string>, child: React.ReactNode) => {
    this.setState((prevState) => ({
      editUser: {
        ...prevState.editUser,
        companyName: event.target.value
      }
    }));
  }

  handleDeleteUser = async (id: string) => {
    this.setState({ loading: true });
    this.deleteUserDoneApiCallId = await apiCall({
      method: "DELETE",
      navigation: this.props.navigation,
      contentType: 'application/json',
      token: localStorage.getItem("token"),
      endPoint: `/bx_block_admin/user_managements/bulk_delete_user`,
      body: {
        "ids": [id]
      }
    });
    this.handleDeleteUserModalClose()
    this.handlePopoverClose()
  }

  handleCheckboxChange = (userId: string) => {
    this.setState((prevState) => {
      const { deleteArr } = prevState;
      if (deleteArr.includes(userId)) {
        return { deleteArr: deleteArr.filter((id) => id !== userId) };
      } else {
        return { deleteArr: [...deleteArr, userId] };
      }
    });
  };

  isUserSelected = (userId: string) => {
    return this.state.deleteArr.includes(userId);
  };

  handleBulkDelete = async () => {
    this.bulkdeleteUserDoneApiCallId = await apiCall({
      method: "DELETE",
      contentType: 'application/json',
      navigation: this.props.navigation,
      endPoint: `/bx_block_admin/user_managements/bulk_delete_user`,
      token: localStorage.getItem("token"),
      body: {
        "ids": this.state.deleteArr
      }
    });

    this.setState({
      deleteArr: []
    }, () => {
      this.getUserIndex()
    })
  }

  handleDeselectAll = () => {
    this.setState({ deleteArr: [] });
  };

  handleSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const allUserdata = this.state.allUserdata;
    const searchQuery = event.target.value;
    const userData = allUserdata.filter((item) => {
      if ((item.first_name?.toLowerCase() + " " + item.last_name?.toLowerCase()).includes(searchQuery.toLowerCase())) {
        return true;
      }
    });
    this.setState({ userData, searchQuery });
  }
  debouncedSearch = debounce((searchQuery: string) => {
    const { originalLearningPathData } = this.state;

    if (searchQuery === '') {
      this.setState({ learningPathData: originalLearningPathData });
      return;
    }

    const updatedData = originalLearningPathData.map((path: any) => {
      const filteredCourses = path.data?.filter((course: any) =>
        path.learning_path_title.toLowerCase().includes(searchQuery) ||
        course.course.title.toLowerCase().includes(searchQuery) ||
        course.course.description.toLowerCase().includes(searchQuery)
      );

      if (filteredCourses?.length > 0) {
        return { ...path, course: filteredCourses };
      }
      return null;
    }).filter((path: any) => path !== null);

    this.setState({ learningPathData: updatedData });
  }, 300); 

  handleSearchCourseQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchQuery = event.target.value.toLowerCase();

    this.setState({ searchCourseQuery: searchQuery });
    this.debouncedSearch(searchQuery);
  };
  getLearningPathByClient = async()=>{
    let clientId = localStorage.getItem('SelectedClientID');
    this.getLearningPathByClientApiCallId= await apiCall({
      method: "GET",
      contentType: 'application/json',
      navigation: this.props.navigation,
      endPoint: `/bx_block_categories/learning_paths/courses_by_client?client_id=${clientId}`,
      token: localStorage.getItem("token"),
    })
  }
  handleExpandLearningPath =(learning_path_id:number)=>{
    if(this.state.selectedLearningPathId === learning_path_id){
      return this.setState({selectedLearningPathId:0})
    }
    this.setState({selectedLearningPathId:learning_path_id})}
  sanitizeData = (htmlString:string) => {
    const sanitizedDOM = DOMPurify.sanitize(htmlString, { RETURN_DOM: true });
    return sanitizedDOM.textContent || '';
  };
  getParentCheckboxState(courses:any) {
    const { selectedCourses,  } = this.state; 
    const allChildrenSelected = courses.every((course:any) => selectedCourses.includes(course.course.id));
    
    const someChildrenSelected = courses.some((course:any) => selectedCourses.includes(course.course.id));

    if (allChildrenSelected) {
        return 'checked'; 
    } else if (someChildrenSelected) {
        return 'indeterminate'; 
    } else {
        return 'unchecked'; 
    }
}
handleParentCheckboxChange = (event: any, courses: any) => {
  const { checked } = event.target;
  event.stopPropagation();

  this.setState((prevState: any) => {
    let updatedSelectedCourses = [...prevState.selectedCourses];

    const childCourseIds = courses.map((course: any) => course.course?.id);

    if (checked) {
      updatedSelectedCourses = [...new Set([...updatedSelectedCourses, ...childCourseIds])];
    } else {
      updatedSelectedCourses = updatedSelectedCourses.filter(
        (courseId: any) => !childCourseIds.includes(courseId)
      );
    }

    return { selectedCourses: updatedSelectedCourses };
  });
};
handleChooseCourse=(itemId:number)=>{
  const { selectedCourses } = this.state;
  const isCourseExists = selectedCourses.includes(itemId);

  const updatedSelectedCourses = isCourseExists
      ? selectedCourses.filter((course:any) => course !== itemId) 
      : [...selectedCourses, itemId]; 

  this.setState({ selectedCourses: updatedSelectedCourses });
}
handleAssignCoursesClick=async()=>{
    const clientId = localStorage.getItem('SelectedClientID');
    const body = {
    course_ids:this.state.selectedCourses,
    account_ids:this.state.deleteArr,
    client_ids:[clientId]
  }
 this.bulkAssignCoursesToUsersApiCallId = await apiCall({
  method: "POST",
  navigation: this.props.navigation,
  contentType: 'application/json',
  token: localStorage.getItem("token"),
  endPoint: `bx_block_account_groups/assign_courses`,
  body
});
}
handleBulkAssignCourseClick =()=>{
  this.setState({bulkAnchorEl:null})
  if(this.state.deleteArr.length === 0){
    return toast.error("Please select users first!")
  }
  this.setState({bulkAssignCourseModal:true,})
  this.getLearningPathByClient()
}
  // Customizable Area End
}
